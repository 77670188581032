/*
* Componente che crea una singola Tab
*/

import { OnDestroy, Component } from '@angular/core';
import { Subject } from 'rxjs';
import { BaseComponent } from './base-component.component';

/**
 * Classe che rappresenta le caratteristiche base dei componenti custom per gestione della validazioni
 */
@Component({
    template: '<span></span>'
})
export class BaseSubscriberComponent extends BaseComponent implements OnDestroy {

    // utility per sfruttare il takeUntil per fare l'unsubscribe delle subscriptions create nei components
    unsubscribe$ = new Subject();

    constructor() {
        super();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
