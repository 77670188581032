// Login dei welfare enel (test sierologici) senza sso

import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, Subscription } from 'rxjs';

import * as fromApp from '../../ngrx/app.reducers';
// Import dello State dell'autenticazione, che è quello che selezioneremo con lo slice dallo Store
import * as AuthActions from '../ngrx/auth.actions';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Lang } from "../../../cm2-commonclasses";

@Component({
    selector: 'app-welfare-enel-local-login',
    templateUrl: './welfare-enel-local-login.component.html',
    styleUrls: ['./welfare-enel-local-login.component.scss']
})
export class WelfareEnelLocalLoginComponent implements OnInit, OnDestroy {
    isFetchingLangs: boolean;
    langs: Lang[];
    result$;
    showLoader: boolean;
    subscriptionFormVisible: boolean;
    resetPasswordFormVisible: boolean;
    isManagingUser$: Subscription;
    userId: string;
    token: string;
    translatedModalText: string;
    translatedModalTitle: string;
    isEmailConfirmation: boolean;
    noCheckNames: any;
    newValidationToken: string;
    recoveryUserPassword: boolean;
    isDemoUsersPublicLogin: boolean;

    constructor(private store: Store<fromApp.AppState>,
        public translate: TranslateService,
        private router: Router) {
        // Sto in ascolto di quando il globalApplicationData cambia, così da sapere quando ci sono le lingue disponibili poiché, se non ci fossero, il pulsante per il login sarebbe disabilitato
        let availableLangs$: Observable<Lang[]> = this.store.select(fromApp.getAvailableLangs);
        let isFetchingLangs$: Observable<boolean> = this.store.select(fromApp.isFetchingLangs);
        const combinedSelectes$ = combineLatest(availableLangs$, isFetchingLangs$);
        this.result$ = combinedSelectes$.subscribe(
            ([langs, isFetchingLangs]) => {
                this.langs = langs;
                this.isFetchingLangs = isFetchingLangs;
            });
    }
    ngOnInit() {
        let welfareEnelUserId = sessionStorage.getItem('welfareEnelUserId');
        let welfareEnelEmail = sessionStorage.getItem('welfareEnelEmail');
        // Se ho lo userId (oppure il cid) nel session storage, posso subito eseguire il login
        if (welfareEnelUserId || welfareEnelEmail) {
            this.showLoader = true;
            this.store.dispatch(new AuthActions.DoLogin({ userId: welfareEnelUserId || welfareEnelEmail, password: null, isWelfareEnel: true }));
            setTimeout(() => {
                this.showLoader = false;
            }, 5000)
        }
    }
    ngOnDestroy() {
        this.result$.unsubscribe();
    }

    // Dal form passato come parametro, ricavo i dati immessi dall'utente per inserirli nel dispatch dell'action che tenterà il login
    onLogin(form: NgForm) {
        this.showLoader = true;
        const email = form.value.email;
        const password = form.value.password;
        this.store.dispatch(new AuthActions.DoLogin({ email: email, password: password, isWelfareEnel: true }));
        this.showLoader = false;
    }


    // Toggle del form per l'iscrizione
    toggleSubscriptionFormVisible() {
        this.subscriptionFormVisible = !this.subscriptionFormVisible;
    }

    // Toggle del form per il reset della password
    toggleResetPasswordFormVisible() {
        this.resetPasswordFormVisible = !this.resetPasswordFormVisible;
    }

    changeInput(x: any) {
        if (x.viewModel.trim().length || x.viewModel == "") {
            return false;
        } else {
            return true;
        }

    }

    checkRegExp(x: any) {
        if (x.viewModel || x.viewModel == "") {
            if (/^[A-Za-z\'\`\s\/^[a-z\u00E0-\u00FC]+$\]*$/g.test(x.viewModel.trim()) && x.viewModel.trim().length > 1) {
                return false;

            } else {
                return true;
            }
        } else {
            if (/^[A-Za-z\'\`\s\/^[a-z\u00E0-\u00FC]+$\]*$/g.test(x.trim()) && x.trim().length > 1) {
                return false;

            } else {
                return true;
            }
        }
    }

    isValidForm(form: NgForm) {
        if (form && form.value && form.value.newUserEmail && form.value.newUserFirstName && form.value.newUserLastName && form.value.allowUserPrivacy && form.value.newUserEmail.trim().length && form.value.newUserFirstName.trim().length && form.value.newUserLastName.trim().length
            && !this.checkRegExp(form.value.newUserFirstName) && !this.checkRegExp(form.value.newUserLastName)) {
            return false;
        } else {
            return true;
        }
    }
}