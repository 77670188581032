/*
* Componente relativo alla modale di conferma
*/

import { Component, OnInit, EventEmitter } from '@angular/core';
@Component({
    selector: 'public-users-privacy-modal',
    templateUrl: './public-users-privacy-modal.component.html'
})
export class PublicUsersModalComponent implements OnInit {
    constructor() { }
    ngOnInit() {
    }

    emitConfirmation(data) {
        if (data.confirmation instanceof EventEmitter) {
            data.confirmation.emit('CONFIRMED', data);
        }
        if (data.confirmAction && data.componentRef) {
            data.confirmAction(data.componentRef);
        }
    }
}
