/*
*  Componente che permette l'accesso all'utente tramite form
*/

import { Component, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'supplier-login-form',
    templateUrl: './supplier-login-form.component.html',
    styleUrls: ['./supplier-login-form.component.scss']
})
export class SupplierLoginFormComponent implements OnInit {
    @Output() onLogin: EventEmitter<NgForm> = new EventEmitter();

    constructor() { }

    ngOnInit() {
    }

    // Effettua il login
    login(form: NgForm) {
        this.onLogin.emit(form);
    }
}
