/*
*  Reducer che gestiscono e modificano lo State applicativo riguardante fundingSupplier
*/

import * as fundingSupplierActions from './fundingSupplier.actions';
import * as fromApp from '../../ngrx/app.reducers';

export interface FeatureState extends fromApp.AppState {
    initative: InitiativeState;
}

// Interfaccia dello Store
export interface InitiativeState {
    selectedInitiativeId: string;
    initiatives: any[];
    count: number;
    fromRecord: number;
    numRecords: number;
    page: number;
    isFetchingInitiatives: boolean;
    selectedInitiativeType: string;
    initiativesTextFilter: string;
    offerErrors: any;
    invoiceErrors: any;
    materialErrors: any;
    communicationErrors: any;
}

// Store iniziale
const initialState: InitiativeState = {
    selectedInitiativeId: null,
    initiatives: [], // lista delle iniziative
    count: 0, // contatore totale delle iniziative
    fromRecord: 0, // numero da cui recuperare gli elementi
    numRecords: 5, // elementi da recuperare
    page: 1, // pagina della tabella visualizzata
    isFetchingInitiatives: false, // verifica se sto eseguendo una chiamata per recuperare le iniziative
    selectedInitiativeType: null,
    initiativesTextFilter: null,
    offerErrors: 0, // errori homepage (OFFERTE)
    invoiceErrors: 0, // errori homepage (FATTURE)
    materialErrors: 0, // errori homepage (MATERIALI)
    communicationErrors: 0 // errori homepage (COMUNICAZIONI)
};

export function fundingSupplierReducer(state = initialState, action: fundingSupplierActions.InitiativeActions) {
    switch (action.type) {
        case fundingSupplierActions.SET_INITIATIVES:
            return {
                ...state,
                isFetchingInitiatives: false,
                initiatives: [...action.payload] // sovrascrivo le iniziative
            };
        case fundingSupplierActions.SET_PAGINATION:
            return {
                ...state,
                fromRecord: action.payload.fromRecord,
                numRecords: action.payload.numRecords
            };
        case fundingSupplierActions.GET_OFFER_INITIATIVES:
            return {
                ...state,
                isFetchingInitiatives: true
            };
        case fundingSupplierActions.GET_INVOICE_INITIATIVES:
            return {
                ...state,
                isFetchingInitiatives: true
            };
        case fundingSupplierActions.GET_MATERIAL_INITIATIVES:
            return {
                ...state,
                isFetchingInitiatives: true
            };
        case fundingSupplierActions.GET_COMMUNICATION_INITIATIVES:
            return {
                ...state,
                isFetchingInitiatives: true
            };
        case fundingSupplierActions.SET_INITIATIVES_TYPE:
            return {
                ...state,
                selectedInitiativeType: action.payload
            };
        case fundingSupplierActions.SET_INITIATIVES_TEXT_FILTER:
            return {
                ...state,
                initiativesTextFilter: action.payload
            };
        case fundingSupplierActions.SET_PAGE:
            return {
                ...state,
                page: action.payload
            };
        case fundingSupplierActions.SET_INITIATIVES_COUNT:
            return {
                ...state,
                count: action.payload,
            };
        case fundingSupplierActions.GET_ERRORS:
            return {
                ...state,
            };
        case fundingSupplierActions.SET_OFFER_ERRORS:
            return {
                ...state,
                offerErrors: action.payload
            };
        case fundingSupplierActions.SET_INVOICE_ERRORS:
            return {
                ...state,
                invoiceErrors: action.payload
            };
        case fundingSupplierActions.SET_MATERIAL_ERRORS:
            return {
                ...state,
                materialErrors: action.payload
            };
        case fundingSupplierActions.SET_COMMUNICATION_ERRORS:
            return {
                ...state,
                communicationErrors: action.payload
            };
        default:
            return state;
    }
}

// Export delle funzioni che tornano una parte dello Store applicativo
export const getInitiatives = (state: InitiativeState) => state.initiatives;
export const getInitiativesCount = (state: InitiativeState) => state.count;
export const getInitiativesFromRecord = (state: InitiativeState) => state.fromRecord;
export const getInitiativesNumRecords = (state: InitiativeState) => state.numRecords;
export const getInitiativesPage = (state: InitiativeState) => state.page;
export const isFetchingInitiatives = (state: InitiativeState) => state.isFetchingInitiatives;
export const getInitiativesFilterType = (state: InitiativeState) => (state && state.selectedInitiativeType) || null;
export const getInitiativesTextFilter = (state: InitiativeState) => (state && state.initiativesTextFilter) || null;
export const getOfferErrors = (state: InitiativeState) => state.offerErrors;
export const getInvoiceErrors = (state: InitiativeState) => state.invoiceErrors;
export const getMaterialErrors = (state: InitiativeState) => state.materialErrors;
export const getCommunicationErrors = (state: InitiativeState) => state.communicationErrors;