import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from '../../ngrx/app.reducers';
import { BaseSubscriberComponent } from '../../shared/components/base-subscriber.component';
import { Router } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { GlobalApplicationData } from 'src/app/shared/models/global-application-data.model';

@Component({
    selector: 'app-campagnaVaccinaleMogliano',
    templateUrl: './campagnaVaccinaleMogliano.component.html',
    styleUrls: ['./campagnaVaccinaleMogliano.component.scss']
})
export class CampagnaVaccinaleMoglianoComponent extends BaseSubscriberComponent {
    applicationContext: string;
    urlList = [
        { id: "7500ed01-0f23-42d5-95bb-3d9446663fa4", title: "26/11/2021" },
    ]

    constructor(private router: Router,
        private store: Store<fromApp.AppState>) {
        super();
        this.store.select(fromApp.getGlobalApplicationData).pipe(
            map((applicationData: GlobalApplicationData) => {
                if (applicationData && applicationData.applicationContext) {
                    this.applicationContext = applicationData.applicationContext + '#/takers/courseSubscription/';
                }
            }),
            take(1)
        ).subscribe();
    }

    openUrl(id) {
        window.open(this.applicationContext + id, '_blank');
    }
}