import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from '../../ngrx/app.reducers';
import { BaseSubscriberComponent } from '../../shared/components/base-subscriber.component';
import { Router } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { GlobalApplicationData } from 'src/app/shared/models/global-application-data.model';

@Component({
  selector: 'app-campagnaVaccinaleTorino',
  templateUrl: './campagnaVaccinaleTorino.component.html',
  styleUrls: ['./campagnaVaccinaleTorino.component.scss']
})
export class CampagnaVaccinaleTorinoComponent extends BaseSubscriberComponent {
  applicationContext: string;
  urlList = [
    { id: "bd1d32e4-d818-4d3e-8fbc-6abfd7ec70ce", title: "12/11/2021" }
  ]

  constructor(private router: Router,
    private store: Store<fromApp.AppState>) {
    super();
    this.store.select(fromApp.getGlobalApplicationData).pipe(
      map((applicationData: GlobalApplicationData) => {
        if (applicationData && applicationData.applicationContext) {
          this.applicationContext = applicationData.applicationContext + '#/takers/courseSubscription/';
        }
      }),
      take(1)
    ).subscribe();
  }

  openUrl(id) {
    window.open(this.applicationContext + id, '_blank');
  }
}