import { Router, ActivatedRoute } from "@angular/router";
import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subscription, combineLatest } from "rxjs";
import { Store } from "@ngrx/store";
import * as fromApp from '../../ngrx/app.reducers';
import * as CoreActions from '../../core/ngrx/core.actions';
import { UrlService } from "./url.service";

/*
*    Servizio che gestisce i vari redirect
*/
@Injectable()
export class RedirectService implements OnDestroy {
    isMainMenuSidebarOpened: boolean;
    isProfileMenuSidebarOpened: boolean;
    sidenavObservables$: Subscription;
    meetingUpUrl: any;
    constructor(private store: Store<fromApp.AppState>, private router: Router, private route: ActivatedRoute, private urlService: UrlService) {
        // Recupero dallo Store applicativo le variabili che mi indicano se le sidenav sono aperte
        let isMainMenuSidebarOpened$: Observable<boolean> = this.store.select(fromApp.isMainMenuSidebarOpened);
        // Recupero dallo Store applicativo le variabili che mi indicano se le sidenav sono aperte
        let isProfileMenuSidebarOpened$: Observable<boolean> = this.store.select(fromApp.isProfileMenuSidebarOpened);
        const combinedSelectes$ = combineLatest(isMainMenuSidebarOpened$, isProfileMenuSidebarOpened$);

        this.sidenavObservables$ = combinedSelectes$.subscribe(
            ([isMainMenuSidebarOpened, isProfileMenuSidebarOpened]) => {
                this.isMainMenuSidebarOpened = isMainMenuSidebarOpened;
                this.isProfileMenuSidebarOpened = isProfileMenuSidebarOpened;
            });
    }

    // Quando il componente è distrutto, eseguo anche l'unsubscribe
    ngOnDestroy() {
        this.sidenavObservables$.unsubscribe();
    }

    // Chiude la sidebar di sinistra contente il menu principale
    closeSidenavMainMenu() {
        this.store.dispatch(new CoreActions.CloseMainMenuSidebar());
    }

    // Chiude la sidebar di destra con il profilo utente
    closeSidenavProfileMenu() {
        this.store.dispatch(new CoreActions.CloseProfileMenuSidebar());
    }

    // Porta all'home page
    goToHome() {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        this.router.navigate(['/home']);
    }

    // Porta alla pagina per le notifiche della LearningUP
    goGoLearningUpNotifications() {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        this.router.navigate(['/learningUpNotifications']);
    }

    goToHomefundingSupplier() {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        this.router.navigate(['/fundingSupplier']);

    }
    // Porta all'home page dei meetings
    goToMeetings() {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        this.router.navigate(['/meetings']);
        //    this.meetingUpUrl = this.urlService.getMeetingUpApplicationUrl();
        //    window.location.href = this.meetingUpUrl.url + "/meetingup/#/meetings";
    }

    // Porta alla pagina di atterraggio del performance e manager
    goToLandingPerformanceManager() {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        this.router.navigate(['/landingPerformanceManager']);
    }

    // Porta alla pagina del performance management
    goToPerformanceManagement() {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        this.router.navigate(['/performanceManagement']);
    }

    goToPerformanceManagementMonitoringUsers(formationArea: string) {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        this.router.navigate(['/performanceManagement/monitoringPerformance', formationArea]);
    }

    goToHomeMonitoringPerformanceManagement() {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        this.router.navigate(['/performanceManagement/monitoringPerformance']);
    }

    // Porta all'home page della pagina dei suppliers
    goToHomeSupplier() {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        this.router.navigate(['/suppliers/supplierHome']);
    }

    goToOffers() {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        this.router.navigate(['/fundingSupplier/offers']);
    }

    goToInvoices() {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        this.router.navigate(['/fundingSupplier/invoices']);
    }

    goToWishlist() {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        this.router.navigate(['/wishlistManagement']);
    }


    goToMaterials() {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        this.router.navigate(['/fundingSupplier/materials']);
    }

    goToCommunications() {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        this.router.navigate(['/fundingSupplier/communications']);
    }

    // Porta alla creazione di un'iniziativa da nuovo Syllabus
    goToCreateInitiativeNewSyllabus() {
        this.router.navigate(['/initiatives/newInitiative/newSyllabus']);
    }

    // Porta alla pagina con il dettaglio di un ruolo
    goToRoleDetail(tagId: string) {
        this.router.navigate(['/dashboard/roleDetail', tagId]);
    }

    // Porta alla pagina con il dettaglio di una società
    goToSocietyDetail(societyName: string) {
        if (societyName && societyName.length) {
            this.router.navigate(['/dashboard/societyDetail', societyName]);
        }
    }

    // Porta alla pagina con il dettaglio di una persona
    goToDashboardUserDetails(userId: string, tagId: string) {
        if (userId && userId.length) {
            this.router.navigate(['/dashboard/userDetail', userId, tagId]);
        }
    }

    // Porta alla pagina con il dettaglio delle persone di un ruolo (dopo aver selezionato una specifica società)
    goToRoleUsers(societyName: string, roleId: string) {
        if (societyName && societyName.length && roleId && roleId.length) {
            this.router.navigate(['/dashboard/societyDetail', societyName, roleId]);
        }
    }

    // Porta alla pagina con il dettaglio degli utenti di un gruppo
    goToGroupUsers(tagId: string, groupId: string) {
        this.router.navigate(['/dashboard/roleDetail', tagId, groupId]);
    }

    // Porta ai ruoli nella dashboard
    goToDashboardRoles() {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        this.router.navigate(['/dashboard/roles']);
    }

    // Porta alla creazione di un'iniziativa da un syllabus esistente
    goToCreateInitiativeFromSyllabus() {
        this.router.navigate(['/initiatives/newInitiative/fromSyllabus']);
    }

    // Porta alla creazione di un'iniziativa smart
    goToCreateInitiativeSmart() {
        this.router.navigate(['/initiatives/newInitiative/smart']);
    }

    // Porta alla pagina con la lista delle iniziative
    goToInitiatives() {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }

        let queryParams: any = {
            selectedInitiativeType: 'ALL',
            inProgressTabOpened: true
        };

        this.router.navigate(['initiatives'], {
            // relativeTo: this.route,
            queryParams: queryParams
        });
    }
    // Porta alla pagina per l'edit dei gruppi e aziende
    goToEditGroups() {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        this.router.navigate(['/dashboard/editGroups']);
    }

    // Porta alla pagina dei gruppi formazione obbligatoria
    goToListGroups() {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        this.router.navigate(['/groups/listGroups']);
    }

    // Porta alla pagina con la lista dei Supplier (fornitori)
    goToSuppliers() {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        this.router.navigate(['/suppliers']);
    }

    // Porta alla home del portale per i Suppliers (fornitori)
    goToSuppliersHome() {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        this.router.navigate(['/suppliers-home']);
    }

    // Porta alla pagina con la lista dei template mail
    goToEmailTemplates() {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        this.router.navigate(['/templates']);
    }

    // Porta all'home page
    goToProfile() {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        this.router.navigate(['/profile']);
    }

    // Porta alla lista dei template mail
    goToTemplates() {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        this.router.navigate(['/templates']);
    }

    // Porta alla pagina con la lista dei Supplier (fornitori)
    goToLocations() {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        this.router.navigate(['/locations']);
    }

    // Porta alla pagina  GroupReportTag
    goToReportTag() {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        this.router.navigate(['/reportTags']);
    }
    // Porta alla pagina LinguisticManagemen
    goToLinguisticManagement() {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        this.router.navigate(['/linguisticManagement']);
    }
    // Porta alla pagina di Woman Insurance
    goToWomenInsuranceNetwork() {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        this.router.navigate(['/womenInsuranceNetwork']);
    }
    // Porta alla pagina di gestione formazione Welion
    goToWelion() {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        this.router.navigate(['/welion']);
    }
    goToListUsersAdmin() {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        this.router.navigate(['/usersAdmin']);
    }

    goToSpecialProjects() {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        this.router.navigate(['/specialProjects']);
    }

    // Vai all update ruoli formazione obbligatoria
    goToUpdateRoles() {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        this.router.navigate(['/dashboard/updateTitleRoles']);
    }

    goToNudgingReport() {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        this.router.navigate(['/nudging/nudgingReport']);
    }
    
    goToImportFinancingNotices() {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        this.router.navigate(['/importFinancingNotices']);
    }
}