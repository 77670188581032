/*
*  Reducer che gestiscono e modificano lo State applicativo riguardante i progetti speciali
*/

import * as fromApp from '../../ngrx/app.reducers';
import * as SpecialProjectsActions from "./specialProjects.actions";

// I template sono una feature dell'applicazione, infatti è un modulo caricato in maniera lazy (e quindi anche lo Store).
// Creo un'interfaccia, FeatureState, in cui mappo i template dello state Templatestate
// Estendo fromApp.AppState così per ereditare tutte le proprietà dello Store principale
export interface FeatureState extends fromApp.AppState {
    specialProject: SpecialProjectState
}

// Interfaccia dello Store che torna la lista dei template recuperata
export interface SpecialProjectState {
    specialProjects: any[];
    count: number;
    fromRecord: number;
    numRecords: number;
    page: number;
    isFetchingSpecialProjects: boolean;
    filterText: string;
}

// Store iniziale
const initialState: SpecialProjectState = {
    specialProjects: [], // lista dei progetti speciali
    count: 0, // contatore totale dei progetti speciali
    fromRecord: 0, // numero da cui recuperare gli elementi
    numRecords: 5, // elementi da recuperare
    page: 1, // pagina della tabella visualizzata
    isFetchingSpecialProjects: false, // verifica se sto eseguendo una chiamata per recuperare i progetti speciali
    filterText: ""
};

export function specialProjectsReducer(state = initialState, action: SpecialProjectsActions.SpecialProjectActions) {
    switch (action.type) {
        case SpecialProjectsActions.SET_SPECIAL_PROJECTS:

            return {
                ...state,
                isFetchingSpecialProjects: false,
                specialProjects: [...action.payload] // sovrascrivo i progetti speciali
            };
        case SpecialProjectsActions.SET_PAGINATION:
            return {
                ...state,
                fromRecord: action.payload.fromRecord,
                numRecords: action.payload.numRecords
            };
        case SpecialProjectsActions.GET_SPECIAL_PROJECTS:
            return {
                ...state,
                isFetchingSpecialProjects: true
            };
        case SpecialProjectsActions.SET_PAGE:
            return {
                ...state,
                page: action.payload
            };
        case SpecialProjectsActions.SET_TEXT_FILTER:
            return {
                ...state,
                filterText: action.payload
            };
        case SpecialProjectsActions.SET_SPECIAL_PROJECTS_COUNT:
            return {
                ...state,
                count: action.payload,
            };
        case (SpecialProjectsActions.ADD_SPECIAL_PROJECT):
            return {
                ...state,
                specialProjects: [action.payload, ...state.specialProjects]
            };
        case (SpecialProjectsActions.UPDATE_SPECIAL_PROJECT):
            // Torneremo sempre lo stato aggiornato, ma prima devo modificare il template. Quindi procedo recuperandolo
            const template = state.specialProjects[action.payload.updatedSpecialProject.itemId]; // template che vogliamo cambiare
            const updatedSpecialProject = {
                ...template,
                ...action.payload.updatedSpecialProject
            };
            const specialProjects = [...state.specialProjects]; // con lo spread operator tiro fuori tutti gli elementi dell'array state.specialProjects per aggiungerli immediatamente nel nuovo array. Così, li ho aggiunti in maniera immutabile
            specialProjects[action.payload.updatedSpecialProject.itemId] = updatedSpecialProject; // ora posso aggiornare il template
            return {
                ...state,
                specialProjects: specialProjects
            };
        case (SpecialProjectsActions.DELETE_SPECIAL_PROJECT):
            const oldTemplates = [...state.specialProjects];
            for (let i = 0, templatesLength = oldTemplates.length; i < templatesLength; i++) {
                let currentTemplate = oldTemplates[i];
                if (currentTemplate.itemId === action.payload) {
                    oldTemplates.splice(i, 1);
                    break;
                }
            }
            return {
                ...state,
                locations: oldTemplates
            };
        default:
            return state;
    }
}

// Export delle funzioni che tornano una parte dello Store applicativo
export const getSpecialProjects = (state: SpecialProjectState) => (state && state.specialProjects) || [];
export const getSpecialProjectsCount = (state: SpecialProjectState) => state.count;
export const getSpecialProjectsFromRecord = (state: SpecialProjectState) => (state && state.fromRecord) || 0;
export const getSpecialProjectsNumRecords = (state: SpecialProjectState) => (state && state.numRecords) || 5;
export const getSpecialProjectsPage = (state: SpecialProjectState) => (state && state.page) || 0;
export const isFetchingSpecialProjects = (state: SpecialProjectState) => state.isFetchingSpecialProjects;
export const getSpecialProjectFilterText = (state: SpecialProjectState) => state.filterText;
