<!-- Modale di conferma -->
<ngx-smart-modal #switchBrowserModal identifier="switchBrowserModal"
    customClass="nsm-dialog-animation-fade full-width nsm-centered confirm-modal privacy-modal-container"
    [dismissable]="false" [escapable]="false">
    <div class="switch-browser-modal">
        <div class="inner-modal-close">
            <svg-icon id="close-icon-modal" tabindex="0" src="assets/img/assets-IE-noworking-modal/icon-close.svg"
                tabindex="0"
                (click)="closeSwitchBrowserModal(switchBrowserModal.getData()); switchBrowserModal.close();">
            </svg-icon>
        </div>
        <div class="wrapper-gif">
            <img class="gif" src="assets/img/assets-IE-noworking-modal/loader.gif">
        </div>
        <h1>Cambia browser per una migliore esperienza</h1>
        <p>Internet Explorer non permette una completa fruizione della piattaforma.<br>
            Utilizza un browser diverso come:</p>
        <div class="icon-browser-wrapper">
            <div class="browser-icon-wrapper">
                <img src="assets/img/assets-IE-noworking-modal/chrome.png">
                <p>Chrome</p>
            </div>
            <div class="browser-icon-wrapper">
                <img src="assets/img/assets-IE-noworking-modal/edge.png">
                <p>Edge</p>
            </div>
        </div>
    </div>
</ngx-smart-modal>