<div class="content">
    <div fxLayout="column" class="theme-primary" fxFlexFill fxLayoutAlign="start start" role="tablist">
        <div class="space40" *ngIf="!isSupplier"></div>
        <!-- Intestazione -->
        <div fxLayout="row" *ngIf="!isSupplier" fxFlexFill role="tablist">
            <!-- Cambia il filtro per la visualizzazione delle iniziative (fra iniziative in sospeso e iniziative in corso) -->
            <div class="modal-body-container custom-select generali-input full-width">
                <div class="title-input">
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayout.sm="column" fxLayout.xs="column">
                        <div fxLayout="row" fxLayoutAlign="start center" class="view-for-label">
                            <p class="full-width p-for-h3" translate="generic.VIEW_FOR"></p>
                        </div>
                        <div class="basic-toggle-button" fxLayout="row" fxLayoutAlign="start center">
                            <!-- Iniziative in corso -->
                            <button (click)="onInitiativeStatusFilterChanged('IN_PROGRESS_INITIATIVES')"
                                [disabled]="initiativesLoading" class="btn-primary-1 large margin-left25"
                                [ngClass]="{active: showInitiativeFor == 'IN_PROGRESS_INITIATIVES'}" type="button"
                                role="tab">
                                <span translate="home.MY_IN_PROGRESS_INITIATIVES"></span>
                            </button>
                            <!-- Iniziative in sospeso -->
                            <button (click)="onInitiativeStatusFilterChanged('PENDING_INITIATIVES')"
                                [disabled]="initiativesLoading" class="btn-primary-1 large margin-left25"
                                [ngClass]="{active: showInitiativeFor == 'PENDING_INITIATIVES'}" type="button"
                                role="tab">
                                <span translate="home.MY_IN_PROJECT_INITIATIVES"></span>
                            </button>
                            <!-- Nuova iniziativa -->
                            <button (click)="onInitiativeStatusFilterChanged('NEW_INITIATIVES')"
                                [disabled]="initiativesLoading" class="btn-primary-1 large margin-left25"
                                [ngClass]="{active: showInitiativeFor == 'NEW_INITIATIVES'}" type="button" role="tab">
                                <span translate="generic.NEW_INITIATIVE"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="space20"></div>

        <!-- Titolo della sezione con le iniziative in sospeso (per i supplier) -->
        <div fxLayout="row" *ngIf="isSupplier" class="margin-bottom30" fxFlexFill>
            <p translate="home.PENDING_INITIATIVES" class="p-for-h3-home"></p>
        </div>

        <!-- Tabella con le ultime iniziative -->
        <div *ngIf="!initiativesLoading && !isViewNewInitiativeBlock" fxLayout="column" fxFlexFill>
            <last-initiatives [lastInitiatives]="lastInitiatives" (getLastInitiatives)="getLastInitiatives()"
                (openRemoveInitiativeConfirmModal)="openRemoveInitiativeModal($event)"
                (goToEdit)="goToEditInitiative($event)" *ngIf="lastInitiatives && lastInitiatives.length" fxFlexFill>
            </last-initiatives>

            <div *ngIf="!lastInitiatives || !lastInitiatives.length" fxFlexFill fxLayout="row"
                fxLayoutAlign="center center">
                <p translate="generic.NO_DATA_FOUND"></p>
            </div>
        </div>

        <!-- Loader delle ultime iniziative -->
        <ng-template [ngIf]="initiativesLoading">
            <pending-initiatives-placeholder *ngFor="let number of [0,1,2,3,4]"></pending-initiatives-placeholder>
        </ng-template>

        <div class="space40" *ngIf="!isSupplier && isViewNewInitiativeBlock"></div>
        <!-- Titolo della sezione per la creazione dei corsi -->
        <div fxLayout="row" *ngIf="!isSupplier && isViewNewInitiativeBlock" fxFlexFill>
            <p translate="home.CREATE_AN_INITIATIVE_FROM" class="p-for-h3-home"></p>
        </div>
        <div class="space20" *ngIf="!isSupplier && isViewNewInitiativeBlock"></div>
        <!-- Card con le possibilità di creazione -->
        <div fxLayout="row" *ngIf="!isSupplier && isViewNewInitiativeBlock" fxLayout.sm="column"
            fxLayoutAlign="start center" fxLayout.xs="column" fxFlexFill role="radiogroup">
            <new-syllabus-card fxFlex="33" (toggledChange)="toggledChange($event)"
                [(selectedCreationPossibility)]="selectedCreationPossibility" role="radio"
                attr.aria-checked="{{selectedCreationPossibility && selectedCreationPossibility.options && selectedCreationPossibility.options === '1' ? true : false}}">
            </new-syllabus-card>
            <new-concept-card fxFlex="33" (toggledChange)="toggledChange($event)"
                [(selectedCreationPossibility)]="selectedCreationPossibility" role="radio"
                attr.aria-checked="{{selectedCreationPossibility && selectedCreationPossibility.options && selectedCreationPossibility.options === '2' ? true : false}}">
            </new-concept-card>
            <new-smart-card fxFlex="33" (toggledChange)="toggledChange($event)"
                [(selectedCreationPossibility)]="selectedCreationPossibility" role="radio"
                attr.aria-checked="{{selectedCreationPossibility && selectedCreationPossibility.options && selectedCreationPossibility.options === '3' ? true : false}}">
            </new-smart-card>
        </div>
        <!-- Pulsante che conferma la selezione e porta alla pagina di creazione -->
        <div *ngIf="!isSupplier && isViewNewInitiativeBlock" class="margin-top25 margin-bottom50" fxLayout="row"
            fxLayout.sm="column" fxLayoutAlign="center center" fxLayout.xs="column" fxFlexFill>
            <button (click)="goToCreateInitiative()" class="btn-primary-1 large to-uppercase" type="button"
                role="button">
                <p class="button-label" translate="home.START"></p>
            </button>
        </div>
    </div>
</div>