import { Item, ItemAttribute, ItemAttributeLang, ItemLang, ErrorCodes, SenecaResponse, ItemAttributeTypes, ItemTypes } from "../../../cm2-commonclasses";
import { LangsService } from '../../core/services/langs.service';
import { ExportService } from "../services/export.service";
import { CourseEdition } from "./course.model";

/*
* COMMON Item model Utility
*/
export class ItemUtil {

    constructor() {
        /* this.wrapper.getNativeMap().then((m) => {
            console.log("test");
        }); */
    }

    static getAttributeValue(item: Item | CourseEdition, key: string, order?: number, fromSubscriptionPage?: boolean): any {

        let attr = null;
        if (fromSubscriptionPage) {
            attr = this.getAttributeByKey(item, key);
        } else {
            order = !isNaN(order) && order >= 0 ? order : 0;
            attr = this.getAttributeByKeyAndOrder(item, key, order);
        }
        const val = attr && attr.attributeValue;
        return val;
    }

    static getAttributeByKey(item: Item | CourseEdition, key: string): ItemAttribute {
        if (!item || !item.itemAttributes) {
            return null
        }

        for (let i = 0, attrsLength = item.itemAttributes.length; i < attrsLength; i++) {
            const currentAttribute = item.itemAttributes[i];
            if (currentAttribute && currentAttribute.attributeType && currentAttribute.attributeType === key) {
                return currentAttribute;
            }
        }
        return null;
    }

    static findAttributesByKey(item: Item, key: string): Array<ItemAttribute> {
        const attrs = new Array<ItemAttribute>();
        for (let i = 0, attrsLength = item.itemAttributes.length; i < attrsLength; i++) {
            const currentAttribute = item.itemAttributes[i];
            if (currentAttribute.attributeType && currentAttribute.attributeType.includes(key)) {
                attrs.push(currentAttribute);
            }
        }
        return attrs;
    }

    static getAttributesByKey(item: Item, key: string): Array<ItemAttribute> {
        const attrs = new Array<ItemAttribute>();
        for (let i = 0, attrsLength = item.itemAttributes.length; i < attrsLength; i++) {
            const currentAttribute = item.itemAttributes[i];
            if (currentAttribute.attributeType === key) {
                attrs.push(currentAttribute);
            }
        }
        return attrs;
    }

    static getAttributeByKeyAndOrder(item: Item | CourseEdition, key: string, order: number): ItemAttribute {
        for (let i = 0, attrsLength = item.itemAttributes.length; i < attrsLength; i++) {
            const currentAttribute = item.itemAttributes[i];
            if (currentAttribute.attributeType === key && currentAttribute.attributeOrder === order) {
                return currentAttribute;
            }
        }
        return null;
    }

    static getLastAttributeByOrderAndKey(item: Item, key: string): ItemAttribute {
        let lastAttributeByOrder: ItemAttribute;
        let lastOrder = 0;
        for (let i = 0, attrsLength = item.itemAttributes.length; i < attrsLength; i++) {
            const currentAttribute = item.itemAttributes[i];
            if (currentAttribute.attributeType === key && currentAttribute.attributeOrder >= lastOrder) {
                lastAttributeByOrder = currentAttribute;
                lastOrder = currentAttribute.attributeOrder;
            }
        }
        return lastAttributeByOrder;
    }


    static _normValue(val: any) {
        // normalizzo il valore del attributo, al momento normalizza solo i booleani
        let _valueNorm = val === true ? 'true'
            : val === false ? 'false' : val;
        return _valueNorm;
    }

    // aggiorna o aggiunge un itemAttribute, usato per creare/aggiornare attributi che non si ripetono
    static setKeyValueAttribute(item: Item, key: string, value: any, update?: boolean, order?: number, removeIfEmptyOrFalse?: boolean, retrieveWithoutOrder?: boolean): ItemAttribute {
        let attribute = null;
        order = order || 0;

        if (!!removeIfEmptyOrFalse && !value) {
            ItemUtil.removeItemAttributeByKey(item, key);
        } else if (update) {
            // Cerco l'attributo dello status solo per chiave, perchè non ha una posizione fissa e salta il recupero se fatto per key && order
            if (retrieveWithoutOrder || key == ItemAttributeTypes.STATUS) {
                attribute = ItemUtil.getAttributeByKey(item, key);
            } else {
                attribute = ItemUtil.getAttributeByKeyAndOrder(item, key, order);
            }
            if (!attribute && value) {
                attribute = ItemUtil._newAttr(key, this._normValue(value), order);
                if (!item.itemAttributes) {
                    item.itemAttributes = [];
                }
                item.itemAttributes.push(attribute);
            } else if (attribute) {
                attribute.attributeType = key;
                attribute.attributeValue = this._normValue(value);
            }
        } else {
            attribute = ItemUtil._newAttr(key, this._normValue(value), order);
            if (!item.itemAttributes) {
                item.itemAttributes = [];
            }
            item.itemAttributes.push(attribute);
        }
        return attribute;
    }

    static _newAttr(key: string, value: any, order?: number,
        referenceType?: string, referenceId?: string, referenceApplicationName?: string) {
        return <ItemAttribute>{
            attributeId: null,
            attributeType: key,
            attributeValue: value,
            attributeOrder: (order || 0),
            attributeWeight: null,
            itemId: null,
            crossReferenceObject: null,
            referenceApplicationName: referenceApplicationName,
            referenceId: referenceId,
            referenceType: referenceType
        };
    }

    /**
     * 
     */
    static mapAttributesToItem(item, attrKeys: string[], mapNullIfNotExists?: boolean) {
        // se trovo la chiave mappo l'attributo esistente
        attrKeys.forEach(key => {
            item[key] = ItemUtil.getAttributeByKey(item, key) || (!!mapNullIfNotExists ? null : ItemUtil.setKeyValueAttribute(item, key, null));
        });
    }

    static setAttributeWithReference(item: Item, key: string, referenceId: string, referenceType: string,
        update?: boolean, order?: number, referenceApplicationName?: string): ItemAttribute {
        let attribute = null;
        order = order || 0;
        if (update) {
            attribute = ItemUtil.getAttributeByKeyAndOrder(item, key, order);
            if (!attribute && referenceId) {
                attribute = ItemUtil._newAttr(key, referenceId, order, referenceType, referenceId, referenceApplicationName);
                if (!item.itemAttributes) {
                    item.itemAttributes = [];
                }
                item.itemAttributes.push(attribute);
            } else if (attribute) {
                attribute.attributeType = key;
                attribute.attributeValue = referenceId;
                attribute.attributeOrder = order;
                attribute.referenceId = referenceId;
                attribute.referenceType = referenceType;
                attribute.referenceApplicationName = referenceApplicationName;
            }
        } else {
            attribute = ItemUtil._newAttr(key, referenceId, order, referenceType, referenceId, referenceApplicationName);
            if (!item.itemAttributes) {
                item.itemAttributes = [];
            }
            item.itemAttributes.push(attribute);
        }
        return attribute;
    }

    // aggiunge/aggiorna un itemAttribute che può avere più occorrenze
    static setProgressiveKeyValueAttribute(item: Item, key: string, value: any, update?: boolean): ItemAttribute {
        // cerco l'attributeOrder maggiore per la chiave passata e incremento
        const lastAttributeByOrder: ItemAttribute = ItemUtil.getLastAttributeByOrderAndKey(item, key);
        let lastOrder = 0;
        if (lastAttributeByOrder) {
            lastOrder = lastAttributeByOrder.attributeOrder + 1;
        }
        return ItemUtil.setKeyValueAttribute(item, key, value, update, lastOrder);
    }

    // aggiunge/aggiorna un itemAttribute che può avere più occorrenze
    static setProgressiveAttributeWithReference(item: Item, key: string, referenceId: string, referenceType: string,
        update?: boolean): ItemAttribute {
        // cerco l'attributeOrder maggiore per la chiave passata e incremento
        const lastAttributeByOrder: ItemAttribute = ItemUtil.getLastAttributeByOrderAndKey(item, key);
        let lastOrder = 0;
        if (lastAttributeByOrder) {
            lastOrder = lastAttributeByOrder.attributeOrder + 1;
        }
        return ItemUtil.setAttributeWithReference(item, key, referenceId, referenceType, update, lastOrder);
    }

    // Ritorna la lingua corrente dell'item
    static getItemLang(langsService: LangsService, applicationLang: string, item: Item): ItemLang {
        const initiativeIndex: number = langsService.findItemLangIndex(applicationLang.toLowerCase(), item);
        return item.itemLangs[initiativeIndex];
    }

    static getAttributeLangByKey(item: Item, key: string, langCode: string) {
        const currentAttribute = ItemUtil.getAttributeByKey(item, key);
        if (currentAttribute && currentAttribute.attributeType === key &&
            currentAttribute.attributeLangs && currentAttribute.attributeLangs.length) {
            for (let k = 0, langsLength = currentAttribute.attributeLangs.length; k < langsLength; k++) {
                const currentAttributeLang = currentAttribute.attributeLangs[k];
                if (currentAttributeLang.langCode === langCode) {
                    return currentAttributeLang;
                }
            }
        }
        return null;
    }

    /**
     * Create a new ItemAttribute with an ItemAttributeLang with the given langCode-langValue.
     *  - if the langValue is empty so it creates one ItemAttributeLang with an empty value
     * @param itemId 
     * @param attrKey 
     * @param langCode 
     * @param langValue 
     * @param subtitle 
     */
    static createAttributeWithLang(itemId, attrKey, langCode, langValue: string, subtitle?: string) {
        const attributeLang = <ItemAttributeLang>{
            attributeId: null,
            description: langValue,
            langCode: langCode,
            subTitle: subtitle,
            title: null
        };
        return <ItemAttribute>{
            attributeId: null,
            itemId: itemId,
            attributeOrder: 0,
            attributeType: attrKey,
            attributeValue: null,
            attributeWeight: null,
            crossReferenceObject: {
                tagLangs: [],
                tagTenants: [],
                title: null
            },
            attributeLangs: [attributeLang],
            referenceType: null,
            referenceApplicationName: null,
            referenceId: null
        };
    }

    static copyAttributesFromAttributeTypeToAnotherType(item: Item, originAttributeType, destinationAttributeType) {
        if (item && item.itemAttributes) {
            const originAttrs = ItemUtil.getAttributesByKey(item, originAttributeType);
            const destinationAttrs = ItemUtil.getAttributesByKey(item, destinationAttributeType);

            // se il numero di attributi di destinazione è maggiore di quelli della sorgente allora li rimuovo
            if (destinationAttrs.length > originAttrs.length && originAttrs.length) {
                for (let i = 0, attrsLength = destinationAttrs.length; i < attrsLength; i++) {
                    ItemUtil.removeItemAttributeById(item, destinationAttrs[i]);
                }
            }

            if (originAttrs && originAttrs.length) {
                originAttrs.forEach((originAttr: ItemAttribute) => {
                    let destinationAttr = ItemUtil.getAttributeByKeyAndOrder(item, destinationAttributeType, originAttr.attributeOrder);
                    // se non lo trovo provo a cercare il primo senza order preciso
                    destinationAttr = !destinationAttr ?
                        ItemUtil.getAttributeByKey(item, destinationAttributeType)
                        : destinationAttr;
                    if (destinationAttr) {
                        originAttr = { ...originAttr, attributeId: destinationAttr.attributeId, attributeType: destinationAttributeType };
                        Object.assign(destinationAttr, originAttr);
                    } else {
                        destinationAttr = { ...originAttr, attributeId: null };
                        destinationAttr.attributeType = destinationAttributeType;
                        item.itemAttributes.push(destinationAttr);
                    }
                });
            }
        }
    }

    static removeItemAttributeById(item: Item, itemAttribute: ItemAttribute) {
        const attrToRemove = itemAttribute.attributeId;
        for (let i = 0, attrsLength = item.itemAttributes.length; i < attrsLength; i++) {
            const currentItemAttr = item.itemAttributes[i];
            if (currentItemAttr.attributeId === attrToRemove) {
                item.itemAttributes.splice(i, 1);
                break;
            }
        }
    }

    /**
     * Rimuove il primo attributo trovato per la chiave data
     * @param item
     * @param attributeKey
     */
    static removeItemAttributeByKey(item: Item, attributeKey: string) {
        for (let i = 0, attrsLength = item.itemAttributes.length; i < attrsLength; i++) {
            const currentItemAttr = item.itemAttributes[i];
            if (currentItemAttr.attributeType === attributeKey) {
                item.itemAttributes.splice(i, 1);
                break;
            }
        }
    }

    static getItemChildById(item: Item, itemIdOfChild: string) {
        if (item && item.itemChilds && item.itemChilds.length && itemIdOfChild) {

            const _itemChild = item.itemChilds.find((child) => {
                return child && child.childObject && child.childObject.itemId === itemIdOfChild;
            });

            if (_itemChild) {
                // se ho trovato ritorno
                return _itemChild.childObject;
            } else {
                // altrimenti vado in profondità
                for (let i = 0, attrsLength = item.itemChilds.length; i < attrsLength; i++) {
                    const child = item.itemChilds[i];
                    const _item = child.childObject;
                    if (_item && _item.itemChilds && _item.itemChilds.length) {
                        const _foundItem = ItemUtil.getItemChildById(_item, itemIdOfChild);
                        if (_foundItem) {
                            return _foundItem;
                        }
                    }
                }
            }
        }
        return null;
    }

    static removeTagFromItem(item: Item, selectedTag: any, removeCluster?: boolean, removeArgument?: boolean): void {
        if (item && item.itemAttributes && item.itemAttributes.length) {
            if (!selectedTag) {
                if (removeCluster) {
                    // Se non ho il tag vuol dire che devo rimuovere il cluster dall'item
                    for (let i = 0, attrsLength = item.itemAttributes.length; i < attrsLength; i++) {
                        let currentItemAttr = item.itemAttributes[i];
                        if (currentItemAttr.referenceApplicationName === 'COURSEMANAGER' && currentItemAttr.referenceType === 'TAG' && currentItemAttr.attributeType === 'CLUSTERS') {
                            item.itemAttributes.splice(i, 1);
                            break;
                        }
                    }
                } else if (removeArgument) {
                    // Se non ho il tag vuol dire che devo rimuovere il cluster dall'item
                    for (let i = 0, attrsLength = item.itemAttributes.length; i < attrsLength; i++) {
                        let currentItemAttr = item.itemAttributes[i];
                        if (currentItemAttr.referenceApplicationName === 'COURSEMANAGER' && currentItemAttr.referenceType === 'TAG' && currentItemAttr.attributeType === 'ARGUMENTS') {
                            item.itemAttributes.splice(i, 1);
                            break;
                        }
                    }
                }
            } else if (selectedTag && selectedTag.value && selectedTag.value.tagType != "WAVE") {
                for (let i = 0, attrsLength = item.itemAttributes.length; i < attrsLength; i++) {
                    let currentItemAttr = item.itemAttributes[i];
                    if (currentItemAttr.referenceApplicationName === 'COURSEMANAGER' && selectedTag.value.tagId == currentItemAttr.referenceId) {
                        item.itemAttributes.splice(i, 1);
                        break;
                    }
                }
            } else {
                let tagToRemove = selectedTag.tagId ? selectedTag : selectedTag.value;
                for (let i = 0, attrsLength = item.itemAttributes.length; i < attrsLength; i++) {
                    let currentItemAttr = item.itemAttributes[i];
                    if (currentItemAttr.referenceId === tagToRemove.tagId
                        || (currentItemAttr.attributeType === 'WAVE' && currentItemAttr.attributeValue === tagToRemove.tagId)) {
                        item.itemAttributes.splice(i, 1);
                        break;
                    }
                }
            }
        }
    }

    static async downloadDamAttachmentOfItem(exportService: ExportService, materialItem: Item, userId: string, isSupplier: boolean, supplierId: string) {
        if (materialItem && materialItem && materialItem.itemChilds && materialItem.itemChilds.length) {
            let damAttachment = materialItem.itemChilds.find(child => !!child.childObject && child.childObject.itemType == ItemTypes.DAM_ATTACHMENT);
            if (damAttachment) {
                let externalObjectId = ItemUtil.getAttributeValue(damAttachment.childObject, ItemAttributeTypes.EXTERNAL_OBJECT_ID);
                exportService.retriveAttachmentUrl(userId, materialItem, externalObjectId, false, false, isSupplier, supplierId)
                    .subscribe((senecaResponse: SenecaResponse<string>) => {
                        if (senecaResponse && senecaResponse.error) {
                            throw senecaResponse.error;
                        } else {
                            if (senecaResponse && senecaResponse.response) {
                                setTimeout(() => {
                                    document.location.assign(senecaResponse.response);
                                }, 500)
                            }
                            return Promise.resolve();
                        }
                    });
            } else {
                throw ErrorCodes.OBJECT_NOT_FOUND;
            }
        } else {
            return Promise.resolve();
        }
    }

}
