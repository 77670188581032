import { Component, Output, EventEmitter, ViewChildren, AfterViewInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromApp from '../../ngrx/app.reducers';
import * as AuthActions from '../../auth/ngrx/auth.actions';
import * as CoreActions from '../ngrx/core.actions';
import * as ProfileActions from '../../profile/ngrx/profile.actions';
import { Router } from '@angular/router';
import { User, JwtPayload, RequiredAuth } from "../../../cm2-commonclasses";
import { TranslateService } from '@ngx-translate/core';
import { Observable, combineLatest, Subscription } from 'rxjs';
import { RedirectService } from '../../shared/services/redirect.service';
import { TooltipDirective } from 'ng2-tooltip-directive';
import { authControl } from 'src/app/shared/models/global-application-data.model';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html'
})
export class HeaderComponent implements AfterViewInit, OnDestroy {
    loggedUser: JwtPayload;
    avatar: string;
    notificationList: any[] = [];
    isMainMenuSidebarOpened: boolean;
    isProfileMenuSidebarOpened: boolean;
    isSupplier: boolean;
    isFundingSupplier: boolean;
    result$: Subscription;
    @ViewChildren(TooltipDirective) tooltipDirective;
    @Output() activateAnimation = new EventEmitter();
    mainMenuSidebarToolip: any;
    openSecondaryMenuToolip: any;
    logoutToolip: any;
    isManager: boolean;
    isAdmin: boolean;
    isPerformance: boolean;
    authObject: any;

    constructor(private store: Store<fromApp.AppState>, private router: Router, public translate: TranslateService, private redirectService: RedirectService) {
        // Recupero le informazioni sull'utente loggato dallo Store applicativo
        let loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
        // Recupero dallo Store applicativo la variabile che mi indica che la sidenav di sinistra col menu principale è aperta
        let isMainMenuSidebarOpened$: Observable<boolean> = this.store.select(fromApp.isMainMenuSidebarOpened);
        // Recupero dallo Store applicativo la variabile che mi indica che la sidenav di destra col menu utente è aperta
        let isProfileMenuSidebarOpened$: Observable<boolean> = this.store.select(fromApp.isProfileMenuSidebarOpened);
        // Collegamento alla variabile indicante se l'utente loggato è un supplier
        const isSupplier$: Observable<boolean> = this.store.select(fromApp.isSupplier);
        // Collegamento alla variabile indicante se l'utente loggato è un fundingSupplier
        const isFundingSupplier$: Observable<boolean> = this.store.select(fromApp.isFundingSupplier);
        // Sto in ascolto dei vari cambiamenti
        const combinedSelectes$ = combineLatest(loggedUser$, isMainMenuSidebarOpened$, isProfileMenuSidebarOpened$, isSupplier$, isFundingSupplier$);
        this.result$ = combinedSelectes$.subscribe(
            ([loggedUser, isOpened, isProfileMenuSidebarOpened, isSupplier, isFundingSupplier]) => {
                this.loggedUser = loggedUser;
                this.isMainMenuSidebarOpened = isOpened;
                this.isProfileMenuSidebarOpened = isProfileMenuSidebarOpened;
                this.isSupplier = isSupplier;
                this.isFundingSupplier = isFundingSupplier;
                // Recupero l'avatar dell'utente loggato dal suo payload, con una foto di default qualora mancasse
                this.avatar = (this.loggedUser && this.loggedUser.user && this.loggedUser.user.userOptions && this.loggedUser.user.userOptions.avatarImage)
                    || (this.loggedUser && this.loggedUser.user && this.loggedUser.user.chiaveSesso == "M" ? 'assets/img/placeholder.png' : 'assets/img/placeholder_female.png');
            });

    }
    // La sidenav si può chiudere anche cliccando sul backdrop. In tal caso, eseguo il dispatch dell'azione che setta come chiusa la sidenav nello Store
    closeMainMenuSidenav(): void {
        this.store.dispatch(new CoreActions.CloseMainMenuSidebar());
    }

    // La sidenav si può chiudere anche cliccando sul backdrop. In tal caso, eseguo il dispatch dell'azione che setta come chiusa la sidenav nello Store
    closeProfileMenuSidenav(): void {
        this.store.dispatch(new CoreActions.CloseProfileMenuSidebar());
    }

    ngAfterViewInit() {
        // Collegamenti ai tooltip
        this.mainMenuSidebarToolip = this.tooltipDirective.find(elem => elem.id === "mainMenuSidebarToolip");
        this.openSecondaryMenuToolip = this.tooltipDirective.find(elem => elem.id === "openSecondaryMenuToolip");
        this.logoutToolip = this.tooltipDirective.find(elem => elem.id === "logoutToolip");
    }

    // Porta alla home page
    goToHome(): void {
        this.redirectService.goToHome();
    }

    // Porta alla home page pagina suppliers
    goToHomeSupplier(): void {
        if(this.showMenuButton()) {
            this.redirectService.goToHomeSupplier();
        }
    }

    // Porta alla home page pagina fundingSupplier
    goToHomeFundingSupplier(): void {
        if(this.showMenuButton()) {
            this.redirectService.goToHomefundingSupplier();
        }
    }

    // Porta alla pagina del profilo
    goToProfile(): void {
        this.redirectService.goToProfile();
    }

    // Porta alla pagina della home dei meetings
    goToMeetings(): void {
        this.redirectService.goToMeetings();
    }

    // Porta aalla pagina della pagina di atterraggio performance e manager
    goToLandingPerformanceManager(): void {
        this.redirectService.goToLandingPerformanceManager();
    }

    goToPerformanceManagementPage(): void {
        this.redirectService.goToPerformanceManagement();
    }

    // Verifica in quale pagina sono
    isThisCurrentPage(page: string) {
        if (
            page &&
            this.router &&
            this.router.url &&
            this.router.url.indexOf(page) !== -1
        ) {
            return true;
        }

        return false;
    }

    // Dispatch dell'azione che esegue il toggle sulla sidebar di sinistra col menu principale
    toggleMainMenuSidebar(): void {
        // Alza l'evento per il componente padre, che attive le animazioni sulle sidenav
        this.activateAnimation.emit();
        if (!this.isMainMenuSidebarOpened) {
            // Chiudo manualmente il toolip poiché, altrimenti, rimarrebbe visibile a causa dell'overlay della sidebar
            this.mainMenuSidebarToolip.hide();
            this.store.dispatch(new CoreActions.OpenMainMenuSidebar());
        } else {
            this.closeMainMenuSidenav();
        }
    }

    // Dispatch dell'azione che esegue il toggle sulla sidebar di sinistra col profilo
    toggleProfileMenuSidebar(): void {
        // Alza l'evento per il componente padre, che attiva le animazioni sulle sidenav
        this.activateAnimation.emit();
        if (!this.isProfileMenuSidebarOpened) {
            // Chiudo manualmente il toolip poiché, altrimenti, rimarrebbe visibile a causa dell'overlay della sidebar
            this.openSecondaryMenuToolip.hide();
            this.store.dispatch(new CoreActions.OpenProfileMenuSidebar());
        } else {
            this.closeProfileMenuSidenav();
        }
    }

    // Quando il componente è distrutto, eseguo anche l'unsubscribe
    ngOnDestroy() {
        this.result$.unsubscribe();
    }

    ngAfterViewChecked() {
        this.authObject = authControl(this.loggedUser && this.loggedUser.auths);
        this.isAdmin = this.authObject.isAdmin;
        this.isManager = this.authObject.isManager;
        this.isPerformance = this.authObject.isPerformance;
    }

    // Logout dall'applicazione
    onLogout() {
        // Chiudo manualmente il toolip poiché, altrimenti, rimarrebbe visibile a causa dell'overlay della sidebar
        this.logoutToolip.hide();
        // Al click sul logout, eseguo il dispatch dell'action che disautentica l'utente, cancella il token (sia dal session storage che dallo State), e cancella il Payload (dato dalla decodifica del Token) dentro lo State
        this.store.dispatch(new AuthActions.Logout());
        this.store.dispatch(new CoreActions.RemoveApplicationLang());
        this.store.dispatch(new ProfileActions.CancelLoggedUser());
        // Redirect alla pagina di login
        this.router.navigate(['/login']);
    }

    showMenuButton() {
        return !this.isThisCurrentPage('medexCourseSubscription');
    }
}