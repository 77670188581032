/*
* Wrapper di tutti i reducer dell'applicazione, ossia funzioni pure che trasformano il nostro Stato applicativo
*/

import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromProfile from '../profile/ngrx/profile.reducers';
import * as fromAuth from '../auth/ngrx/auth.reducers';
import * as fromCore from '../core/ngrx/core.reducers';
import * as fromTemplate from '../templates/ngrx/template.reducers';
import * as fromDashboard from '../dashboard/ngrx/dashboard.reducers';
import * as fromInitiative from '../initiatives/ngrx/initiative.reducers';
import * as fromMeeting from '../meetings/ngrx/meeting.reducers';
import * as fromSupplier from '../suppliers/ngrx/supplier.reducers';
import * as fromLocation from '../locations/ngrx/location.reducers';
import * as fromSpecialProject from '../specialProjects/ngrx/specialProjects.reducers';
import * as fromCourseEdition from '../initiatives/ngrx/course-edition.reducers';
import * as fromFundingSupplier from '../fundingSupplier/ngrx/fundingSupplier.reducers';
import * as fromGroups from '../groups/ngrx/groups.reducers';
import * as fromPerformanceManagement from '../performanceManagement/ngrx/performance-management.reducers';

export interface AppState {
    user: fromProfile.ProfileState;
    auth: fromAuth.AuthState;
    core: fromCore.CoreState;
}

export const reducers: ActionReducerMap<AppState> = {
    user: fromProfile.profileReducer,
    auth: fromAuth.authReducer,
    core: fromCore.coreReducer
};

// Grazie al metodo createFeatureSelector, siamo in grado di ottenere facilmente un riferimento ad una proprietà dello State. Una volta ottenuto tale riferimento,
// possiamo quindi comporlo con altri selettori che puntano a proprietà dello State di livello più basso attraversando in modo efficace il tree dello State.
// E' un feature selector, che ci torna uno slice dello State corrispondente ad AppState
export const getAuthAppState = createFeatureSelector<fromAuth.AuthState>('auth');
export const getCoreAppState = createFeatureSelector<fromCore.CoreState>('core');
export const getProfileAppState = createFeatureSelector<fromProfile.ProfileState>('user');
export const getTemplateAppState = createFeatureSelector<fromTemplate.TemplateState>('template');
export const getDashboardAppState = createFeatureSelector<fromDashboard.DashboardState>('dashboard');
export const getInitiativeAppState = createFeatureSelector<fromInitiative.InitiativeState>('initiative');
export const getFundingSupplierAppState = createFeatureSelector<fromFundingSupplier.InitiativeState>('fundingSupplier');
export const getMeetingAppState = createFeatureSelector<fromMeeting.MeetingState>('meeting');
export const getSupplierAppState = createFeatureSelector<fromSupplier.SupplierState>('supplier');
export const getLocationAppState = createFeatureSelector<fromLocation.LocationState>('location');
export const getSpecialProjectsAppState = createFeatureSelector<fromSpecialProject.SpecialProjectState>('specialProjects');
export const getCourseEditionAppState = createFeatureSelector<fromCourseEdition.CourseEditionState>('courseEdition');
export const getGroupsAppState = createFeatureSelector<fromGroups.GroupState>('group');
export const getPerformanceManagementAppState = createFeatureSelector<fromPerformanceManagement.PerformanceManagementState>('performanceManagement');


// Per ottenere riferimenti alle proprietà più in basso nel tree dello State, utilizziamo il createSelector
// Selettori relativi allo state dell'auth
export const getAuthState = createSelector(
    getAuthAppState, // Passato come primo parametro affinché possiamo iniziare la nostra ricerca nello State da questo punto. Possiamo passare più State
    (state: fromAuth.AuthState) => state // Projector function è passata come argomento ai valori restituiti dei selettori indicati e nell'ordine in cui sono stato fatto
);
export const getToken = createSelector(
    getAuthState,
    fromAuth.getToken
);
// export const isAuthenticated = createSelector(
//     getAuthState,
//     fromAuth.isAuthenticated
// );

// Selettori relativi allo state del core
export const getCoreState = createSelector(
    getCoreAppState,
    (state: fromCore.CoreState) => state
);
export const isAuthenticated = createSelector(
    getAuthState,
    (state: fromAuth.AuthState) => state.authenticated
);
export const isSupplier = createSelector(
    getAuthState,
    (state: fromAuth.AuthState) => state.isSupplier
);
export const isFundingSupplier = createSelector(
    getAuthState,
    (state: fromAuth.AuthState) => state.isFundingSupplier
);
export const isTaker = createSelector(
    getAuthState,
    (state: fromAuth.AuthState) => state.isTaker
);
export const isSmartEnergyTaker = createSelector(
    getAuthState,
    (state: fromAuth.AuthState) => state.isSmartEnergyTaker
);
export const isWelionTs = createSelector(
    getAuthState,
    (state: fromAuth.AuthState) => state.isWelionTs
);
export const isOwnership = createSelector(
    getAuthState,
    (state: fromAuth.AuthState) => state.isOwnership
);
export const isFetchingLangs = createSelector(
    getCoreAppState,
    fromCore.isFetchingLangs
);
export const areInitiativeStepsDisabled = createSelector(
    getCoreAppState,
    fromCore.areInitiativeStepsDisabled
);
export const areMeetingStepsDisabled = createSelector(
    getCoreAppState,
    fromCore.areMeetingStepsDisabled
);
export const getRedirectUrl = createSelector(
    getCoreAppState,
    fromCore.getRedirectUrl
);
export const getApplicationContext = createSelector(
    getCoreAppState,
    fromCore.getApplicationContext
);
export const getGlobalApplicationData = createSelector(
    getCoreAppState,
    fromCore.getGlobalApplicationData
);
export const showApplicationLoader = createSelector(
    getCoreAppState,
    fromCore.showApplicationLoader
);
export const isMainMenuSidebarOpened = createSelector(
    getCoreAppState,
    fromCore.isMainMenuSidebarOpened
);
export const getActiveTab = (tabName: string) =>
    createSelector(
        getCoreAppState,
        (state: fromCore.CoreState) => state[tabName].activeTabId
    );
export const getApplicationLang = createSelector(
    getCoreAppState,
    fromCore.getApplicationLang
);
export const getDefaultLang = createSelector(
    getCoreAppState,
    fromCore.getDefaultLang
);
export const getClusters = createSelector(
    getCoreAppState,
    fromCore.getClusters
);
export const isFetchingClusters = createSelector(
    getCoreAppState,
    fromCore.isFetchingClusters
);
export const isProfileMenuSidebarOpened = createSelector(
    getCoreAppState,
    fromCore.isProfileMenuSidebarOpened
);

export const isHeritageTaker = createSelector(
    getAuthState,
    (state: fromAuth.AuthState) => state.isHeritageTaker
);

// Selettori relativi allo state della dashboard
export const getRolesIndicators = createSelector(
    getDashboardAppState,
    fromDashboard.getRolesIndicators
);
export const isFetchingRolesIndicators = createSelector(
    getDashboardAppState,
    fromDashboard.isFetchingRolesIndicators
);

// Selettori relativi allo state dei gruppi formazione obbligatoria
export const getGroups = createSelector(
    getGroupsAppState,
    fromGroups.getGroups
);
export const isFetchingGroups = createSelector(
    getGroupsAppState,
    fromGroups.isFetchingGroups
);
export const getGroupsCount = createSelector(
    getGroupsAppState,
    fromGroups.getGroupsCount
);
export const getGroupsFromRecord = createSelector(
    getGroupsAppState,
    fromGroups.getGroupsFromRecord
);
export const getGroupsNumRecords = createSelector(
    getGroupsAppState,
    fromGroups.getGroupsNumRecords
);
export const getGroupsPage = createSelector(
    getGroupsAppState,
    fromGroups.getGroupsPage
);
export const getGroupsTextFilter = createSelector(
    getGroupsAppState,
    fromGroups.getGroupsTextFilter
);


// Selettori relativi allo state dei template
export const getTemplates = createSelector(
    getTemplateAppState,
    fromTemplate.getTemplates
);
export const isFetchingTemplates = createSelector(
    getTemplateAppState,
    fromTemplate.isFetchingTemplates
);
export const getTemplatesCount = createSelector(
    getTemplateAppState,
    fromTemplate.getTemplatesCount
);
export const getTemplatesFromRecord = createSelector(
    getTemplateAppState,
    fromTemplate.getTemplatesFromRecord
);
export const getTemplatesNumRecords = createSelector(
    getTemplateAppState,
    fromTemplate.getTemplatesNumRecords
);
export const getTemplatesPage = createSelector(
    getTemplateAppState,
    fromTemplate.getTemplatesPage
);
export const getTemplateFilterType = createSelector(
    getTemplateAppState,
    fromTemplate.getTemplateFilterType
);

// Selettori relativi allo state delle iniziative
export const getInitiatives = createSelector(
    getInitiativeAppState,
    fromInitiative.getInitiatives
);
export const isFetchingInitiatives = createSelector(
    getInitiativeAppState,
    fromInitiative.isFetchingInitiatives
);
export const getInitiativesCount = createSelector(
    getInitiativeAppState,
    fromInitiative.getInitiativesCount
);
export const getInitiativesFromRecord = createSelector(
    getInitiativeAppState,
    fromInitiative.getInitiativesFromRecord
);
export const getInitiativesNumRecords = createSelector(
    getInitiativeAppState,
    fromInitiative.getInitiativesNumRecords
);
export const getInitiativesPage = createSelector(
    getInitiativeAppState,
    fromInitiative.getInitiativesPage
);
export const getSelectedInitiativeItem = createSelector(
    getInitiativeAppState,
    fromInitiative.getSelectedInitiativeItem
);
export const getPathItemOfSelectedInitiative = createSelector(
    getInitiativeAppState,
    fromInitiative.getPathItemOfSelectedInitiative
);
export const getWizardStepOfSelectedInitiative = createSelector(
    getInitiativeAppState,
    fromInitiative.getWizardCurrentStep
);
export const getInitiativeFilterType = createSelector(
    getInitiativeAppState,
    fromInitiative.getInitiativesFilterType
);
export const getInitiativesTextFilter = createSelector(
    getInitiativeAppState,
    fromInitiative.getInitiativesTextFilter
);

// Selettori relativi allo state di fundingSupplier
export const getInitiativesFundingSupplier = createSelector(
    getFundingSupplierAppState,
    fromFundingSupplier.getInitiatives
);
export const isFetchingInitiativesFundingSupplier = createSelector(
    getFundingSupplierAppState,
    fromFundingSupplier.isFetchingInitiatives
);
export const getInitiativesCountFundingSupplier = createSelector(
    getFundingSupplierAppState,
    fromFundingSupplier.getInitiativesCount
);
export const getInitiativesFromRecordFundingSupplier = createSelector(
    getFundingSupplierAppState,
    fromFundingSupplier.getInitiativesFromRecord
);
export const getInitiativesNumRecordsFundingSupplier = createSelector(
    getFundingSupplierAppState,
    fromFundingSupplier.getInitiativesNumRecords
);
export const getInitiativesPageFundingSupplier = createSelector(
    getFundingSupplierAppState,
    fromFundingSupplier.getInitiativesPage
);
export const getInitiativeFilterTypeFundingSupplier = createSelector(
    getFundingSupplierAppState,
    fromFundingSupplier.getInitiativesFilterType
);
export const getInitiativesTextFilterFundingSupplier = createSelector(
    getFundingSupplierAppState,
    fromFundingSupplier.getInitiativesTextFilter
);
export const getOfferErrorsFundingSupplier = createSelector(
    getFundingSupplierAppState,
    fromFundingSupplier.getOfferErrors
);
export const getInvoiceErrorsFundingSupplier = createSelector(
    getFundingSupplierAppState,
    fromFundingSupplier.getInvoiceErrors
);
export const getMaterialErrorsFundingSupplier = createSelector(
    getFundingSupplierAppState,
    fromFundingSupplier.getMaterialErrors
);
export const getCommunicationErrorsFundingSupplier = createSelector(
    getFundingSupplierAppState,
    fromFundingSupplier.getCommunicationErrors
);
// Selettori relativi allo state delle riunioni
export const getMeetings = createSelector(
    getMeetingAppState,
    fromMeeting.getMeetings
);
export const isFetchingMeetings = createSelector(
    getMeetingAppState,
    fromMeeting.isFetchingMeetings
);
export const getMeetingsCount = createSelector(
    getMeetingAppState,
    fromMeeting.getMeetingsCount
);
export const getMeetingsFromRecord = createSelector(
    getMeetingAppState,
    fromMeeting.getMeetingsFromRecord
);
export const getMeetingsNumRecords = createSelector(
    getMeetingAppState,
    fromMeeting.getMeetingsNumRecords
);
export const getMeetingsPage = createSelector(
    getMeetingAppState,
    fromMeeting.getMeetingsPage
);
export const getSelectedMeetingItem = createSelector(
    getMeetingAppState,
    fromMeeting.getSelectedMeetingItem
);
export const getWizardStepOfSelectedMeeting = createSelector(
    getMeetingAppState,
    fromMeeting.getWizardCurrentStep
);
export const getMeetingFilterType = createSelector(
    getMeetingAppState,
    fromMeeting.getMeetingsFilterType
);
export const getMeetingsTextFilter = createSelector(
    getMeetingAppState,
    fromMeeting.getMeetingsTextFilter
);
export const getNotificationNumber = createSelector(
    getMeetingAppState,
    fromMeeting.getNotificationNumber
);

// Selettori relativi allo state dei supplier
export const getSuppliers = createSelector(
    getSupplierAppState,
    fromSupplier.getSuppliers
);
export const isFetchingSuppliers = createSelector(
    getSupplierAppState,
    fromSupplier.isFetchingSuppliers
);
export const getSuppliersCount = createSelector(
    getSupplierAppState,
    fromSupplier.getSuppliersCount
);
export const getSuppliersFromRecord = createSelector(
    getSupplierAppState,
    fromSupplier.getSuppliersFromRecord
);
export const getSuppliersNumRecords = createSelector(
    getSupplierAppState,
    fromSupplier.getSuppliersNumRecords
);
export const getSuppliersPage = createSelector(
    getSupplierAppState,
    fromSupplier.getSuppliersPage
);
export const getSupplierFilterType = createSelector(
    getSupplierAppState,
    fromSupplier.getSupplierFilterType
);
export const getSupplierTextFilter = createSelector(
    getSupplierAppState,
    fromSupplier.getSupplierTextFilter
);

// Selettori relativi allo state del profilo
export const getProfileState = createSelector(
    getProfileAppState,
    (state: fromProfile.ProfileState) => state
);
export const getLoggedUser = createSelector(
    getProfileState,
    fromProfile.getLoggedUser
);
export const getAvailableLangs = createSelector(
    getProfileState,
    fromProfile.getAvailableLangs
);

// Selettori relativi allo state di una edizione
export const getCourseEdition = createSelector(
    getCourseEditionAppState,
    fromCourseEdition.getCourseEdition
);
export const getCourseEditionOptions = createSelector(
    getCourseEditionAppState,
    fromCourseEdition.getCourseEditionOptions
);

// Selettori relativi allo state delle Locations
export const getLocations = createSelector(
    getLocationAppState,
    fromLocation.getLocations
);
export const isFetchingLocations = createSelector(
    getLocationAppState,
    fromLocation.isFetchingLocations
);
export const getLocationsCount = createSelector(
    getLocationAppState,
    fromLocation.getLocationsCount
);
export const getLocationsFromRecord = createSelector(
    getLocationAppState,
    fromLocation.getLocationsFromRecord
);
export const getLocationsNumRecords = createSelector(
    getLocationAppState,
    fromLocation.getLocationsNumRecords
);
export const getLocationsPage = createSelector(
    getLocationAppState,
    fromLocation.getLocationsPage
);
export const getLocationFilterText = createSelector(
    getLocationAppState,
    fromLocation.getLocationFilterText
);

// Selettori relativi allo state dei progetti speciali
export const getSpecialProjects = createSelector(
    getSpecialProjectsAppState,
    fromSpecialProject.getSpecialProjects
);
export const isFetchingSpecialProjects = createSelector(
    getSpecialProjectsAppState,
    fromSpecialProject.isFetchingSpecialProjects
);
export const getSpecialProjectsCount = createSelector(
    getSpecialProjectsAppState,
    fromSpecialProject.getSpecialProjectsCount
);
export const getSpecialProjectsFromRecord = createSelector(
    getSpecialProjectsAppState,
    fromSpecialProject.getSpecialProjectsFromRecord
);
export const getSpecialProjectsNumRecords = createSelector(
    getSpecialProjectsAppState,
    fromSpecialProject.getSpecialProjectsNumRecords
);
export const getSpecialProjectsPage = createSelector(
    getSpecialProjectsAppState,
    fromSpecialProject.getSpecialProjectsPage
);
export const getSpecialProjectFilterText = createSelector(
    getSpecialProjectsAppState,
    fromSpecialProject.getSpecialProjectFilterText
);

// Selettori relativi allo state del Performance Management
export const getPerformanceYears = createSelector(
    getPerformanceManagementAppState,
    fromPerformanceManagement.getPerformanceYears
);
export const getPerformanceYearSelected = createSelector(
    getPerformanceManagementAppState,
    fromPerformanceManagement.getPerformanceYearSelected
);
export const isFetchingPerformanceYears = createSelector(
    getPerformanceManagementAppState,
    fromPerformanceManagement.isFetchingPerformanceYears
);
export const getPerformanceRents = createSelector(
    getPerformanceManagementAppState,
    fromPerformanceManagement.getPerformanceRents
);
export const getPerformancesRentsCount = createSelector(
    getPerformanceManagementAppState,
    fromPerformanceManagement.getPerformancesRentsCount
);
export const getPerformanceRentsFromRecord = createSelector(
    getPerformanceManagementAppState,
    fromPerformanceManagement.getPerformanceRentsFromRecord
);
export const getPerformanceRentsNumRecords = createSelector(
    getPerformanceManagementAppState,
    fromPerformanceManagement.getPerformanceRentsNumRecords
);
export const getPerformanceRentsPage = createSelector(
    getPerformanceManagementAppState,
    fromPerformanceManagement.getPerformanceRentsPage
);
export const isFetchingPerformanceRents = createSelector(
    getPerformanceManagementAppState,
    fromPerformanceManagement.isFetchingPerformanceRents
);

