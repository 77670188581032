/*
*  Componente che gestisce la pagina di atterraggio se non sei admin e hai 2 o piu auth
*/

import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { BaseSubscriberComponent } from '../../shared/components/base-subscriber.component';
import { RequiredAuth, JwtPayload } from 'src/cm2-commonclasses';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromApp from '../../ngrx/app.reducers';
import { RedirectService } from 'src/app/shared/services/redirect.service';

@Component({
    selector: 'app-landing-performance-manager',
    templateUrl: './landing-performance-manager.component.html',
    styleUrls: ['./landing-performance-manager.component.scss']
})
export class LandingPerformanceManagerComponent extends BaseSubscriberComponent implements OnDestroy, OnInit {

    // Utente loggato
    loggedUser: JwtPayload;
    // Array usato per l' ngFor dei pulsanti così è dinamico(per adesso cè solo performance e meetings)
    interestedAuths: InterestedAuth[];

    constructor(
        private store: Store<fromApp.AppState>,
        public ngxSmartModalService: NgxSmartModalService,
        private redirectService: RedirectService) {
        super();
    }

    ngOnInit() {

        // Recupero le informazioni sull'utente loggato dallo Store applicativo
        let loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);

        loggedUser$.subscribe(
            (loggedUser) => {
                this.loggedUser = loggedUser;
                this.interestedAuths = this.loggedUser && this.loggedUser.auths && this.loggedUser.auths.filter((x: string) => {
                    return x == RequiredAuth.COURSEMANAGER_MANAGE_MEETING_MANAGER || x == RequiredAuth.COURSEMANAGER_MANAGE_PERFORMANCE
                }).map((y: string) => {
                    return {
                        auth: y,
                        authName: 'generic.authPages.' + y
                    }
                })
            })
    }

    ngOnDestroy() {
    }
}

export interface InterestedAuth {
    auth: string;
    authName: string;
}