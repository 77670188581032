/*
*  Lista di azioni riguardanti i Supplier (fornitori)
*/

import { Action } from '@ngrx/store';

// Salva la lista di supplier recuperati
export const SET_SUPPLIERS = 'SET_SUPPLIERS';

// Aggiunge un nuovo fornitore
export const ADD_SUPPLIER = 'ADD_SUPPLIER';

// Aggiorna un fornitore
export const UPDATE_SUPPLIER = 'UPDATE_SUPPLIER';

// Cancella un fornitore
export const DELETE_SUPPLIER = 'DELETE_SUPPLIER';

// Salva un'fornitore
export const STORE_SUPPLIER = 'STORE_SUPPLIER';

// Recupera le supplier da remoto
export const GET_SUPPLIERS = 'GET_SUPPLIERS';

// Setta il contatore delle supplier recuperati da remoto
export const SET_SUPPLIERS_COUNT = 'SET_SUPPLIERS_COUNT';

// Setta la paginazione
export const SET_PAGINATION = 'SET_PAGINATION';

// Setta il filtro per la tipologia di supplier da cercare
export const SET_SUPPLIER_TYPE = 'SET_SUPPLIER_TYPE';

// Setta il filtro di ricerca testuale di supplier da cercare
export const SET_SUPPLIER_TEXT_FILTER = 'SET_SUPPLIER_TEXT_FILTER';

// Setta la pagina corrente
export const SET_PAGE = 'SET_PAGE';

export class SetSuppliers implements Action {
    readonly type = SET_SUPPLIERS;

    constructor(public payload: any[]) { }
}

export class AddSupplier implements Action {
    readonly type = ADD_SUPPLIER;

    constructor(public payload: any) { }
}

export class UpdateSupplier implements Action {
    readonly type = UPDATE_SUPPLIER;

    constructor(public payload: { index: number, updatedSupplier: any }) { }
}

export class DeleteSupplier implements Action {
    readonly type = DELETE_SUPPLIER;

    constructor(public payload: string) { }
}

export class SetSupplierType implements Action {
    readonly type = SET_SUPPLIER_TYPE;

    constructor(public payload: string) { }
}

export class SetSupplierTextFilter implements Action {
    readonly type = SET_SUPPLIER_TEXT_FILTER;

    constructor(public payload: string) { }
}

export class StoreSupplier implements Action {
    readonly type = STORE_SUPPLIER;
}

export class GetSuppliers implements Action {
    readonly type = GET_SUPPLIERS;
}

export class SetSuppliersCount implements Action {
    readonly type = SET_SUPPLIERS_COUNT;

    constructor(public payload: number) { }
}

export class SetPage implements Action {
    readonly type = SET_PAGE;

    constructor(public payload: number) { }
}

export class SetPagination implements Action {
    readonly type = SET_PAGINATION;

    constructor(public payload: { fromRecord: number, numRecords: number }) { }
}

export type SupplierActions =
    SetSuppliers |
    AddSupplier |
    UpdateSupplier |
    DeleteSupplier |
    SetSupplierTextFilter |
    StoreSupplier |
    SetSuppliersCount |
    SetPagination |
    SetPage |
    SetSupplierType |
    GetSuppliers;