<div class="card card0 card_placeholder_container">
    <div class="card-image card-image_placeholder">
        <div class="timeline-item card-title-loader">
            <div class="animated-background facebook">
                <div class="background-masker header-top"></div>
            </div>
        </div>
        <div class="timeline-item card-subtitle-loader">
            <div class="animated-background facebook">
                <div class="background-masker header-top"></div>
            </div>
        </div>
    </div>
    <pending-initiatives-placeholder></pending-initiatives-placeholder>
</div>