// Login utenti ai meeting online

import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';

import * as fromApp from '../../ngrx/app.reducers';
// Import dello State dell'autenticazione, che è quello che selezioneremo con lo slice dallo Store
import * as AuthActions from '../ngrx/auth.actions';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Lang } from "../../../cm2-commonclasses";

@Component({
    selector: 'app-online-meeting-support-login',
    templateUrl: './online-meeting-support-login.component.html',
    styleUrls: ['./online-meeting-support-login.component.scss']
})
export class OnlineMeetingSupportLoginComponent implements OnInit, OnDestroy {
    isFetchingLangs: boolean;
    langs: Lang[];
    result$;

    constructor(private store: Store<fromApp.AppState>,
        public translate: TranslateService,
        private router: Router) {
        // Sto in ascolto di quando il globalApplicationData cambia, così da sapere quando ci sono le lingue disponibili poiché, se non ci fossero, il pulsante per il login sarebbe disabilitato
        let availableLangs$: Observable<Lang[]> = this.store.select(fromApp.getAvailableLangs);
        let isFetchingLangs$: Observable<boolean> = this.store.select(fromApp.isFetchingLangs);
        const combinedSelectes$ = combineLatest(availableLangs$, isFetchingLangs$);
        this.result$ = combinedSelectes$.subscribe(
            ([langs, isFetchingLangs]) => {
                this.langs = langs;
                this.isFetchingLangs = isFetchingLangs;
            });
    }
    ngOnInit() {
        let sessionStorageOnlineMeetingSupportEmail = sessionStorage.getItem('onlineMeetingSupportEmail');
        let sessionStorageOnlineMeetingSupportId = sessionStorage.getItem('onlineMeetingSupportId');
        // Se ho lo userId (oppure il cid) nel session storage, posso subito eseguire il login
        if (sessionStorageOnlineMeetingSupportEmail || sessionStorageOnlineMeetingSupportId) {
            this.store.dispatch(new AuthActions.DoLogin({ email: sessionStorageOnlineMeetingSupportEmail || sessionStorageOnlineMeetingSupportId, password: null, isOnlineMeetingSupport: true }));
        }
    }
    ngOnDestroy() {
        this.result$.unsubscribe();
    }

    // Dal form passato come parametro, ricavo i dati immessi dall'utente per inserirli nel dispatch dell'action che tenterà il login
    onLogin(form: NgForm) {
        let email: string = form.value.email;
        this.store.dispatch(new AuthActions.DoLogin({ email: email, password: null, isOnlineMeetingSupport: true }));
    }
}