import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-report-download',
  templateUrl: './report-download.component.html',
  styleUrls: ['./report-download.component.scss']
})
export class ReportDownloadComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

  downloadReport(){
    const medietor = "rest-api/coursemanager-mediator"
    const baseUrl = location.href.replace("/#/", "/");
    const url = new URL(baseUrl);
    const urlParams = new URLSearchParams(url.search);
    
    const downloadUrl = urlParams.get('downloadUrl');
    const filename = urlParams.get('filename');
    const ssortkqp = urlParams.get('ssortkqp');
    
    if(!downloadUrl || !filename || !ssortkqp){
      return;
    }

    window.open(`${downloadUrl}/${medietor}/download-temp-file?filename=${filename}&ssortkqp=${ssortkqp}`, "_blank");
  }

}
