<!-- Il form di login si vede solamente se ci sono lingue disponibili a sistema -->
<div class="login-page theme-primary" fxLayout="row" *ngIf="langs && langs.length" fxFlexFill>
    <div class="login-column" fxFlex.sm="60" fxFlex.gt-sm="40" fxFlexFill-xs fxLayout="column"
        fxLayoutAlign="center center">
        <img class="margin-bottom30 margin-top30" alt="{{'accessibilityAttributes.ENEL' | translate}}"
            title="{{'accessibilityAttributes.ENEL' | translate}}" width="140" height="50" title="generaliLogo"
            src="assets/img/enel_logo.png">
        <enel-welfare-support-login-form (onLogin)="onLogin($event)"></enel-welfare-support-login-form>
    </div>
</div>

<!-- Nessuna lingua disponibile a sistema -->
<div *ngIf="!langs || !langs.length">
    <p translate="errors.NO_AVAILABLE_LANG"></p>
</div>