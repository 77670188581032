/*
* Componente che crea un placeholder per un input text
*/

import { Component, Input } from '@angular/core';
@Component({
    selector: 'input-placeholder',
    templateUrl: './input-placeholder.component.html',
    styleUrls: ['./input-placeholder.component.scss']
})

export class InputPlaceholderComponent {
    @Input() public customContainerClass: string;
    @Input() public customFirstLineClass: string;
    @Input() public customSecondLineClass: string;
    constructor() {
    }
}