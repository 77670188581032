<form class="login-wrapper" (ngSubmit)="login(f)" #f="ngForm">
    <div fxLayout="row" fxFlexFill fxLayoutAlign="center center">
        <div class="generali-input small">
            <div class="title-input" fxLayout="row" fxLayoutAlign="start start" fxFlexFill>
                <h5 translate="generic.EMAIL_OR_CID"></h5>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center">
                <input class="full-width" type="text" id="email"
                    placeholder="{{'accessibilityAttributes.INSERT_EMAIL_OR_CID' | translate}}"
                    attr.aria-label="{{'accessibilityAttributes.INSERT_EMAIL_OR_CID' | translate}}" name="email"
                    #email="ngModel" ngModel required>
            </div>
        </div>
    </div>

    <!-- Pulsante per il login -->
    <div fxLayout="row" fxFlexFill fxLayoutAlign="center end">
        <button class="btn-primary-1 large margin-top40 margin-bottom30" type="submit" [disabled]="!f.valid"
            role="button">
            <p translate="login.SIGN_IN"></p>
        </button>
    </div>
</form>