/*
*    Modulo principale dell'applicazione, utilizzato per le cose più importanti e condivise.
*    Esso infatti esegue il bundle degli import e dei providers. Questi ultimi non si potrebbero inserire nello SharedModule o nei Feature Module in quanto
*    mentre i servizi forniti dal Core Module utilizzano tutti la stessa istanza, i Feature Module, e in particolare quelli in lazy load, utilizzano il Child
*    Injector e puntano ad nuova istanza dei servizi forniti.
*/

import { NgModule, Injectable } from '@angular/core';

import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { SharedModule } from '../shared/shared.module';
import { UrlService } from '../shared/services/url.service';
import { RedirectService } from '../shared/services/redirect.service';
import { AuthService } from '../auth/services/auth.service';
import { AuthGuard } from '../auth/services/auth-guard.service';
import { CompatibilityGuard } from '../shared/services/compatibility-guard.service';
import { LoginGuard } from '../shared/services/login-guard.service';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { CompatibilityErrorComponent } from './compatibility-error/compatibility-error.component';
import { LangsService } from './services/langs.service';
import { TagService } from './services/tag.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { LastInitiativesComponent } from './tables/last-initiatives/last-initiatives.component';
import { ItemsService } from './services/items.service';
import { OwlDateTimeIntl, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { CourseEditionService } from '../initiatives/services/course-edition.service';
import { TakerGuard } from '../shared/services/taker-guard-service';
import { ErrorPageComponent } from './error/error-page.component';
import { AgmCoreModule } from '@agm/core';
import { DashboardGuard } from '../shared/services/dashboard-guard.service';
import { HomeGuard } from '../shared/services/home-guard.service';
import { InitiativeService } from '../initiatives/services/initiative.service';
import { LandingPerformanceManagerComponent } from './landing-performance-manager/landing-performance-manager.component';
import { LinguisticManagementGuard } from '../shared/services/linguistic-management-guard.service';
import { SupplierOrAdminDashboardGuard } from '../shared/services/supplier-or-admin-dashboard-guard.service';
import { WomenInsuranceNetworkGuard } from '../shared/services/women-insurance-network-guard.service';
import { TenantGuard } from '../shared/services/tenant-guard.service';
import { PageNotAuthorizedComponent } from './page-not-authorized/page-not-authorized.component';

export const MY_NATIVE_FORMATS = {
    fullPickerInput: { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' },
    datePickerInput: { year: 'numeric', month: 'numeric', day: 'numeric' },
    timePickerInput: { hour: 'numeric', minute: 'numeric' },
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
};
@Injectable()
export class DefaultIntl extends OwlDateTimeIntl {
    /** A label for the up second button (used by screen readers).  */
    upSecondLabel = 'Add a second';
    /** A label for the down second button (used by screen readers).  */
    downSecondLabel = 'Minus a second';
    /** A label for the up minute button (used by screen readers).  */
    upMinuteLabel = 'Add a minute';
    /** A label for the down minute button (used by screen readers).  */
    downMinuteLabel = 'Minus a minute';
    /** A label for the up hour button (used by screen readers).  */
    upHourLabel = 'Add a hour';
    /** A label for the down hour button (used by screen readers).  */
    downHourLabel = 'Minus a hour';
    /** A label for the previous month button (used by screen readers). */
    prevMonthLabel = 'Previous month';
    /** A label for the next month button (used by screen readers). */
    nextMonthLabel = 'Next month';
    /** A label for the previous year button (used by screen readers). */
    prevYearLabel = 'Previous year';
    /** A label for the next year button (used by screen readers). */
    nextYearLabel = 'Next year';
    /** A label for the previous multi-year button (used by screen readers). */
    prevMultiYearLabel = 'Previous 21 years';
    /** A label for the next multi-year button (used by screen readers). */
    nextMultiYearLabel = 'Next 21 years';
    /** A label for the 'switch to month view' button (used by screen readers). */
    switchToMonthViewLabel = 'Change to month view';
    /** A label for the 'switch to year view' button (used by screen readers). */
    switchToMultiYearViewLabel = 'Choose month and year';
    /** A label for the cancel button */
    cancelBtnLabel = 'Annulla';
    /** A label for the set button */
    setBtnLabel = 'Conferma';
    /** A label for the range 'from' in picker info */
    rangeFromLabel = 'From';
    /** A label for the range 'to' in picker info */
    rangeToLabel = 'To';
    /** A label for the hour12 button (AM) */
    hour12AMLabel = 'AM';
    /** A label for the hour12 button (PM) */
    hour12PMLabel = 'PM';
}

@NgModule({
    declarations: [
        HeaderComponent,
        HomeComponent,
        LandingPerformanceManagerComponent,
        PageNotFoundComponent,
        CompatibilityErrorComponent,
        LastInitiativesComponent,
        ErrorPageComponent,
        PageNotAuthorizedComponent
    ],
    imports: [
        SharedModule,
        AgmCoreModule
    ],
    exports: [
        HeaderComponent,
        LastInitiativesComponent,
        ErrorPageComponent
    ],
    providers: [
        NgxSmartModalService,
        UrlService,
        RedirectService,
        AuthService,
        TenantGuard,
        AuthGuard,
        CompatibilityGuard,
        LoginGuard,
        DashboardGuard,
        SupplierOrAdminDashboardGuard,
        LinguisticManagementGuard,
        WomenInsuranceNetworkGuard,
        TakerGuard,
        LangsService,
        TagService,
        ItemsService,
        CourseEditionService,
        HomeGuard,
        InitiativeService,
        { provide: OWL_DATE_TIME_LOCALE, useValue: 'it' },
        { provide: OWL_DATE_TIME_FORMATS, useValue: MY_NATIVE_FORMATS },
        { provide: OwlDateTimeIntl, useClass: DefaultIntl }
    ]
})
export class CoreModule { }
