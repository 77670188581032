/*
*  Reducer che gestiscono e modificano lo State applicativo riguardante i Supplier (fornitori)
*/

import * as SupplierActions from './supplier.actions';
import * as fromApp from '../../ngrx/app.reducers';

// I fornitori sono una feature dell'applicazione, infatti è un modulo caricato in maniera lazy (e quindi anche lo Store).
// Creo un'interfaccia, FeatureState, in cui mappo i fornitori dello state SupplierState
// Estendo fromApp.AppState così per ereditare tutte le proprietà dello Store principale
export interface FeatureState extends fromApp.AppState {
    supplier: SupplierState
}

// Interfaccia dello Store
export interface SupplierState {
    suppliers: any[];
    count: number;
    fromRecord: number;
    numRecords: number;
    page: number;
    isFetchingSuppliers: boolean;
    selectedSupplierType: string;
    supplierTextFilter: string;
}

// Store iniziale
const initialState: SupplierState = {
    suppliers: [], // lista dei fornitori
    count: 0, // contatore totale dei fornitori
    fromRecord: 0, // numero da cui recuperare gli elementi
    numRecords: 5, // elementi da recuperare
    page: 1, // pagina della tabella visualizzata
    isFetchingSuppliers: false, // verifica se sto eseguendo una chiamata per recuperare i fornitori
    selectedSupplierType: null,
    supplierTextFilter: null
};

export function supplierReducer(state = initialState, action: SupplierActions.SupplierActions) {
    switch (action.type) {
        case SupplierActions.SET_SUPPLIERS:
            // return {
            //     ...state,
            //     templates: [...state.templates, ...action.payload] // sovrascrivo i fornitori
            // };
            return {
                ...state,
                isFetchingSuppliers: false,
                suppliers: [...action.payload] // sovrascrivo i fornitori
            };
        case SupplierActions.SET_PAGINATION:
            return {
                ...state,
                fromRecord: action.payload.fromRecord,
                numRecords: action.payload.numRecords
            };
        case SupplierActions.SET_SUPPLIER_TYPE:
            return {
                ...state,
                selectedSupplierType: action.payload
            };
        case SupplierActions.GET_SUPPLIERS:
            return {
                ...state,
                isFetchingSuppliers: true
            };
        case SupplierActions.SET_PAGE:
            return {
                ...state,
                page: action.payload
            };
        case SupplierActions.SET_SUPPLIERS_COUNT:
            return {
                ...state,
                count: action.payload,
            };
        case (SupplierActions.ADD_SUPPLIER):
            return {
                ...state,
                suppliers: [action.payload, ...state.suppliers]
            };
        case SupplierActions.SET_SUPPLIER_TEXT_FILTER:
            return {
                ...state,
                supplierTextFilter: action.payload
            };
        case (SupplierActions.UPDATE_SUPPLIER):
            // Torneremo sempre lo stato aggiornato, ma prima devo modificare l'iniziativa. Quindi procedo recuperandola
            const supplier = state.suppliers[action.payload.updatedSupplier.supplierId]; // iniziativa che vogliamo cambiare
            const updatedSupplier = {
                ...supplier,
                ...action.payload.updatedSupplier
            };
            const suppliers = [...state.suppliers]; // con lo spread operator tiro fuori tutti gli elementi dell'array state.suppliers per aggiungerli immediatamente nel nuovo array. Così, li ho aggiunti in maniera immutabile
            suppliers[action.payload.updatedSupplier.supplierId] = updatedSupplier; // ora posso aggiornare l'iniziativa
            return {
                ...state,
                suppliers: suppliers
            };
        case (SupplierActions.DELETE_SUPPLIER):
            const oldSuppliers = [...state.suppliers];
            for (let i = 0, suppliersLength = oldSuppliers.length; i < suppliersLength; i++) {
                let currentSupplier = oldSuppliers[i];
                if (currentSupplier.supplierId === action.payload) {
                    oldSuppliers.splice(i, 1);
                    break;
                }
            }
            return {
                ...state,
                suppliers: oldSuppliers
            };
        default:
            return state;
    }
}

// Export delle funzioni che tornano una parte dello Store applicativo
export const getSuppliers = (state: SupplierState) => state.suppliers;
export const getSuppliersCount = (state: SupplierState) => state.count;
export const getSuppliersFromRecord = (state: SupplierState) => (state && state.fromRecord) || 0;
export const getSuppliersNumRecords = (state: SupplierState) => (state && state.numRecords) || 5;
export const getSuppliersPage = (state: SupplierState) => (state && state.page) || 0;
export const isFetchingSuppliers = (state: SupplierState) => state.isFetchingSuppliers;
export const getSupplierFilterType = (state: SupplierState) => (state && state.selectedSupplierType) || null;
export const getSupplierTextFilter = (state: SupplierState) => (state && state.supplierTextFilter) || null;