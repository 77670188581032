/*
*  Componente che permette l'accesso al docente tramite form
*/

import { Component, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'online-meeting-support-login-form',
    templateUrl: './online-meeting-support-login-form.component.html',
    styleUrls: ['./online-meeting-support-login-form.component.scss']
})
export class OnlineMeetingSupportLoginFormComponent implements OnInit {
    @Output() onLogin: EventEmitter<NgForm> = new EventEmitter();

    constructor() { }

    ngOnInit() {
    }

    // Effettua il login
    login(form: NgForm) {
        this.onLogin.emit(form);
    }
}
