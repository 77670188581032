import { Component, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as fromApp from '../../ngrx/app.reducers';
import * as AuthActions from '../ngrx/auth.actions';
import { TranslateService } from '@ngx-translate/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { BaseSubscriberComponent } from 'src/app/shared/components/base-subscriber.component';

@Component({
    selector: 'app-online-meeting-customer-automatic-login',
    templateUrl: './online-meeting-customer-automatic-login.component.html',
    styleUrls: ['./online-meeting-customer-automatic-login.component.scss']
})
export class OnlineMeetingCustomerAutomaticLoginComponent extends BaseSubscriberComponent implements OnInit, OnDestroy {

    constructor(private route: ActivatedRoute,
        private store: Store<fromApp.AppState>,
        public translate: TranslateService) {
        super();
    }

    ngOnInit() {
        let sessionStorageOnlineMeetingUserId = sessionStorage.getItem('onlineMeetingUserId');
        // Se ho lo userId (oppure il cid) nel session storage, posso subito eseguire il login
        if (sessionStorageOnlineMeetingUserId) {
            this.store.dispatch(new AuthActions.DoLogin({ publicMeetingUserCustomerUserId: sessionStorageOnlineMeetingUserId, isPublicMeetingUser: true }));
        }
    }
}
