/*
*  Lista di azioni riguardanti le riunioni
*/

import { Action } from '@ngrx/store';
import { Item } from "../../../cm2-commonclasses";

// Salva la lista di riunioni recuperate
export const SET_MEETINGS = 'SET_MEETINGS';

// Aggiunge una nuova riunione
export const ADD_MEETING = 'ADD_MEETING';

// Aggiorna una riunione
export const UPDATE_MEETING = 'UPDATE_MEETING';

// Get di una riunione
export const GET_MEETING = 'GET_MEETING';

// Set di una riunione
export const SELECT_MEETING = 'SELECT_MEETING';

// Cancella una riunione
export const DELETE_MEETING = 'DELETE_MEETING';

// Salva una riunione
export const STORE_MEETING = 'STORE_MEETING';

// Recupera le riunioni da remoto
export const GET_MEETINGS = 'GET_IMEETINGS';

// Setta il contatore delle riunioni recuperate da remoto
export const SET_MEETINGS_COUNT = 'SET_MEETINGS_COUNT';

// Setta la paginazione
export const SET_PAGINATION = 'SET_PAGINATIONN';

// Setta la pagina corrente
export const SET_PAGE = 'SET_PAGE';

// Salva l'intero oggetto della riunione scelta
export const SET_SELECTED_MEETING_ITEM = 'SET_SELECTED_MEETING_ITEM';

// Salva l'intero oggetto padre (quindi il percorso) della riunione scelta
export const SET_PATH_ITEM_OF_SELECTED_MEETING = 'SET_PATH_ITEM_OF_SELECTED_MEETING';

// Resetta i dati della riunione scelta
export const DELETE_SELECTED_MEETING_DATA = 'DELETE_SELECTED_MEETING_DATA';

// Resetta i dati della riunione scelta
export const SET_CURRENT_WIZARD_STEP = 'SET_CURRENT_WIZARD_STEPP';

// Setta il filtro per la tipologia
export const SET_MEETINGS_TYPE = 'SET_MEETINGS_TYPE';

// Setta il filtro per la ricerca testuale
export const SET_MEETINGS_TEXT_FILTER = 'SET_MEETINGS_TEXT_FILTER';

// Salva la lista di riunioni recuperate
export const START_COUNT_QRS = 'START_COUNT_QRS';

// Setta il filtro per la ricerca testuale
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';

export class SetMeetings implements Action {
    readonly type = SET_MEETINGS;

    constructor(public payload: any[]) { }
}

export class AddMeeting implements Action {
    readonly type = ADD_MEETING;

    constructor(public payload: any) { }
}

export class UpdateMeeting implements Action {
    readonly type = UPDATE_MEETING;

    constructor(public payload: { index: number, updatedMeeting: any }) { }
}

export class GetMeeting implements Action {
    readonly type = GET_MEETING;

    constructor(public payload: string) { }
}

export class SetSelectedMeetingItem implements Action {
    readonly type = SET_SELECTED_MEETING_ITEM;

    constructor(public payload: Item) { }
}

export class DeleteSelectedMeetingData implements Action {
    readonly type = DELETE_SELECTED_MEETING_DATA;
}

export class SetPathItemOfSelectedMeeting implements Action {
    readonly type = SET_PATH_ITEM_OF_SELECTED_MEETING;

    constructor(public payload: Item) { }
}

export class SelectMeeting implements Action {
    readonly type = SELECT_MEETING;

    constructor(public payload: string) { }
}

export class SetMeetingsType implements Action {
    readonly type = SET_MEETINGS_TYPE;

    constructor(public payload: string) { }
}

export class SetMeetingsTextFilter implements Action {
    readonly type = SET_MEETINGS_TEXT_FILTER;

    constructor(public payload: string) { }
}

export class DeleteMeeting implements Action {
    readonly type = DELETE_MEETING;

    constructor(public payload: string) { }
}

export class StoreMeeting implements Action {
    readonly type = STORE_MEETING;
}

export class GetMeetings implements Action {
    readonly type = GET_MEETINGS;
}

export class SetMeetingsCount implements Action {
    readonly type = SET_MEETINGS_COUNT;

    constructor(public payload: number) { }
}

export class SetPage implements Action {
    readonly type = SET_PAGE;

    constructor(public payload: number) { }
}

export class SetPagination implements Action {
    readonly type = SET_PAGINATION;

    constructor(public payload: { fromRecord: number, numRecords: number }) { }
}

export class SetCurrentWizardStep implements Action {
    readonly type = SET_CURRENT_WIZARD_STEP;

    constructor(public payload: string) { }
}

export class StartCountsQrs implements Action {
    readonly type = START_COUNT_QRS;

    constructor(public payload: any) { }
}

export class SetNotifications implements Action {
    readonly type = SET_NOTIFICATIONS;

    constructor(public payload: any) { }
}

export type MeetingsActions =
    SetMeetings |
    GetMeeting |
    SelectMeeting |
    AddMeeting |
    UpdateMeeting |
    DeleteMeeting |
    SetMeetingsTextFilter |
    StoreMeeting |
    SetMeetingsCount |
    SetPagination |
    DeleteSelectedMeetingData |
    SetPage |
    SetSelectedMeetingItem |
    SetPathItemOfSelectedMeeting |
    GetMeetings |
    SetMeetingsType |
    SetCurrentWizardStep |
    StartCountsQrs |
    SetNotifications;
