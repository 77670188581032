/*
*  Reducer che gestiscono e modificano lo State applicativo riguardante il profilo dell'utente loggato
*/

import * as ProfileActions from './profile.actions';
import * as CommonClasses from "../../../cm2-commonclasses";

export interface ProfileState {
    loggedUser: CommonClasses.JwtPayload;
    editedUser: CommonClasses.User;
    langs: CommonClasses.Lang[]
}

const initialState: ProfileState = {
    loggedUser: null,
    editedUser: null,
    langs: null
};

// Importo il componente per decodificare il token JWT
const jwtDecode = require('jwt-decode');

export function profileReducer(state = initialState, action: ProfileActions.ProfileActions) {
    switch (action.type) {
        case ProfileActions.UPDATE_USER:
            const oldUserInfo = state.loggedUser;
            const updatedUser = {
                ...oldUserInfo,
                ...action.payload.loggedUser
            };
            const user = updatedUser;
            return {
                ...state,
                loggedUser: user,
                editedUser: null
            };
        case (ProfileActions.CANCEL_LOGGED_USER):
            // Cancello le informazioni sull'utente loggato
            return {
                ...state,
                loggedUser: null
            };
        case (ProfileActions.SAVE_AVAILABLE_LANGS):
            // Salva le lingue disponibili a sistema recuperate tramite servizi
            return {
                ...state,
                langs: action.payload
            };
        case (ProfileActions.DECODE_TOKEN):
            // Decodifico il token ricevuto dopo il login e lo salvo, questo mi identificherà l'oggetto rappresentante l'utente loggato
            const decodedJwt = {
                ...state.loggedUser,
                ...jwtDecode(action.payload)
            };
            state.loggedUser = decodedJwt;
            return {
                ...state,
                loggedUser: state.loggedUser
            };
        case ProfileActions.START_EDIT:
            // Inizia l'edit del profilo dell'utente loggato
            const editedUser = { ...state.editedUser };
            return {
                ...state,
                editedUser: editedUser
            };
        case ProfileActions.STOP_EDIT:
            // Ferma l'edit del profilo dell'utente loggato
            return {
                ...state,
                editedUser: null
            };
        default:
            return state;
    }
}

// Export delle funzioni che tornano una parte dello Store applicativo
export const getLoggedUser = (state: ProfileState) => state.loggedUser;
export const getAvailableLangs = (state: ProfileState) => state.langs;