<div class="card-container" [ngStyle]="{'background-image': backgroundStyleSelected}" fxLayoutFill>
    <div fxLayout="column" fxLayoutAlign="center center">
        <!-- TITOLO -->
        <div class="title-box" fxLayout="column" fxLayoutAlign="center center">
            <span class="title-text">{{'generic.authPages.title.' + auth | translate}}</span>
        </div>
        <hr class="margin0 hr-width">
    </div>

    <div fxLayout="row" class="description-box" fxLayoutAlign="center center">
        <!-- DESCRIZIONE -->
        <p class="description-text">{{'generic.authPages.description.' + auth | translate}}</p>
    </div>
    <div fxLayout="row" class="padding-top30" fxLayoutAlign="center center">
        <!-- FUNZIONE REDIRECT -->
        <div (click)="redirectFunction(auth)" role="button" class="go-to-section-box">
            <p class="go-to-section-text" translate="generic.authPages.GO_TO_SECTION"></p>
        </div>
    </div>
</div>