/*
*  Componente che gestisce l'home page dell'applicazione
*/

import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { Item, PaginationWrapper, SenecaResponse, ItemTypes, ItemAttribute, ItemAttributeTypes, ItemAttributeStatusTypes } from "../../../cm2-commonclasses";
import * as InitiativeActions from '../../initiatives/ngrx/initiative.actions';
import * as fromApp from '../../ngrx/app.reducers';
import { BaseSubscriberComponent } from '../../shared/components/base-subscriber.component';
import { CourseEditionUtil, CourseModuleUtil } from '../../shared/models/course.model';
import { ExtendedItem } from '../../shared/models/extended-item.model';
import { RedirectService } from '../../shared/services/redirect.service';
import { ItemsService } from '../services/items.service';
import { LangsService } from '../services/langs.service';
import { ItemUtil } from '../../shared/models/item.model';
import moment = require('moment');

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseSubscriberComponent implements OnDestroy, OnInit {
    getLastInitiatives$: Subscription;
    lastInitiatives: Item[];
    initiativesLoading: boolean;
    radioPossibilities = [1, 2, 3];
    selectedCreationPossibility = { options: '1' };
    result$: Subscription;
    applicationLang: string;
    initiativeToDelete: Item;
    showInitiativeFor: string;
    isSupplier: boolean;
    isMenuVisible: boolean[];
    dateFormat: string;
    isViewNewInitiativeBlock: boolean;

    constructor(private itemsService: ItemsService,
        private translate: TranslateService,
        public ngxSmartModalService: NgxSmartModalService,
        private router: Router,
        private redirectService: RedirectService,
        private langsService: LangsService,
        private store: Store<fromApp.AppState>,
        private toastr: ToastrService) {
        super();
        this.showInitiativeFor = 'IN_PROGRESS_INITIATIVES';
        this.isViewNewInitiativeBlock = false;
    }

    ngOnInit() {
        // Collegamento alla lingua scelta dall'utente
        const applicationLang$: Observable<string> = this.store.select(fromApp.getApplicationLang);
        // Collegamento alla variabile indicante se l'utente loggato è un supplier
        const isSupplier$: Observable<boolean> = this.store.select(fromApp.isSupplier);
        if (!this.lastInitiatives) {
            this.initiativesLoading = true;
        }

        // Il cast <any> è necessario per evitare l'errore di Typescript che non riesce a costruire un combineLatest con più di 6 parametri
        const combinedSelectes$ = combineLatest<any>(isSupplier$, applicationLang$)
        this.result$ = combinedSelectes$.subscribe(
            ([isSupplier, applicationLang]) => {
                this.applicationLang = applicationLang;
                if (this.applicationLang && !this.lastInitiatives && !this.getLastInitiatives$) {
                    this.getLastInitiatives();
                    this.dateFormat = this.translate.instant('generic.DATE_FORMAT');
                };
                this.isSupplier = isSupplier;
                this.isMenuVisible = [];

            });
    }

    // Effettua il toggle sulla selezione del modo con cui creare l'iniziativa
    toggledChange(newValue: string): void {
        this.selectedCreationPossibility.options = newValue;
    }

    toggleMenu(index: number) {
        this.isMenuVisible[index] = !this.isMenuVisible[index];

    }


    // Pulisce dalla memoria la selezione del template scelto per la rimozione
    clearInitiativeToDelete() {
        // Pulisco le iniziative
        this.initiativeToDelete = null;
    }

    // Annulla l'operazione di cancellare l'iniziativa, chiudendo la mdoale
    cancelDeleteInitiative() {
        return;
    }

    // Apre una modale per chiedere conferma sulla rimozione della iniziativa selezionate
    openRemoveInitiativeModal(initiative: any) {
        const confimation = new EventEmitter<any>();
        const secondConfirmation = new EventEmitter<any>();
        // Archivio l'iniziativa che mi è stata passata
        this.initiativeToDelete = initiative;
        // Prima di aprire la modale, setto le traduzioni e i metodi da agganciare le funzioni
        const confirmModalData: Object = {
            modalTitle: this.translate.instant('initiatives.REMOVE_INITIATIVE_MODAL.TITLE'),
            modalBody: this.translate.instant('initiatives.REMOVE_SYLLABUS_OF_INITIATIVE_MODAL.BODY'),
            confirmBtnLabel: this.translate.instant('initiatives.REMOVE_SYLLABUS_OF_INITIATIVE_MODAL.DELETE_WITHOUTH_SYLLABUS'),
            confirmSecondBtnLabel: this.translate.instant('initiatives.REMOVE_SYLLABUS_OF_INITIATIVE_MODAL.DELETE_WITH_SYLLABUS'),
            cancelBtnLabel: this.translate.instant('modals.CANCEL_BTN'),
            confirmation: confimation,
            secondConfirmation: secondConfirmation,
            dismissAction: this.cancelDeleteInitiative,
            componentRef: this
        };
        // Setto i dati
        this.ngxSmartModalService.setModalData(confirmModalData, 'confirmModal');
        // Recupero un'istanza della modale
        let confirmModalRef = this.ngxSmartModalService.getModal('confirmModal');
        confirmModalRef.setData(confirmModalData, true);
        confimation.subscribe(() => {
            if (this.initiativeToDelete && this.initiativeToDelete.itemId) {
                this.initiativesLoading = true;
                this.deleteInitiative();
            }
        });
        secondConfirmation.subscribe(() => {
            if (this.initiativeToDelete && this.initiativeToDelete.itemId) {
                this.initiativesLoading = true;
                this.deleteInitiative(true);
            }
        });
        // Sto in ascolto del dismiss, cioè dell'annullamento
        confirmModalRef.onDismiss.subscribe(() => {
            // Pulisco l'iniziativa
            this.clearInitiativeToDelete();
        });
        // Apro la modale
        confirmModalRef.open();
    }

    // Vedi blocco nuova iniziativa o no
    viewNewInitiativeBlock() {
        this.isViewNewInitiativeBlock = !this.isViewNewInitiativeBlock;
    }

    // Cancella l'iniziativa selezionata
    deleteInitiative(removeSyllabus?: boolean) {
        this.itemsService.deleteItem(this.initiativeToDelete.itemId, removeSyllabus)
            .subscribe(data => {
                if (data.error) {
                    // Mostro il toaster di errore
                    this.toastr.error(data.error);
                    // Pulisco l'iniziativa
                    this.clearInitiativeToDelete();
                } else {
                    this.store.dispatch(new InitiativeActions.DeleteInitiative(this.initiativeToDelete.itemId));
                    this.toastr.success(this.translate.instant('initiatives.INITIATIVE_DELETED'));
                    // Recupero la lista delle iniziative
                    this.getLastInitiatives();
                    // Pulisco le iniziative
                    this.clearInitiativeToDelete();
                }
            },
                (err) => {
                    this.toastr.error(err.message);
                    // Pulisco le iniziative
                    this.clearInitiativeToDelete();
                })
    }

    // Funzione che esegue lo switch tra le iniziative in sospeso e in corso
    onInitiativeStatusFilterChanged(showFor: string) {
        this.showInitiativeFor = showFor;
        if (showFor != 'NEW_INITIATIVES') {
            this.isViewNewInitiativeBlock = false;
            // Avvio una nuova ricerca
            this.getLastInitiatives();
        } else {
            this.isViewNewInitiativeBlock = true;
        }
    }

    // Recupera le ultime 6 iniziative (o proposte, qualora fossi loggato come fornitore)
    getLastInitiatives(newShowInitiativeFor?: string) {
        this.initiativesLoading = true;

        // Se necessario (ad esempio quando cambio la modalità iniziative in corso/sospese) resetto i dati
        if (newShowInitiativeFor && this.lastInitiatives && this.lastInitiatives.length) {
            this.lastInitiatives.length = 0;
        };

        // All'inizializzazione del componente, recupero la lista delle ultime dieci iniziative
        this.getLastInitiatives$ = this.itemsService.getLatestOpenedSuspendedInitiatives('0', '6', this.showInitiativeFor)
            .subscribe(
                (data: SenecaResponse<PaginationWrapper<ExtendedItem>>) => {
                    if (data.error) {
                        // Vedo se c'è la traduzione dell'errore
                        this.toastr.error(this.translate.instant('errors.' + data.error));
                    } else {
                        if (data.response && data.response.rows) {
                            for (let i = 0, initiativesLength = data.response.rows.length; i < initiativesLength; i++) {
                                let currentInitiative = data.response.rows[i];
                                // Setto la lingua corrente dell'Item
                                currentInitiative.currentItemLang = ItemUtil.getItemLang(this.langsService, this.applicationLang, currentInitiative);
                                // Traduco la tipologia
                                currentInitiative.itemTypeLabel = CourseEditionUtil.getCourseTypologyLabel(currentInitiative, this.translate);
                                // Traduco los tato
                                currentInitiative.itemStatusLabel = CourseEditionUtil.getCourseStatusLabel(currentInitiative, this.translate);
                            }
                        }
                        this.lastInitiatives = data.response && data.response.rows;
                        for (let i = 0; i < this.lastInitiatives.length; i++) {
                            this.isMenuVisible.push(false);
                        }
                    }
                    this.initiativesLoading = false;
                }
                , (err) => {
                    if (err && err.message) {
                        // Vedo se c'è la traduzione dell'errore
                        this.toastr.error(this.translate.instant('errors.' + err.message));
                    }
                    this.initiativesLoading = false;
                }
            );
    }

    // Porta alla pagina di modifica di una iniziativa
    goToEditInitiative(initiative: Item): void {
        this.router.navigate(['initiatives/editInitiative', initiative.itemId]);
    }

    // Porta alla pagina di creazione dell'iniziativa, in base alla card scelta
    goToCreateInitiative() {
        if (this.selectedCreationPossibility.options === '1') {
            // Da nuovo syllabus
            this.redirectService.goToCreateInitiativeNewSyllabus();
        } else if (this.selectedCreationPossibility.options === '2') {
            // Da syllabus esistente
            this.redirectService.goToCreateInitiativeFromSyllabus();
        } else if (this.selectedCreationPossibility.options === '3') {
            // Da smart
            this.redirectService.goToCreateInitiativeSmart();
        }
    }

    ngOnDestroy() {
        if (this.getLastInitiatives$) {
            this.getLastInitiatives$.unsubscribe();
        }
        if (this.result$) {
            this.result$.unsubscribe();
        }
    }
}