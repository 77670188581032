<div *ngIf="applicationLang" class="content">
    <div class="theme-primary margin-bottom48 margin-top25" fxLayout="column">
        <h1 class="text-center" [translate]="'routing.IMPORT_FINANCING_NOTICE' | translate"></h1>
    </div>

    <div class="import-container">
        <!-- Input di upload di un nuovo "lenzuolone" -->
        <div class="upload-new-file-form">
            <div class="custom-select generali-input small" fxFlexFill>
                <input name="new-import-financing-file" id="new-import-financing-file" class="supplier-input-file"
                    type="file" ng2FileSelect [uploader]="importFileTmp.uploader" required
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                <!-- Nome del file aggiunto -->
                <label for="new-import-financing-file" class="selected-file-name-preview">{{
                    importFileTmp
                    &&
                    importFileTmp.file &&
                    importFileTmp.file &&
                    importFileTmp.file.file
                    &&
                    importFileTmp.file.file.name || (
                    'generic.SELECT_FILE' |
                    translate ) }}</label>
                <label for="new-import-financing-file" class="new-proposal-file-label">
                    <svg-icon class="svg email-icon" src="assets/img/gear-tree-file.svg"></svg-icon>
                    <span translate="generic.FIND_FILE_IN_DIRECTORY"></span>
                </label>
            </div>
        </div>
        <!-- Scarica tracciato vuoto -->
        <div class="download-track">
            <p (click)="downloadTrack()">Scarica tracciato</p>
            <svg-icon src="assets/img/xls_icon.svg"></svg-icon>
        </div>

        <div class="action-bar margin-top32">
            <!-- Annulla l'upload del file nuovo selezionato -->
            <button (click)="resetFileData();" class="btn-secondary-1 large margin-top20 margin-right20 text-white"
                type="button">
                <span translate="generic.CANCEL"></span>
            </button>
            <!-- Conferma import  -->
            <button (click)="uploadImportFinancingNotices()"
                [disabled]="!importFileTmp || !importFileTmp.file || !importFileTmp.file.file"
                class="btn-primary-1 large margin-top20" type="button">
                <span translate="generic.CONFIRM"></span>
            </button>
        </div>
    </div>
</div>