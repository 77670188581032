/*
*  Lista di azioni riguardanti la dashboard
*/

import { Action } from '@ngrx/store';
import { RoleIndicator } from 'src/app/shared/models/role-indicator.model';

// Salva la lista degli indicatori dei ruoli
export const SET_ROLES_INDICATORS = 'SET_ROLES_INDICATORS';

// Recupera i dati per gli indicatori dei ruoli
export const GET_ROLES_INDICATORS = 'GET_ROLES_INDICATORS';

// Elimina gli indicatori
export const DELETE_ROLES_INDICATORS = 'DELETE_ROLES_INDICATORS';

export class SetRolesIndicators implements Action {
    readonly type = SET_ROLES_INDICATORS;

    constructor(public payload: RoleIndicator[]) { }
}

export class GetRolesIndicators implements Action {
    readonly type = GET_ROLES_INDICATORS;

    constructor(public payload?: boolean) { }
}

export class DeleteRolesIndicators implements Action {
    readonly type = DELETE_ROLES_INDICATORS;

    constructor() { }
}

export type DashboardActions =
    SetRolesIndicators |
    DeleteRolesIndicators |
    GetRolesIndicators;