/*
*  Lista di azioni riguardanti il performance management
*/

import { Action } from '@ngrx/store';

// Salva la lista degli anni periodo
export const SET_PERFORMANCE_YEARS = 'SET_PERFORMANCE_YEARS';

// Recupera i dati per gli anni periodo
export const GET_PERFORMANCE_YEARS = 'GET_PERFORMANCE_YEARS';

// Setta il valore dell anno selezionato
export const SET_PERFORMANCE_YEAR_SELECTED = 'SET_PERFORMANCE_YEAR_SELECTED';

// Aggiorna i rent recuperati
export const SET_PERFORMANCE_RENTS = 'SET_PERFORMANCE_RENTS';

// Recupera i rent
export const GET_PERFORMANCE_RENTS = 'GET_PERFORMANCE_RENTS';

// Setta il contatore delle riunioni recuperate da remoto
export const SET_PERFORMANCE_RENTS_COUNT = 'SET_PERFORMANCE_RENTS_COUNT';

// Setta la paginazione
export const SET_PAGINATION_PERFORMANCE = 'SET_PAGINATION_PERFORMANCE';

// Setta la pagina corrente
export const SET_PAGE_PERFORMANCE = 'SET_PAGE_PERFORMANCE';

export class SetPerformanceYears implements Action {
    readonly type = SET_PERFORMANCE_YEARS;

    constructor(public payload: string[]) { }
}

export class GetPerformanceYears implements Action {
    readonly type = GET_PERFORMANCE_YEARS;

    constructor(public payload?: any) { }
}

export class SetPerformanceYearSelected implements Action {
    readonly type = SET_PERFORMANCE_YEAR_SELECTED;

    constructor(public payload: string) { }
}

export class SetPerformanceRents implements Action {
    readonly type = SET_PERFORMANCE_RENTS;

    constructor(public payload: any[]) { }
}

export class GetPerformanceRents implements Action {
    readonly type = GET_PERFORMANCE_RENTS;

    // Payload dell'azione
    constructor(
        public payload: {
            attributeTypes?: string | string[],
            attributeValues?: string | string[],
            statusTypes?: string | string[],
            userSearchedText?: string,
            groupId?: string,
            yearHomeSelected: string,
            noCountRents?: boolean,
            referenceId?: string
        }
    ) { }
}

export class SetPerformanceRentsCount implements Action {
    readonly type = SET_PERFORMANCE_RENTS_COUNT;

    constructor(public payload: number) { }
}

export class SetPageMonitoring implements Action {
    readonly type = SET_PAGE_PERFORMANCE;

    constructor(public payload: number) { }
}

export class SetPaginationMonitoring implements Action {
    readonly type = SET_PAGINATION_PERFORMANCE;

    constructor(public payload: { fromRecord: number, numRecords: number }) { }
}

export type PerformanceManagementActions =
    SetPerformanceYears |
    GetPerformanceYears |
    SetPerformanceYearSelected |
    SetPerformanceRents |
    GetPerformanceRents |
    SetPerformanceRentsCount |
    SetPageMonitoring |
    SetPaginationMonitoring;