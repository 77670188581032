import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from '../../ngrx/app.reducers';
import { BaseSubscriberComponent } from '../../shared/components/base-subscriber.component';
import { Router } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { GlobalApplicationData } from 'src/app/shared/models/global-application-data.model';

@Component({
  selector: 'app-campagnaVaccinaleTrieste',
  templateUrl: './campagnaVaccinaleTrieste.component.html',
  styleUrls: ['./campagnaVaccinaleTrieste.component.scss']
})
export class CampagnaVaccinaleTriesteComponent extends BaseSubscriberComponent {
  applicationContext: string;
  urlList = [
    { id: "e2ca08d0-a272-495a-9b2d-b0e54babdd8d", title: "22/11/2021" },
    { id: "37582adc-80fb-4e41-a031-c964997a3670", title: "25/11/2021" },
    { id: "56c791ed-52e5-4610-94a9-91fe7cc29740", title: "29/11/2021" },
  ]

  constructor(private router: Router,
    private store: Store<fromApp.AppState>) {
    super();
    this.store.select(fromApp.getGlobalApplicationData).pipe(
      map((applicationData: GlobalApplicationData) => {
        if (applicationData && applicationData.applicationContext) {
          this.applicationContext = applicationData.applicationContext + '#/takers/courseSubscription/';
        }
      }),
      take(1)
    ).subscribe();
  }

  openUrl(id) {
    window.open(this.applicationContext + id, '_blank');
  }
}