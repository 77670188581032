/*
 * Modulo per la gestione dell'autenticazione
 *
 */

import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { LoginComponent } from "./login/login.component";
import { TranslateModule } from "@ngx-translate/core";
import { CommonModule } from "@angular/common";
import { FlexLayoutModule } from "@angular/flex-layout";
import { LoginFormComponent } from "./login/login-form/login-form.component";
import { SupplierLoginComponent } from "./supplier-login/supplier-login.component";
import { SupplierLoginFormComponent } from "./supplier-login/supplier-login-form/supplier-login-form.component";
import { TakerLoginFormComponent } from "./taker-login/taker-login-form/taker-login-form.component";
import { TakerLoginComponent } from "./taker-login/taker-login.component";
import { OwnershipLoginFormComponent } from "./ownership-login/ownership-login-form/ownership-login-form.component";
import { OwnershipLoginComponent } from "./ownership-login/ownership-login.component";
import { UserMeetingLoginComponent } from "./user-meeting-login/user-meeting-login.component";
import { LocalLoginComponent } from "./login/local-login.component";
import { LoginSamlErrorComponent } from "./login/login-saml-error.component";
import { LogoutSsoCompletedComponent } from "./login/logout-sso-completed.component";
import { TakerLocalLoginFormComponent } from "./taker-local-login/taker-local-login-form/taker-local-login-form.component";
import { TakerLocalLoginComponent } from "./taker-local-login/taker-local-login.component";
import { OwnershipLocalLoginFormComponent } from "./ownership-local-login/ownership-local-login-form/ownership-local-login-form.component";
import { OwnershipLocalLoginComponent } from "./ownership-local-login/ownership-local-login.component";
import { UserMeetingLocalLoginFormComponent } from "./user-meeting-local-login/user-meeting-local-login-form/user-meeting-local-login-form.component";
import { UserMeetingLocalLoginComponent } from "./user-meeting-local-login/user-meeting-local-login.component";
import { OnlineMeetingUserLoginComponent } from "./online-meeting-user-login/online-meeting-user-login.component";
import { OnlineMeetingUserLoginFormComponent } from "./online-meeting-user-login/online-meeting-user-login-form/online-meeting-user-login-form.component";
import { OnlineMeetingTeacherLoginComponent } from "./online-meeting-teacher-login/online-meeting-teacher-login.component";
import { OnlineMeetingTeacherLoginFormComponent } from "./online-meeting-teacher-login/online-meeting-teacher-login-form/online-meeting-teacher-login-form.component";
import { OnlineMeetingSupportLoginComponent } from "./online-meeting-support-login/online-meeting-support-login.component";
import { OnlineMeetingSupportLoginFormComponent } from "./online-meeting-support-login/online-meeting-support-login-form/online-meeting-support-login-form.component";
import { PublicUsersLoginComponent } from "./public-users-login/public-users-login.component";
import { InputPlaceholderComponent } from "../core/placeholders/input-placeholders/input-placeholder.component";
import { WelionWelfareUsersLoginComponent } from "./welion-welfare-users-login/welion-welfare-users-login.component";
import { WelionWelfareSupportLoginComponent } from "./welion-welfare-support-login/welion-welfare-support-login.component";
import { WelionWelfareSupportLoginFormComponent } from "./welion-welfare-support-login/welion-welfare-support-login-form/welion-welfare-support-login-form.component";
import { WelionWelfareTeacherLoginComponent } from "./welion-welfare-teacher-login/welion-welfare-teacher-login.component";
import { WelionWelfareTeacherLoginFormComponent } from "./welion-welfare-teacher-login/welion-welfare-teacher-login-form/welion-welfare-teacher-login-form.component";
import { WelionTSLocalLoginFormComponent } from "./welion-ts-local-login/welion-ts-local-login-form/welion-ts-local-login-form.component";
import { WelionTSLocalLoginComponent } from "./welion-ts-local-login/welion-ts-local-login.component";
import { WelionTSLoginFormComponent } from "./welion-ts-login/welion-ts-login-form/welion-ts-login-form.component";
import { WelionTSLoginComponent } from "./welion-ts-login/welion-ts-login.component";
import { OnlineMeetingCustomerAutomaticLoginComponent } from "./online-meeting-customer-automatic-login/online-meeting-customer-automatic-login.component";
import { WelfareEnelLoginComponent } from "./welfare-enel-login/welfare-enel-login.component";
import { WelfareEnelLoginFormComponent } from "./welfare-enel-login/welfare-enel-login-form/welfare-enel-login-form.component";
import { WelfareEnelLocalLoginComponent } from "./welfare-enel-local-login/welfare-enel-local-login.component";
import { EnelLoginSamlErrorComponent } from "./login/enel-login-saml-error.component";
import { EnelLogoutSsoCompletedComponent } from "./login/enel-logout-sso-completed.component";
import { EnelWelfareSupportLoginFormComponent } from "./enel-welfare-support-login/enel-welfare-support-login-form/enel-welfare-support-login-form.component";
import { EnelWelfareSupportLoginComponent } from "./enel-welfare-support-login/enel-welfare-support-login.component";
import { EnelWelfareTeacherLoginFormComponent } from "./enel-welfare-teacher-login/enel-welfare-teacher-login-form/enel-welfare-teacher-login-form.component";
import { EnelWelfareTeacherLoginComponent } from "./enel-welfare-teacher-login/enel-welfare-teacher-login.component";
import { EuropeAssistanceLocalLoginComponent } from "./europe-assistance-local-login/europe-assistance-local-login.component";
import { EuropeAssistanceLoginComponent } from "./europe-assistance-login/europe-assistance-login.component";
import { EuropeAssistanceLoginFormComponent } from "./europe-assistance-login/europe-assistance-login-form/europe-assistance-login-form.component";
import { NgSelectModule } from '@ng-select/ng-select';
import { SmartEnergySubscriptionLoginComponent } from "./smart-energy-subscription-login/smart-energy-subscription-login.component";
import { SmartEnergySubscriptionLocalLoginComponent } from "./smart-energy-subscription-local-login/smart-energy-subscription-local-login.component";
import { NudgingLocalLoginComponent } from "./login/nudging-local-login.component";
import { MedexLoginComponent } from "./medex-login/medex-login.component";
import { MedexLoginFormComponent } from "./medex-login/medex-login-form/medex-login-form.component";
import { HeritageLoginComponent } from "./heritage-login/heritage-login.component";
import { AngularSvgIconModule } from "angular-svg-icon";
import { DeiLoginComponent } from "./dei-login/dei-login.component";

@NgModule({
    declarations: [
        LoginComponent,
        LocalLoginComponent,
        LoginSamlErrorComponent,
        LogoutSsoCompletedComponent,
        EnelLogoutSsoCompletedComponent,
        EnelLoginSamlErrorComponent,
        LoginFormComponent,
        SupplierLoginComponent,
        SupplierLoginFormComponent,
        TakerLoginComponent,
        OwnershipLoginComponent,
        WelfareEnelLoginComponent,
        WelfareEnelLoginFormComponent,
        WelionTSLoginComponent,
        WelfareEnelLocalLoginComponent,
        OnlineMeetingUserLoginComponent,
        OnlineMeetingUserLoginFormComponent,
        OnlineMeetingTeacherLoginComponent,
        OnlineMeetingTeacherLoginFormComponent,
        OnlineMeetingSupportLoginComponent,
        OnlineMeetingSupportLoginFormComponent,
        WelionWelfareSupportLoginComponent,
        WelionWelfareSupportLoginFormComponent,
        EnelWelfareSupportLoginComponent,
        EnelWelfareSupportLoginFormComponent,
        EnelWelfareTeacherLoginComponent,
        EnelWelfareTeacherLoginFormComponent,
        WelionWelfareTeacherLoginComponent,
        WelionWelfareTeacherLoginFormComponent,
        UserMeetingLoginComponent,
        WelionWelfareUsersLoginComponent,
        TakerLoginFormComponent,
        TakerLocalLoginComponent,
        NudgingLocalLoginComponent,
        OwnershipLoginFormComponent,
        OwnershipLocalLoginComponent,
        WelionTSLoginFormComponent,
        OnlineMeetingCustomerAutomaticLoginComponent,
        WelionTSLocalLoginComponent,
        PublicUsersLoginComponent,
        InputPlaceholderComponent,
        TakerLocalLoginFormComponent,
        OwnershipLocalLoginFormComponent,
        WelionTSLocalLoginFormComponent,
        UserMeetingLocalLoginFormComponent,
        UserMeetingLocalLoginComponent,
        UserMeetingLocalLoginFormComponent,
        EuropeAssistanceLocalLoginComponent,
        EuropeAssistanceLoginComponent,
        EuropeAssistanceLoginFormComponent,
        SmartEnergySubscriptionLoginComponent,
        SmartEnergySubscriptionLocalLoginComponent,
        MedexLoginComponent,
        MedexLoginFormComponent,
        HeritageLoginComponent,
        DeiLoginComponent
    ],
    imports: [FlexLayoutModule, CommonModule, FormsModule, TranslateModule, NgSelectModule, AngularSvgIconModule],
    exports: [NgSelectModule]
})
export class AuthModule { }
