/*
*  Lista di azioni riguardanti i progetti speciali
*/

import { Action } from '@ngrx/store';
import { Item } from '../../../cm2-commonclasses';

// Salva la lista di progetti speciali recuperati
export const SET_SPECIAL_PROJECTS = 'SET_SPECIAL_PROJECTS';

// Aggiunge un nuovo progetto speciale
export const ADD_SPECIAL_PROJECT = 'ADD_SPECIAL_PROJECT';

// Aggiorna un progetto speciale
export const UPDATE_SPECIAL_PROJECT = 'UPDATE_SPECIAL_PROJECT';

// Cancella un progetto speciale
export const DELETE_SPECIAL_PROJECT = 'DELETE_SPECIAL_PROJECT';

// Salva il progetto speciale
export const STORE_SPECIAL_PROJECT = 'STORE_SPECIAL_PROJECT';

// Recupera i progetti speciali da remoto
export const GET_SPECIAL_PROJECTS = 'GET_SPECIAL_PROJECTS';

// Setta il filtro per la tipologia
export const SET_SPECIAL_PROJECTS_TYPE = 'SET_SPECIAL_PROJECTS_TYPE';

// Setta il contatore dei progetti speciali recuperato da remoto
export const SET_SPECIAL_PROJECTS_COUNT = 'SET_SPECIAL_PROJECTS_COUNT';

// Setta la paginazione
export const SET_PAGINATION = 'SET_PAGINATION';

// Setta la pagina corrente
export const SET_PAGE = 'SET_PAGE';

// Setta il testo di ricerca
export const SET_TEXT_FILTER = 'SET_TEXT_FILTER'

export class SetSpecialProjects implements Action {
    readonly type = SET_SPECIAL_PROJECTS;

    constructor(public payload: any[]) { }
}

export class AddSpecialProject implements Action {
    readonly type = ADD_SPECIAL_PROJECT;

    constructor(public payload: Item) { }
}

export class UpdateSpecialProject implements Action {
    readonly type = UPDATE_SPECIAL_PROJECT;

    constructor(public payload: { index: number, updatedSpecialProject: Item }) { }
}

export class DeleteSpecialProject implements Action {
    readonly type = DELETE_SPECIAL_PROJECT;

    constructor(public payload: string) { }
}

export class StoreSpecialProject implements Action {
    readonly type = STORE_SPECIAL_PROJECT;
}

export class GetSpecialProjects implements Action {
    readonly type = GET_SPECIAL_PROJECTS;

    constructor(public payload?: boolean) { }
}

export class SetSpecialProjectsCount implements Action {
    readonly type = SET_SPECIAL_PROJECTS_COUNT;

    constructor(public payload: number) { }
}

export class SetSpecialProjectsType implements Action {
    readonly type = SET_SPECIAL_PROJECTS_TYPE;

    constructor(public payload: string) { }
}

export class SetPage implements Action {
    readonly type = SET_PAGE;

    constructor(public payload: number) { }
}

export class SetPagination implements Action {
    readonly type = SET_PAGINATION;

    constructor(public payload: { fromRecord: number, numRecords: number }) { }
}

export class SetFilterType implements Action {
    readonly type = SET_TEXT_FILTER;

    constructor(public payload: string) { }
}

export type SpecialProjectActions = SetSpecialProjects |
    AddSpecialProject |
    UpdateSpecialProject |
    DeleteSpecialProject |
    StoreSpecialProject |
    SetSpecialProjectsCount |
    SetPagination |
    SetPage |
    SetSpecialProjectsType |
    SetFilterType |
    GetSpecialProjects;