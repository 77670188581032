/*
 * Servizio che verifica dallo State, unica fonte di verità, se l'utente è correttamente loggato e autenticato
 */

import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router
} from "@angular/router";
import { Injectable } from "@angular/core";
import { take, map } from "rxjs/operators";

// Import dello State dell'applicativo
import * as fromApp from "../../ngrx/app.reducers";
// Import del tipo di informazione che estrapolerò dallo State
import * as fromAuth from "../ngrx/auth.reducers";
// Import delle azioni dell'auth
import * as AuthActions from "../ngrx/auth.actions";
// Import delle azioni del core
import * as CoreActions from "../../core/ngrx/core.actions";
import * as ProfileActions from "../../profile/ngrx/profile.actions";
import { LangsService } from "src/app/core/services/langs.service";
import { AuthService } from "./auth.service";
import { Store } from "@ngrx/store";
import { UrlService } from "src/app/shared/services/url.service";
import { DeviceDetectorService } from "ngx-device-detector";

function removeURLParameter(url, parameter) {
    //prefer to use l.search if you have a location/link object
    var urlparts = url.split('?');
    if (urlparts.length >= 2) {

        var prefix = encodeURIComponent(parameter) + '=';
        var pars = urlparts[1].split(/[&;]/g);

        //reverse iteration as may be destructive
        for (var i = pars.length; i-- > 0;) {
            //idiom for string.startsWith
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                pars.splice(i, 1);
            }
        }

        return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
    }
    return url;
}

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private store: Store<fromApp.AppState>,
        private langsService: LangsService,
        private authService: AuthService,
        private router: Router,
        private urlService: UrlService,
        private deviceService: DeviceDetectorService
    ) { }

    // Ritorna un Observable che, risolvendo alla fine un boolean, possiamo mapparlo
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return (
            this.store
                .select("auth")
                // Con il select() eseguiamo una subscription allo State, e quindi si trigghera ogni volta che ci sono delle modifiche; per
                // ovviare a tale comportamento sfruttiamo il take(1). (Il metodo pipe() invece è richiesto da RxJS6+)
                .pipe(
                    take(1),
                    map((authState: fromAuth.AuthState) => {
                        let url = state.url;
                        let isMeetingUser = false;
                        let isOnlineMeetingUser = false;
                        let isNudgingUser = false;
                        let isPublicMeetingUser = false;
                        let isWelionWelfarePublicMeetingUser = false;
                        let isWelfareEnel = false;
                        let isOnlineMeetingTeacher = false;
                        let isOnlineMeetingSupport = false;
                        let isWelionWelfareTeacher = false;
                        let isWelionWelfareSupport = false;
                        let isEnelWelfareTeacher = false;
                        let isEnelWelfareSupport = false;
                        let isEuropeAssistance = false;
                        let isSmartEnergyTaker = false;
                        let isHeritageTaker = false;
                        let isDeiTaker = false;

                        let skipSso: boolean;

                        if (authState.authenticated && this.isValidEntryPoint(url, authState)) {
                            // Utente correttamente loggato e può accedere alla risorsa
                            return true;
                        }
                        if (url.indexOf("meetingUsers") !== -1) {
                            isMeetingUser = true;
                        }

                        if (url.indexOf("europeAssistanceUsers") !== -1) {
                            isEuropeAssistance = true;
                        }

                        if (url.indexOf("smartEnergyTakers") !== -1) {
                            isSmartEnergyTaker = true;
                        }

                        let isWelfareEnelSso = false;
                        if (url.indexOf("myWelfareEnel") !== -1) {
                            isWelfareEnel = true;
                        }
                        if (url.indexOf("myWelfareEnel/sso/userMeetings") !== -1) {
                            isWelfareEnelSso = true;
                        }

                        if (url.indexOf("welionWelfare/userCourses") !== -1) {
                            isWelionWelfarePublicMeetingUser = true;
                        }
                        if (url.indexOf("welionWelfare/teacherCourses") !== -1) {
                            isWelionWelfareTeacher = true;
                        }
                        if (url.indexOf("welionWelfare/supportCourses") !== -1) {
                            isWelionWelfareSupport = true;
                        }

                        if (url.indexOf("myWelfareEnel/teacherCourses") !== -1) {
                            isEnelWelfareTeacher = true;
                        }
                        if (url.indexOf("myWelfareEnel/supportCourses") !== -1) {
                            isEnelWelfareSupport = true;
                        }

                        if (url.indexOf("onlineMeeting/userCourses") !== -1) {
                            isOnlineMeetingUser = true;
                            isPublicMeetingUser = true;
                        }
                        if (url.indexOf("onlineMeeting/teacherCourses") !== -1) {
                            isOnlineMeetingTeacher = true;
                        }
                        if (url.indexOf("onlineMeeting/supportCourses") !== -1) {
                            isOnlineMeetingSupport = true;
                        }
                        if (url.indexOf("nudgingSurvey") !== -1) {
                            isNudgingUser = true;
                        }

                        if (url.indexOf("/heritage/courseSubscription") !== -1) {
                            isHeritageTaker = true;
                        }

                        if (url.indexOf("/dei/courseSubscription") !== -1) {
                            isDeiTaker = true;
                        }

                        // Potrebbe essere stato fatto un refresh, di conseguenza non c'è più il dato all'interno dello State. Guardo quindi se ho il token in sessione, perché se ce l'avessi significa che l'utente è già autenticato
                        let sessionStorageToken: string = sessionStorage.getItem("token");
                        let sessionStorageTakerEmail = sessionStorage.getItem("takerEmail");
                        let sessionStorageSmartEnergyTakerEmail = sessionStorage.getItem("smartEnergyTakerEmail");
                        let sessionStorageWelionTsEmail = sessionStorage.getItem("welionTSEmail");
                        let sessionStorageOwnershipEmail = sessionStorage.getItem("ownershipEmail");
                        let sessionStorageWelfareEnelEmail = sessionStorage.getItem("welfareEnelEmail");
                        let sessionStorageWelfareEnelUserId = sessionStorage.getItem("welfareEnelUserId");
                        let sessionStorageOnlineMeetingUserEmail = sessionStorage.getItem("onlineMeetingUserEmail");
                        let sessionStorageOnlineMeetingTeacherEmail = sessionStorage.getItem("onlineMeetingTeacherEmail");
                        let sessionStorageOnlineMeetingSupportEmail = sessionStorage.getItem("onlineMeetingSupportEmail");
                        let sessionStorageMeetingUserEmail = sessionStorage.getItem("meetingUserEmail");

                        let sessionStorageWelionWelfareTeacherEmail = sessionStorage.getItem("welionWelfareTeacherEmail");
                        let sessionStorageWelionWelfareSupportEmail = sessionStorage.getItem("welionWelfareSupportEmail");

                        let sessionStorageEnelWelfareTeacherEmail = sessionStorage.getItem("enelWelfareTeacherEmail");
                        let sessionStorageEnelWelfareSupportEmil = sessionStorage.getItem("enelWelfareSupportEmail");

                        let sessionStorageEuropeAssistanceUserId = sessionStorage.getItem("europeAssistanceUserId");

                        let localStorageToken: string = localStorage.getItem("token");

                        if (localStorageToken && !sessionStorageToken && !sessionStorageTakerEmail && !sessionStorageSmartEnergyTakerEmail && !sessionStorageWelfareEnelEmail && !sessionStorageWelionTsEmail && !sessionStorageMeetingUserEmail && !sessionStorageEuropeAssistanceUserId && !sessionStorageOwnershipEmail) {
                            sessionStorage.setItem("token", localStorageToken);
                            sessionStorageToken = localStorageToken;
                            localStorage.removeItem("token");
                        }

                        // Potrebbe essermi arrivato il parametro "skipSso" nell'url, che mi avverte di forzare il redirect dell'utente non loggato al local login
                        const query =
                            window.location.search.substring(1) ||
                            window.location.hash.substring(1);
                        const vars = query.split("&");
                        let skipSsoParam;
                        let ssortkqpParam;
                        let urlToken: string = null;

                        if (vars && vars.length) {
                            for (let i = 0; i < vars.length; i++) {
                                let pair = vars[i].split("=");
                                if (
                                    decodeURIComponent(pair[0]) == "skipSso" ||
                                    pair[0].includes("skipSso")
                                ) {
                                    skipSsoParam = decodeURIComponent(pair[1]);
                                    break;
                                }

                                if (
                                    decodeURIComponent(pair[0]) == "token" ||
                                    pair[0].includes("token")
                                ) {
                                    urlToken = decodeURIComponent(pair[1]);
                                    sessionStorage.setItem("token", urlToken);
                                    sessionStorageToken = urlToken;
                                    break;
                                } else if (
                                    decodeURIComponent(pair[0]) == "ssortkqp" ||
                                    pair[0].includes("ssortkqp")
                                ) {
                                    ssortkqpParam = decodeURIComponent(pair[1]);
                                    break;
                                }
                            }
                        }
                        if (skipSsoParam) {
                            skipSso = true;
                            url = removeURLParameter(url, "skipSso");
                        }
                        if (ssortkqpParam && !urlToken) {
                            let getTokenFromKeyPromise = this.getTokenFromSsortkqp(ssortkqpParam, sessionStorageToken);
                            getTokenFromKeyPromise.then((token: string) => {
                                if (token && token.length) {
                                    sessionStorage.setItem("token", token);
                                    sessionStorageToken = sessionStorage.getItem("token");
                                }

                                // Salvataggio se dell'url di redirect se questo è tra gli entrypoint consentiti
                                if (url) {
                                    if (this.isValidEntryPoint(url, authState)) {
                                        this.store.dispatch(new CoreActions.SaveRedirectUrl(url));
                                        // Salvo il redirect url anche nel session storage (visto che per i taker non c'è una home page di default su cui atterrare, e se facessi F5 l'informazione del redirectUrl in redux si perderebbe)
                                        sessionStorage.setItem("redirectUrl", url);
                                    }
                                }

                                let isSsoTaker = false;
                                // Se sono un taker ma ho eseguito il login da sso avrò un token valido; pertanto devo basarmi sulla pagina di destinazione (courseSubscription/sso) per sapere se sono un take o un admin
                                if ((url.indexOf('heritage') == -1) && (url && url.indexOf('courseSubscription/sso') !== -1 || url && url.indexOf('survey/sso') !== -1)) {
                                    isSsoTaker = true;
                                }

                                let isHeritageSso = false;
                                // Finora non sono state gestite survey per heritage, se dovesse servire aggiungere questo e il link nel routing || url && url.indexOf('heritage/survey/sso') !== -1) {
                                if (url && url.indexOf('heritage/courseSubscription/sso') !== -1) {
                                    isHeritageSso = true;
                                }

                                let isDeiSso = false;
                                if (url && url.indexOf('dei/courseSubscription/sso') !== -1) {
                                    isDeiSso = true;
                                }

                                let isSsoSmartEnergyTaker = false;
                                // Se sono un taker ma ho eseguito il login da sso avrò un token valido; pertanto devo basarmi sulla pagina di destinazione (courseSubscription/sso) per sapere se sono un take o un admin
                                if (url && url.indexOf('courseSmartEnergySubscription/sso') !== -1) {
                                    isSsoSmartEnergyTaker = true;
                                }

                                let isWelionTsSso = false;
                                // Se sono un utente welion (per i test sierologici) ma ho eseguito il login da sso avrò un token valido; pertanto devo basarmi sulla pagina di destinazione (courseSubscription/sso)
                                if (url && url.indexOf('availableSubscriptions/sso') !== -1) {
                                    isWelionTsSso = true;
                                }

                                let isOwnershipSso = false;
                                // Se sono un utente welion (per i test sierologici) ma ho eseguito il login da sso avrò un token valido; pertanto devo basarmi sulla pagina di destinazione (courseSubscription/sso)
                                if (url && url.indexOf('availableSubscriptions/sso') !== -1) {
                                    isOwnershipSso = true;
                                }

                                let isSsoMeetingUser = false;
                                if (url && url.indexOf("meetingSubscription/sso") !== -1) {
                                    isSsoMeetingUser = true;
                                }

                                let isEuropeAssistanceSSO = false;
                                if (url && url.indexOf("europeAssistanceUsers/europeAssistanceCourseSubscription/sso") !== -1) {
                                    isEuropeAssistanceSSO = true;
                                }

                                let isSmartEnergyTakerSso = false;
                                if (url && url.indexOf("smartEnergyTakers/smartEnergyCourseSubscription/sso") !== -1) {
                                    isSmartEnergyTakerSso = true;
                                }

                                let sessionStorageOnlineMeetingUserId = sessionStorage.getItem(
                                    "onlineMeetingUserId"
                                );
                                let sessionStorageOnlineMeetingTeacherId = sessionStorage.getItem(
                                    "onlineMeetingTeacherId"
                                );
                                let sessionStorageOnlineMeetingSupportId = sessionStorage.getItem(
                                    "onlineMeetingSupportId"
                                );
                                let sessionStoragetakerUserId = sessionStorage.getItem(
                                    "takerUserId"
                                );
                                let sessionStorageNudgingUserId = sessionStorage.getItem("nudgingUserId");
                                let sessionStoragewelionTSUserId = sessionStorage.getItem(
                                    "welionTSUserId"
                                );
                                let sessionStorageOwnershipUserId = sessionStorage.getItem(
                                    "ownershipUserId"
                                );
                                let sessionStorageMeetingUserId = sessionStorage.getItem(
                                    "meetingUserId"
                                );

                                let sessionStorageWelionWelfareTeacherId = sessionStorage.getItem(
                                    "welionWelfareTeacherId"
                                );
                                let sessionStorageWelionWelfareSupportId = sessionStorage.getItem(
                                    "welionWelfareSupportId"
                                );

                                let sessionStorageEnelWelfareTeacherId = sessionStorage.getItem(
                                    "enelWelfareTeacherId"
                                );
                                let sessionStorageEnelWelfareSupportId = sessionStorage.getItem(
                                    "enelWelfareSupportId"
                                );

                                // Utenti pubblici per meeting online
                                let sessionStoragePublicMeetingUser = sessionStorage.getItem("publicMeetingUser");
                                let decodedMeetingUserObj = sessionStoragePublicMeetingUser && JSON.parse(sessionStoragePublicMeetingUser);

                                // Utenti pubblici per welfare welion
                                let sessionStorageWelionWelfarePublicMeetingUser = sessionStorage.getItem("welionWelfarePublicMeetingUser");
                                let decodedWelfareWelionUserObj = sessionStorageWelionWelfarePublicMeetingUser && JSON.parse(sessionStorageWelionWelfarePublicMeetingUser);

                                // Utenti pubblici per welfare Enel
                                let sessionStorageWelfareEnelPublicMeetingUser = sessionStorage.getItem("welfareEnelPublicMeetingUser");
                                let decodedWelfareEnelUserObj = sessionStorageWelfareEnelPublicMeetingUser && JSON.parse(sessionStorageWelfareEnelPublicMeetingUser);

                                if (decodedMeetingUserObj && decodedMeetingUserObj.userId) {
                                    // Sono già loggato e ho l'utente copmleto, quindi vado direttamente nella lista dei meeting
                                    let langToUse = (decodedMeetingUserObj.userOptions && decodedMeetingUserObj.userOptions.langCode) || 'it';
                                    this.langsService.useLanguage(langToUse);
                                    this.store.dispatch(new CoreActions.SetApplicationLang(langToUse));
                                    this.store.dispatch(new AuthActions.SetUserAuthenticated({ isPublicMeetingUser: true }));
                                    let updatedUser = {
                                        loggedUser: decodedMeetingUserObj
                                    };
                                    this.store.dispatch(new ProfileActions.UpdateUser(updatedUser));
                                    this.router.navigate(['onlineMeeting/userCourses']);
                                } else if (decodedWelfareWelionUserObj && decodedWelfareWelionUserObj.userId) {
                                    // Sono già loggato e ho l'utente copmleto, quindi vado direttamente nella lista dei meeting
                                    let langToUse = (decodedWelfareWelionUserObj.userOptions && decodedWelfareWelionUserObj.userOptions.langCode) || 'it';
                                    this.langsService.useLanguage(langToUse);
                                    this.store.dispatch(new CoreActions.SetApplicationLang(langToUse));
                                    this.store.dispatch(new AuthActions.SetUserAuthenticated({ isWelionWelfarePublicMeetingUser: true }));
                                    let updatedUser = {
                                        loggedUser: decodedWelfareWelionUserObj
                                    };
                                    this.store.dispatch(new ProfileActions.UpdateUser(updatedUser));
                                    this.router.navigate(['welionWelfare/userCourses']);
                                } else if (url.indexOf('welionsUsersTS') !== -1) {
                                    // Login per gli utenti Welfare (per i test sierologici)
                                    if (sessionStorageWelionTsEmail || sessionStoragewelionTSUserId) {
                                        this.store.dispatch(
                                            new AuthActions.DoLogin({
                                                email:
                                                    sessionStoragewelionTSUserId ||
                                                    sessionStorageWelionTsEmail,
                                                password: null,
                                                isWelionTs: true
                                            })
                                        );
                                    } else if (sessionStorageToken) {
                                        if (isWelionTsSso) {
                                            this.store.dispatch(
                                                new AuthActions.DoLogin({
                                                    email: sessionStorageToken,
                                                    password: null,
                                                    isWelionTs: true,
                                                    isWelionTsSso: true
                                                })
                                            );
                                        } else {
                                            // Non è ancora autenticato l'utente ma avevo il token
                                            // TODO verificare se è un Supplier o Admin, sarebbe anche opportuno verificare un uuid del device per capire se è stato sniffato il token
                                            // Setto l'utente come autenticato
                                            this.store.dispatch(new AuthActions.SetUserAuthenticated());
                                            // Ri inizio il polling per il token. L'effect si occuperà anche di settare il nuovo token e di decodificarlo
                                            this.store.dispatch(new CoreActions.StartRenewTokenPolling());
                                        }
                                        return true;
                                    } else {
                                        // Utente non loggato, quindi redirect alla pagina di Login. Prima però salvo nello Store l'url corrente affinché, una volta eseguito correttamente il login, si esegua il redirect nella pagina richiesta
                                        let url = state.url;

                                        if (url) {
                                            if (skipSsoParam) {
                                                url = removeURLParameter(url, "skipSso");
                                            }
                                            this.store.dispatch(new CoreActions.SaveRedirectUrl(url));
                                            // Ci sono due tipi di login per i taker, uno per chi è abilitato all'sso e uno per chi non lo è; in quest'ultimo caso vado alla pagina classica di login, nel primo caso invece eseguo il redirect all'sso. Conosco l'abilitazione dell'utente in base all'url da cui arrivo, ovvero se esso contiene la parola 'sso'
                                            if (url.indexOf('sso') !== -1) {
                                                // Vado login con sso
                                                this.router.navigate(['/welionTSLogin']);
                                            } else {
                                                // Cosa poco bella ma necessaria: in questo punto non si riescono a recuepreare i params dall'activa route, quindi bisogna prenderli tramite js;
                                                // In questo caso mi serve vedere se nell'url c'è lo userId. Esso è situato, se prensente, come ultimo parametro. Pertanto, ci saranno
                                                // in questo caso quattro slash /
                                                let welionTSUserId = null;
                                                if (url) {
                                                    let slashInUrl = (url.match(/\//g) || []).length;
                                                    let isSurveyRedirect = 4;
                                                    if (url.indexOf('survey') !== -1) {
                                                        isSurveyRedirect = 5;
                                                    }
                                                    if (slashInUrl && slashInUrl === isSurveyRedirect) {
                                                        let lastSlashindex = url.lastIndexOf('/');
                                                        welionTSUserId = url.substring(lastSlashindex + 1);
                                                        // Se ho lo userId nell'url, lo salvo nel sessionStorage
                                                        if (welionTSUserId) {
                                                            sessionStorage.setItem('welionTSUserId', welionTSUserId);
                                                        }
                                                    }
                                                }
                                                // Vado login senza sso
                                                this.router.navigate(['/welionTSLocalLogin']);
                                            }
                                        }
                                    }
                                } else if (url.indexOf('ownershipUsers') !== -1) {
                                    // Login per gli utenti ownership
                                    if (sessionStorageOwnershipEmail || sessionStorageOwnershipUserId) {
                                        this.store.dispatch(
                                            new AuthActions.DoLogin({
                                                email:
                                                    sessionStorageOwnershipUserId ||
                                                    sessionStorageOwnershipEmail,
                                                password: null,
                                                isOwnership: true
                                            })
                                        );
                                    } else if (sessionStorageToken) {
                                        if (isOwnershipSso) {
                                            this.store.dispatch(
                                                new AuthActions.DoLogin({
                                                    email: sessionStorageToken,
                                                    password: null,
                                                    isOwnership: true,
                                                    isOwnershipSso: true
                                                })
                                            );
                                        } else {
                                            // Setto l'utente come autenticato
                                            this.store.dispatch(new AuthActions.SetUserAuthenticated());
                                            // Ri inizio il polling per il token. L'effect si occuperà anche di settare il nuovo token e di decodificarlo
                                            this.store.dispatch(new CoreActions.StartRenewTokenPolling());
                                        }
                                        return true;
                                    } else {
                                        // Utente non loggato, quindi redirect alla pagina di Login. Prima però salvo nello Store l'url corrente affinché, una volta eseguito correttamente il login, si esegua il redirect nella pagina richiesta
                                        let url = state.url;

                                        if (url) {
                                            if (skipSsoParam) {
                                                url = removeURLParameter(url, "skipSso");
                                            }
                                            this.store.dispatch(new CoreActions.SaveRedirectUrl(url));
                                            // Ci sono due tipi di login per i taker, uno per chi è abilitato all'sso e uno per chi non lo è; in quest'ultimo caso vado alla pagina classica di login, nel primo caso invece eseguo il redirect all'sso. Conosco l'abilitazione dell'utente in base all'url da cui arrivo, ovvero se esso contiene la parola 'sso'
                                            if (url.indexOf('sso') !== -1) {
                                                // Vado login con sso
                                                this.router.navigate(['/ownershipLogin']);
                                            } else {
                                                // Cosa poco bella ma necessaria: in questo punto non si riescono a recuepreare i params dall'activa route, quindi bisogna prenderli tramite js;
                                                // In questo caso mi serve vedere se nell'url c'è lo userId. Esso è situato, se prensente, come ultimo parametro. Pertanto, ci saranno
                                                // in questo caso quattro slash /
                                                let ownershipUserId = null;
                                                if (url) {
                                                    let slashInUrl = (url.match(/\//g) || []).length;
                                                    let isSurveyRedirect = 4;
                                                    if (url.indexOf('survey') !== -1) {
                                                        isSurveyRedirect = 5;
                                                    }
                                                    if (slashInUrl && slashInUrl === isSurveyRedirect) {
                                                        let lastSlashindex = url.lastIndexOf('/');
                                                        ownershipUserId = url.substring(lastSlashindex + 1);
                                                        // Se ho lo userId nell'url, lo salvo nel sessionStorage
                                                        if (ownershipUserId) {
                                                            sessionStorage.setItem('ownershipUserId', ownershipUserId);
                                                        }
                                                    }
                                                }
                                                // Vado login senza sso
                                                this.router.navigate(['/ownershipLocalLogin']);
                                            }
                                        }
                                    }
                                } else if (isWelfareEnel) {
                                    // Login per gli utenti enel
                                    if (sessionStorageWelfareEnelEmail || sessionStorageWelfareEnelUserId) {
                                        this.store.dispatch(
                                            new AuthActions.DoLogin({
                                                userId:
                                                    sessionStorageWelfareEnelUserId ||
                                                    sessionStorageWelfareEnelEmail,
                                                password: null,
                                                isWelfareEnel: true
                                            })
                                        );
                                    } else if (sessionStorageToken) {
                                        if (isWelfareEnelSso) {
                                            this.store.dispatch(
                                                new AuthActions.DoLogin({
                                                    email: sessionStorageToken,
                                                    password: null,
                                                    isWelfareEnel: true,
                                                    isWelfareEnelSso: true
                                                })
                                            );
                                        } else {
                                            // Non è ancora autenticato l'utente ma avevo il token
                                            // TODO verificare se è un Supplier o Admin, sarebbe anche opportuno verificare un uuid del device per capire se è stato sniffato il token
                                            // Setto l'utente come autenticato
                                            this.store.dispatch(new AuthActions.SetUserAuthenticated());
                                            // Ri inizio il polling per il token. L'effect si occuperà anche di settare il nuovo token e di decodificarlo
                                            this.store.dispatch(new CoreActions.StartRenewTokenPolling());
                                        }
                                        return true;
                                    } else {
                                        // Utente non loggato, quindi redirect alla pagina di Login. Prima però salvo nello Store l'url corrente affinché, una volta eseguito correttamente il login, si esegua il redirect nella pagina richiesta
                                        let url = state.url;

                                        if (url) {
                                            if (skipSsoParam) {
                                                url = removeURLParameter(url, "skipSso");
                                            }
                                            this.store.dispatch(new CoreActions.SaveRedirectUrl(url));
                                            // Ci sono due tipi di login per i taker, uno per chi è abilitato all'sso e uno per chi non lo è; in quest'ultimo caso vado alla pagina classica di login, nel primo caso invece eseguo il redirect all'sso. Conosco l'abilitazione dell'utente in base all'url da cui arrivo, ovvero se esso contiene la parola 'sso'
                                            if (url.indexOf('sso') !== -1) {
                                                // Vado login con sso
                                                this.router.navigate(['/welfareEnelLogin']);
                                            } else {
                                                // Cosa poco bella ma necessaria: in questo punto non si riescono a recuepreare i params dall'activa route, quindi bisogna prenderli tramite js;
                                                // In questo caso mi serve vedere se nell'url c'è lo userId. Esso è situato, se prensente, come ultimo parametro. Pertanto, ci saranno
                                                // in questo caso quattro slash /
                                                let welfareEnelUrlUserId = null;
                                                if (url) {
                                                    let slashInUrl = (url.match(/\//g) || []).length;
                                                    if (slashInUrl && slashInUrl === 3) {
                                                        let lastSlashindex = url.lastIndexOf('/');
                                                        welfareEnelUrlUserId = url.substring(lastSlashindex + 1);
                                                        // Se ho lo userId nell'url, lo salvo nel sessionStorage
                                                        if (welfareEnelUrlUserId) {
                                                            sessionStorage.setItem('welfareEnelUserId', welfareEnelUrlUserId);
                                                        }
                                                    }
                                                }

                                                if (isEnelWelfareTeacher) {
                                                    // Login docenti
                                                    this.router.navigate(['/enelWelfareTeacherLogin']);
                                                } else if (isEnelWelfareSupport) {
                                                    this.router.navigate(['/enelWelfareSupportLogin']);
                                                } else {
                                                    // Vado login senza sso
                                                    this.router.navigate(['/welfareEnelLocalLogin']);
                                                }
                                            }
                                        }
                                    }
                                } else {
                                    if (sessionStorageTakerEmail ||
                                        sessionStorageSmartEnergyTakerEmail ||
                                        sessionStoragetakerUserId ||
                                        sessionStorageNudgingUserId ||
                                        sessionStorageOnlineMeetingUserEmail ||
                                        sessionStorageOnlineMeetingUserId ||
                                        sessionStorageOnlineMeetingTeacherEmail ||
                                        sessionStorageOnlineMeetingTeacherId ||
                                        sessionStorageOnlineMeetingSupportEmail ||
                                        sessionStorageOnlineMeetingSupportId ||
                                        sessionStorageWelionWelfareTeacherEmail ||
                                        sessionStorageWelionWelfareTeacherId ||
                                        sessionStorageWelionWelfareSupportEmail ||
                                        sessionStorageWelionWelfareSupportId ||
                                        sessionStorageEnelWelfareSupportEmil ||
                                        sessionStorageEnelWelfareSupportId ||
                                        sessionStorageMeetingUserId ||
                                        sessionStorageMeetingUserEmail
                                    ) {
                                        this.store.dispatch(
                                            new AuthActions.DoLogin({
                                                email:
                                                    sessionStoragetakerUserId ||
                                                    sessionStorageTakerEmail ||
                                                    sessionStorageSmartEnergyTakerEmail ||
                                                    sessionStorageNudgingUserId ||
                                                    sessionStorageMeetingUserEmail ||
                                                    sessionStorageOnlineMeetingUserEmail ||
                                                    sessionStorageOnlineMeetingUserId ||
                                                    sessionStorageOnlineMeetingTeacherEmail ||
                                                    sessionStorageOnlineMeetingTeacherId ||
                                                    sessionStorageOnlineMeetingSupportEmail ||
                                                    sessionStorageOnlineMeetingSupportId ||
                                                    sessionStorageWelionWelfareTeacherEmail ||
                                                    sessionStorageWelionWelfareTeacherId ||
                                                    sessionStorageWelionWelfareSupportEmail ||
                                                    sessionStorageWelionWelfareSupportId ||
                                                    sessionStorageEnelWelfareSupportEmil ||
                                                    sessionStorageEnelWelfareSupportId ||
                                                    sessionStorageMeetingUserId,
                                                password: null,
                                                isTaker: !isMeetingUser && !isOnlineMeetingUser && !isOnlineMeetingTeacher && !isOnlineMeetingSupport && !isWelionWelfareTeacher && !isWelionWelfareSupport,
                                                isMeetingUser: isMeetingUser,
                                                isOnlineMeetingUser: isOnlineMeetingUser,
                                                isOnlineMeetingTeacher: isOnlineMeetingTeacher,
                                                isOnlineMeetingSupport: isOnlineMeetingSupport,
                                                isWelionWelfareTeacher: isWelionWelfareTeacher,
                                                isWelionWelfareSupport: isWelionWelfareSupport,
                                                isEnelWelfareTeacher: isEnelWelfareTeacher,
                                                isEnelWelfareSupport: isEnelWelfareSupport,
                                                isNudgingUser: isNudgingUser,
                                                isHeritage: isHeritageTaker,
                                                isDei: isDeiTaker
                                            })
                                        );
                                    } else if (sessionStorageToken) {
                                        if (isSsoMeetingUser) {
                                            this.store.dispatch(
                                                new AuthActions.DoLogin({
                                                    email: sessionStorageToken,
                                                    password: null,
                                                    isMeetingUser: true,
                                                    isSso: true
                                                })
                                            );
                                        }
                                        if (isSsoTaker) {
                                            this.store.dispatch(
                                                new AuthActions.DoLogin({
                                                    email: sessionStorageToken,
                                                    password: null,
                                                    isTaker: true,
                                                    isSso: true
                                                })
                                            );
                                        } if (isEuropeAssistanceSSO) {
                                            this.store.dispatch(
                                                new AuthActions.DoLogin({
                                                    email: sessionStorageToken,
                                                    password: null,
                                                    isEuropeAssistance: true,
                                                    isEuropeAssistanceSso: true
                                                })
                                            );
                                        } if (isSmartEnergyTakerSso) {
                                            this.store.dispatch(
                                                new AuthActions.DoLogin({
                                                    email: sessionStorageToken,
                                                    password: null,
                                                    isSmartEnergyTaker: true,
                                                    isSmartEnergyTakerSso: true
                                                })
                                            );
                                        }
                                        if (isHeritageSso) {
                                            this.store.dispatch(
                                                new AuthActions.DoLogin({
                                                    email: sessionStorageToken,
                                                    password: null,
                                                    isHeritage: true,
                                                    isSso: true
                                                })
                                            );
                                        }
                                        else {
                                            // Non è ancora autenticato l'utente ma avevo il token
                                            // TODO verificare se è un Supplier o Admin, sarebbe anche opportuno verificare un uuid del device per capire se è stato sniffato il token
                                            // Setto l'utente come autenticato
                                            this.store.dispatch(new AuthActions.SetUserAuthenticated());
                                            // Ri inizio il polling per il token. L'effect si occuperà anche di settare il nuovo token e di decodificarlo
                                            this.store.dispatch(new CoreActions.StartRenewTokenPolling());
                                        }
                                        return true;
                                    } else {
                                        // Utente non loggato, quindi redirect alla pagina di Login. Prima però salvo nello Store l'url corrente affinché, una volta eseguito correttamente il login, si esegua il redirect nella pagina richiesta
                                        let url = state.url;
                                        let isTaker = false;
                                        let isSupplier = false;
                                        // Funzione modificata per l' utente fundingSupplier
                                        let isFundingSupplier = false;

                                        if (url) {
                                            if (skipSsoParam) {
                                                url = removeURLParameter(url, "skipSso");
                                            }
                                            this.store.dispatch(new CoreActions.SaveRedirectUrl(url));
                                            if (url.indexOf('takers') !== -1) {
                                                // Salvo il redirect url anche nel session storage (visto che per i taker non c'è una home page di default su cui atterrare, e se facessi F5 l'informazione del redirectUrl in redux si perderebbe)
                                                isTaker = true;
                                                // Ci sono due tipi di login per i taker, uno per chi è abilitato all'sso e uno per chi non lo è; in quest'ultimo caso vado alla pagina classica di login, nel primo caso invece eseguo il redirect all'sso. Conosco l'abilitazione dell'utente in base all'url da cui arrivo, ovvero se esso contiene la parola 'sso'
                                                if (url.indexOf('sso') !== -1) {
                                                    // Vado login con sso
                                                    this.router.navigate(['/takerLogin']);
                                                } else {
                                                    // Cosa poco bella ma necessaria: in questo punto non si riescono a recuepreare i params dall'activa route, quindi bisogna prenderli tramite js;
                                                    // In questo caso mi serve vedere se nell'url c'è lo userId. Esso è situato, se prensente, come ultimo parametro. Pertanto, ci saranno
                                                    // in questo caso quattro slash /
                                                    let takerUserId = null;
                                                    if (url) {
                                                        let slashInUrl = (url.match(/\//g) || []).length;
                                                        let isSurveyRedirect = 4;
                                                        if (url.indexOf('survey') !== -1) {
                                                            isSurveyRedirect = 5;
                                                        }
                                                        if (slashInUrl && slashInUrl === isSurveyRedirect) {
                                                            let lastSlashindex = url.lastIndexOf('/');
                                                            takerUserId = url.substring(lastSlashindex + 1);
                                                            // Se ho lo userId nell'url, lo salvo nel sessionStorage
                                                            if (takerUserId) {
                                                                sessionStorage.setItem('takerUserId', takerUserId);
                                                            }
                                                        }
                                                    }
                                                    // Vado login senza sso
                                                    this.router.navigate(['/takerLocalLogin']);
                                                }
                                            }

                                            if (url.indexOf('nudgingSurvey') !== -1) {
                                                // Cosa poco bella ma necessaria: in questo punto non si riescono a recuepreare i params dall'activa route, quindi bisogna prenderli tramite js;
                                                // In questo caso mi serve vedere se nell'url c'è lo userId. Esso è situato, se prensente, come ultimo parametro. Pertanto, ci saranno
                                                // in questo caso quattro slash /
                                                let nudgingUserId = null;
                                                if (url) {
                                                    let lastSlashindex = url.lastIndexOf('/', url.lastIndexOf('/') - 1);
                                                    nudgingUserId = url.substring(lastSlashindex + 1);
                                                    // Se ho lo userId nell'url, lo salvo nel sessionStorage
                                                    if (nudgingUserId) {
                                                        sessionStorage.setItem('nudgingUserId', nudgingUserId);
                                                    }
                                                }
                                                // Vado login senza sso
                                                this.router.navigate(['/nudgingLocalLogin']);
                                            }

                                            if (isOnlineMeetingUser) {
                                                // Può essere che mi sia arrivato lo userId dal dall'url (userCourses/:userId oppure userCourses/customers/:userId)
                                                let onlineMeetingUserId = null;
                                                let goToAutomaticLoginForCustomers
                                                if (url) {
                                                    let slashInUrl = (url.match(/\//g) || []).length;
                                                    let slashToCompare = 3;
                                                    if (url.indexOf('customers') !== -1) {
                                                        goToAutomaticLoginForCustomers = true;
                                                        slashToCompare = 4;
                                                    }

                                                    if (slashInUrl && slashInUrl === slashToCompare) {
                                                        let lastSlashindex = url.lastIndexOf('/');
                                                        onlineMeetingUserId = url.substring(lastSlashindex + 1);
                                                        // Se ho lo userId nell'url, lo salvo nel sessionStorage
                                                        if (onlineMeetingUserId) {
                                                            sessionStorage.setItem('onlineMeetingUserId', onlineMeetingUserId);
                                                        }
                                                    }
                                                }

                                                if (!goToAutomaticLoginForCustomers) {
                                                    this.router.navigate(['/onlineMeetingUserLogin']);
                                                } else {
                                                    this.router.navigate(['/onlineMeetingCustomerAutomaticLogin']);
                                                }
                                            } else if (isOnlineMeetingTeacher) {
                                                this.router.navigate(['/onlineMeetingTeacherLogin']);
                                            } else if (isOnlineMeetingSupport) {
                                                this.router.navigate(['/onlineMeetingSupportLogin']);
                                            } else if (isPublicMeetingUser) {
                                                this.router.navigate(['/publicUsersLogin']);
                                            } else if (isWelionWelfareTeacher) {
                                                this.router.navigate(['/welionWelfareTeacherLogin']);
                                            } else if (isWelionWelfareSupport) {
                                                this.router.navigate(['/welionWelfareSupportLogin']);
                                            } else if (isWelionWelfarePublicMeetingUser) {
                                                this.router.navigate(['/welionWelfareUsersLogin']);
                                            } else if (isEnelWelfareTeacher) {
                                                this.router.navigate(['/enelWelfareTeacherLogin']);
                                            } else if (isEnelWelfareSupport) {
                                                this.router.navigate(['/enelWelfareSupportLogin']);
                                            } else if (isEuropeAssistance) {
                                                if (url.indexOf('sso') !== -1) {
                                                    // Vado login con sso
                                                    this.router.navigate(['/europeAssistanceLogin']);
                                                } else {
                                                    this.router.navigate(['/europeAssistanceLocalLogin']);
                                                }
                                            } else if (isSmartEnergyTaker) {
                                                if (url.indexOf('sso') !== -1) {
                                                    // Vado login con sso
                                                    this.router.navigate(['/smartEnergySubscriptionLogin']);
                                                } else {
                                                    this.router.navigate(['/smartEnergySubscriptionLocalLogin']);
                                                }
                                            }
                                            else if (isHeritageTaker || isDeiTaker) {
                                                if (url.indexOf('sso') !== -1) {
                                                    // Prima di chiamare il servizio per il login, identifico l'userAgent e il tipo di device dell'utente
                                                    const deviceInfo = this.deviceService.getDeviceInfo();
                                                    const userAgent = deviceInfo && deviceInfo.userAgent;
                                                    let deviceType;
                                                    if (this.deviceService.isMobile()) {
                                                        // Salvo il fatto che è uno smartphone
                                                        deviceType = 'P';
                                                    } else if (this.deviceService.isTablet()) {
                                                        // Salvo il fatto che è un tablet
                                                        deviceType = 'T';
                                                    } else if (this.deviceService.isDesktop()) {
                                                        // Salvo il fatto che è un computer desktop
                                                        deviceType = 'D';
                                                    }
                                                    // Ridireziono l'utente verso il sistema di SSO
                                                    let storageRedirectUrl = sessionStorage.getItem('redirectUrl');
                                                    const redirectUrl = this.urlService.getSSOUrl(deviceType, userAgent, '/#/' + storageRedirectUrl);
                                                    window.location.href = redirectUrl;
                                                } else {

                                                    this.router.navigate([url]);
                                                }
                                            }
                                            if (isMeetingUser) {
                                                if (url.indexOf("sso") !== -1) {
                                                    // Vado login con sso
                                                    this.router.navigate(["/meetingUserLogin"]);
                                                } else {
                                                    // Cosa poco bella ma necessaria: in questo punto non si riescono a recuepreare i params dall'activa route, quindi bisogna prenderli tramite js;
                                                    // In questo caso mi serve vedere se nell'url c'è lo userId. Esso è situato, se prensente, come ultimo parametro. Pertanto, ci saranno
                                                    // in questo caso quattro slash /
                                                    let meetingUserId = null;
                                                    if (url) {
                                                        let slashInUrl = (url.match(/\//g) || []).length;
                                                        if (slashInUrl && slashInUrl === 4) {
                                                            let lastSlashindex = url.lastIndexOf("/");
                                                            meetingUserId = url.substring(lastSlashindex + 1);
                                                            // Se ho lo userId nell'url, lo salvo nel sessionStorage
                                                            if (meetingUserId) {
                                                                sessionStorage.setItem(
                                                                    "meetingUserId",
                                                                    meetingUserId
                                                                );
                                                            }
                                                        }
                                                    }
                                                    // Vado login senza sso
                                                    this.router.navigate(["/meetingUserLocalLogin"]);
                                                }
                                            }
                                            if (url.indexOf('suppliers') !== -1) {
                                                // Salvo il redirect url anche nel session storage (visto che per i taker non c'è una home page di default su cui atterrare, e se facessi F5 l'informazione del redirectUrl in redux si perderebbe)
                                                isSupplier = true;
                                                // Vado al login dei taker
                                                this.router.navigate(['/supplierLogin']);
                                            }

                                            if (url.indexOf('fundingSupplier') !== -1) {
                                                // Salvo il redirect url anche nel session storage (visto che per i taker non c'è una home page di default su cui atterrare, e se facessi F5 l'informazione del redirectUrl in redux si perderebbe)
                                                isFundingSupplier = true;
                                                // Vado al login dei taker
                                                this.router.navigate(['/supplierLogin']);
                                            }
                                        }

                                        if (!isOnlineMeetingUser && !isEuropeAssistance && !isSmartEnergyTaker && !isPublicMeetingUser && !isWelionWelfarePublicMeetingUser && !isOnlineMeetingTeacher && !isOnlineMeetingSupport && !isWelionWelfareTeacher && !isWelionWelfareSupport && !isEnelWelfareTeacher && !isEnelWelfareSupport && !isTaker && !isSupplier && !isMeetingUser && !isFundingSupplier && !isNudgingUser) {
                                            if (skipSso) {
                                                this.router.navigate(["/localLogin"]);
                                            } else {
                                                this.router.navigate(["/login"]);
                                            }
                                        }
                                    }
                                }
                            }
                            )
                            return true;
                        } else {
                            // Salvataggio se dell'url di redirect se questo è tra gli entrypoint consentiti
                            if (url) {
                                if (this.isValidEntryPoint(url, authState)) {
                                    this.store.dispatch(new CoreActions.SaveRedirectUrl(url));
                                    // Salvo il redirect url anche nel session storage (visto che per i taker non c'è una home page di default su cui atterrare, e se facessi F5 l'informazione del redirectUrl in redux si perderebbe)
                                    sessionStorage.setItem("redirectUrl", url);
                                }
                            }

                            let isSsoTaker = false;
                            // Se sono un taker ma ho eseguito il login da sso avrò un token valido; pertanto devo basarmi sulla pagina di destinazione (courseSubscription/sso) per sapere se sono un take o un admin
                            if ((url.indexOf('heritage') == -1) && (url && url.indexOf('courseSubscription/sso') !== -1 || url && url.indexOf('survey/sso') !== -1)) {
                                isSsoTaker = true;
                            }

                            let isWelionTsSso = false;
                            // Se sono un utente welion (per i test sierologici) ma ho eseguito il login da sso avrò un token valido; pertanto devo basarmi sulla pagina di destinazione (courseSubscription/sso)
                            if (url && url.indexOf('availableSubscriptions/sso') !== -1) {
                                isWelionTsSso = true;
                            }

                            let isOwnershipSso = false;
                            // Se sono un utente welion (per i test sierologici) ma ho eseguito il login da sso avrò un token valido; pertanto devo basarmi sulla pagina di destinazione (courseSubscription/sso)
                            if (url && url.indexOf('availableSubscriptions/sso') !== -1) {
                                isOwnershipSso = true;
                            }

                            let isSsoMeetingUser = false;
                            if (url && url.indexOf("meetingSubscription/sso") !== -1) {
                                isSsoMeetingUser = true;
                            }

                            let isEuropeAssistanceSSO = false;
                            if (url && url.indexOf("europeAssistanceUsers/europeAssistanceCourseSubscription/sso") !== -1) {
                                isEuropeAssistanceSSO = true;
                            }

                            let isSmartEnergyTakerSso = false;
                            if (url && url.indexOf("smartEnergyTakers/smartEnergyCourseSubscription/sso") !== -1) {
                                isSmartEnergyTakerSso = true;
                            }

                            let isHeritageSso = false;
                            if (url && url.indexOf("heritage/courseSubscription/sso") !== -1) {
                                isHeritageSso = true;
                            }
                            let isDeiSso = false;
                            if (url && url.indexOf("dei/courseSubscription/sso") !== -1) {
                                isHeritageSso = true;
                            }

                            let sessionStorageOnlineMeetingUserId = sessionStorage.getItem(
                                "onlineMeetingUserId"
                            );
                            let sessionStorageOnlineMeetingTeacherId = sessionStorage.getItem(
                                "onlineMeetingTeacherId"
                            );
                            let sessionStorageOnlineMeetingSupportId = sessionStorage.getItem(
                                "onlineMeetingSupportId"
                            );
                            let sessionStoragetakerUserId = sessionStorage.getItem(
                                "takerUserId"
                            );
                            let sessionStorageNudgingUserId = sessionStorage.getItem(
                                "nudgingUserId"
                            );
                            let sessionStoragewelionTSUserId = sessionStorage.getItem(
                                "welionTSUserId"
                            );
                            let sessionStorageOwnershipUserId = sessionStorage.getItem(
                                "ownershipUserId"
                            );
                            let sessionStorageMeetingUserId = sessionStorage.getItem(
                                "meetingUserId"
                            );

                            let sessionStorageWelionWelfareTeacherId = sessionStorage.getItem(
                                "welionWelfareTeacherId"
                            );
                            let sessionStorageWelionWelfareSupportId = sessionStorage.getItem(
                                "welionWelfareSupportId"
                            );

                            let sessionStorageEnelWelfareTeacherId = sessionStorage.getItem(
                                "enelWelfareTeacherId"
                            );
                            let sessionStorageEnelWelfareSupportId = sessionStorage.getItem(
                                "enelWelfareSupportId"
                            );
                            let sessionStorageSmartEnergyTakerUserId = sessionStorage.getItem(
                                "smartEnergyTakerEmail"
                            );

                            // Utenti pubblici per meeting online
                            let sessionStoragePublicMeetingUser = sessionStorage.getItem("publicMeetingUser");
                            let decodedMeetingUserObj = sessionStoragePublicMeetingUser && JSON.parse(sessionStoragePublicMeetingUser);

                            // Utenti pubblici per welfare welion
                            let sessionStorageWelionWelfarePublicMeetingUser = sessionStorage.getItem("welionWelfarePublicMeetingUser");
                            let decodedWelfareWelionUserObj = sessionStorageWelionWelfarePublicMeetingUser && JSON.parse(sessionStorageWelionWelfarePublicMeetingUser);

                            // Utenti pubblici per welfare Enel
                            let sessionStorageWelfareEnelPublicMeetingUser = sessionStorage.getItem("welfareEnelPublicMeetingUser");
                            let decodedWelfareEnelUserObj = sessionStorageWelfareEnelPublicMeetingUser && JSON.parse(sessionStorageWelfareEnelPublicMeetingUser);

                            if (decodedMeetingUserObj && decodedMeetingUserObj.userId) {
                                // Sono già loggato e ho l'utente copmleto, quindi vado direttamente nella lista dei meeting
                                let langToUse = (decodedMeetingUserObj.userOptions && decodedMeetingUserObj.userOptions.langCode) || 'it';
                                this.langsService.useLanguage(langToUse);
                                this.store.dispatch(new CoreActions.SetApplicationLang(langToUse));
                                this.store.dispatch(new AuthActions.SetUserAuthenticated({ isPublicMeetingUser: true }));
                                let updatedUser = {
                                    loggedUser: decodedMeetingUserObj
                                };
                                this.store.dispatch(new ProfileActions.UpdateUser(updatedUser));
                                this.router.navigate(['onlineMeeting/userCourses']);
                            } else if (decodedWelfareWelionUserObj && decodedWelfareWelionUserObj.userId) {
                                // Sono già loggato e ho l'utente copmleto, quindi vado direttamente nella lista dei meeting
                                let langToUse = (decodedWelfareWelionUserObj.userOptions && decodedWelfareWelionUserObj.userOptions.langCode) || 'it';
                                this.langsService.useLanguage(langToUse);
                                this.store.dispatch(new CoreActions.SetApplicationLang(langToUse));
                                this.store.dispatch(new AuthActions.SetUserAuthenticated({ isWelionWelfarePublicMeetingUser: true }));
                                let updatedUser = {
                                    loggedUser: decodedWelfareWelionUserObj
                                };
                                this.store.dispatch(new ProfileActions.UpdateUser(updatedUser));
                                this.router.navigate(['welionWelfare/userCourses']);
                            } else if (url.indexOf('welionsUsersTS') !== -1) {
                                // Login per gli utenti Welfare (per i test sierologici)
                                if (sessionStorageWelionTsEmail || sessionStoragewelionTSUserId) {
                                    this.store.dispatch(
                                        new AuthActions.DoLogin({
                                            email:
                                                sessionStoragewelionTSUserId ||
                                                sessionStorageWelionTsEmail,
                                            password: null,
                                            isWelionTs: true
                                        })
                                    );
                                } else if (sessionStorageToken) {
                                    if (isWelionTsSso) {
                                        this.store.dispatch(
                                            new AuthActions.DoLogin({
                                                email: sessionStorageToken,
                                                password: null,
                                                isWelionTs: true,
                                                isWelionTsSso: true
                                            })
                                        );
                                    } else {
                                        // Non è ancora autenticato l'utente ma avevo il token
                                        // TODO verificare se è un Supplier o Admin, sarebbe anche opportuno verificare un uuid del device per capire se è stato sniffato il token
                                        // Setto l'utente come autenticato
                                        this.store.dispatch(new AuthActions.SetUserAuthenticated());
                                        // Ri inizio il polling per il token. L'effect si occuperà anche di settare il nuovo token e di decodificarlo
                                        this.store.dispatch(new CoreActions.StartRenewTokenPolling());
                                    }
                                    return true;
                                } else {
                                    // Utente non loggato, quindi redirect alla pagina di Login. Prima però salvo nello Store l'url corrente affinché, una volta eseguito correttamente il login, si esegua il redirect nella pagina richiesta
                                    let url = state.url;

                                    if (url) {
                                        if (skipSsoParam) {
                                            url = removeURLParameter(url, "skipSso");
                                        }
                                        this.store.dispatch(new CoreActions.SaveRedirectUrl(url));
                                        // Ci sono due tipi di login per i taker, uno per chi è abilitato all'sso e uno per chi non lo è; in quest'ultimo caso vado alla pagina classica di login, nel primo caso invece eseguo il redirect all'sso. Conosco l'abilitazione dell'utente in base all'url da cui arrivo, ovvero se esso contiene la parola 'sso'
                                        if (url.indexOf('sso') !== -1) {
                                            // Vado login con sso
                                            this.router.navigate(['/welionTSLogin']);
                                        } else {
                                            // Cosa poco bella ma necessaria: in questo punto non si riescono a recuepreare i params dall'activa route, quindi bisogna prenderli tramite js;
                                            // In questo caso mi serve vedere se nell'url c'è lo userId. Esso è situato, se prensente, come ultimo parametro. Pertanto, ci saranno
                                            // in questo caso quattro slash /
                                            let welionTSUserId = null;
                                            if (url) {
                                                let slashInUrl = (url.match(/\//g) || []).length;
                                                let isSurveyRedirect = 4;
                                                if (url.indexOf('survey') !== -1) {
                                                    isSurveyRedirect = 5;
                                                }
                                                if (slashInUrl && slashInUrl === isSurveyRedirect) {
                                                    let lastSlashindex = url.lastIndexOf('/');
                                                    welionTSUserId = url.substring(lastSlashindex + 1);
                                                    // Se ho lo userId nell'url, lo salvo nel sessionStorage
                                                    if (welionTSUserId) {
                                                        sessionStorage.setItem('welionTSUserId', welionTSUserId);
                                                    }
                                                }
                                            }
                                            // Vado login senza sso
                                            this.router.navigate(['/welionTSLocalLogin']);
                                        }
                                    }
                                }
                            } else if (url.indexOf('ownershipUsers') !== -1) {
                                // Login per gli utenti ownership
                                if (sessionStorageOwnershipEmail || sessionStorageOwnershipUserId) {
                                    this.store.dispatch(
                                        new AuthActions.DoLogin({
                                            email:
                                                sessionStorageOwnershipUserId ||
                                                sessionStorageOwnershipEmail,
                                            password: null,
                                            isOwnership: true
                                        })
                                    );
                                } else if (sessionStorageToken) {
                                    if (isOwnershipSso) {
                                        this.store.dispatch(
                                            new AuthActions.DoLogin({
                                                email: sessionStorageToken,
                                                password: null,
                                                isOwnership: true,
                                                isOwnershipSso: true
                                            })
                                        );
                                    } else {
                                        // Setto l'utente come autenticato
                                        this.store.dispatch(new AuthActions.SetUserAuthenticated());
                                        // Ri inizio il polling per il token. L'effect si occuperà anche di settare il nuovo token e di decodificarlo
                                        this.store.dispatch(new CoreActions.StartRenewTokenPolling());
                                    }
                                    return true;
                                } else {
                                    // Utente non loggato, quindi redirect alla pagina di Login. Prima però salvo nello Store l'url corrente affinché, una volta eseguito correttamente il login, si esegua il redirect nella pagina richiesta
                                    let url = state.url;

                                    if (url) {
                                        if (skipSsoParam) {
                                            url = removeURLParameter(url, "skipSso");
                                        }
                                        this.store.dispatch(new CoreActions.SaveRedirectUrl(url));
                                        // Ci sono due tipi di login per i taker, uno per chi è abilitato all'sso e uno per chi non lo è; in quest'ultimo caso vado alla pagina classica di login, nel primo caso invece eseguo il redirect all'sso. Conosco l'abilitazione dell'utente in base all'url da cui arrivo, ovvero se esso contiene la parola 'sso'
                                        if (url.indexOf('sso') !== -1) {
                                            // Vado login con sso
                                            this.router.navigate(['/ownershipLogin']);
                                        } else {
                                            // Cosa poco bella ma necessaria: in questo punto non si riescono a recuepreare i params dall'activa route, quindi bisogna prenderli tramite js;
                                            // In questo caso mi serve vedere se nell'url c'è lo userId. Esso è situato, se prensente, come ultimo parametro. Pertanto, ci saranno
                                            // in questo caso quattro slash /
                                            let ownershipUserId = null;
                                            if (url) {
                                                let slashInUrl = (url.match(/\//g) || []).length;
                                                let isSurveyRedirect = 4;
                                                if (url.indexOf('survey') !== -1) {
                                                    isSurveyRedirect = 5;
                                                }
                                                if (slashInUrl && slashInUrl === isSurveyRedirect) {
                                                    let lastSlashindex = url.lastIndexOf('/');
                                                    ownershipUserId = url.substring(lastSlashindex + 1);
                                                    // Se ho lo userId nell'url, lo salvo nel sessionStorage
                                                    if (ownershipUserId) {
                                                        sessionStorage.setItem('ownershipUserId', ownershipUserId);
                                                    }
                                                }
                                            }
                                            // Vado login senza sso
                                            this.router.navigate(['/ownershipLocalLogin']);
                                        }
                                    }
                                }
                            } else if (isWelfareEnel) {
                                // Login per gli utenti enel
                                if (sessionStorageWelfareEnelEmail || sessionStorageWelfareEnelUserId) {
                                    this.store.dispatch(
                                        new AuthActions.DoLogin({
                                            userId:
                                                sessionStorageWelfareEnelUserId ||
                                                sessionStorageWelfareEnelEmail,
                                            password: null,
                                            isWelfareEnel: true
                                        })
                                    );
                                } else if (sessionStorageToken) {
                                    if (isWelfareEnelSso) {
                                        this.store.dispatch(
                                            new AuthActions.DoLogin({
                                                email: sessionStorageToken,
                                                password: null,
                                                isWelfareEnel: true,
                                                isWelfareEnelSso: true
                                            })
                                        );
                                    } else {
                                        // Non è ancora autenticato l'utente ma avevo il token
                                        // TODO verificare se è un Supplier o Admin, sarebbe anche opportuno verificare un uuid del device per capire se è stato sniffato il token
                                        // Setto l'utente come autenticato
                                        this.store.dispatch(new AuthActions.SetUserAuthenticated());
                                        // Ri inizio il polling per il token. L'effect si occuperà anche di settare il nuovo token e di decodificarlo
                                        this.store.dispatch(new CoreActions.StartRenewTokenPolling());
                                    }
                                    return true;
                                } else {
                                    // Utente non loggato, quindi redirect alla pagina di Login. Prima però salvo nello Store l'url corrente affinché, una volta eseguito correttamente il login, si esegua il redirect nella pagina richiesta
                                    let url = state.url;

                                    if (url) {
                                        if (skipSsoParam) {
                                            url = removeURLParameter(url, "skipSso");
                                        }
                                        this.store.dispatch(new CoreActions.SaveRedirectUrl(url));
                                        // Ci sono due tipi di login per i taker, uno per chi è abilitato all'sso e uno per chi non lo è; in quest'ultimo caso vado alla pagina classica di login, nel primo caso invece eseguo il redirect all'sso. Conosco l'abilitazione dell'utente in base all'url da cui arrivo, ovvero se esso contiene la parola 'sso'
                                        if (url.indexOf('sso') !== -1) {
                                            // Vado login con sso
                                            this.router.navigate(['/welfareEnelLogin']);
                                        } else {
                                            // Cosa poco bella ma necessaria: in questo punto non si riescono a recuepreare i params dall'activa route, quindi bisogna prenderli tramite js;
                                            // In questo caso mi serve vedere se nell'url c'è lo userId. Esso è situato, se prensente, come ultimo parametro. Pertanto, ci saranno
                                            // in questo caso quattro slash /
                                            let welfareEnelUrlUserId = null;
                                            if (url) {
                                                let slashInUrl = (url.match(/\//g) || []).length;
                                                if (slashInUrl && slashInUrl === 3) {
                                                    let lastSlashindex = url.lastIndexOf('/');
                                                    welfareEnelUrlUserId = url.substring(lastSlashindex + 1);
                                                    // Se ho lo userId nell'url, lo salvo nel sessionStorage
                                                    if (welfareEnelUrlUserId) {
                                                        sessionStorage.setItem('welfareEnelUserId', welfareEnelUrlUserId);
                                                    }
                                                }
                                            }

                                            if (isEnelWelfareTeacher) {
                                                // Login docenti
                                                this.router.navigate(['/enelWelfareTeacherLogin']);
                                            } else if (isEnelWelfareSupport) {
                                                this.router.navigate(['/enelWelfareSupportLogin']);
                                            } else {
                                                // Vado login senza sso
                                                this.router.navigate(['/welfareEnelLocalLogin']);
                                            }
                                        }
                                    }
                                }
                            } else {
                                if (sessionStorageTakerEmail ||
                                    sessionStorageSmartEnergyTakerUserId ||
                                    sessionStoragetakerUserId ||
                                    sessionStorageNudgingUserId ||
                                    sessionStorageOnlineMeetingUserEmail ||
                                    sessionStorageOnlineMeetingUserId ||
                                    sessionStorageOnlineMeetingTeacherEmail ||
                                    sessionStorageOnlineMeetingTeacherId ||
                                    sessionStorageOnlineMeetingSupportEmail ||
                                    sessionStorageOnlineMeetingSupportId ||
                                    sessionStorageWelionWelfareTeacherEmail ||
                                    sessionStorageWelionWelfareTeacherId ||
                                    sessionStorageWelionWelfareSupportEmail ||
                                    sessionStorageWelionWelfareSupportId ||
                                    sessionStorageEnelWelfareSupportEmil ||
                                    sessionStorageEnelWelfareSupportId ||
                                    sessionStorageMeetingUserId ||
                                    sessionStorageMeetingUserEmail
                                ) {
                                    this.store.dispatch(
                                        new AuthActions.DoLogin({
                                            email:
                                                sessionStoragetakerUserId ||
                                                sessionStorageSmartEnergyTakerUserId ||
                                                sessionStorageTakerEmail ||
                                                sessionStorageNudgingUserId ||
                                                sessionStorageMeetingUserEmail ||
                                                sessionStorageOnlineMeetingUserEmail ||
                                                sessionStorageOnlineMeetingUserId ||
                                                sessionStorageOnlineMeetingTeacherEmail ||
                                                sessionStorageOnlineMeetingTeacherId ||
                                                sessionStorageOnlineMeetingSupportEmail ||
                                                sessionStorageOnlineMeetingSupportId ||
                                                sessionStorageWelionWelfareTeacherEmail ||
                                                sessionStorageWelionWelfareTeacherId ||
                                                sessionStorageWelionWelfareSupportEmail ||
                                                sessionStorageWelionWelfareSupportId ||
                                                sessionStorageEnelWelfareSupportEmil ||
                                                sessionStorageEnelWelfareSupportId ||
                                                sessionStorageMeetingUserId,
                                            password: null,
                                            isTaker: !isMeetingUser && !isOnlineMeetingUser && !isSmartEnergyTaker && !isOnlineMeetingTeacher && !isOnlineMeetingSupport && !isWelionWelfareTeacher && !isWelionWelfareSupport,
                                            isMeetingUser: isMeetingUser,
                                            isOnlineMeetingUser: isOnlineMeetingUser,
                                            isOnlineMeetingTeacher: isOnlineMeetingTeacher,
                                            isOnlineMeetingSupport: isOnlineMeetingSupport,
                                            isWelionWelfareTeacher: isWelionWelfareTeacher,
                                            isWelionWelfareSupport: isWelionWelfareSupport,
                                            isEnelWelfareTeacher: isEnelWelfareTeacher,
                                            isEnelWelfareSupport: isEnelWelfareSupport,
                                            isSmartEnergyTaker: isSmartEnergyTaker,
                                            isNudgingUser: isNudgingUser
                                        })
                                    );
                                } else if (sessionStorageToken) {
                                    if (isSsoMeetingUser) {
                                        this.store.dispatch(
                                            new AuthActions.DoLogin({
                                                email: sessionStorageToken,
                                                password: null,
                                                isMeetingUser: true,
                                                isSso: true
                                            })
                                        );
                                    }
                                    if (isSsoTaker) {
                                        this.store.dispatch(
                                            new AuthActions.DoLogin({
                                                email: sessionStorageToken,
                                                password: null,
                                                isTaker: true,
                                                isSso: true
                                            })
                                        );
                                    } if (isEuropeAssistanceSSO) {
                                        this.store.dispatch(
                                            new AuthActions.DoLogin({
                                                email: sessionStorageToken,
                                                password: null,
                                                isEuropeAssistance: true,
                                                isEuropeAssistanceSso: true
                                            })
                                        );
                                    } if (isSmartEnergyTakerSso) {
                                        this.store.dispatch(
                                            new AuthActions.DoLogin({
                                                email: sessionStorageToken,
                                                password: null,
                                                isSmartEnergyTaker: true,
                                                isSmartEnergyTakerSso: true
                                            })
                                        );
                                    } else if (isHeritageSso) {
                                        this.store.dispatch(
                                            new AuthActions.DoLogin({
                                                email: sessionStorageToken,
                                                password: null,
                                                isHeritage: true,
                                                isSso: true
                                            })
                                        );
                                    } else {
                                        // Non è ancora autenticato l'utente ma avevo il token
                                        // TODO verificare se è un Supplier o Admin, sarebbe anche opportuno verificare un uuid del device per capire se è stato sniffato il token
                                        // Setto l'utente come autenticato
                                        this.store.dispatch(new AuthActions.SetUserAuthenticated());
                                        // Ri inizio il polling per il token. L'effect si occuperà anche di settare il nuovo token e di decodificarlo
                                        this.store.dispatch(new CoreActions.StartRenewTokenPolling());
                                    }
                                    return true;
                                } else {
                                    // Utente non loggato, quindi redirect alla pagina di Login. Prima però salvo nello Store l'url corrente affinché, una volta eseguito correttamente il login, si esegua il redirect nella pagina richiesta
                                    let url = state.url;
                                    let isTaker = false;
                                    let isSupplier = false;
                                    // Funzione modificata per l' utente fundingSupplier
                                    let isFundingSupplier = false;
                                    if (url) {
                                        if (skipSsoParam) {
                                            url = removeURLParameter(url, "skipSso");
                                        }
                                        this.store.dispatch(new CoreActions.SaveRedirectUrl(url));
                                        if (url.indexOf('takers') !== -1) {
                                            // Salvo il redirect url anche nel session storage (visto che per i taker non c'è una home page di default su cui atterrare, e se facessi F5 l'informazione del redirectUrl in redux si perderebbe)
                                            isTaker = true;
                                            // Ci sono due tipi di login per i taker, uno per chi è abilitato all'sso e uno per chi non lo è; in quest'ultimo caso vado alla pagina classica di login, nel primo caso invece eseguo il redirect all'sso. Conosco l'abilitazione dell'utente in base all'url da cui arrivo, ovvero se esso contiene la parola 'sso'
                                            if (url.indexOf('sso') !== -1) {
                                                // Vado login con sso
                                                this.router.navigate(['/takerLogin']);
                                            } else {
                                                // Cosa poco bella ma necessaria: in questo punto non si riescono a recuepreare i params dall'activa route, quindi bisogna prenderli tramite js;
                                                // In questo caso mi serve vedere se nell'url c'è lo userId. Esso è situato, se prensente, come ultimo parametro. Pertanto, ci saranno
                                                // in questo caso quattro slash /
                                                let takerUserId = null;
                                                if (url) {
                                                    let slashInUrl = (url.match(/\//g) || []).length;
                                                    let isSurveyRedirect = 4;
                                                    if (url.indexOf('survey') !== -1) {
                                                        isSurveyRedirect = 5;
                                                    }
                                                    if (slashInUrl && slashInUrl === isSurveyRedirect) {
                                                        let lastSlashindex = url.lastIndexOf('/');
                                                        takerUserId = url.substring(lastSlashindex + 1);
                                                        // Se ho lo userId nell'url, lo salvo nel sessionStorage
                                                        if (takerUserId) {
                                                            sessionStorage.setItem('takerUserId', takerUserId);
                                                        }
                                                    }
                                                }
                                                // Vado login senza sso
                                                this.router.navigate(['/takerLocalLogin']);
                                            }
                                        }
                                        if (url.indexOf('nudgingSurvey') !== -1) {
                                            // Cosa poco bella ma necessaria: in questo punto non si riescono a recuepreare i params dall'activa route, quindi bisogna prenderli tramite js;
                                            // In questo caso mi serve vedere se nell'url c'è lo userId. Esso è situato, se prensente, come ultimo parametro. Pertanto, ci saranno
                                            // in questo caso quattro slash /
                                            let nudgingUserId = null;
                                            if (url) {
                                                let lastSlashindex = url.lastIndexOf('/', url.lastIndexOf('/') - 1);
                                                nudgingUserId = url.substring(lastSlashindex + 1);
                                                // Se ho lo userId nell'url, lo salvo nel sessionStorage
                                                if (nudgingUserId) {
                                                    sessionStorage.setItem('nudgingUserId', nudgingUserId);
                                                }
                                            }
                                            // Vado login senza sso
                                            this.router.navigate(['/nudgingLocalLogin']);
                                        }
                                        if (isOnlineMeetingUser) {
                                            // Può essere che mi sia arrivato lo userId dal dall'url (userCourses/:userId oppure userCourses/customers/:userId)
                                            let onlineMeetingUserId = null;
                                            let goToAutomaticLoginForCustomers
                                            if (url) {
                                                let slashInUrl = (url.match(/\//g) || []).length;
                                                let slashToCompare = 3;
                                                if (url.indexOf('customers') !== -1) {
                                                    goToAutomaticLoginForCustomers = true;
                                                    slashToCompare = 4;
                                                }

                                                if (slashInUrl && slashInUrl === slashToCompare) {
                                                    let lastSlashindex = url.lastIndexOf('/');
                                                    onlineMeetingUserId = url.substring(lastSlashindex + 1);
                                                    // Se ho lo userId nell'url, lo salvo nel sessionStorage
                                                    if (onlineMeetingUserId) {
                                                        sessionStorage.setItem('onlineMeetingUserId', onlineMeetingUserId);
                                                    }
                                                }
                                            }

                                            if (!goToAutomaticLoginForCustomers) {
                                                this.router.navigate(['/onlineMeetingUserLogin']);
                                            } else {
                                                this.router.navigate(['/onlineMeetingCustomerAutomaticLogin']);
                                            }
                                        } else if (isOnlineMeetingTeacher) {
                                            this.router.navigate(['/onlineMeetingTeacherLogin']);
                                        } else if (isOnlineMeetingSupport) {
                                            this.router.navigate(['/onlineMeetingSupportLogin']);
                                        } else if (isPublicMeetingUser) {
                                            this.router.navigate(['/publicUsersLogin']);
                                        } else if (isWelionWelfareTeacher) {
                                            this.router.navigate(['/welionWelfareTeacherLogin']);
                                        } else if (isWelionWelfareSupport) {
                                            this.router.navigate(['/welionWelfareSupportLogin']);
                                        } else if (isWelionWelfarePublicMeetingUser) {
                                            this.router.navigate(['/welionWelfareUsersLogin']);
                                        } else if (isEnelWelfareTeacher) {
                                            this.router.navigate(['/enelWelfareTeacherLogin']);
                                        } else if (isEnelWelfareSupport) {
                                            this.router.navigate(['/enelWelfareSupportLogin']);
                                        } else if (isEuropeAssistance) {
                                            if (url.indexOf('sso') !== -1) {
                                                // Vado login con sso
                                                this.router.navigate(['/europeAssistanceLogin']);
                                            } else {
                                                this.router.navigate(['/europeAssistanceLocalLogin']);
                                            }
                                        } else if (isSmartEnergyTaker) {
                                            if (url.indexOf('sso') !== -1) {
                                                // Vado login con sso
                                                this.router.navigate(['/smartEnergySubscriptionLogin']);
                                            } else {
                                                this.router.navigate(['/smartEnergySubscriptionLocalLogin']);
                                            }
                                        } else if (isHeritageTaker) {
                                            sessionStorage.setItem("redirectHeritageUrl", url);
                                            if (url.indexOf('sso') !== -1) {
                                                // Prima di chiamare il servizio per il login, identifico l'userAgent e il tipo di device dell'utente
                                                const deviceInfo = this.deviceService.getDeviceInfo();
                                                const userAgent = deviceInfo && deviceInfo.userAgent;
                                                let deviceType;
                                                if (this.deviceService.isMobile()) {
                                                    // Salvo il fatto che è uno smartphone
                                                    deviceType = 'P';
                                                } else if (this.deviceService.isTablet()) {
                                                    // Salvo il fatto che è un tablet
                                                    deviceType = 'T';
                                                } else if (this.deviceService.isDesktop()) {
                                                    // Salvo il fatto che è un computer desktop
                                                    deviceType = 'D';
                                                }
                                                // Ridireziono l'utente verso il sistema di SSO
                                                let storageRedirectUrl = sessionStorage.getItem('redirectUrl');
                                                const redirectUrl = this.urlService.getSSOUrl(deviceType, userAgent, '/#/' + storageRedirectUrl);
                                                window.location.href = redirectUrl;
                                            } else {
                                                this.router.navigate(['/heritageLogin']);
                                            }
                                        } else if (isDeiTaker) {
                                            sessionStorage.setItem("redirectDEIUrl", url);
                                            if (url.indexOf('sso') !== -1) {
                                                // Prima di chiamare il servizio per il login, identifico l'userAgent e il tipo di device dell'utente
                                                const deviceInfo = this.deviceService.getDeviceInfo();
                                                const userAgent = deviceInfo && deviceInfo.userAgent;
                                                let deviceType;
                                                if (this.deviceService.isMobile()) {
                                                    // Salvo il fatto che è uno smartphone
                                                    deviceType = 'P';
                                                } else if (this.deviceService.isTablet()) {
                                                    // Salvo il fatto che è un tablet
                                                    deviceType = 'T';
                                                } else if (this.deviceService.isDesktop()) {
                                                    // Salvo il fatto che è un computer desktop
                                                    deviceType = 'D';
                                                }
                                                // Ridireziono l'utente verso il sistema di SSO
                                                let storageRedirectUrl = sessionStorage.getItem('redirectUrl');
                                                const redirectUrl = this.urlService.getSSOUrl(deviceType, userAgent, '/#/' + storageRedirectUrl);
                                                window.location.href = redirectUrl;
                                            } else {
                                                this.router.navigate(['/deiLogin']);
                                            }
                                        }


                                        if (isMeetingUser) {
                                            if (url.indexOf("sso") !== -1) {
                                                // Vado login con sso
                                                this.router.navigate(["/meetingUserLogin"]);
                                            } else {
                                                // Cosa poco bella ma necessaria: in questo punto non si riescono a recuepreare i params dall'activa route, quindi bisogna prenderli tramite js;
                                                // In questo caso mi serve vedere se nell'url c'è lo userId. Esso è situato, se prensente, come ultimo parametro. Pertanto, ci saranno
                                                // in questo caso quattro slash /
                                                let meetingUserId = null;
                                                if (url) {
                                                    let slashInUrl = (url.match(/\//g) || []).length;
                                                    if (slashInUrl && slashInUrl === 4) {
                                                        let lastSlashindex = url.lastIndexOf("/");
                                                        meetingUserId = url.substring(lastSlashindex + 1);
                                                        // Se ho lo userId nell'url, lo salvo nel sessionStorage
                                                        if (meetingUserId) {
                                                            sessionStorage.setItem(
                                                                "meetingUserId",
                                                                meetingUserId
                                                            );
                                                        }
                                                    }
                                                }
                                                // Vado login senza sso
                                                this.router.navigate(["/meetingUserLocalLogin"]);
                                            }
                                        }
                                        if (url.indexOf('suppliers') !== -1) {
                                            // Salvo il redirect url anche nel session storage (visto che per i taker non c'è una home page di default su cui atterrare, e se facessi F5 l'informazione del redirectUrl in redux si perderebbe)
                                            isSupplier = true;
                                            // Vado al login dei taker
                                            this.router.navigate(['/supplierLogin']);
                                        }

                                        if (url.indexOf('fundingSupplier') !== -1) {
                                            // Salvo il redirect url anche nel session storage (visto che per i taker non c'è una home page di default su cui atterrare, e se facessi F5 l'informazione del redirectUrl in redux si perderebbe)
                                            isFundingSupplier = true;
                                            // Vado al login dei taker
                                            this.router.navigate(['/supplierLogin']);
                                        }
                                    }

                                    if (!isOnlineMeetingUser && !isEuropeAssistance && !isSmartEnergyTaker && !isPublicMeetingUser && !isWelionWelfarePublicMeetingUser && !isOnlineMeetingTeacher && !isOnlineMeetingSupport && !isWelionWelfareTeacher && !isWelionWelfareSupport && !isEnelWelfareTeacher && !isEnelWelfareSupport && !isTaker && !isSupplier && !isMeetingUser && !isFundingSupplier && !isNudgingUser && !isHeritageTaker && !isDeiTaker) {
                                        if (skipSso) {
                                            this.router.navigate(["/localLogin"]);
                                        } else {
                                            this.router.navigate(["/login"]);
                                        }
                                    }
                                }
                            }
                            return false;
                        }

                    })
                )
        );
    }

    isValidEntryPoint(url: string, authState: fromAuth.AuthState) {
        let isValidUrl = false;
        // con l'utente autenticato solo determinati entrypoints sono ammessi in base al tipo di autenticazione
        if (authState && authState.authenticated) {
            const isDefaulAuth = !authState.isTaker && !authState.isWelionTs && !authState.isWelfareEnel && !authState.isWelionWelfarePublicMeetingUser && !authState.isOnlineMeetingUser && !authState.isPublicMeetingUser && !authState.isOnlineMeetingTeacher && !authState.isOnlineMeetingSupport && !authState.isWelionWelfareTeacher && !authState.isWelionWelfareSupport && !authState.isEnelWelfareTeacher && !authState.isEnelWelfareSupport && !authState.isMeetingUser && !authState.isSupplier && !authState.isFundingSupplier && !authState.isEuropeAssistance && !authState.isSmartEnergyTaker && !authState.isOwnership && !authState.isNudgingUser;
            isValidUrl = (authState.isTaker && url.indexOf("takers") !== -1) ||
                (authState.isOnlineMeetingUser && url.indexOf("onlineMeeting/userCourses") !== -1) ||
                (authState.isWelionTs && url.indexOf("welionsUsersTS") !== -1) ||
                (authState.isOwnership && url.indexOf("ownershipUsers") !== -1) ||
                (authState.isWelfareEnel && url.indexOf("myWelfareEnel") !== -1) ||
                (authState.isEuropeAssistance && url.indexOf("europeAssistanceUsers") !== -1) ||
                (authState.isSmartEnergyTaker && url.indexOf("smartEnergyTakers") !== -1) ||
                (authState.isPublicMeetingUser && url.indexOf("onlineMeeting/userCourses") !== -1) ||
                (authState.isWelionWelfarePublicMeetingUser && url.indexOf("welionWelfare/userCourses") !== -1) ||
                (authState.isOnlineMeetingTeacher && url.indexOf("onlineMeeting/teacherCourses") !== -1) ||
                (authState.isOnlineMeetingSupport && url.indexOf("onlineMeeting/supportCourses") !== -1) ||
                (authState.isWelionWelfareTeacher && url.indexOf("welionWelfare/teacherCourses") !== -1) ||
                (authState.isWelionWelfareSupport && url.indexOf("welionWelfare/supportCourses") !== -1) ||
                (authState.isEnelWelfareTeacher && url.indexOf("myWelfareEnel/teacherCourses") !== -1) ||
                (authState.isEnelWelfareSupport && url.indexOf("myWelfareEnel/supportCourses") !== -1) ||
                (authState.isMeetingUser && url.indexOf("meetingUsers") !== -1) ||
                (authState.isFundingSupplier && url.indexOf('fundingSupplier') !== -1) ||
                (authState.isSupplier && url.indexOf("suppliers") !== -1) ||
                (authState.isNudgingUser && url.indexOf("nudgingSurvey") !== -1) ||
                (authState.isHeritageTaker && url.indexOf("heritageHone") !== -1) ||
                (authState.isHeritageTaker && url.indexOf("heritage/courseSubscription/sso") !== -1) ||
                (authState.isDeiTaker && url.indexOf("deiHone") !== -1) ||
                (authState.isDeiTaker && url.indexOf("dei/courseSubscription/sso") !== -1) ||
                (isDefaulAuth && (
                    url.indexOf('templates') !== -1
                    || url.indexOf('initiatives') !== -1
                    || url.indexOf('takers') !== -1
                    || url.indexOf('nudgingSurvey') !== -1
                    || url.indexOf('myWelfareEnel') !== -1
                    || url.indexOf('welionsUsersTS') !== -1
                    || url.indexOf('ownershipUsers') !== -1
                    || url.indexOf('onlineMeeting') !== -1
                    || url.indexOf('welionWelfare') !== -1
                    || url.indexOf('locations') !== -1
                    || url.indexOf('specialProjects') !== -1
                    || url.indexOf('home') !== -1
                    || url.indexOf('landingPerformanceManager') !== -1
                    || url.indexOf('dashboard') !== -1
                    || url.indexOf('groups') !== -1
                    || url.indexOf('europeAssistanceUsers') !== -1
                    || url.indexOf('smartEnergyTakers') !== -1
                ));
        } else {
            // altrimenti (se devo autenticare) consento il tentativo di accesso per rimandare l'utente alla login opportuna
            isValidUrl = url.indexOf('takers') !== -1
                || url.indexOf('suppliers') !== -1
                || url.indexOf('templates') !== -1
                || url.indexOf('initiatives') !== -1
                || url.indexOf('fundingSupplier') !== -1
                || url.indexOf("meetingUsers") !== -1
                || url.indexOf('takers') !== -1
                || url.indexOf('nudgingSurvey') !== -1
                || url.indexOf('welionsUsersTS') !== -1
                || url.indexOf('ownershipUsers') !== -1
                || url.indexOf('myWelfareEnel') !== -1
                || url.indexOf('onlineMeeting') !== -1
                || url.indexOf('welionWelfare') !== -1
                || url.indexOf('home') !== -1
                || url.indexOf('europeAssistanceUsers') !== -1
                || url.indexOf('landingPerformanceManager') !== -1
                || url.indexOf('dashboard') !== -1
                || url.indexOf('groups') !== -1
                || url.indexOf('locations') !== -1
                || url.indexOf('specialProjects') !== -1
                || url.indexOf('smartEnergyTakers') !== -1
                || url.indexOf('heritage/courseSubscription/sso') !== -1
                || url.indexOf('dei/courseSubscription/sso') !== -1;
        }
        return isValidUrl;
    }

    // Recupera token dalla chiave dell'url
    getTokenFromSsortkqp(key: string, sessionStorageToken?: string) {
        return new Promise((resolve, reject) => {
            if (sessionStorageToken && sessionStorageToken.length) {
                // Può capitare di avere già un tiny token in session. Se c'è uso quello.
                resolve(sessionStorageToken);
            } else {
                this.authService.retrieveTokenAfterLogin(key).subscribe((senecaResponse) => {
                    if (senecaResponse.error) {
                        reject();
                    } else {
                        if (senecaResponse && senecaResponse.response) {
                            resolve(senecaResponse.response);
                        } else {
                            resolve(null);
                        }
                    }
                },
                    (err) => {
                        reject();
                    })
            }
        })
    }
}