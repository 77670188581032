import { ItemTypes, Item, ItemAttributeTypes, parseBoolean, TagAttributeTypes } from '../../../cm2-commonclasses';

/*
 * Modello che dinisce le classi utili per la gestione delle iniziative
*/

export class InitiativeType {
    public id?: string;
    public name: string;
    public code: string;
    public disabled?: boolean;

    constructor(name: string, code: string, disabled: boolean, id?: string) {
        this.id = id;
        this.name = name;
        this.code = code;
        this.disabled = disabled;
    }
}

export class LibraryType {
    public id?: string;
    public name: string;
    public code: string;
    public disabled?: boolean;

    constructor(name: string, code: string, disabled: boolean, id?: string) {
        this.id = id;
        this.name = name;
        this.code = code;
        this.disabled = disabled;
    }
}

export const CKEditorConfig = this.ckeConfig = {
    allowedContent: true,
    autoParagraph: false,
    extraPlugins: 'richcombo,placeholder_select,doNothing,divarea',
    height: 317,
    enterMode: 2,
    toolbar: [
        {
            name: 'document',
            groups: ['mode', 'document', 'doctools', 'undo', 'basicstyles', 'cleanup', 'list', 'indent', 'blocks', 'align'],
            items: [
                'Source', '-', 'Preview', 'Undo', 'Redo', 'Bold', 'Italic', 'Underline',
                'Strike', 'Subscript', 'Superscript', 'RemoveFormat', 'NumberedList', 'BulletedList', 'Outdent',
                'Indent', 'Blockquote', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', 'Image', 'Table',
                'Link', 'Unlink', 'Smiley', 'SpecialChar', 'TextColor', 'BGColor', 'Maximize'
            ]
        },
        { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] }
    ]
};

const markers = [
    { text: 'ora fine prima giornata (day.endTime)', textEditor: 'day.endTime' },
    { text: 'data inizio prima giornata (day.startDate)', textEditor: 'day.startDate' },
    { text: 'giorno inizio prima giornata (day.startDateDay)', textEditor: 'day.startDateDay' },
    { text: 'mese fine prima giornata (day.startDateMonth)', textEditor: 'day.startDateMonth' },
    { text: 'ora inizio prima giornata (day.startTime)', textEditor: 'day.startTime' },
    { text: 'data fine edizione (edition.endDate)', textEditor: 'edition.endDate' },
    { text: 'data inizio edizione (edition.startDate)', textEditor: 'edition.startDate' },
    { text: 'giorno inizio edizione (edition.startDateDay)', textEditor: 'edition.startDateDay' },
    { text: 'mese inizio edizione (edition.startDateMonth)', textEditor: 'edition.startDateMonth' },
    { text: 'elenco giornate edizione (edition.allDaysAsList)', textEditor: 'edition.allDaysAsList' },
    { text: 'link alla rilevazione (links.assessment)', textEditor: 'links.assessment' },
    { text: 'link per iscrizione (links.confirmation)', textEditor: 'links.confirmation' },
    { text: 'link per il download (links.download)', textEditor: 'links.download' },
    { text: 'link al LearningSystem della GLP (links.learningSystemHome)', textEditor: 'links.learningSystemHome' },
    { text: 'link alla Homepage di Library (links.libraryHome)', textEditor: 'links.libraryHome' },
    { text: 'link alla pagina di validazione persone (links.peopleListValidationPage)', textEditor: 'links.peopleListValidationPage' },
    { text: 'indirizzo luogo del corso (location.fullAddress)', textEditor: 'location.fullAddress' },
    { text: 'stanza luogo del corso (location.room)', textEditor: 'location.room' },
    { text: 'piano luogo del corso (location.floor)', textEditor: 'location.floor' },
    { text: 'struttura luogo del corso (location.building)', textEditor: 'location.building' },
    { text: 'città luogo del corso (location.city)', textEditor: 'location.city' },
    { text: 'paese luogo del corso (location.country)', textEditor: 'location.country' },
    { text: 'nome luogo del corso (location.fullName)', textEditor: 'location.fullName' },
    { text: 'posto luogo del corso (location.place)', textEditor: 'location.place' },
    { text: 'zip luogo del corso (location.zip)', textEditor: 'location.zip' },
    { text: 'email del manager (manager.email)', textEditor: 'manager.email' },
    { text: 'nome del manager (manager.forename)', textEditor: 'manager.forename' },
    { text: 'num. telefono del manager (manager.primoTelUff)', textEditor: 'manager.primoTelUff' },
    { text: 'cognome del manager (manager.surname)', textEditor: 'manager.surname' },
    { text: 'titolo iniziativa (syllabus.title)', textEditor: 'syllabus.title' },
    { text: 'anno corrente (time.year)', textEditor: 'time.year' },
    { text: 'nome utente (user.forename)', textEditor: 'user.forename' },
    { text: 'cognome utente (user.surname)', textEditor: 'user.surname' },
    { text: 'firma (userInput.signature)', textEditor: 'userInput.signature' }

]

export const CKEditorConfigInitiativeTemplateEdit = this.ckeConfigInitiativeTemplateEdit = {
    allowedContent: true,
    autoParagraph: false,
    extraPlugins: 'richcombo,placeholder_select,doNothing',
    enterMode: 2,
    format_tags: 'p;gen_box;gen_bold',
    format_p: { element: 'p', name: 'p', styles: { 'font-size': '13px', color: '#333', 'background-color': 'white', 'line-height': '1.6' } },
    format_gen_box: { element: 'p', name: 'Box Generali', styles: { color: 'white', 'background-color': '#c5281c', 'font-weight': 'bold', 'padding': '10px' } },
    format_gen_bold: { element: 'p', name: 'Bold Generali', styles: { color: '#c5281c', 'font-weight': 'bold' } },
    fillEmptyBlocks: false,
    placeholder_select: {
        placeholders: markers,
        format: '${%placeholder%}'
    },
    toolbar: [
        {
            name: 'document',
            groups: ['mode', 'document', 'doctools', 'undo', 'basicstyles', 'cleanup', 'list', 'indent', 'blocks', 'align'],
            items: [
                '-', 'Undo', 'Redo', 'placeholder_select', 'Bold', 'Italic', 'Underline',
                'Strike', 'RemoveFormat', 'NumberedList', 'BulletedList',
                'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock',
                'Link', 'Unlink', 'TextColor', 'BGColor'
            ]
        },
        { name: 'styles', items: ['Format', 'FontSize'] }
    ]

};

export class ChooseFieldProposalsSidebar {
    isOpened: boolean;
    initiativeName?: any;
    fieldName?: any;
    title: string;
    proposalId?: any;
    fieldProposalsData: any;
    confirmAction?: Function;
    currentValue?: any;
    originalValue?: any;
    get proposalsLength() {
        return this.fieldProposalsData && this.fieldProposalsData.data && this.fieldProposalsData.data.length > 2 ?
            this.fieldProposalsData.data.length - 2 : 0;
    }
    constructor() {
        this.fieldProposalsData = {
            data: []
        };
        this.isOpened = false;
    }
}


export function addSupplierDataToSupplierMap(suppliersMap: any, courseModuleId: string, supplierId: string, supplierTaskId: string) {
    // non fa nulla senza i riferimenti necessari
    if (!courseModuleId || !supplierId) {
        return;
    }

    if (!suppliersMap[courseModuleId]) {
        suppliersMap[courseModuleId] = {};
    }
    if (supplierId && !suppliersMap[courseModuleId][supplierId]) {
        suppliersMap[courseModuleId][supplierId] = [];
    }
    const dataObj = <any>{
        courseModuleId: courseModuleId,
        supplierId: supplierId,
        supplierTaskId: supplierTaskId

    };

    // se ho un supplierTaskId per il supplierId allora verifico di non aver già inserito questo id
    //  oppure di non avere un oggetto senza supplierTask
    if (supplierTaskId && suppliersMap[courseModuleId][supplierId].length) {
        const taskList = suppliersMap[courseModuleId][supplierId];
        let suppliertaskIdFound = false;
        for (let index = 0; index < taskList.length; index++) {
            const element = taskList[index];
            // se vuoto posso aggiornare l'oggetto esistente
            if (!element.supplierTaskId) {
                element.supplierTaskId = supplierTaskId;
            }
            if (element.supplierTaskId === supplierTaskId) {
                suppliertaskIdFound = true;
            }
        }
        // pusho se non trovato    
        if (!suppliertaskIdFound) {
            suppliersMap[courseModuleId][supplierId].push(dataObj);
        }

    } else if (!supplierTaskId && !suppliersMap[courseModuleId][supplierId].length) {
        // altrimenti inserisco il supplier solo se per questo non ho già inserito un task
        suppliersMap[courseModuleId][supplierId].push(dataObj);
    }
}

export class InitiativeUtils {

    static isClassCourseInitiative(initiativeType: string): boolean {
        return initiativeType === ItemTypes.COURSE_CLASS_STAGE;
    }

    static isClassEvent(initiativeType: string): boolean {
        return initiativeType === ItemTypes.EVENT_CLASS_STAGE;
    }

    static isOnlineInitiative(initiativeType: string): boolean {
        return this.isOnlineCourseOrEvent(initiativeType) || initiativeType === ItemTypes.ASSESSMENT;
    }

    static isOnlineEvent(initiativeType: string): boolean {
        return initiativeType === ItemTypes.EVENT_ONLINE_STAGE || initiativeType === ItemTypes.TALENT_FARM_EVENT_ONLINE_STAGE;
    }

    static isTalentFarmOnlineEvent(initiativeType: string): boolean {
        return initiativeType === ItemTypes.TALENT_FARM_EVENT_ONLINE_STAGE;
    }

    static isOnlineCourseOrEvent(initiativeType: string): boolean {
        return initiativeType === ItemTypes.EVENT_ONLINE_STAGE || initiativeType === ItemTypes.TALENT_FARM_EVENT_ONLINE_STAGE || initiativeType === ItemTypes.COURSE_ONLINE_STAGE || initiativeType === ItemTypes.TALENT_FARM_COURSE_ONLINE_STAGE || initiativeType === ItemTypes.TALENT_FARM_OFCOURSEME_COLLECTION_STAGE || initiativeType === ItemTypes.EXTERNAL_ONLINE_STAGE;
    }

    static hasLinkedLibraryItem(initiativeType: string): boolean {
        return initiativeType === ItemTypes.EVENT_ONLINE_STAGE || initiativeType === ItemTypes.TALENT_FARM_EVENT_ONLINE_STAGE || initiativeType === ItemTypes.COURSE_ONLINE_STAGE || initiativeType === ItemTypes.TALENT_FARM_COURSE_ONLINE_STAGE || initiativeType === ItemTypes.TALENT_FARM_OFCOURSEME_COLLECTION_STAGE;
    }

    static isAssessment(initiativeType: string): boolean {
        return initiativeType === ItemTypes.ASSESSMENT;
    }

    static isEvent(initiativeType: string): boolean {
        return initiativeType === ItemTypes.EVENT_ONLINE_STAGE || initiativeType === ItemTypes.TALENT_FARM_EVENT_ONLINE_STAGE || initiativeType === ItemTypes.EVENT_CLASS_STAGE || initiativeType === ItemTypes.TALENT_FARM_EVENT_CLASS_STAGE;
    }

    static isCourse(initiativeType: string): boolean {
        return initiativeType === ItemTypes.COURSE_ONLINE_STAGE || initiativeType === ItemTypes.TALENT_FARM_COURSE_ONLINE_STAGE || initiativeType === ItemTypes.TALENT_FARM_OFCOURSEME_COLLECTION_STAGE || initiativeType === ItemTypes.COURSE_CLASS_STAGE || initiativeType === ItemTypes.EXTERNAL_ONLINE_STAGE || initiativeType === ItemTypes.EXTERNAL_COURSE_CLASS_STAGE;
    }

    static isWebinar(initiativeType: string): boolean {
        return initiativeType === ItemTypes.WEBINAR;
    }

    static isStage(initiativeType: string): boolean {
        return this.isAssessment(initiativeType) || this.isCourse(initiativeType) || this.isEvent(initiativeType) || this.isWebinar(initiativeType);
    }

    static isOnPremiseStage(initiativeType: string): boolean {
        return initiativeType == ItemTypes.COURSE_CLASS_STAGE || initiativeType == ItemTypes.EVENT_CLASS_STAGE || initiativeType == ItemTypes.TALENT_FARM_EVENT_CLASS_STAGE || initiativeType == ItemTypes.EXTERNAL_COURSE_CLASS_STAGE;
    }

    static isPath(initiativeType: string): boolean {
        return initiativeType === ItemTypes.COURSE_PATH;
    }
}


export function loadInitiativeAttributes(_context: any, initiative: any) {
    _context.initiativeAdvancedSidebar = !!_context.initiativeAdvancedSidebar ? _context.initiativeAdvancedSidebar : {};

    initiative.itemAttributes.forEach((attr) => {
        if (attr.attributeType === ItemAttributeTypes.FUNDING_TYPE) {
            // Attributo relativo alla tipologia del finanziamento
            _context.initiativeAdvancedSidebar.selectedFunding = attr.attributeValue;
            initiative.fundingType = attr.attributeValue;
        } else if (attr.attributeType === TagAttributeTypes.FUNDING_CODE) {
            // Attributo relativo al codice del finanziamento
            _context.initiativeAdvancedSidebar.selectedNotice = {
                id: attr.referenceId,
                title: attr.attributeValue,
                tagType: attr.referenceType,
                originApplicationName: attr.referenceApplicationName
            }
        } else if (attr.attributeType === ItemAttributeTypes.FUNDING_MODULE_NAME && attr.attributeValue && attr.attributeValue.length) {
            // Attributo relativo al nome modulo
            _context.initiativeAdvancedSidebar.selectedModuleName = {
                id: attr.referenceId,
                title: attr.attributeValue,
                tagType: attr.referenceType,
                originApplicationName: attr.referenceApplicationName
            };
        } else if (attr.attributeType === 'OLD_SYLLABUS_REFERENCE') {
            // Verifico se l'iniziativa che sto modificando possiede la referenza ad un Syllabus vecchio, già creato, quindi facente parte di altre iniziative
            _context.isLinkedToOldSyllabus = true;
            _context.enableEditOfSyllabus = false;
        } else if (attr.attributeType === 'CREATION_PATH') {
            _context.creationPath = attr.attributeValue;
        } else if (attr.attributeType === 'ENABLE_COMMUNICATIONS_KIT_STEP') {
            // Verifico se far mostrare lo step delle comunicazioni
            _context.isCommunicationsKitEnabled = parseBoolean(attr.attributeValue);
        } else if (attr.attributeType === ItemAttributeTypes.EVALUATION) {
            _context.isCourseEvaluationEnabled = parseBoolean(attr.attributeValue);
        } else if (attr.attributeType === ItemAttributeTypes.REQUIRED_PREMIUM_COINS) {
            _context.isRequiredPremiumCoins = true;
            _context.premiumCoinsValule = parseInt(attr.attributeValue, 10);
        } else if (attr.attributeType === ItemAttributeTypes.COMPLETION_PREMIUM_COINS_PRIZE) {
            _context.isRequiredCompleteCoins = true;
            _context.completeCoinsValule = parseInt(attr.attributeValue, 10);
        } else if (attr.attributeType === ItemAttributeTypes.OPENING_PREMIUM_COINS_PRIZE) {
            _context.isRequiredConsultingCoins = true;
            _context.consultingCoinsValule = parseInt(attr.attributeValue, 10);
        } else if (attr.attributeType === ItemAttributeTypes.GALLERY) {
            _context.isCourseGalleryEnabled = parseBoolean(attr.attributeValue);
        } else if (attr.attributeType === ItemAttributeTypes.SOCIAL_WALL) {
            _context.isCourseSocialWallEnabled = parseBoolean(attr.attributeValue);
        } else if (attr.attributeType === ItemAttributeTypes.SENIORITY) {
            if (!_context.selectedSeniorityLevel) {
                _context.selectedSeniorityLevel = [];
            }
            if (_context.seniorityLevelOptions && _context.seniorityLevelOptions.length) {
                for (let h = 0, competencesLength = _context.seniorityLevelOptions.length; h < competencesLength; h++) {
                    const currentLevel = _context.seniorityLevelOptions[h];
                    if (currentLevel.code === attr.attributeValue) {
                        _context.initiativeAdvancedSidebar.selectedSeniorityLevel.push(currentLevel);
                        break;
                    }
                }
            }
        } else if (attr.attributeType === ItemAttributeTypes.PROFESSIONAL_FIELD) {
            if (!_context.selectedProfessionalAreas) {
                _context.selectedProfessionalAreas = [];
            }
            const customSelectedData = {
                tagId: attr.attributeValue,
                title: ''
            };
            _context.initiativeAdvancedSidebar.selectedProfessionalAreas.push(customSelectedData);
        } else if (attr.attributeType === ItemAttributeTypes.CONTENT_AREA) {
            if (!_context.selectedContentAreas) {
                _context.selectedContentAreas = [];
            }
            const customContentArea = {
                tagId: attr.attributeValue,
                title: ''
            };
            _context.initiativeAdvancedSidebar.selectedContentAreas.push(customContentArea);
        } else if (attr.attributeType === 'ENABLE_SURVEY_STEP') {
            // Verifico se far mostrare lo step della survey
            _context.isSurveyStepEnabled = parseBoolean(attr.attributeValue);
        } else if (attr.attributeType === 'EXTERNAL_OBJECT_ID') {
            // Verifico se, al corso online, è collegato ed è stato aggiunto un oggetto Library
            if (!!_context.selectedItem) {
                _context.selectedItem.itemId = attr.attributeValue;
            }
        } else if (attr.attributeType === ItemAttributeTypes.DEFAULT_MAIL_SENDER) {
            // Verifico gli attr DEFAULT_MAIL_SENDER, DEFAULT_MAIL_SIGNATURE per pre-selezionare mittente e firma
            initiative.defaultMailSenderId = attr.referenceId;
        } else if (attr.attributeType === ItemAttributeTypes.DEFAULT_MAIL_SIGNATURE) {
            initiative.defaultSingatureTexttemplateId = attr.referenceId;
        } else if (attr.attributeType === ItemAttributeTypes.PUBLIC_EDITION) {
            initiative.isPublicEdition = parseBoolean(attr.attributeValue);
        } else if (attr.attributeType === ItemAttributeTypes.PREREGISTRATION_REQUIRED) {
            // Verifico il flag per la preiscrizione
            initiative.preRegistrationRequired = parseBoolean(attr.attributeValue);
        } else if (attr.attributeType === 'ENABLE_SAP_EXPORT') {
            // Verifico il flag per l'export verso sapENABLE_SAP_EXPORT
            _context.isSapExportEnabled = parseBoolean(attr.attributeValue);
        } else if (attr.attributeType === 'NUDGING_ENABLED') {
            // Verifico il flag per il nudging
            _context.isNudgingEnabled = parseBoolean(attr.attributeValue);
        } else if (attr.attributeType === ItemAttributeTypes.USER_NUDGE_1_AFTER_DAYS) {
            // Verifico il flag per il nudging mail user 1
            _context.firstNudgingUser = parseInt(attr.attributeValue);
            _context.firstNudgingUserForCheckbox = !!attr.attributeValue;
        } else if (attr.attributeType === ItemAttributeTypes.USER_NUDGE_2_AFTER_DAYS) {
            // Verifico il flag per il nudging mail user 2
            _context.secondNudgingUser = parseInt(attr.attributeValue);
            _context.secondNudgingUserForCheckbox = !!attr.attributeValue;
        } else if (attr.attributeType === ItemAttributeTypes.MANAGER_NUDGE_1_AFTER_DAYS) {
            // Verifico il flag per il nudging mail manager 1
            _context.firstNudgingManager = parseInt(attr.attributeValue);
            _context.firstNudgingManagerForCheckbox = !!attr.attributeValue;
        } else if (attr.attributeType === ItemAttributeTypes.USER_SURVEY_NUDGE_AFTER_DAYS) {
            // Verifico il flag per il nudging survey user
            _context.impactSurveyUser = parseInt(attr.attributeValue);
            _context.impactSurveyUserForCheckbox = !!attr.attributeValue;
        } else if (attr.attributeType === ItemAttributeTypes.MANAGER_SURVEY_NUDGE_AFTER_DAYS) {
            // Verifico il flag per il nudgingnudging survey manager
            _context.impactSurveyManager = parseInt(attr.attributeValue);
            _context.impactSurveyManagerForCheckbox = !!attr.attributeValue;
        } else if (attr.attributeType === ItemAttributeTypes.ENABLE_COURSE_CERTIFICATION) {
            // Verifico se far mostrare lo step delle comunicazioni
            _context.isCourseCertificationEnabled = parseBoolean(attr.attributeValue);
        } else if (attr.attributeType === ItemAttributeTypes.INITIATIVE_TYPE && _context && _context.translate) {
            // Tipo di formazione
            const translationKey = "awWizard.generalInfo.trainingTypeList." + attr.attributeValue;
            const selectTrainingType: InitiativeType = {
                name: _context.translate.instant(translationKey),
                code: attr.attributeValue,
            };
            _context.selectTrainingTypeChanged(selectTrainingType);
        }
    })
}