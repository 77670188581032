/*
 * Servizio che gestisce le chiamate ai servizi relative agli item
*/
import * as CommonClasses from "../../../cm2-commonclasses";
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as fromApp from '../../ngrx/app.reducers';
import { GlobalApplicationData } from "../../shared/models/global-application-data.model";
import { Observable, combineLatest, Subscription } from "rxjs";
import { ItemAttributeTypes, Item, ItemAttributeObjectTypes, ItemTypes, Upload, TextTemplateTypes } from "../../../cm2-commonclasses";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class ItemsService {
    applicationData: GlobalApplicationData;
    result$: Subscription;
    isSupplier: boolean;

    constructor(private store: Store<fromApp.AppState>,
        private translate: TranslateService,
        private http: HttpClient) {
        // Dallo store recupero l'oggetto principale contenente generici dati, e la lingua dell'applicazione
        let globalApplicationData$: Observable<GlobalApplicationData> = this.store.select(fromApp.getGlobalApplicationData);
        // Collegamento alla variabile indicante se l'utente loggato è un supplier
        const isSupplier$: Observable<boolean> = this.store.select(fromApp.isSupplier);
        const combinedSelectes$ = combineLatest(globalApplicationData$, isSupplier$);
        this.result$ = combinedSelectes$.subscribe(
            ([globalApplicationData, isSupplier]) => {
                this.applicationData = globalApplicationData;
                if (!this.isSupplier)
                    this.isSupplier = isSupplier;
            });
    }

    // Imposta l'etichetta in traduzione degli item library
    setItemTypologyTranslations = (itemList) => {
        if (itemList && itemList.length) {
            for (let i = 0, itemsLength = itemList.length; i < itemsLength; i++) {
                let currentItem = itemList[i];
                // Tipologia di ogggetto
                let typology: string = null;

                if (currentItem.itemType && (currentItem.itemType == ItemTypes.SCORM_FREE || currentItem.itemType == ItemTypes.SCORM_INVITE)) {
                    currentItem.typeTranslation = this.translate.instant('awWizard.materials.itemTypes.ELEARNING');
                    currentItem.typology = currentItem.itemType;
                }

                if (!currentItem.typeTranslation && currentItem.itemAttributes && currentItem.itemAttributes.length) {
                    for (let k = 0; k < currentItem.itemAttributes.length; k++) {
                        let currentAttribute = currentItem.itemAttributes[k];
                        if (currentAttribute.attributeType == ItemAttributeTypes.OBJECT_TYPE) {
                            typology = currentAttribute.attributeValue;
                            break;
                        }
                    }

                    if (typology) {
                        currentItem.typology = typology;
                        if (typology == ItemAttributeObjectTypes.PODCAST) {
                            currentItem.typeTranslation = this.translate.instant('awWizard.materials.itemTypes.PODCAST');
                        } else if (typology == ItemAttributeObjectTypes.GRAPH) {
                            currentItem.typeTranslation = this.translate.instant('awWizard.materials.itemTypes.GRAPH');
                        } else if (typology == ItemAttributeObjectTypes.DOCUMENT) {
                            currentItem.typeTranslation = this.translate.instant('awWizard.materials.itemTypes.DOCUMENT');
                        } else if (typology == ItemAttributeObjectTypes.EBOOK) {
                            currentItem.typeTranslation = this.translate.instant('awWizard.materials.itemTypes.EBOOK');
                        } else if (typology == ItemAttributeObjectTypes.VIDEO) {
                            currentItem.typeTranslation = this.translate.instant('awWizard.materials.itemTypes.VIDEO');
                        } else if (typology == ItemAttributeObjectTypes.IMAGE) {
                            currentItem.typeTranslation = this.translate.instant('awWizard.materials.itemTypes.IMAGE');
                        }
                    }
                }
            }
        }
    }

    // Recupera le ultime iniziative (o proposte, qualora fossi loggato come fornitore)
    getLatestOpenedSuspendedInitiatives(fromRecord: string, numRecords: string, showInitiativeFor: string, isSupplier?: boolean): any {
        if (this.isSupplier || isSupplier) {
            return this.getLatestSuspendedSupplierInitiatives(fromRecord, numRecords);
        } else if (showInitiativeFor && showInitiativeFor === 'IN_PROGRESS_INITIATIVES') {
            return this.getLatestOpenedInProgressInitiatives(fromRecord, numRecords);
        } else {
            // Preparo i parametri per la richiesta http
            let httpParams = new HttpParams();
            httpParams = httpParams.append('fromRecord', fromRecord);
            httpParams = httpParams.append('numRecords', numRecords);
            return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-latest-opened-suspended-initiatives/' + fromRecord + '/' + numRecords, {
                params: httpParams
            });
        }
    }

    // Recupera le ultime iniziative sospese
    getLatestOpenedInProgressInitiatives(fromRecord: string, numRecords: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('fromRecord', fromRecord);
        httpParams = httpParams.append('numRecords', numRecords);
        return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-latest-opened-in-progress-initiatives/' + fromRecord + '/' + numRecords, {
            params: httpParams
        });
    }

    /**
     * Ritorna la liste delle iniziative per il Portale Fornitori
     * @param fromRecord 
     * @param numRecords 
     * @param showInitiativeFor 
     *  filtro sullo stato delle iniziative da cercare
     */
    getLatestSupplierInitiatives(fromRecord: string, numRecords: string, showInitiativeFor: string): any {

        if (showInitiativeFor == showInitiativeFor && showInitiativeFor === 'ARCHIVED_INITIATIVES') {
            return this.getLatestArchivedSupplierInitiatives(fromRecord, numRecords);
        }
        return this.getLatestSuspendedSupplierInitiatives(fromRecord, numRecords);
    }

    /**
     * Ritorna la liste delle iniziative in sospeso per il Portale Fornitori
     * @param fromRecord 
     * @param numRecords 
     */
    getLatestSuspendedSupplierInitiatives(fromRecord: string, numRecords: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('fromRecord', fromRecord);
        httpParams = httpParams.append('numRecords', numRecords);
        return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/list-supplier-initiatives/' + fromRecord + '/' + numRecords, {
            params: httpParams
        });
    }

    /**
 * Ritorna la liste delle iniziative archiviate per il Portale Fornitori
 * @param fromRecord 
 * @param numRecords 
 */
    getLatestArchivedSupplierInitiatives(fromRecord: string, numRecords: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('closedTaskOnly', "true");
        return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/list-supplier-initiatives/' + fromRecord + '/' + numRecords, {
            params: httpParams
        });
    }

    // Recupera il count degli item
    countItems(searchedText: string, objectTypeSpecializations?: string[]): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        if (objectTypeSpecializations && objectTypeSpecializations.length) {
            objectTypeSpecializations.forEach(objectTypeSpecialization => {
                httpParams = httpParams.append('objectTypeSpecializations', objectTypeSpecialization);
            });
        }
        httpParams = httpParams.append('searchedText', searchedText || '');
        return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/count-items', {
            params: httpParams
        });
    }

    // Recupera il count dei materiali
    countMaterialsForParent(parentId: string, materialType: string, title?: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('title', title || '');
        httpParams = httpParams.append('parentId', parentId || '');
        httpParams = httpParams.append('materialType', materialType || '');
        return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/count-materials-for-parent', {
            params: httpParams
        });
    }

    // Recupera una lista di materiali
    listMaterialsForParent(fromRecord: number, numRecords: number, parentId: string, materialType: string, title?: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        let from = fromRecord;
        let to = numRecords;
        httpParams = httpParams.append('fromRecord', from && from.toString());
        httpParams = httpParams.append('numRecords', to && to.toString());
        httpParams = httpParams.append('title', title || '');
        httpParams = httpParams.append('parentId', parentId || '');
        httpParams = httpParams.append('materialType', materialType || '');
        return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/list-materials-for-parent', {
            params: httpParams
        });
    }

    // Recupera una lista di item
    searchItems(searchedText: string, fromRecord: number, numRecords: string, objectTypeSpecializations?: string[]): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        let from = fromRecord;
        let to = numRecords;
        httpParams = httpParams.append('fromRecord', from && from.toString());
        httpParams = httpParams.append('numRecords', to && to.toString());
        httpParams = httpParams.append('searchedText', searchedText || '');
        if (objectTypeSpecializations && objectTypeSpecializations.length) {
            objectTypeSpecializations.forEach(objectTypeSpecialization => {
                httpParams = httpParams.append('objectTypeSpecializations', objectTypeSpecialization);
            });
        }
        return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/search-items', {
            params: httpParams
        });
    }

    // Recupera una lista di item
    listItemsByAny(searchedText?: string, fromRecord?: number, numRecords?: string, allData?: boolean, referenceId?: string[], referenceApplicationName?: string[], referenceType?: string[], originApplicationName?: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('fromRecord', (fromRecord && fromRecord.toString()) || '');
        httpParams = httpParams.append('numRecords', (numRecords && numRecords.toString()) || '');
        httpParams = httpParams.append('allData', (allData && allData.toString()) || '');
        httpParams = httpParams.append('originApplicationName', (originApplicationName && originApplicationName.toString()) || '');
        if (referenceId && referenceId.length) {
            referenceId.forEach(referenceId => {
                httpParams = httpParams.append('referenceId', referenceId);
            });
        }
        if (referenceApplicationName && referenceApplicationName.length) {
            referenceApplicationName.forEach(referenceApplicationName => {
                httpParams = httpParams.append('referenceApplicationName', referenceApplicationName);
            });
        }
        if (referenceType && referenceType.length) {
            referenceType.forEach(referenceType => {
                httpParams = httpParams.append('referenceType', referenceType);
            });
        }
        httpParams = httpParams.append('searchedText', searchedText || '');
        return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/list-items-by-any', {
            params: httpParams
        });
    }

    // Recupera gli assessment di un item
    listAssessmentOfItemByReferenceId(referenceId: string[], referenceType: string[], referenceApplicationName?: string[], originApplicationName?: string, fromRecord?: number, numRecords?: number, allData?: boolean): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();

        if (referenceId && referenceId.length) {
            referenceId.forEach(referenceId => {
                httpParams = httpParams.append('referenceId', referenceId);
            });
        }
        if (referenceType && referenceType.length) {
            referenceType.forEach(referenceType => {
                httpParams = httpParams.append('referenceType', referenceType);
            });
        }
        if (referenceApplicationName && referenceApplicationName.length) {
            referenceApplicationName.forEach(referenceApplicationName => {
                httpParams = httpParams.append('referenceApplicationName', referenceApplicationName);
            });
        }
        httpParams = httpParams.append('originApplicationName', (originApplicationName && originApplicationName.toString()) || '');
        httpParams = httpParams.append('fromRecord', (fromRecord && fromRecord.toString()) || '');
        httpParams = httpParams.append('numRecords', (numRecords && numRecords.toString()) || '');
        httpParams = httpParams.append('allData', (allData && allData.toString()) || '');

        return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/list-assessment-of-item-by-referenceId', {
            params: httpParams
        });
    }

    // Torna il percorso di una tappa (utilizato anche per identificare se un oggetto corso è in realtà una tappa, ovvero è contenuto in un percorso)
    getPathFromStage(stageItemId: string): any {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('stageItemId', stageItemId);
        if (this.isSupplier) {
            return this.http.get<CommonClasses.SenecaResponse<CommonClasses.TextTemplate[]>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-path-from-stage-for-supplier/' + stageItemId, {
                params: httpParams
            });
        } else {
            return this.http.get<CommonClasses.SenecaResponse<CommonClasses.TextTemplate[]>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-path-from-stage/' + stageItemId, {
                params: httpParams
            });
        }
    }

    getMeetingById(itemId: string) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('meetingItemId', itemId);
        return this.http.get<CommonClasses.SenecaResponse<CommonClasses.TextTemplate[]>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-meeting-by-id/', {
            params: httpParams
        });
    }

    getMeetingByIdForPublic(userId: string, itemId: string) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('userId', userId);
        httpParams = httpParams.append('meetingItemId', itemId);
        return this.http.get<CommonClasses.SenecaResponse<CommonClasses.TextTemplate[]>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-meeting-by-id-for-public/', {
            params: httpParams
        });
    }

    // Servizio per registrarmi al meeting
    selfRegisterToMeeting(userId: string, meetingItemId: string, meetingPin: string) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('userId', userId);
        httpParams = httpParams.append('meetingItemId', meetingItemId);
        httpParams = httpParams.append('meetingItemId', meetingPin);
        return this.http.post<CommonClasses.SenecaResponse<CommonClasses.TextTemplate[]>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/self-register-meeting-presence/', {
            userId: userId,
            meetingItemId: meetingItemId,
            meetingPin: meetingPin
        });
    }

    // Torna un percorso dato il suo Id
    getPathFromIdForAdmin(pathItemId: string): any {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('pathItemId', pathItemId);
        return this.http.get<CommonClasses.SenecaResponse<CommonClasses.Item>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-path-from-id-for-admin/' + pathItemId, {
            params: httpParams
        });
    }

    // Torna uno Stage dato il suo Id
    getStageFromIdForAdmin(stageItemId: string, isSupplier?: boolean): any {
        if (isSupplier) {
            let httpParams = new HttpParams();
            httpParams = httpParams.append('stageItemId', stageItemId);
            httpParams = httpParams.append('withFullAttributesLoaded', 'true');
            httpParams = httpParams.append('withFullChildrenAttributesLoaded', 'true');
            httpParams = httpParams.append('itemAttributeTypesToResolve',
                JSON.stringify([ItemAttributeTypes.LOCATION, ItemAttributeTypes.TUTOR, ItemAttributeTypes.TEACHER, ItemAttributeTypes.INVITATION_MAIL, ItemAttributeTypes.STAGE_INVITATION_MAIL, ItemAttributeTypes.SELF_CANCEL_MAIL, ItemAttributeTypes.PREREGISTRATION_MAIL,
                ItemAttributeTypes.CONFIRMATION_MAIL, ItemAttributeTypes.DISCARDED_MAIL, ItemAttributeTypes.OB_CONFIRMATION_MAIL,
                ItemAttributeTypes.OB_PROMOTION_MAIL, ItemAttributeTypes.PRESENCE_REGISTERED_MAIL, ItemAttributeTypes.MANAGER_NOTIFY_MAIL,
                ItemAttributeTypes.HRBP_NOTIFY_MAIL, ItemAttributeTypes.MANAGER_VERIFY_MAIL, ItemAttributeTypes.HRBP_VERIFY_MAIL,
                ItemAttributeTypes.COURSE_REMINDER_MAIL, ItemAttributeTypes.INVITATION_SOLICIT_MAIL_1, ItemAttributeTypes.INVITATION_SOLICIT_MAIL_2, ItemAttributeTypes.INVITATION_SOLICIT_MAIL_3, ItemAttributeTypes.RECURRENT_INVITATION_SOLICIT_MAIL, ItemAttributeTypes.RECURRENT_INVITATION_SOLICIT_MAIL_AFTER_DEADLINE,
                ItemAttributeTypes.MAIL_NUDGE_USER_1, ItemAttributeTypes.MAIL_NUDGE_USER_2, ItemAttributeTypes.MAIL_NUDGE_MANAGER_1, ItemAttributeTypes.MAIL_SURVEY_NUDGE_USER, ItemAttributeTypes.MAIL_SURVEY_NUDGE_MANAGER
                ]));

            return this.http.get<CommonClasses.SenecaResponse<CommonClasses.TextTemplate[]>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-stage-from-id-for-supplier/' + stageItemId, {
                params: httpParams
            });
        } else {
            let httpParams = new HttpParams();
            httpParams = httpParams.append('stageItemId', stageItemId);
            httpParams = httpParams.append('withFullAttributesLoaded', 'true');
            httpParams = httpParams.append('withFullChildrenAttributesLoaded', 'true');
            // httpParams = httpParams.append('itemAttributeTypesToResolve',
            //     JSON.stringify([ItemAttributeTypes.LOCATION, ItemAttributeTypes.TUTOR, ItemAttributeTypes.TEACHER, ItemAttributeTypes.INVITATION_MAIL, ItemAttributeTypes.STAGE_INVITATION_MAIL, ItemAttributeTypes.SELF_CANCEL_MAIL, ItemAttributeTypes.PREREGISTRATION_MAIL,
            //     ItemAttributeTypes.CONFIRMATION_MAIL, ItemAttributeTypes.DISCARDED_MAIL, ItemAttributeTypes.OB_CONFIRMATION_MAIL,
            //     ItemAttributeTypes.OB_PROMOTION_MAIL, ItemAttributeTypes.PRESENCE_REGISTERED_MAIL, ItemAttributeTypes.MANAGER_NOTIFY_MAIL,
            //     ItemAttributeTypes.HRBP_NOTIFY_MAIL, ItemAttributeTypes.MANAGER_VERIFY_MAIL, ItemAttributeTypes.HRBP_VERIFY_MAIL,
            //     ItemAttributeTypes.COURSE_REMINDER_MAIL, ItemAttributeTypes.INVITATION_SOLICIT_MAIL_1, ItemAttributeTypes.INVITATION_SOLICIT_MAIL_2, ItemAttributeTypes.INVITATION_SOLICIT_MAIL_3
            //     ]));
            httpParams = httpParams.append('itemAttributeTypesToResolve',
                JSON.stringify([ItemAttributeTypes.LOCATION, ItemAttributeTypes.TUTOR, ItemAttributeTypes.TEACHER]));

            return this.http.get<CommonClasses.SenecaResponse<CommonClasses.TextTemplate[]>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-stage-from-id-for-admin/' + stageItemId, {
                params: httpParams
            });
        }
    }

    // Servizio che recupera un Item dato il suo id
    getItemByIdForAdmin(itemId: string, withChildsLoaded?: boolean, isSupplier?: boolean) {
        if (isSupplier) {
            return this.itemByIdForSupplier(itemId, withChildsLoaded);
        } else {
            return this.itemByIdForAdmin(itemId, withChildsLoaded);
        }
    }

    // Servizio che recupera un Item dato il suo id per admin
    itemByIdForAdmin(itemId: string, withChildsLoaded?: boolean) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('itemId', itemId);
        httpParams = withChildsLoaded ? httpParams.append('withChildsLoaded', 'true') : httpParams;
        return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-item-by-id-for-admin/' + itemId, {
            params: httpParams
        });
    }

    // Servizio che recupera un Item dato il suo id per supplier
    itemByIdForSupplier(itemId: string, withChildsLoaded?: boolean) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('itemId', itemId);
        httpParams = withChildsLoaded ? httpParams.append('withChildsLoaded', 'true') : httpParams;
        return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-item-by-id-for-supplier/' + itemId, {
            params: httpParams
        });
    }

    // Servizio che recupera un Item dato il suo id
    deleteItem(itemId: string, removeSyllabus?: boolean) {
        return this.http.post<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/delete-item/' + itemId, {
            itemId: itemId,
            removeSyllabus: removeSyllabus
        });
    }

    // Servizio che cancella un multimedia item
    deleteMultimediaItem(itemId: string) {
        return this.http.post<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + `rest-api/coursemanager-mediator/delete-multimedia-item/${itemId}`, {
            itemId: itemId
        });
    }

    getCertificatesList() {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/list-certificates', {
            params: httpParams
        });
    }

    generateCertificates(editionId: string, userIds?: string[], asImage?: boolean, getFullPath?: boolean) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('itemId', editionId);
        httpParams = getFullPath ? httpParams.append('getFullPath', 'true') : httpParams;
        httpParams = asImage ? httpParams.append('asImage', 'true') : httpParams;
        if (userIds && userIds.length) {
            userIds.forEach(userId => {
                httpParams = httpParams.append('userIds', userId);
            })
        }

        //per Eura hanno chiesto una Data di completamento diversa quindi invio parametro per differenziarlada GI nel servizio
        httpParams = httpParams.append('euraCertificates', 'true');
        
        return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-all-certificates', {
            params: httpParams
        });
    }

    generateAndAssignUserCourseCertificateEngagement(stageIds?: string[], editionIds?: string[], userIds?: string[]) {
        let httpParams = new HttpParams();
        if (editionIds && editionIds.length) {
            editionIds.forEach(editionId => {
                httpParams = httpParams.append('editionIds', editionId);
            })
        }
        // httpParams = stageIds ? httpParams.append('stageIds', stageIds) : httpParams;
        if (userIds && userIds.length) {
            userIds.forEach(userId => {
                httpParams = httpParams.append('userIds', userId);
            })
        }

        return this.http.post<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/generate-and-assign-user-course-certificate-engagement', {
            stageIds: stageIds
        });
    }

    uploadCertificateImages(uploadObj: any, stageId?: string) {
        let httpParams = new HttpParams();
        httpParams = stageId ? httpParams.append('stageId', stageId) : httpParams;

        let formData = new FormData();
        formData.append('file', uploadObj.file.file.rawFile);
        formData.append('stageId', uploadObj.stageId);
        //formData.append('surveyType', uploadObj.surveyType);

        return this.http.post<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/upload-certificate-image', formData, {
            params: httpParams
        });
    }

    countCertificateImages(stageId?: string) {
        let httpParams = new HttpParams();
        httpParams = stageId ? httpParams.append('stageId', stageId) : httpParams;

        return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/count-certificate-images', {
            params: httpParams
        });
    }

    listCertificateImages(stageId?: string) {
        let httpParams = new HttpParams();
        httpParams = stageId ? httpParams.append('stageId', stageId) : httpParams;

        return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/list-certificate-images', {
            params: httpParams
        });
    }

    deleteCertificateImages(certificateImageId?: string, upload?: Upload) {
        let httpParams = new HttpParams();
        httpParams = certificateImageId ? httpParams.append('certificateImageId', certificateImageId) : httpParams;

        return this.http.post<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/delete-certificate-image', { upload: upload }, {
            params: httpParams
        });
    }

    getImageMaskedUrl(stageId?: string, uploadId?: string) {
        let httpParams = new HttpParams();
        httpParams = stageId ? httpParams.append('stageId', stageId) : httpParams;
        httpParams = uploadId ? httpParams.append('uploadId', uploadId) : httpParams;

        return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-image-masked-url', {
            params: httpParams
        });
    }
    uploadImportFinancingNotices(uploadObj: any) {
        let formData = new FormData();
        formData.append('file', uploadObj.file.file.rawFile);
        formData.append('hasHeader', "true");

        let params = new HttpParams();
        const options = {
            params: params
        };
        
        const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/import-info-for-funding-modules', formData, options);
        return this.http.request(req);
    }
    
    getCertificatesCustomAssets(assetType: 'signature' | 'logo') {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('assetType', assetType);

        return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/list-certificate-custom-assets', {
            params: httpParams
        });
    }

}