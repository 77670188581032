<!-- Il form di login si vede solamente se ci sono lingue disponibili a sistema -->
<div>
    <div class="login-page theme-primary" *ngIf="langs && langs.length" fxFill>
        <div fxLayout="column" fxLayoutAlign="center center" fxFill>

            <img class="margin-top80" width="230px" height="57px" title="generaliLogo" src="assets/img/logo-welion.svg">

            <!-- Pannello di accesso -->
            <div class="login-column margin-top80" fxLayout="column" fxLayoutAlign="center center">

                <h3 class="signin-text bold-text to-uppercase"
                    translate="europeAssistance.ACCESS_TO_EUROPE_ASSISTANCE_WELION">
                </h3>
                <h5 class="margin-top15 subtitle-grey-form"
                    translate="europeAssistance.ACCESS_TO_EUROPE_ASSISTANCE_WELION_DESCRIPTION"></h5>
                <div class="space15"></div>

                <form class="login-wrapper full-width" (ngSubmit)="onLogin(loginForm)" #loginForm="ngForm">
                    <!-- Codice Fiscale -->
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <div class="generali-input full">
                            <div class="title-input" fxLayout="row" fxLayoutAlign="start start">
                                <h5 translate="europeAssistance.FISCAL_CODE"></h5>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <!-- Placeholder di caricamento -->
                                <input-placeholder class="full-width" *ngIf="showLoader"></input-placeholder>
                                <input [hidden]="showLoader"
                                    attr.aria-label="{{'europeAssistance.TYPE_FISCAL_CODE' | translate}}"
                                    class="full-width" type="email"
                                    placeholder="{{'europeAssistance.TYPE_FISCAL_CODE' | translate}}" id="email"
                                    name="email" #email="ngModel" ngModel required>

                            </div>
                            <!-- Codice fiscale non inserito -->
                            <div *ngIf="!email.valid && email.touched" class="input-error" fxLayout="row"
                                fxLayoutAlign="start center">
                                <span translate="europeAssistance.TYPE_FISCAL_CODE"></span>
                            </div>
                        </div>
                    </div>

                    <div class="space30"></div>

                    <div fxLayout="column">
                        <div class="custom-select" fxFlexFill>
                            <div class="margin-bottom10" fxLayout="row" fxLayoutAlign="space-between center">
                                <h5 translate="europeAssistance.INDICE_CENTER"></h5>
                            </div>
                            <ng-select class="login-europe-assistance-select" [virtualScroll]="true" #selectCenter
                                [loading]="isFetchingCenters" [items]="centersList"
                                (scrollToEnd)="fetchMoreCenters(selectCenter)"
                                placeholder="{{ 'europeAssistance.SEARCH_CENTER' | translate }}" [multiple]="false"
                                [(ngModel)]="selectedCentersList" (ngModelChange)="updateCenterValue($event)"
                                [typeahead]="getCentersListInput$" [dropdownPosition]="'bottom'"
                                [ngModelOptions]="{standalone: true}">
                                <!-- Template per i singoli item selezionati -->
                                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                    <div class="selected-cluster-option">
                                        <h5 class="card-title">{{item.title}}</h5>
                                    </div>
                                </ng-template>
                                <!-- Template per i dati non trovati -->
                                <ng-template ng-notfound-tmp let-searchTerm="centersSearchedText">
                                    <div class="ng-option disabled" translate="generic.NO_DATA_FOUND"></div>
                                </ng-template>
                                <!-- Template per l'inizio della ricerca -->
                                <ng-template ng-typetosearch-tmp>
                                    <div class="ng-option disabled" translate="europeAssistance.WRITE_CENTER_DATA">
                                    </div>
                                </ng-template>
                                <!-- Template per il caricamento dei risultati -->
                                <ng-template ng-loadingtext-tmp>
                                    <div class="ng-option disabled" translate="generic.LOADING_DATA"></div>
                                </ng-template>
                                <!-- Template per i singoli item nelle options -->
                                <ng-template ng-option-tmp let-item="item" let-search="centersSearchedText">
                                    <div class="select-options-card">
                                        <div class="card-body" fxLayout="row" fxLayoutAlign="start center">
                                            <div fxLayout="column">
                                                <span class="card-title">{{item.title}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>

                    <div class="space15"></div>

                    <!-- Password -->
                    <div fxFlexFill="row" fxLayoutAlign="center center">
                        <div class="generali-input full">
                            <div class="title-input" fxLayout="row" fxLayoutAlign="start start">
                                <h5 translate="generic.PASSWORD"></h5>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <!-- Placeholder di caricamento -->
                                <input-placeholder class="full-width" *ngIf="showLoader"></input-placeholder>
                                <input [hidden]="showLoader" attr.aria-label="{{'errors.TYPE_PASSWORD' | translate}}"
                                    placeholder="{{'errors.TYPE_PASSWORD' | translate}}" class="full-width"
                                    type="password" id="password" name="password" ngModel #password="ngModel" required>
                            </div>

                            <!-- Password non inserita -->
                            <div *ngIf="!password.valid && password.touched" class="input-error" fxLayout="row"
                                fxLayoutAlign="start center">
                                <span translate="errors.TYPE_PASSWORD"></span>
                            </div>
                        </div>
                    </div>
                    <!-- Pulsante per il login (lato demo, quindi senza la possibilità di registrazione) -->
                    <div class="margin-top40 margin-bottom30" fxLayout="row" fxLayout.xs="column" fxFlexFill
                        fxLayoutAlign="center center">
                        <button class="btn-primary-1 medium" type="submit" [disabled]="!loginForm.valid || showLoader">
                            <p translate="europeAssistance.LOGIN"></p>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- Nessuna lingua disponibile a sistema -->
<div *ngIf="!langs || !langs.length">
    <p translate="errors.NO_AVAILABLE_LANG"></p>
</div>