/*
 * Servizio che permette le chiamate rest relative all'autenticazione
*/
import * as CommonClasses from "../../../cm2-commonclasses";
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as fromApp from '../../ngrx/app.reducers';
import { UrlService } from "../../shared/services/url.service";
import { CommonConstants, User, SenecaResponse } from "../../../cm2-commonclasses";
import { of } from "rxjs";

@Injectable()
export class AuthService {
    applicationContext: string;
    constructor(private store: Store<fromApp.AppState>,
        private urlService: UrlService,
        private http: HttpClient) {
        this.applicationContext = this.urlService.getApplicationUrl().baseUrl;
    }

    // Servizio che tenta il login
    login(email: string, password: string, device?: string, userAgent?: string): any {
        return this.http.post<CommonClasses.SenecaResponse<string>>(this.applicationContext + 'rest-api/coursemanager-mediator/login-local-password', { email: email, password: password, langCode: 'it', deviceType: device, userAgent: userAgent });
    }

    // Servizio che tenta il login per i supplier
    loginSupplier(email: string, password: string, device?: string, userAgent?: string): any {
        return this.http.post<CommonClasses.SenecaResponse<string>>(this.applicationContext + 'rest-api/coursemanager-mediator/login-supplier-person', { username: email, password: password, langCode: 'it', deviceType: device, userAgent: userAgent });
    }

    // Servizio che tenta il login per i taker
    loginTaker(searchedText: string, device?: string, userAgent?: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('searchedText', searchedText);
        httpParams = httpParams.append('device', device);
        httpParams = httpParams.append('userAgent', userAgent);
        return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationContext + 'rest-api/coursemanager-mediator/search-user-for-public', {
            params: httpParams
        });
    }

    // Servizio che registra un nuovo utente
    registerCustomer(email: string, firstName: string, lastName: string, userAgent: string, deviceType: string, allowPrivacy: boolean, customerFamily: string, url?: string): any {
        return this.http.post<CommonClasses.SenecaResponse<User>>(this.applicationContext + 'rest-api/coursemanager-mediator/register-customer', {
            email: email,
            forename: firstName,
            surname: lastName,
            userAgent: userAgent,
            deviceType: deviceType,
            privacyAccepted: allowPrivacy,
            customerFamily: customerFamily,
            returnUrl: url
        });
    }

    // Servizio che setta la password all'utente (la setta, o l'aggiorna)
    setCustomerUserPassword(userId: string, validationToken: string, password: string, customerFamily: string): any {
        return this.http.post<CommonClasses.SenecaResponse<any>>(this.applicationContext + 'rest-api/coursemanager-mediator/set-customer-password',
            {
                userId: userId,
                validationToken: validationToken,
                password: password,
                customerFamily: customerFamily
            }
        );
    }

    // Servizio che setta la password all'utente
    setCustomerUserPasswordWithDeviceTypeAndUserAgent(userId: string, validationToken: string, password: string, customerFamily: string, doLogin : boolean, deviceType: string, userAgent: string): any {
        return this.http.post<CommonClasses.SenecaResponse<any>>(this.applicationContext + 'rest-api/coursemanager-mediator/set-customer-password',
            {
                userId: userId,
                validationToken: validationToken,
                password: password,
                customerFamily: customerFamily,
                deviceType: deviceType,
                userAgent: userAgent,
                doLogin: doLogin
            }
        );
    }

    // Servizio che inizia il processo di recovery della password (manda una mail per il reset)
    initPasswordRecovery(email: string, customerFamily: string, url?: string): any {
        return this.http.post<CommonClasses.SenecaResponse<any>>(this.applicationContext + 'rest-api/coursemanager-mediator/init-password-recovery',
            {
                email: email,
                customerFamily: customerFamily,
                redirectUrl: url
            }
        );
    }

    // Servizio che tenta il login per i docenti e supporto nel webinar
    checkWebinarPagePassword(password: string, device?: string, userAgent?: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('password', password);
        httpParams = httpParams.append('device', device);
        httpParams = httpParams.append('userAgent', userAgent);

        return this.http.post<CommonClasses.SenecaResponse<string>>(this.applicationContext + 'rest-api/coursemanager-mediator/check-webinar-page-password', {
            password: password,
            deviceType: device, userAgent: userAgent
        });
    }

    // Servizio che tenta il login per i manager
    loginManager(searchedText: string, device?: string, userAgent?: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('searchedText', searchedText);
        httpParams = httpParams.append('device', device);
        httpParams = httpParams.append('userAgent', userAgent);
        return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationContext + 'rest-api/coursemanager-mediator/search-user-for-public', {
            params: httpParams
        });
    }

    // Servizio che tenta il login per gli utenti pubblici
    searchCustomerForPublic(email: string, password: string, device: string, userAgent: string, customerFamily: string, userId?: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('email', email || '');
        httpParams = httpParams.append('password', password || '');
        httpParams = httpParams.append('device', device || '');
        httpParams = httpParams.append('userAgent', userAgent || '');
        httpParams = httpParams.append('userId', userId || '');
        httpParams = httpParams.append('customerFamily', customerFamily || '');
        return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationContext + 'rest-api/coursemanager-mediator/search-customer-for-public', {
            params: httpParams
        });
    }

    // Servizio che recupera il JWT token dato un tiny token
    getJWTToken(token: string): any {
        return this.http.get<CommonClasses.SenecaResponse<string>>(this.applicationContext + 'rest-api/coursemanager-mediator/get-full-jwt-token', { headers: new HttpHeaders().set('Authorization', 'Bearer ' + token) });
    }

    // Servizio che recupera un token nuovo
    renewToken(sessionStorageToken: string): any {
        return this.http.post<CommonClasses.SenecaResponse<string>>(this.applicationContext + 'rest-api/coursemanager-mediator/renew-token', null, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + sessionStorageToken) });
    }

    countCenters() {
        return of(new SenecaResponse(null, 30));
    }

    getCenters() {
        let response = [];
        for (let i = 0; i < 32; i++) {
            response.push({
                title: 'centro' + i
            })
        }

        return of(new SenecaResponse(null, response));
    }

    // Ritrova il token dall'ssortkqp
    retrieveTokenAfterLogin(retrieveKey: string) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('retrieveKey', retrieveKey);
        return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationContext + 'rest-api/coursemanager-mediator/retrieve-token-after-login', {
            params: httpParams
        });
    }

    // Crea in base al token l'ssorqtp
    crateRetrieveTokenAfterLogin(): any {
        return this.http.post<CommonClasses.SenecaResponse<any>>(this.applicationContext + 'rest-api/coursemanager-mediator/create-retrieve-token-after-login', null);
    }

    // Crea in base al token l'ssorqtp passando userId e mail
    crateRetrieveTokenAfterLoginWithUserId(userId: string, email: string): any {
        return this.http.post<CommonClasses.SenecaResponse<any>>(this.applicationContext + 'rest-api/coursemanager-mediator/create-retrieve-token-after-login-with-email-userId', {
            userId: userId,
            email: email
        });
    }

    // Logout
    logout() {
        return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationContext + 'rest-api/coursemanager-mediator/logout', {
        });
    }
}