/*
*  Componente che mostra le informazioni sull'utente loggato
*/

import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as CommonClasses from "../../cm2-commonclasses";
import * as ProfileActions from './ngrx/profile.actions';
import * as fromApp from '../ngrx/app.reducers';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
    userState: Observable<{ loggedUser: CommonClasses.JwtPayload }>;

    constructor(private store: Store<fromApp.AppState>) { }

    ngOnInit() {
        this.userState = this.store.select('user');
    }

    onEditItem(index: number) {
        this.store.dispatch(new ProfileActions.StartEdit(index));
    }
}
