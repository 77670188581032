import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Lang } from 'atfcore-commonclasses';
import { Observable, Subscription, combineLatest } from 'rxjs';
import * as fromApp from '../../ngrx/app.reducers';
import { NgForm } from '@angular/forms';
import { take } from 'rxjs/operators';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../services/auth.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import * as AuthActions from '../ngrx/auth.actions';
import * as CoreActions from '../../core/ngrx/core.actions';
import { UrlService } from 'src/app/shared/services/url.service';

@Component({
  selector: 'app-dei.login',
  templateUrl: './dei-login.component.html',
  styleUrls: ['./dei-login.component.scss']
})
export class DeiLoginComponent implements OnInit {

  isFetchingLangs: boolean;
  langs: Lang[];
  result$;
  loginFormVisible: boolean;
  subscriptionFormVisible: boolean;
  resetPasswordFormVisible: boolean;
  showLoader: boolean;
  isManagingUser$: Subscription;
  isEmailConfirmation = false;
  isPwdSetted = false;
  userId: string;
  token: string;
  CUSTOMER_FAMILY = "DEI";
  notShowAlertLogin = false;


  constructor(
    private store: Store<fromApp.AppState>,
    public translate: TranslateService,
    private router: Router,
    private ngxSmartModalService: NgxSmartModalService,
    private toastr: ToastrService,
    private authService: AuthService,
    private deviceService: DeviceDetectorService,
    private route: ActivatedRoute,
    private urlService: UrlService) {
    // Sto in ascolto di quando il globalApplicationData cambia, così da sapere quando ci sono le lingue disponibili poiché, se non ci fossero, il pulsante per il login sarebbe disabilitato

    let availableLangs$: Observable<Lang[]> = this.store.select(fromApp.getAvailableLangs);
    let isFetchingLangs$: Observable<boolean> = this.store.select(fromApp.isFetchingLangs);
    const combinedSelectes$ = combineLatest(availableLangs$, isFetchingLangs$);
    this.result$ = combinedSelectes$.subscribe(
      ([langs, isFetchingLangs]) => {
        this.langs = langs;
        this.isFetchingLangs = isFetchingLangs;
      });
  }
  ngOnInit(): void {
    this.route.params
      .subscribe(
        (params: Params) => {
          this.userId = params['userId'];
          this.token = params['token'];

          if (this.router.url.includes('emailConfirmation')) {
            this.isEmailConfirmation = true;
          } else {
            this.isEmailConfirmation = false;
          }
        });

    let DEIUrl = sessionStorage.getItem("redirectDEIUrl");
    let slashCounter = sessionStorage.getItem("redirectDEIUrl").lastIndexOf("/");
    let initiativeId = DEIUrl.substring(slashCounter + 1);
    if (initiativeId == "4bf6dc6d-d14e-49d3-8eb9-cd4b2fc1aabd") {
      this.notShowAlertLogin = true;
    }
  }

  // Toggle del form per l'iscrizione
  toggleLoginFormVisible() {
    this.loginFormVisible = !this.loginFormVisible;
  }

  // Toggle del form per l'iscrizione
  toggleSubscriptionFormVisible() {
    this.subscriptionFormVisible = !this.subscriptionFormVisible;
    this.loginFormVisible = !this.loginFormVisible;

  }


  changeInput(x: any) {
    if (x.viewModel.trim().length || x.viewModel == "") {
      return false;
    } else {
      return true;
    }
  }

  checkRegExp(x: any) {
    if (x.viewModel || x.viewModel == "") {
      if (/^[A-Za-z\'\`\s\/^[a-z\u00E0-\u00FC]+$\]*$/g.test(x.viewModel.trim()) && x.viewModel.trim().length > 1) {
        return false;

      } else {
        return true;
      }
    } else {
      if (/^[A-Za-z\'\`\s\/^[a-z\u00E0-\u00FC]+$\]*$/g.test(x.trim()) && x.trim().length > 1) {
        return false;

      } else {
        return true;
      }
    }
  }

  isValidForm(form: NgForm) {
    if (form && form.value && form.value.newUserEmail && form.value.newUserFirstName && form.value.newUserLastName && form.value.allowUserPrivacy && form.value.newUserEmail.trim().length && form.value.newUserFirstName.trim().length && form.value.newUserLastName.trim().length
      && !this.checkRegExp(form.value.newUserFirstName) && !this.checkRegExp(form.value.newUserLastName)) {
      return false;
    } else {
      return true;
    }
  }

  // Apre l'informativa della privacy
  openPrivacyDocument() {
    const confimation = new EventEmitter<any>();

    const body = `<b>Informativa sul trattamento dei dati personali ai sensi degli articoli 13 e 14 del Regolamento UE n. 679/2016 del 27 aprile 2016.</b><br><br>
    La informiamo che i suoi dati personali anagrafici e identificativi, raccolti nell’ambito dell’iniziativa Formazione Punti Viola per le Agenzie sono trattati dalla Società Generali Italia S.p.A. e dalla Società Alleanza Assicurazioni S.p.A., quali Titolari del trattamento, al fine di (i) organizzare l'iniziativa sopraindicata, consentendo la sua partecipazione alla stessa, (ii) mettere a disposizione dei contenuti di approfondimento su tematiche di Formazione.<br>
    La informiamo, inoltre, che il trattamento dei suoi dati personali per le finalità di cui ai punti (i), (ii) è necessario e funzionale all’esecuzione dei contratti in essere.<br>
    La informiamo quindi che per le finalità del trattamento come sopra illustrate da (i) a (ii) il conferimento dei dati è obbligatorio ed il loro mancato, parziale o inesatto conferimento potrà avere, come conseguenza, l'impossibilità di svolgere le attività richieste e preclude al Titolare del trattamento di assolvere gli adempimenti contrattuali come previsti dai contratti in essere.<br><br>
    Lei potrà conoscere quali sono i suoi dati trattati presso la Società e, ove ne ricorrano le condizioni, esercitare i diversi diritti relativi al loro utilizzo (diritto di accesso, rettifica, aggiornamento, integrazione, cancellazione, limitazione al trattamento, alla portabilità, alla revoca del consenso al trattamento e di ottenere una copia dei propri dati laddove questi siano conservati in paesi al di fuori dell’Unione Europea, nonché di ottenere indicazione del luogo nel quale tali dati vengono conservati o trasferiti) nonché opporsi per motivi legittimi ad un loro particolare trattamento e comunque al loro uso a fini commerciali, in tutto o in parte anche per quanto riguarda l’uso di modalità automatizzate rivolgendosi a:
    <ul>
    <li>Generali Italia S.p,A., Via Marocchesa 14, 31021 Mogliano Veneto TV, privacy.it@generali.com o al Responsabile della Protezione dei Dati (RPD), contattabile via e-mail a “RPD.it@generali.com” e/o via posta ordinaria all’indirizzo “RPD Generali Italia - Mogliano Veneto, Via Marocchesa 14 31021.</li><br>
    <li>Alleanza Assicurazioni S.p.A., Piazza Tre Torri 1, 20145 Milano (MI), privacy@alleanza.it. o al Responsabile della Protezione dei Dati (RPD), contattabile via e-mail a “RPD.it@generali.com” e/o via posta ordinaria all’indirizzo “RPD Generali Italia - Mogliano Veneto, Via Marocchesa 14 31021.</li>
    </ul>
    La informiamo, inoltre, che, qualora ravvisi un trattamento dei Suoi dati non coerente con le finalità e/o i consensi da Lei espressi, può sporgere reclamo al Garante per la protezione dei dati personali, con le modalità indicate sul sito del Garante stesso.<br><br>
    I Suoi dati saranno conservati in conformità alla normativa privacy tempo per tempo applicabile, in particolare, per le finalità connesse all’iniziativa, per il periodo necessario agli scopi per i quali sono stati raccolti o trattati e comune, non superiore a 24 mesi dal momento della raccolta.<br><br>
    I Suoi dati non saranno diffusi nei limiti delle finalità suddette e saranno trattati con idonee modalità e procedure anche informatizzate, da nostri dipendenti, collaboratori ed altri soggetti anche esterni, designati Responsabili e/o autorizzati al trattamento o, comunque, operanti quali Titolari, che sono coinvolti nella gestione dei rapporti con lei in essere o che svolgono per nostro conto compiti di natura tecnica, organizzativa, operativa.<br>`;
    
    const publicUsersPrivacyData: Object = {
      modalTitle: this.translate.instant('generic.PUBLIC_USER_PRIVACY_TITLE_SECOND'),
      modalBody: this.translate.instant(body),
      confirmBtnLabel: this.translate.instant('generic.CLOSE'),
      confirmation: confimation,
      componentRef: this
    };
    let publicUsersPrivacyRef = this.ngxSmartModalService.getModal('publicUsersPrivacyModal');
    publicUsersPrivacyRef.setData(publicUsersPrivacyData, true);
    confimation
      .pipe(take(1))
      .subscribe(() => {
      });
    // Apro la modale
    publicUsersPrivacyRef.open();
  }

  preventDefault(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  // Dal form passato come parametro, ricavo i dati immessi dall'utente per inserirli nel dispatch dell'action che tenterà il login
  onLogin(form: NgForm) {
    this.showLoader = true;
    const email = form.value.email;
    const password = form.value.password;
    this.store.dispatch(new AuthActions.DoLogin({ email: email, password: password, isDei: true }));
    this.showLoader = false;
  }

  // Dal form passato come parametro, ricavo i dati immessi dall'utente per il recupero della password
  onRecoverPassword(form: NgForm) {
    this.showLoader = true;

    // Altrimenti significa che devo inviare la mail all'utente
    this.isManagingUser$ = this.authService.initPasswordRecovery(form.value.resetPswEmail, this.CUSTOMER_FAMILY, sessionStorage.getItem("redirectDEIUrl"))
      .subscribe(data => {
        this.showLoader = false;
        // Se ci sono errori, li mostro e torno alla lista dei template
        if (data.error) {
          this.toastr.error(this.translate.instant('errors.' + data.error));
          // Eseguo il toggle
          this.toggleResetPasswordFormVisible();
        } else {
          // Mostro il messaggio di avviso
          this.openSuccessModal('generic.modals.EMAIL_FOR_CHANGE_PSW_SENT_BODY', 'generic.modals.EMAIL_FOR_CHANGE_PSW_SENT');
          // Eseguo il toggle
          this.toggleResetPasswordFormVisible();
        }
      },
        (err) => {
          this.toastr.error(this.translate.instant('errors.' + err.message));
          this.showLoader = false;
        });
  }

  // Dal form passato come parametro, ricavo i dati immessi dall'utente per il recupero della password
  setPassword(form: NgForm) {
    this.showLoader = true;

    let deviceType;
    if (this.deviceService.isMobile()) {
      // Salvo il fatto che è uno smartphone
      deviceType = "P";
    } else if (this.deviceService.isTablet()) {
      // Salvo il fatto che è un tablet
      deviceType = "T";
    } else if (this.deviceService.isDesktop()) {
      // Salvo il fatto che è un computer desktop
      deviceType = "D";
    }
    const deviceInfo = this.deviceService.getDeviceInfo();
    const userAgent = deviceInfo && deviceInfo.userAgent;
    // Altrimenti significa che devo inviare la mail all'utente

    this.isManagingUser$ = this.authService.setCustomerUserPasswordWithDeviceTypeAndUserAgent(this.userId, this.token, form.value.repeatPassword, this.CUSTOMER_FAMILY, true, deviceType, userAgent)
      .subscribe(data => {
        this.showLoader = false;
        // Se ci sono errori, li mostro e torno alla lista dei template
        if (data.error) {
          this.toastr.error(this.translate.instant('errors.' + data.error));
        } else {
          this.store.dispatch(new AuthActions.SetToken(data.response.token));
          this.store.dispatch(new AuthActions.SetIsHeritageTaker());
          const url = data.response.returnUrl;
          this.authService.getJWTToken(data.response.token).subscribe(data => {
            if (data.error) {
              this.toastr.error(this.translate.instant('errors.' + data.error));
            } else {
              this.store.dispatch(new CoreActions.StartRenewTokenPolling());
              this.router.navigateByUrl(url);
            }
          });
        }
      },
        (err) => {
          this.toastr.error(this.translate.instant('errors.' + err.message));
          this.showLoader = false;
        });
  }

  // Dal form passato come parametro, ricavo i dati immessi dall'utente per la registrazione
  onRegister(form: NgForm) {
    this.showLoader = true;
    const deviceInfo = this.deviceService.getDeviceInfo();
    const userAgent = deviceInfo && deviceInfo.userAgent;
    let deviceType;
    if (this.deviceService.isMobile()) {
      // Salvo il fatto che è uno smartphone
      deviceType = 'P';
    } else if (this.deviceService.isTablet()) {
      // Salvo il fatto che è un tablet
      deviceType = 'T';
    } else if (this.deviceService.isDesktop()) {
      // Salvo il fatto che è un computer desktop
      deviceType = 'D';
    }

    // Chiamo il servizio per la registrazione
    this.isManagingUser$ = this.authService.registerCustomer(form.value.newUserEmail.trim(), form.value.newUserFirstName.trim(), form.value.newUserLastName.trim(), userAgent, deviceType, form.value.allowUserPrivacy, this.CUSTOMER_FAMILY, sessionStorage.getItem("redirectDEIUrl"))
      .subscribe(data => {
        this.showLoader = false;
        // Se ci sono errori, li mostor e torno alla lista dei template
        if (data.error) {
          this.toastr.error(this.translate.instant('errors.' + data.error));
        } else {
          // Mostro il messaggio di avviso
          this.openSuccessModal('generic.modals.USER_CREATED', 'generic.modals.USER_CREATED_TITLE');
        }
      },
        (err) => {
          this.toastr.error(this.translate.instant('errors.' + err.message));
          this.showLoader = false;
        });
  }

  openSuccessModal(body: string, title: string) {
    const confimation = new EventEmitter<any>();
    // Prima di aprire la modale, setto le traduzioni e i metodi da agganciare le funzioni
    const confirmModalData: Object = {
      modalTitle: this.translate.instant(title),
      modalBody: this.translate.instant(body),
      confirmBtnLabel: this.translate.instant('generic.OK'),
      confirmation: confimation,
      componentRef: this
    };
    let confirmModalRef = this.ngxSmartModalService.getModal('confirmModal');
    confirmModalRef.setData(confirmModalData, true);
    confimation
      .pipe(take(1))
      .subscribe(() => {
      });
    // Apro la modale
    confirmModalRef.open();
  }

  ngOnDestroy() {
    if (this.isManagingUser$) {
      this.isManagingUser$.unsubscribe();
    }
  }

  // Dal form passato come parametro, controllo se le due password nuove sono uguali
  areNewPswSame(form: NgForm) {
    const newPsw = form.value.newPsw;
    const newPswRepeated = form.value.repeatPassword;
    if (newPsw && newPswRepeated && newPsw === newPswRepeated) {
      return true;
    }
    return false;
  }

  // Toggle del form per il reset della password
  toggleResetPasswordFormVisible() {
    this.resetPasswordFormVisible = !this.resetPasswordFormVisible;
  }
}
