/*
*  Lista di azioni riguardanti le iniziative (corsi)
*/

import { Action } from '@ngrx/store';
import { Item } from "../../../cm2-commonclasses";

// Salva la lista di iniziative recuperate
export const SET_INITIATIVES = 'SET_INITIATIVES_fundingSupplier';

// Recupera le iniziative da remoto (OFFERTE)
export const GET_OFFER_INITIATIVES = 'GET_OFFER_INITIATIVES';

// Recupera le iniziative da remoto (FATTURE)
export const GET_INVOICE_INITIATIVES = 'GET_INVOICE_INITIATIVES';

// Recupera le iniziative da remoto (MATERIALI)
export const GET_MATERIAL_INITIATIVES = 'GET_MATERIAL_INITIATIVES';

// Recupera le iniziative da remoto (COMUNICAIONI)
export const GET_COMMUNICATION_INITIATIVES = 'GET_COMMUNICATION_INITIATIVES'

// Setta il contatore delle iniziative recuperate da remoto
export const SET_INITIATIVES_COUNT = 'SET_INITIATIVES_COUNT_fundingSupplier';

// Setta la paginazione
export const SET_PAGINATION = 'SET_PAGINATION_fundingSupplier';

// Setta la pagina corrente
export const SET_PAGE = 'SET_PAGE_fundingSupplier';

// Setta il filtro per la tipologia
export const SET_INITIATIVES_TYPE = 'SET_INITIATIVES_TYPE_fundingSupplier';

// Setta il filtro per la ricerca testuale
export const SET_INITIATIVES_TEXT_FILTER = 'SET_INITIATIVES_TEXT_FILTER_fundingSupplier';

// Ottiene gli errori della HomePage
export const GET_ERRORS = 'GET_ERRORS_fundingSupplier';

// Setta gli errori della pagine delle OFFERTE
export const SET_OFFER_ERRORS = 'SET_OFFER_ERRORS_fundingSupplier';

// Setta gli errori della pagine delle FATTURE
export const SET_INVOICE_ERRORS = 'SET_INVOICE_ERRORS_fundingSupplier';

// Setta gli errori della pagine dei MATERIALI
export const SET_MATERIAL_ERRORS = 'SET_MATERIAL_ERRORS_fundingSupplier';

// Setta gli errori della pagine delle COMUNICAIONI
export const SET_COMMUNICATION_ERRORS = 'SET_COMMUNICATION_ERRORS_fundingSupplier';

export class SetInitiatives implements Action {
    readonly type = SET_INITIATIVES;

    constructor(public payload: any[]) { }
}

export class SetInitiativesType implements Action {
    readonly type = SET_INITIATIVES_TYPE;

    constructor(public payload: string) { }
}

export class SetInitiativesTextFilter implements Action {
    readonly type = SET_INITIATIVES_TEXT_FILTER;

    constructor(public payload: string) { }
}

export class GetOfferInitiatives implements Action {
    readonly type = GET_OFFER_INITIATIVES;
}

export class GetInvoiceInitiatives implements Action {
    readonly type = GET_INVOICE_INITIATIVES;
}

export class GetMaterialInitiatives implements Action {
    readonly type = GET_MATERIAL_INITIATIVES;
}

export class GetCommunicationInitiatives implements Action {
    readonly type = GET_COMMUNICATION_INITIATIVES;
}

export class SetInitiativesCount implements Action {
    readonly type = SET_INITIATIVES_COUNT;

    constructor(public payload: number) { }
}

export class SetPage implements Action {
    readonly type = SET_PAGE;

    constructor(public payload: number) { }
}

export class SetPagination implements Action {
    readonly type = SET_PAGINATION;

    constructor(public payload: { fromRecord: number, numRecords: number }) { }
}

export class GetErrors implements Action {
    readonly type = GET_ERRORS;
}

export class SetOfferErrors implements Action {
    readonly type = SET_OFFER_ERRORS;

    constructor(public payload: number) { }
}

export class SetInvoiceErrors implements Action {
    readonly type = SET_INVOICE_ERRORS;

    constructor(public payload: number) { }
}

export class SetMaterialErrors implements Action {
    readonly type = SET_MATERIAL_ERRORS;

    constructor(public payload: number) { }
}

export class SetCommunicationErrors implements Action {
    readonly type = SET_COMMUNICATION_ERRORS;

    constructor(public payload: number) { }
}


export type InitiativeActions =
    SetInitiatives |
    SetInitiativesTextFilter |
    SetInitiativesCount |
    SetPagination |
    SetPage |
    GetOfferInitiatives |
    GetInvoiceInitiatives |
    GetMaterialInitiatives |
    GetCommunicationInitiatives |
    SetInitiativesType |
    GetErrors |
    SetOfferErrors |
    SetInvoiceErrors |
    SetMaterialErrors |
    SetCommunicationErrors;
