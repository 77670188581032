import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { ProfileModule } from './profile/profile.module';
import { AuthModule } from './auth/auth.module'
import { CoreModule } from './core/core.module';
import { reducers } from './ngrx/app.reducers';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { AuthEffects } from './auth/ngrx/auth.effects';
import { CoreEffects } from './core/ngrx/core.effects';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AuthInterceptor } from './shared/interceptors/auth.interceptor';
import { GoogleMapsAPIWrapper } from '@agm/core';
import { CampagnaVaccinaleComponent } from './publicRoutes/campagnaVaccinale/campagnaVaccinale.component';
import { CampagnaVaccinaleMoglianoComponent } from './publicRoutes/campagnaVaccinaleMogliano/campagnaVaccinaleMogliano.component';
import { CampagnaVaccinaleTorinoComponent } from './publicRoutes/campagnaVaccinaleTorino/campagnaVaccinaleTorino.component';
import { CampagnaVaccinaleRomaComponent } from './publicRoutes/campagnaVaccinaleRoma/campagnaVaccinaleRoma.component';
import { CampagnaVaccinaleTriesteComponent } from './publicRoutes/campagnaVaccinaleTrieste/campagnaVaccinaleTrieste.component';
import { CampagnaVaccinaleGenovaComponent } from './publicRoutes/campagnaVaccinaleGenova/campagnaVaccinaleGenova.component';
import { CampagnaVaccinaleNapoliComponent } from './publicRoutes/campagnaVaccinaleNapoli/campagnaVaccinaleNapoli.component';
import { ReportDownloadComponent } from './dashboard/report-download/report-download.component';
import { ImportFinanincingNoticesComponent } from './importFinanincingNotices/importFinanincingNotices.component';

@NgModule({
    declarations: [
        AppComponent,
        CampagnaVaccinaleComponent,
        CampagnaVaccinaleMoglianoComponent,
        CampagnaVaccinaleTorinoComponent,
        CampagnaVaccinaleRomaComponent,
        CampagnaVaccinaleTriesteComponent,
        CampagnaVaccinaleGenovaComponent,
        CampagnaVaccinaleNapoliComponent,
        ReportDownloadComponent,
        ImportFinanincingNoticesComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({ preventDuplicates: true }),
        HttpClientModule,
        AppRoutingModule,
        SharedModule,
        ProfileModule,
        AuthModule,
        CoreModule,
        AngularSvgIconModule,
        DeviceDetectorModule.forRoot(),
        StoreModule.forRoot(reducers),
        EffectsModule.forRoot([AuthEffects, CoreEffects]),
        StoreRouterConnectingModule,
        !environment.production ? StoreDevtoolsModule.instrument() : [] // Il plugin per lo Store (che funzione con l'estensione di Chrome) lo aggiungiamo solo se non stiamo facendo il build per la produzione
    ],
    providers: [
        GoogleMapsAPIWrapper,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
