<!-- Il form di login si vede solamente se ci sono lingue disponibili a sistema -->
<div>
    <div class="login-page theme-primary" *ngIf="langs && langs.length" fxFill>
        <div fxLayout="column" fxLayoutAlign="center center" fxFill>

            <img class="margin-bottom40" width="280" alt="{{'accessibilityAttributes.GENERALI_HERITAGE' | translate}}"
                title="{{'accessibilityAttributes.GENERALI_HERITAGE' | translate}}" src="assets/img/heritage_logo.png">

            <!-- Pannello di accesso -->
            <div *ngIf="!isEmailConfirmation && !recoveryUserPassword && !token && !userId && loginFormVisible && !resetPasswordFormVisible"
                class="login-column" fxLayout="column" fxLayoutAlign="center center">


                <h3 class="signin-text bold-text to-uppercase" translate="courseHeritage.TITLE_LOGIN"></h3>
                <h5 class="margin-top45 margin-bottom45 subtitle-grey-form"  [innerHTML]="('courseHeritage.TEXT_LOGIN' | translate)">
                    
                </h5>

                <!-- <div *ngIf="!notShowAlertLogin" class="label-warning-container">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <svg-icon src="assets/img/icon_warning.svg"></svg-icon>
                    <div fxLayout="column">
                        <p class="title-text" translate="generic.ATTENTION"></p>
                        <p translate="courseHeritage.LABEL_WARNING"></p>
                    </div>
                    </div>
                </div> -->

                <form class="login-wrapper full-width" (ngSubmit)="onLogin(loginForm)" #loginForm="ngForm">
                    <!-- Email -->
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <div class="generali-input full">
                            <div class="title-input" fxLayout="row" fxLayoutAlign="start start">
                                <h5 translate="generic.EMAIL"></h5>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <!-- Placeholder di caricamento -->
                                <input [hidden]="showLoader" attr.aria-label="{{'generic.TYPE_MAIL' | translate}}"
                                    class="full-width" type="email" placeholder="{{'generic.TYPE_MAIL' | translate}}"
                                    id="email" name="email" #email="ngModel" ngModel required email>
                            </div>
                            <!-- Errore di email non valida -->
                            <div *ngIf="!email.valid && email.touched" class="input-error" fxLayout="row"
                                fxLayoutAlign="start center">
                                <span translate="errors.EMAIL_NOT_VALID"></span>
                            </div>
                        </div>
                    </div>

                    <div class="space15"></div>

                    <!-- Password -->
                    <div fxFlexFill="row" fxLayoutAlign="center center">
                        <div class="generali-input full">
                            <div class="title-input" fxLayout="row" fxLayoutAlign="start start">
                                <h5 translate="generic.PASSWORD"></h5>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <!-- Placeholder di caricamento -->
                                <input [hidden]="showLoader" attr.aria-label="{{'errors.TYPE_PASSWORD' | translate}}"
                                    placeholder="{{'errors.TYPE_PASSWORD' | translate}}" class="full-width"
                                    type="password" id="password" name="password" ngModel #password="ngModel" required>
                            </div>

                            <!-- Password non inserita -->
                            <div *ngIf="!password.valid && password.touched" class="input-error" fxLayout="row"
                                fxLayoutAlign="start center">
                                <span translate="errors.TYPE_PASSWORD"></span>
                            </div>
                        </div>
                    </div>

                    <!--Password dimenticata -->
                    <div fxLayout="row" fxLayout.xs="column" fxFlexFill fxLayoutAlign="space-between center">
                        <a class="margin-top45 display-block" (click)="toggleResetPasswordFormVisible()"
                            translate="generic.DID_YOU_FORGET_PASSWORD"></a>
                    </div>

                    <!-- Pulsanti registrazione e Login -->
                    <div class="margin-top45" fxLayout="row" fxLayout.xs="column" fxFlexFill
                        fxLayoutAlign="space-between center">
                        <button type="button" (click)="toggleSubscriptionFormVisible()"
                            class="btn-secondary-1 medium color-for-button">
                            <p translate="login.REGISTRATION"></p>
                        </button>
                        <div fxHide.gt-xs class="space20"></div>
                        <button class="btn-primary-1 medium color-for-button" type="submit"
                            [disabled]="!loginForm.valid || showLoader">
                            <p translate="login.SIGN_IN"></p>
                        </button>
                    </div>
                </form>
            </div>

            <!-- Pannello di registrazione -->
            <div *ngIf="!isEmailConfirmation && !recoveryUserPassword && !token && !userId && !loginFormVisible && !resetPasswordFormVisible"
                class="login-column" fxLayout="column" fxLayoutAlign="center center">

                <h3 class="bold-text to-uppercase" translate="courseHeritage.REGISTER"></h3>
                <h5 class="margin-top16 margin-bottom45 subtitle-grey-form"
                    [innerHTML]="('courseHeritage.CREATE_NEW_ACCOUNT' | translate)"></h5>
                <!-- <div *ngIf="!notShowAlertLogin" class="label-warning-container">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <svg-icon src="assets/img/icon_warning.svg"></svg-icon>
                    <div fxLayout="column">
                        <p class="title-text" translate="generic.ATTENTION"></p>
                        <p translate="courseHeritage.LABEL_WARNING"></p>
                    </div>
                    </div>
                </div> -->
                <form class="login-wrapper full-width" (ngSubmit)="onRegister(subscriptionForm)"
                    #subscriptionForm="ngForm">
                    <!-- Nome -->
                    <div fxLayout="row" fxFlexFill fxLayoutAlign="center center">
                        <div class="generali-input full">
                            <div class="title-input" fxLayout="row" fxLayoutAlign="start start" fxFlexFill>
                                <h5 translate="generic.FORENAME"></h5>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <!-- Placeholder di caricamento -->
                                <input [hidden]="showLoader"
                                    (keyup)="changeInput(newUserFirstName);checkRegExp(newUserFirstName)"
                                    class="full-width" attr.aria-label="{{'generic.TYPE_FORENAME' | translate}}"
                                    type="text" id="newUserFirstName"
                                    placeholder="{{'generic.TYPE_FORENAME' | translate}}" name="newUserFirstName"
                                    #newUserFirstName="ngModel" ngModel required>
                            </div>
                            <!-- Nome non inserito -->
                            <div *ngIf="!newUserFirstName.valid && newUserFirstName.touched || changeInput(newUserFirstName) || checkRegExp(newUserFirstName)"
                                class="input-error" fxLayout="row" fxLayoutAlign="start center">
                                <span translate="generic.TYPE_FORENAME"></span>
                            </div>
                        </div>
                    </div>

                    <div class="space15"></div>
                    <!-- Cognome -->
                    <div fxLayout="row" fxFlexFill fxLayoutAlign="center center">
                        <div class="generali-input full">
                            <div class="title-input" fxLayout="row" fxLayoutAlign="start start" fxFlexFill>
                                <h5 translate="generic.SURNAME"></h5>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <!-- Placeholder di caricamento -->
                                <input [hidden]="showLoader"
                                    (keyup)="changeInput(newUserLastName);checkRegExp(newUserLastName)"
                                    class="full-width" attr.aria-label="{{'generic.TYPE_SURNAME' | translate}}"
                                    type="text" id="newUserLastName" name="newUserLastName"
                                    placeholder="{{'generic.TYPE_SURNAME' | translate}}" #newUserLastName="ngModel"
                                    ngModel required>
                            </div>
                            <!-- Cognome non inserito -->
                            <div *ngIf="!newUserLastName.valid && newUserLastName.touched || changeInput(newUserLastName) || checkRegExp(newUserLastName)"
                                class="input-error" fxLayout="row" fxLayoutAlign="start center">
                                <span translate="generic.TYPE_SURNAME"></span>
                            </div>
                        </div>
                    </div>

                    <div class="space15"></div>
                    <!-- Email -->
                    <div fxLayout="row" fxFlexFill fxLayoutAlign="center center">
                        <div class="generali-input full">
                            <div class="title-input" fxLayout="row" fxLayoutAlign="start start" fxFlexFill>
                                <h5 translate="generic.EMAIL"></h5>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <!-- Placeholder di caricamento -->
                                <input [hidden]="showLoader" (keyup)="changeInput(newUserEmail)" class="full-width"
                                    attr.aria-label="{{'generic.TYPE_MAIL' | translate}}" type="email" id="newUserEmail"
                                    name="newUserEmail" placeholder="{{'generic.TYPE_MAIL' | translate}}"
                                    #newUserEmail="ngModel" ngModel required email>
                            </div>
                            <!-- Mail non inserita -->
                            <div *ngIf="!subscriptionForm.value.newUserEmail || !subscriptionForm.value.newUserEmail.length"
                                class="input-error" fxLayout="row" fxLayoutAlign="start center">
                                <span translate="generic.TYPE_MAIL"></span>
                            </div>
                            <!-- Errore di email non valida -->
                            <div *ngIf="subscriptionForm.value.newUserEmail && subscriptionForm.value.newUserEmail.length && !newUserEmail.valid && newUserEmail.touched || changeInput(newUserEmail)"
                                class="input-error" fxLayout="row" fxLayoutAlign="start center">
                                <span translate="errors.EMAIL_NOT_VALID"></span>
                            </div>
                        </div>
                    </div>

                    <div class="space15"></div>

                    <!-- Consenso privacy -->
                    <div fxLayout="row" fxFlexFill fxLayoutAlign="center center">
                        <div class="generali-input full">
                            <div class="title-input" fxLayout="row" fxLayoutAlign="start start" fxFlexFill>
                                <h5 translate="generic.PRIVACY"></h5>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <div class="privacy-padding">
                                    <div fxLayoutAlign="start center" class="md-checkbox margin-top10 margin-bottom10"
                                        fxLayout="row">
                                        <input
                                            style="min-width: 30px!important; max-width: 30px!important; width: 30px!important; margin-right: 16px;"
                                            title="{{'generic.PUBLIC_USER_PRIVACY_TITLE_FULL' | translate}}"
                                            attr.aria-label="{{'generic.PUBLIC_USER_PRIVACY_TITLE_FULL' | translate}}"
                                            [disabled]="showLoader" type="checkbox" name="allowUserPrivacy"
                                            id="allowUserPrivacy" ngModel #allowUserPrivacy="ngModel" required>
                                        <label for="allowUserPrivacy" class="bold-text label-for-checkbox">
                                            <p class="padding0">
                                                <span>{{ ('generic.PUBLIC_USER_PRIVACY_TITLE_FIRST' | translate)
                                                    }}</span>
                                                <span (click)="preventDefault($event); openPrivacyDocument();"
                                                    class="link-privacy cursor-pointer"
                                                    translate="generic.PUBLIC_USER_PRIVACY_TITLE_SECOND"></span>
                                                <span>{{ ('generic.PUBLIC_USER_PRIVACY_TITLE_THIRD' | translate)
                                                    }}</span>
                                                <span class="bold-text">{{ ' ' +
                                                    ('generic.PUBLIC_USER_PRIVACY_TITLE_FOURTH' | translate) }}</span>
                                                <span
                                                    [innerHTML]="('generic.PUBLIC_USER_PRIVACY_TITLE_FIFTH_HERITAGE' | translate)"></span>
                                            </p>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="space45"></div>

                    <!-- Pulsante per il login -->
                    <div fxLayout="row" fxLayout.xs="column" fxFlexFill fxLayoutAlign="space-between center">
                        <button type="button" (click)="toggleLoginFormVisible()" class="btn-secondary-1 medium color-for-button">
                            <p translate="courseHeritage.LOGIN"></p>
                        </button>
                        <div fxHide.gt-xs class="space20"></div>
                        <button class="btn-primary-1 medium color-for-button" type="submit"
                            [disabled]="!subscriptionForm.valid || isValidForm(subscriptionForm) || noCheckNames">
                            <p translate="login.REGISTRATION"></p>
                        </button>
                    </div>
                </form>
            </div>

            <!-- Pannello di recupero password (invio dell'email) -->
            <div *ngIf="!isEmailConfirmation && !recoveryUserPassword && !token && !userId && resetPasswordFormVisible"
                class="login-column" fxLayout="column" fxLayoutAlign="center center">
                <h3 class="bold-text" translate="login.RESET_YOUR_PSW"></h3>
                <h5 class="margin-top10" translate="login.RESET_PSW_INFO"></h5>
                <form class="login-wrapper full-width" (ngSubmit)="onRecoverPassword(resetPasswordForm)"
                    #resetPasswordForm="ngForm">
                    <div class="space45"></div>
                    <!-- Email -->
                    <div fxLayout="row" fxFlexFill fxLayoutAlign="center center">
                        <div class="generali-input full">
                            <div class="title-input" fxLayout="row" fxLayoutAlign="start start" fxFlexFill>
                                <h5 translate="generic.EMAIL"></h5>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <!-- Placeholder di caricamento -->
                                <input [hidden]="showLoader" class="full-width"
                                    attr.aria-label="{{'generic.TYPE_MAIL' | translate}}" type="email"
                                    id="resetPswEmail" name="resetPswEmail"
                                    placeholder="{{'generic.TYPE_MAIL' | translate}}" #email="ngModel" ngModel required
                                    email>
                            </div>
                            <!-- Errore di email non valida -->
                            <div *ngIf="resetPswEmail && !resetPswEmail.valid && resetPswEmail.touched"
                                class="input-error" fxLayout="row" fxLayoutAlign="start center">
                                <span translate="errors.EMAIL_NOT_VALID"></span>
                            </div>
                        </div>
                    </div>

                    <div class="space45"></div>

                    <div fxLayout="row" fxLayout.xs="column" fxFlexFill fxLayoutAlign="space-between center">
                        <button type="button" (click)="toggleResetPasswordFormVisible()"
                            class="btn-secondary-1 medium color-for-button">
                            <p translate="generic.CANCEL"></p>
                        </button>
                        <div fxHide.gt-xs class="space20"></div>
                        <button class="btn-primary-1 medium" type="submit" [disabled]="!resetPasswordForm.valid">
                            <p translate="login.CONFIRM"></p>
                        </button>
                    </div>
                </form>
            </div>
            <!-- Pannello di impostazione password (sto immettendo una nuova password, oppure la sto aggiornando) -->
            <div *ngIf="(isEmailConfirmation || recoveryUserPassword) && token && userId" class="login-column"
                fxLayout="column" fxLayoutAlign="center center">
                <h3 *ngIf="recoveryUserPassword" class="margin-top10 bold-text" translate="login.RESET_YOUR_PSW"></h3>
                <h3 *ngIf="isEmailConfirmation" class="margin-top10 bold-text" translate="login.NEW_PSW"></h3>
                <h5 translate="generic.TYPE_NEW_CREDENTIALS"></h5>
                <br>
                <h5 [innerHTML]="('courseHeritage.PASSWORD_TEXT_SETUP_REQUIRED' | translate)"></h5>
                <div class="space30"></div>
                <form class="login-wrapper full-width" (ngSubmit)="setPassword(newPswForm)" #newPswForm="ngForm">
                    <!-- Password -->
                    <div fxFlexFill="row" fxFlexFill fxLayoutAlign="center center">
                        <div class="generali-input full">
                            <div class="title-input" fxLayout="row" fxLayoutAlign="start start" fxFlexFill>
                                <h5 translate="login.NEW_PASSWORD"></h5>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <!-- Placeholder di caricamento -->
                                <input [hidden]="showLoader" class="full-width"
                                    attr.aria-label="{{'errors.TYPE_PASSWORD' | translate}}" type="password" id="newPsw"
                                    name="newPsw" placeholder="{{'errors.TYPE_PASSWORD' | translate}}" ngModel
                                    #newPsw="ngModel" required>
                            </div>

                            <!-- Password non inserita -->
                            <div *ngIf="!newPsw.valid && newPsw.touched" class="input-error" fxLayout="row"
                                fxLayoutAlign="start center">
                                <span translate="errors.TYPE_PASSWORD"></span>
                            </div>
                            <div class="space30"></div>

                            <div class="title-input" fxLayout="row" fxLayoutAlign="start start" fxFlexFill>
                                <h5 translate="login.REPEAT_PASSWORD"></h5>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <!-- Placeholder di caricamento -->
                                <input [hidden]="showLoader" class="full-width"
                                    attr.aria-label="{{'login.REPEAT_PASSWORD' | translate}}" type="password"
                                    id="repeatPassword" placeholder="{{'login.REPEAT_PASSWORD' | translate}}"
                                    name="repeatPassword" ngModel #repeatPassword="ngModel" required>
                            </div>

                            <!-- Password non inserita -->
                            <div *ngIf="!repeatPassword.valid && repeatPassword.touched" class="input-error"
                                fxLayout="row" fxLayoutAlign="start center">
                                <span translate="errors.TYPE_PASSWORD"></span>
                            </div>

                            <!-- Password non corrisponde -->
                            <div *ngIf="repeatPassword.valid && repeatPassword.touched && !areNewPswSame(newPswForm)"
                                class="input-error" fxLayout="row" fxLayoutAlign="start center">
                                <span translate="login.PASSWORD_DONT_MATCH"></span>
                            </div>
                        </div>
                    </div>

                    <div class="margin-top40 margin-bottom30" fxLayout="row" fxLayout.xs="column" fxFlexFill
                        fxLayoutAlign="space-between center">
                        <div fxHide.gt-xs class="space20"></div>
                        <button class="btn-primary-1 medium" type="submit"
                            [disabled]="!newPswForm.valid || !areNewPswSame(newPswForm)">
                            <p translate="login.CONFIRM"></p>
                        </button>
                    </div>
                </form>
            </div>

            <div *ngIf="isPwdSetted" class="login-column" fxLayout="column" fxLayoutAlign="center center">
                <h3 class="signin-text bold-text to-uppercase">Password Impostata</h3>
                <p>Il cambio password è andato a buon fine </p>
                <h5 class="margin-top45 margin-bottom45 subtitle-grey-form">
                    Hai impostato correttamente la Password. Puoi accedere nuovamente alla piattaforma dal link che hai
                    ricevuto via mail inserendo i nuovi dati.
                    <br><br>
                    Grazie!
                </h5>

            </div>

        </div>
    </div>
</div>

<!-- Nessuna lingua disponibile a sistema -->
<div *ngIf="!langs || !langs.length">
    <p translate="errors.NO_AVAILABLE_LANG"></p>
</div>