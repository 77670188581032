// Local login del subscription iniziative smart energy tramite sso

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Subscription } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { Lang } from "../../../cm2-commonclasses";
import * as fromApp from '../../ngrx/app.reducers';
import * as AuthActions from '../ngrx/auth.actions';

@Component({
    selector: 'app-smart-energy-subscription-local-login',
    templateUrl: './smart-energy-subscription-local-login.component.html',
    styleUrls: ['./smart-energy-subscription-local-login.component.scss']
})
export class SmartEnergySubscriptionLocalLoginComponent implements OnInit, OnDestroy {
    isFetchingLangs: boolean;
    langs: Lang[];
    mailCid: string;
    result$: Subscription;

    constructor(private store: Store<fromApp.AppState>,public translate: TranslateService) {
        // Sto in ascolto di quando il globalApplicationData cambia, così da sapere quando ci sono le lingue disponibili poiché, se non ci fossero, il pulsante per il login sarebbe disabilitato
        let availableLangs$: Observable<Lang[]> = this.store.select(fromApp.getAvailableLangs);
        let isFetchingLangs$: Observable<boolean> = this.store.select(fromApp.isFetchingLangs);
        const combinedSelectes$ = combineLatest(availableLangs$, isFetchingLangs$);
        this.result$ = combinedSelectes$.subscribe(
            ([langs, isFetchingLangs]) => {
                this.langs = langs;
                this.isFetchingLangs = isFetchingLangs;
            });
    }
    ngOnInit() {
    }
    ngOnDestroy() {
    }

    doLogin() {
        let email: string = this.mailCid;
        if (email && !email.includes('@')) {
            email = email.replace(/^p?0*/mgi, "");
        }
        this.store.dispatch(new AuthActions.DoLogin({ email: email, password: null, isSmartEnergyTaker: true }));
    }
}