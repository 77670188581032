/*
*  Lista di azioni riguardanti le iniziative (corsi)
*/

import { Action } from '@ngrx/store';
import { Item } from "../../../cm2-commonclasses";

// Salva la lista di iniziative recuperate
export const SET_INITIATIVES = 'SET_INITIATIVES';

// Aggiunge una nuova iniziativa
export const ADD_INITIATIVE = 'ADD_INITIATIVE';

// Aggiorna una iniziativa
export const UPDATE_INITIATIVE = 'UPDATE_INITIATIVE';

// Get di una iniziativa
export const GET_INITIATIVE = 'GET_INITIATIVE';

// Set di una iniziativa
export const SELECT_INITIATIVE = 'SELECT_INITIATIVE';

// Cancella una iniziativa
export const DELETE_INITIATIVE = 'DELETE_INITIATIVE';

// Salva un'iniziativa
export const STORE_INITIATIVE = 'STORE_INITIATIVE';

// Recupera le iniziative da remoto
export const GET_INITIATIVES = 'GET_INITIATIVES';

// Setta il contatore delle iniziative recuperate da remoto
export const SET_INITIATIVES_COUNT = 'SET_INITIATIVES_COUNT';

// Setta la paginazione
export const SET_PAGINATION = 'SET_PAGINATION';

// Setta la pagina corrente
export const SET_PAGE = 'SET_PAGE';

// Salva l'intero oggetto dell'iniziativa scelta
export const SET_SELECTED_INITIATIVE_ITEM = 'SET_SELECTED_INITIATIVE_ITEM';

// Salva l'intero oggetto padre (quindi il percorso) dell'iniziativa scelta
export const SET_PATH_ITEM_OF_SELECTED_INITIATIVE = 'SET_PATH_ITEM_OF_SELECTED_INITIATIVE';

// Resetta i dati dell'iniziativa scelta
export const DELETE_SELECTED_INITIATIVE_DATA = 'DELETE_SELECTED_INITIATIVE_DATA';

// Resetta i dati dell'iniziativa scelta
export const SET_CURRENT_WIZARD_STEP = 'SET_CURRENT_WIZARD_STEP';

// Setta il filtro per la tipologia
export const SET_INITIATIVES_TYPE = 'SET_INITIATIVES_TYPE';

// Setta il filtro per la ricerca testuale
export const SET_INITIATIVES_TEXT_FILTER = 'SET_INITIATIVES_TEXT_FILTER';

export class SetInitiatives implements Action {
    readonly type = SET_INITIATIVES;

    constructor(public payload: any[]) { }
}

export class AddInitiative implements Action {
    readonly type = ADD_INITIATIVE;

    constructor(public payload: any) { }
}

export class UpdateInitiative implements Action {
    readonly type = UPDATE_INITIATIVE;

    constructor(public payload: { index: number, updatedInitiative: any }) { }
}

export class GetInitiative implements Action {
    readonly type = GET_INITIATIVE;

    constructor(public payload: string) { }
}

export class SetSelectedInitiativeItem implements Action {
    readonly type = SET_SELECTED_INITIATIVE_ITEM;

    constructor(public payload: Item) { }
}

export class DeleteSelectedInitativeData implements Action {
    readonly type = DELETE_SELECTED_INITIATIVE_DATA;
}

export class SetPathItemOfSelectedInitiative implements Action {
    readonly type = SET_PATH_ITEM_OF_SELECTED_INITIATIVE;

    constructor(public payload: Item) { }
}

export class SelectInitiative implements Action {
    readonly type = SELECT_INITIATIVE;

    constructor(public payload: string) { }
}

export class SetInitiativesType implements Action {
    readonly type = SET_INITIATIVES_TYPE;

    constructor(public payload: string) { }
}

export class SetInitiativesTextFilter implements Action {
    readonly type = SET_INITIATIVES_TEXT_FILTER;

    constructor(public payload: string) { }
}

export class DeleteInitiative implements Action {
    readonly type = DELETE_INITIATIVE;

    constructor(public payload: string) { }
}

export class StoreInitiative implements Action {
    readonly type = STORE_INITIATIVE;
}

export class GetInitiatives implements Action {
    readonly type = GET_INITIATIVES;
}

export class SetInitiativesCount implements Action {
    readonly type = SET_INITIATIVES_COUNT;

    constructor(public payload: number) { }
}

export class SetPage implements Action {
    readonly type = SET_PAGE;

    constructor(public payload: number) { }
}

export class SetPagination implements Action {
    readonly type = SET_PAGINATION;

    constructor(public payload: { fromRecord: number, numRecords: number }) { }
}

export class SetCurrentWizardStep implements Action {
    readonly type = SET_CURRENT_WIZARD_STEP;

    constructor(public payload: string) { }
}

export type InitiativeActions =
    SetInitiatives |
    GetInitiative |
    SelectInitiative |
    AddInitiative |
    UpdateInitiative |
    DeleteInitiative |
    SetInitiativesTextFilter |
    StoreInitiative |
    SetInitiativesCount |
    SetPagination |
    DeleteSelectedInitativeData |
    SetPage |
    SetSelectedInitiativeItem |
    SetPathItemOfSelectedInitiative |
    GetInitiatives |
    SetInitiativesType |
    SetCurrentWizardStep;
