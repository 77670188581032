/*
 * Azioni per il Core Module
*/

import { Action } from '@ngrx/store';
import { GlobalApplicationData } from '../../shared/models/global-application-data.model';
import { ToastrConfig } from 'ngx-toastr';
import { Tabset } from '../../shared/models/tabset.model';
import { Tag } from "../../../cm2-commonclasses";

// Setta il prefisso applicativo (context) e le altre varibili del server
export const SET_CORE_APPLICATION_DATA = 'SET_CORE_APPLICATION_DATA';

// Pulisce il prefisso applicativo (context) e le altre varibili del server
export const REMOVE_CORE_APPLICATION_DATA = 'REMOVE_CORE_APPLICATION_DATA';

// Gestisce i casi di errori, aprendo un toaster
export const SHOW_ERROR_TOASTR = 'SHOW_ERROR_TOASTR';

// Recupera le lingue disponibili a sistema
export const GET_AVAILABLE_LANGS = 'GET_AVAILABLE_LANGS';

// Setta come completato il caricamento delle lingue disponibili
export const GET_AVAILABLE_LANGS_FINISHED = 'GET_AVAILABLE_LANGS_FINISHED';

// Setta la lingua di default
export const SET_DEFAULT_LANG = 'SET_DEFAULT_LANG';

// Recupera i Cluster
export const GET_CLUSTERS = 'GET_CLUSTERS';

// Setta come completato il caricamento dei cluster, e li salva nello Store applicativo
export const SET_CLUSTERS = 'SET_CLUSTERS';

// Salva l'url richiesto prima di eseguire il redirect alla pagina di login
export const SAVE_REDIRECT_URL = 'SAVE_REDIRECT_URL';

// Mostra il loader dell'applicazione
export const SHOW_APPLICATION_LOADER = 'SHOW_APPLICATION_LOADER';

// Nasconde il loader dell'applicazione
export const HIDE_APPLICATION_LOADER = 'HIDE_APPLICATION_LOADER';

// Attiva gli step nelle edizioni
export const ENABLE_INITIATIVE_STEPS = 'ENABLE_INITIATIVE_STEPS';

// Disabilita gli step nelle edizioni
export const DISABLE_INITIATIVE_STEPS = 'DISABLE_INITIATIVE_STEPS';

// Attiva gli step nelle riunioni
export const ENABLE_MEETING_STEPS = 'ENABLE_MEETING_STEPS';

// Disabilita gli step nelle riunioni
export const DISABLE_MEETING_STEPS = 'DISABLE_MEETING_STEPS';

// Cancella l'url di redirect dopo il login
export const REMOVE_REDIRECT_URL = 'REMOVE_REDIRECT_URL';

// Apre la sidebar di sinistra col menu principale
export const OPEN_MAIN_MENU_SIDEBAR = 'OPEN_MAIN_MENU_SIDEBAR';

// Chiude la sidebar di sinistra col menu principale
export const CLOSE_MAIN_MENU_SIDEBAR = 'CLOSE_MAIN_MENU_SIDEBAR';

// Apre la sidebar di destra col menu utente
export const OPEN_PROFILE_MENU_SIDEBAR = 'OPEN_PROFILE_MENU_SIDEBAR';

// Chiude la sidebar di destra col menu utente
export const CLOSE_PROFILE_MENU_SIDEBAR = 'CLOSE_PROFILE_MENU_SIDEBAR';

// Inizia il polling per il renew del token
export const START_RENEW_TOKEN_POLLING = 'START_RENEW_TOKEN_POLLING';

// Setta la lingua dell'applicazione
export const SET_APPLICATION_LANG = 'SET_APPLICATION_LANG';

// Cancella la lingua dell'applicazione
export const REMOVE_APPLICATION_LANG = 'REMOVE_APPLICATION_LANG';

// Setta la tab aperta
export const ACTIVATE_TAB = 'ACTIVATE_TAB';

export class SetCoreApplicationData implements Action {
    readonly type = SET_CORE_APPLICATION_DATA;

    // Payload dell'azione
    constructor(public payload: GlobalApplicationData) { }
}

export class SaveRedirectUrl implements Action {
    readonly type = SAVE_REDIRECT_URL;

    // Payload dell'azione, che è l'url richiesto
    constructor(public payload: string) { }
}

export class SetApplicationLang implements Action {
    readonly type = SET_APPLICATION_LANG;

    // Payload dell'azione, che contiene l'abbreviazione della lingua scelta (es 'it')
    constructor(public payload: string) { }
}

export class SetDefaultLang implements Action {
    readonly type = SET_DEFAULT_LANG;

    constructor(public payload: string) { }
}

export class ActivateTab implements Action {
    readonly type = ACTIVATE_TAB;

    constructor(public payload: Tabset) { }
}

export class SetClusters implements Action {
    readonly type = SET_CLUSTERS;

    constructor(public payload: Tag[]) { }
}

export class GetClusters implements Action {
    readonly type = GET_CLUSTERS;
}

export class ShowApplicationLoader implements Action {
    readonly type = SHOW_APPLICATION_LOADER;
}

export class HideApplicationLoader implements Action {
    readonly type = HIDE_APPLICATION_LOADER;
}

export class DisableInitiativeSteps implements Action {
    readonly type = DISABLE_INITIATIVE_STEPS;
}

export class EnableInitiativeSteps implements Action {
    readonly type = ENABLE_INITIATIVE_STEPS;
}

export class DisableMeetingSteps implements Action {
    readonly type = DISABLE_MEETING_STEPS;
}

export class EnableMeetingSteps implements Action {
    readonly type = ENABLE_MEETING_STEPS;
}

export class OpenMainMenuSidebar implements Action {
    readonly type = OPEN_MAIN_MENU_SIDEBAR;
}

export class CloseMainMenuSidebar implements Action {
    readonly type = CLOSE_MAIN_MENU_SIDEBAR;
}

export class OpenProfileMenuSidebar implements Action {
    readonly type = OPEN_PROFILE_MENU_SIDEBAR;
}

export class CloseProfileMenuSidebar implements Action {
    readonly type = CLOSE_PROFILE_MENU_SIDEBAR;
}

export class GetAvailableLangs implements Action {
    readonly type = GET_AVAILABLE_LANGS;
}

export class RemoveApplicationLang implements Action {
    readonly type = REMOVE_APPLICATION_LANG;
}

export class GetAvailableLangsFinished implements Action {
    readonly type = GET_AVAILABLE_LANGS_FINISHED;
}

export class StartRenewTokenPolling implements Action {
    readonly type = START_RENEW_TOKEN_POLLING;
}

export class RemoveCoreApplicationData implements Action {
    readonly type = REMOVE_CORE_APPLICATION_DATA;
}

export class RemoveRedirectUrl implements Action {
    readonly type = REMOVE_REDIRECT_URL;
}

export class ShowErrorToastr implements Action {
    readonly type = SHOW_ERROR_TOASTR;

    // Payload dell'azione, cioé il titolo e il messaggio del toaster, e le opzionali configurazioni del toaster
    constructor(public payload: { title: string, message: string, config: ToastrConfig }) { }
}

// Definisco un tipo custom per eseguire il bundle delle azioni in un singolo export
export type CoreActions = SetCoreApplicationData
    | ShowErrorToastr
    | GetAvailableLangs
    | GetAvailableLangsFinished
    | RemoveCoreApplicationData
    | SaveRedirectUrl
    | ShowApplicationLoader
    | OpenMainMenuSidebar
    | SetDefaultLang
    | CloseMainMenuSidebar
    | OpenProfileMenuSidebar
    | CloseProfileMenuSidebar
    | HideApplicationLoader
    | SetClusters
    | GetClusters
    | ActivateTab
    | StartRenewTokenPolling
    | SetApplicationLang
    | RemoveApplicationLang
    | DisableInitiativeSteps
    | EnableInitiativeSteps
    | DisableMeetingSteps
    | EnableMeetingSteps
    | RemoveRedirectUrl;