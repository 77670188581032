import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from '../../ngrx/app.reducers';
import { BaseSubscriberComponent } from '../../shared/components/base-subscriber.component';
import { Router } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { GlobalApplicationData } from 'src/app/shared/models/global-application-data.model';

@Component({
    selector: 'app-campagnaVaccinaleGenova',
    templateUrl: './campagnaVaccinaleGenova.component.html',
    styleUrls: ['./campagnaVaccinaleGenova.component.scss']
})
export class CampagnaVaccinaleGenovaComponent extends BaseSubscriberComponent {
    applicationContext: string;
    urlList = [
        { id: "af106c77-0140-45e4-a688-26cc5f53b029", title: "11/12/2020" }
    ]

    constructor(private router: Router,
        private store: Store<fromApp.AppState>) {
        super();
        this.store.select(fromApp.getGlobalApplicationData).pipe(
            map((applicationData: GlobalApplicationData) => {
                if (applicationData && applicationData.applicationContext) {
                    this.applicationContext = applicationData.applicationContext + '#/takers/courseSubscription/';
                }
            }),
            take(1)
        ).subscribe();
    }

    openUrl(id) {
        window.open(this.applicationContext + id, '_blank');
    }
}