/*
*  Componente che permette l'accesso all'utente welion (test sierologici) tramite form
*/

import { Component, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'welion-ts-local-login-form',
    templateUrl: './welion-ts-local-login-form.component.html',
    styleUrls: ['./welion-ts-local-login-form.component.scss']
})
export class WelionTSLocalLoginFormComponent implements OnInit {
    @Output() onLogin: EventEmitter<NgForm> = new EventEmitter();

    constructor() { }

    ngOnInit() {
    }

    // Effettua il login
    login(form: NgForm) {
        this.onLogin.emit(form);
    }
}
