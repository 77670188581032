/*
* Componente che crea un placeholder per la lista delle iniziative pendenti
*/

import { Component, Input } from '@angular/core';
@Component({
    selector: 'pending-initiatives-placeholder',
    templateUrl: './pending-initiatives-placeholder.component.html',
    styleUrls: ['./pending-initiatives-placeholder.component.scss']
})

export class PendingInitiativesPlaceholderComponent {
    @Input() containerClass: string;
    constructor() {
    }
}