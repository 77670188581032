/*
 * Guard per il componente di Login. Il servizio verifica dallo State, unica fonte di verità, se l'utente è correttamente autenticato e, in questo caso,
 * salta la procedura di Login andando nella pagina di default, l'home page.
*/

import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { take, map, tap, filter, switchMap } from 'rxjs/operators';

// Import dello State dell'applicativo
import * as fromApp from '../../ngrx/app.reducers';
import { onceIfNotEmpty } from 'src/app/ngrx/util';
import { authControl } from '../models/global-application-data.model';
import { UrlService } from './url.service';

@Injectable()
export class HomeGuard implements CanActivate {
    meetingUpUrl: any;
    constructor(private store: Store<fromApp.AppState>, private route: ActivatedRoute, private router: Router, private urlService: UrlService) {
    }



    // Ritorna un Observable che, risolvendo alla fine un boolean, possiamo mapparlo
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.store.select(fromApp.isAuthenticated)
            .pipe(
                filter((isAuthenticated) => isAuthenticated),
                switchMap(() => {
                    return this.store.select(fromApp.getLoggedUser);
                }),
                onceIfNotEmpty(),
                map(loggedUser => {
                    let authObject: any = {};
                    authObject = authControl(loggedUser && loggedUser.auths);
                    if (authObject.isAdmin) {
                        return true;
                    } else if (authObject.isManager && !authObject.isPerformance) {
                        //    this.meetingUpUrl = this.urlService.getMeetingUpApplicationUrl();
                        //    window.location.href = this.meetingUpUrl.url + "/meetingup/#/meetings";
                        this.router.navigate(['/meetings']);
                    }
                    else if (!authObject.isManager && authObject.isPerformance) {
                        //this.router.navigate(['/meetings']);
                    }
                    else if (authObject.isManager && authObject.isPerformance) {
                        this.router.navigate(['/landingPerformanceManager']);
                    }
                    return false;
                })
            )
    }
}