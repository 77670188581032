/*
 * Componente per l'errore 404, pagina richiesta non trovata
*/

import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-page-not-authorized',
    templateUrl: './page-not-authorized.component.html',
    styleUrls: ['./page-not-authorized.component.scss']
})
export class PageNotAuthorizedComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
