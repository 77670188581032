<div [hidden]="!isLoaded" class="cursor-pointer" (click)="changeValue('3')">
    <div class="card-image" fxLayoutFill>
        <img src="assets/img/test_card_3.jpeg" (load)="imgRendered();"
            alt="{{'generic.DELEGATE'|translate}} {{'generic.SUPPLIER'|translate}}"
            title="{{'generic.DELEGATE'|translate}} {{'generic.SUPPLIER'|translate}}">
        <div class="card-content">
            <h1 class="card-header">
                <span translate="generic.DELEGATE"></span><br>
                <span translate="generic.SUPPLIER"></span>
            </h1>
        </div>
        <div class="card-description">
            <p translate="createInitiativeCard.smart.FOOTER"></p>
        </div>
    </div>
    <div class="md-radio text-center">
        <input type="radio" role="radio" name="options" [ngModel]="selectedCreationPossibility.options" value="3">
        <label></label>
    </div>
</div>
<div [hidden]="isLoaded">
    <create-initiative-card-placeholder></create-initiative-card-placeholder>
</div>