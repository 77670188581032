<!-- Modale di conferma -->
<ngx-smart-modal #confirmModal identifier="confirmModal"
    customClass="nsm-dialog-animation-fade full-width nsm-centered confirm-modal">
    <div *ngIf="confirmModal.hasData()">
        <!-- Titolo -->
        <div fxFlexFill fxLayout="row" fxLayoutAlign="start center" class="modal-title-container">
            <p class="modal-title">{{confirmModal.getData().modalTitle}}</p>
        </div>
        <!-- Testo centrale della mdoale-->
        <div fxFlexFill fxLayout="row" fxLayoutAlign="start center" class="modal-body-container">
            <p [innerHTML]="confirmModal.getData().modalBody | safeHtml: 'html'"></p>
        </div>
        <!-- Azioni-->
        <div *ngIf="confirmModal.getData().confirmSecondBtnLabel || confirmModal.getData().cancelBtnLabel" fxFlexFill
            fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="space-between center"
            class="modal-actions-container">
            <!-- Annulla -->
            <button *ngIf="confirmModal.getData().cancelBtnLabel"
                (click)="confirmModal.close(); confirmModal.getData().dismissAction(confirmModal.getData().componentRef)"
                class="btn-secondary-1 large to-uppercase"
                type="button">{{confirmModal.getData().cancelBtnLabel}}</button>
            <!-- Conferma -->
            <button (click)="confirmModal.close(); emitConfirmation(confirmModal.getData());"
                [disabled]="disableConfirm"
                class="btn-primary-1 large to-uppercase"
                type="button">{{confirmModal.getData().confirmBtnLabel}}</button>
            <!-- Secondo tasto di Conferma (opzionale) -->
            <button *ngIf="confirmModal.getData().confirmSecondBtnLabel"
                (click)="confirmModal.close(); emitSecondConfirmation(confirmModal.getData());"
                class="btn-primary-1 large to-uppercase"
                type="button">{{confirmModal.getData().confirmSecondBtnLabel}}</button>
        </div>

        <!-- Blocco se c'è solo il pulsante di conferma -->
        <div *ngIf="!confirmModal.getData().confirmSecondBtnLabel && !confirmModal.getData().cancelBtnLabel && confirmModal.getData().confirmBtnLabel"
            fxFlexFill fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="end center"
            class="modal-actions-container">
            <button (click)="confirmModal.close(); emitConfirmation(confirmModal.getData());"
                [disabled]="disableConfirm"
                class="btn-primary-1 large to-uppercase"
                type="button">{{confirmModal.getData().confirmBtnLabel}}</button>
        </div>

    </div>
</ngx-smart-modal>
