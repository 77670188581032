/*
*  Routing dell'applicazione
*/

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { EnelWelfareSupportLoginComponent } from './auth/enel-welfare-support-login/enel-welfare-support-login.component';
import { EnelWelfareTeacherLoginComponent } from './auth/enel-welfare-teacher-login/enel-welfare-teacher-login.component';
import { EnelLoginSamlErrorComponent } from './auth/login/enel-login-saml-error.component';
import { EnelLogoutSsoCompletedComponent } from './auth/login/enel-logout-sso-completed.component';
import { LocalLoginComponent } from './auth/login/local-login.component';
import { LoginSamlErrorComponent } from './auth/login/login-saml-error.component';
import { LoginComponent } from './auth/login/login.component';
import { LogoutSsoCompletedComponent } from './auth/login/logout-sso-completed.component';
import { OnlineMeetingCustomerAutomaticLoginComponent } from './auth/online-meeting-customer-automatic-login/online-meeting-customer-automatic-login.component';
import { OnlineMeetingSupportLoginComponent } from './auth/online-meeting-support-login/online-meeting-support-login.component';
import { OnlineMeetingTeacherLoginComponent } from './auth/online-meeting-teacher-login/online-meeting-teacher-login.component';
import { OnlineMeetingUserLoginComponent } from './auth/online-meeting-user-login/online-meeting-user-login.component';
import { OwnershipLocalLoginComponent } from './auth/ownership-local-login/ownership-local-login.component';
import { OwnershipLoginComponent } from './auth/ownership-login/ownership-login.component';
import { PublicUsersLoginComponent } from './auth/public-users-login/public-users-login.component';
import { AuthGuard } from './auth/services/auth-guard.service';
import { SupplierLoginComponent } from './auth/supplier-login/supplier-login.component';
import { TakerLocalLoginComponent } from './auth/taker-local-login/taker-local-login.component';
import { TakerLoginComponent } from './auth/taker-login/taker-login.component';
import { UserMeetingLocalLoginComponent } from './auth/user-meeting-local-login/user-meeting-local-login.component';
import { UserMeetingLoginComponent } from './auth/user-meeting-login/user-meeting-login.component';
import { WelfareEnelLocalLoginComponent } from './auth/welfare-enel-local-login/welfare-enel-local-login.component';
import { WelfareEnelLoginComponent } from './auth/welfare-enel-login/welfare-enel-login.component';
import { WelionTSLocalLoginComponent } from './auth/welion-ts-local-login/welion-ts-local-login.component';
import { WelionTSLoginComponent } from './auth/welion-ts-login/welion-ts-login.component';
import { WelionWelfareSupportLoginComponent } from './auth/welion-welfare-support-login/welion-welfare-support-login.component';
import { WelionWelfareTeacherLoginComponent } from './auth/welion-welfare-teacher-login/welion-welfare-teacher-login.component';
import { WelionWelfareUsersLoginComponent } from './auth/welion-welfare-users-login/welion-welfare-users-login.component';
import { CompatibilityErrorComponent } from './core/compatibility-error/compatibility-error.component';
import { ErrorPageComponent } from './core/error/error-page.component';
import { HomeComponent } from './core/home/home.component';
import { LandingPerformanceManagerComponent } from './core/landing-performance-manager/landing-performance-manager.component';
import { PageNotFoundComponent } from './core/page-not-found/page-not-found.component';
import { ProfileComponent } from './profile/profile.component';
import { CompatibilityGuard } from './shared/services/compatibility-guard.service';
import { HomeGuard } from './shared/services/home-guard.service';
import { LinguisticManagementGuard } from './shared/services/linguistic-management-guard.service';
import { WomenInsuranceNetworkGuard } from './shared/services/women-insurance-network-guard.service';
import { LoginGuard } from './shared/services/login-guard.service';
import { EuropeAssistanceLoginComponent } from './auth/europe-assistance-login/europe-assistance-login.component';
import { EuropeAssistanceLocalLoginComponent } from './auth/europe-assistance-local-login/europe-assistance-local-login.component';
import { CampagnaVaccinaleComponent } from './publicRoutes/campagnaVaccinale/campagnaVaccinale.component';
import { CampagnaVaccinaleMoglianoComponent } from './publicRoutes/campagnaVaccinaleMogliano/campagnaVaccinaleMogliano.component';
import { CampagnaVaccinaleTorinoComponent } from './publicRoutes/campagnaVaccinaleTorino/campagnaVaccinaleTorino.component';
import { CampagnaVaccinaleRomaComponent } from './publicRoutes/campagnaVaccinaleRoma/campagnaVaccinaleRoma.component';
import { CampagnaVaccinaleTriesteComponent } from './publicRoutes/campagnaVaccinaleTrieste/campagnaVaccinaleTrieste.component';
import { CampagnaVaccinaleGenovaComponent } from './publicRoutes/campagnaVaccinaleGenova/campagnaVaccinaleGenova.component';
import { CampagnaVaccinaleNapoliComponent } from './publicRoutes/campagnaVaccinaleNapoli/campagnaVaccinaleNapoli.component';
import { PageNotAuthorizedComponent } from './core/page-not-authorized/page-not-authorized.component';
import { TenantGuard } from './shared/services/tenant-guard.service';
import { SmartEnergySubscriptionLoginComponent } from './auth/smart-energy-subscription-login/smart-energy-subscription-login.component';
import { SmartEnergySubscriptionLocalLoginComponent } from './auth/smart-energy-subscription-local-login/smart-energy-subscription-local-login.component';
import { ReportDownloadComponent } from './dashboard/report-download/report-download.component';
import { NudgingLocalLoginComponent } from './auth/login/nudging-local-login.component';
import { MedexLoginComponent } from './auth/medex-login/medex-login.component';
import { HeritageLoginComponent } from './auth/heritage-login/heritage-login.component';
import { DeiLoginComponent } from './auth/dei-login/dei-login.component';
import { ImportFinanincingNoticesComponent } from './importFinanincingNotices/importFinanincingNotices.component';


// Lista delle route
const appRoutes: Routes = [
    { path: 'home', component: HomeComponent, canActivate: [AuthGuard, TenantGuard, CompatibilityGuard, HomeGuard] },
    { path: 'landingPerformanceManager', component: LandingPerformanceManagerComponent, canActivate: [AuthGuard, TenantGuard, CompatibilityGuard] },
    { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard, TenantGuard, CompatibilityGuard] },
    { path: 'localLogin', component: LocalLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
    { path: 'login', component: LoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
    { path: 'smartEnergySubscriptionLogin', component: SmartEnergySubscriptionLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
    { path: 'smartEnergySubscriptionLocalLogin', component: SmartEnergySubscriptionLocalLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
    { path: 'loginSamlError', component: LoginSamlErrorComponent, canActivate: [LoginGuard, CompatibilityGuard] },
    { path: 'logoutSsoCompleted', component: LogoutSsoCompletedComponent, canActivate: [LoginGuard, CompatibilityGuard] },
    { path: 'enelLoginSamlError', component: EnelLoginSamlErrorComponent, canActivate: [LoginGuard, CompatibilityGuard] },
    { path: 'enelLogoutSsoCompleted', component: EnelLogoutSsoCompletedComponent, canActivate: [LoginGuard, CompatibilityGuard] },
    { path: 'supplierLogin', component: SupplierLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
    { path: 'medexLogin', component: MedexLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
    { path: 'takerLogin', component: TakerLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
    { path: 'takerLocalLogin', component: TakerLocalLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
    { path: 'nudgingLocalLogin', component: NudgingLocalLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
    { path: 'ownershipLogin', component: OwnershipLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
    { path: 'ownershipLocalLogin', component: OwnershipLocalLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
    { path: 'ownershipUsers', loadChildren: './ownership/ownership.module#OwnershipModule', canActivate: [CompatibilityGuard] },
    { path: 'welfareEnelLocalLogin', component: WelfareEnelLocalLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
    { path: 'welfareEnelLogin', component: WelfareEnelLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
    { path: 'enelWelfareTeacherLogin', component: EnelWelfareTeacherLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
    { path: 'enelWelfareSupportLogin', component: EnelWelfareSupportLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
    { path: 'myWelfareEnel', loadChildren: './myWelfareEnel/my-welfare-enel.module#MyWelfareEnelModule', canActivate: [CompatibilityGuard] },
    { path: 'welionTSLogin', component: WelionTSLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
    { path: 'welionTSLocalLogin', component: WelionTSLocalLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
    { path: 'europeAssistanceLogin', component: EuropeAssistanceLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
    { path: 'europeAssistanceLocalLogin', component: EuropeAssistanceLocalLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
    { path: 'europeAssistanceUsers/:locationId', component: EuropeAssistanceLocalLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
    { path: 'onlineMeetingUserLogin', component: OnlineMeetingUserLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
    { path: 'publicUsersLogin', component: PublicUsersLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
    { path: 'onlineMeetingCustomerAutomaticLogin', component: OnlineMeetingCustomerAutomaticLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
    { path: 'demoUsersPublicLogin', component: PublicUsersLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
    { path: 'campagnaVaccinale', component: CampagnaVaccinaleComponent, canActivate: [CompatibilityGuard] },
    { path: 'campagnaVaccinaleMogliano', component: CampagnaVaccinaleMoglianoComponent, canActivate: [CompatibilityGuard] },
    { path: 'campagnaVaccinaleTorino', component: CampagnaVaccinaleTorinoComponent, canActivate: [CompatibilityGuard] },
    { path: 'campagnaVaccinaleRoma', component: CampagnaVaccinaleRomaComponent, canActivate: [CompatibilityGuard] },
    { path: 'campagnaVaccinaleTrieste', component: CampagnaVaccinaleTriesteComponent, canActivate: [CompatibilityGuard] },
    { path: 'campagnaVaccinaleGenova', component: CampagnaVaccinaleGenovaComponent, canActivate: [CompatibilityGuard] },
    { path: 'campagnaVaccinaleNapoli', component: CampagnaVaccinaleNapoliComponent, canActivate: [CompatibilityGuard] },
    { path: 'recoveryUserPassword/:userId/:token', component: PublicUsersLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
    { path: 'emailConfirmation/:userId/:token', component: PublicUsersLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
    { path: 'onlineMeetingTeacherLogin', component: OnlineMeetingTeacherLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
    { path: 'onlineMeetingSupportLogin', component: OnlineMeetingSupportLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
    { path: 'meetingUserLocalLogin', component: UserMeetingLocalLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
    { path: 'meetingUserLogin', component: UserMeetingLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
    { path: 'welionWelfareUsersLogin', component: WelionWelfareUsersLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
    { path: 'recoveryWelionWelfareUserPassword/:userId/:token', component: WelionWelfareUsersLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
    { path: 'emailConfirmationWelionWelfareUser/:userId/:token', component: WelionWelfareUsersLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
    { path: 'welionWelfare', loadChildren: './welionWelfare/welion-welfare.module#WelionWelfareModule', canActivate: [CompatibilityGuard] },
    { path: 'welionWelfareTeacherLogin', component: WelionWelfareTeacherLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
    { path: 'welionWelfareSupportLogin', component: WelionWelfareSupportLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
    { path: 'templates', loadChildren: './templates/templates.module#TemplatesModule', canActivate: [AuthGuard, TenantGuard, CompatibilityGuard] },
    { path: 'initiatives', loadChildren: './initiatives/initiatives.module#InitiativesModule', canActivate: [AuthGuard, TenantGuard, CompatibilityGuard] },
    { path: 'suppliers', loadChildren: './suppliers/suppliers.module#SuppliersModule', canActivate: [AuthGuard, TenantGuard, CompatibilityGuard] },
    { path: 'fundingSupplier', loadChildren: './fundingSupplier/fundingSupplier.module#FundingSupplierModule', canActivate: [AuthGuard, TenantGuard, CompatibilityGuard] },
    { path: 'meetings', loadChildren: './meetings/meetings.module#MeetingsModule', canActivate: [AuthGuard, TenantGuard, CompatibilityGuard] },
    { path: 'performanceManagement', loadChildren: './performanceManagement/performance-management.module#PerformanceManagementModule', canActivate: [AuthGuard, TenantGuard, CompatibilityGuard] },
    { path: 'wishlistManagement', loadChildren: './wishlistManagement/wishlist-management.module#WishlistManagementModule', canActivate: [AuthGuard, TenantGuard, CompatibilityGuard] },
    { path: 'locations', loadChildren: './locations/locations.module#LocationsModule', canActivate: [AuthGuard, TenantGuard, CompatibilityGuard] },
    { path: 'nudging', loadChildren: './nudging/nudging.module#NudgingModule' },
    { path: 'specialProjects', loadChildren: './specialProjects/specialProjects.module#SpecialProjectsModule', canActivate: [AuthGuard, TenantGuard, CompatibilityGuard] },
    { path: 'reportTags', loadChildren: './reportTags/reportTags.module#ReportTagsModule', canActivate: [AuthGuard, TenantGuard, CompatibilityGuard] },
    { path: 'learningUpNotifications', loadChildren: './learningUpNotifications/learningUpNotifications.module#LearningUpNotificationsModule', canActivate: [AuthGuard, TenantGuard, CompatibilityGuard] },
    { path: 'linguisticManagement', loadChildren: './linguisticManagement/linguisticManagement.module#LinguisticManagementModule', canActivate: [AuthGuard, TenantGuard, CompatibilityGuard, LinguisticManagementGuard] },
    { path: 'womenInsuranceNetwork', loadChildren: './womenInsuranceNetwork/womenInsuranceNetwork.module#WomenInsuranceNetworkModule', canActivate: [AuthGuard, TenantGuard, CompatibilityGuard, WomenInsuranceNetworkGuard] },
    //{ path: 'usersAdmin', loadChildren: './usersAdmin/usersAdmin.module#UsersAdmiModule', canActivate: [AuthGuard, TenantGuard, CompatibilityGuard] },
    { path: 'dashboard-report', component: ReportDownloadComponent },
    { path: 'dashboard', loadChildren: './dashboard/dashboard.module#DashboardModule', canActivate: [AuthGuard, TenantGuard, CompatibilityGuard/*, DashboardGuard*/] },
    { path: 'groups', loadChildren: './groups/groups.module#GroupsModule', canActivate: [AuthGuard, TenantGuard, CompatibilityGuard, /*DashboardGuard*/] },
    // { path: 'takers', loadChildren: './takers/takers.module#TakersModule', canActivate: [TakerGuard, AuthGuard, TenantGuard, CompatibilityGuard] },
    { path: 'takers', loadChildren: './takers/takers.module#TakersModule', canActivate: [CompatibilityGuard] },
    { path: 'smartEnergyTakers', loadChildren: './smartEnergyTakers/smartEnergyTakers.module#SmartEnergyTakersModule', canActivate: [CompatibilityGuard] },
    { path: 'medexTakers', loadChildren: './medexTakers/medexTakers.module#MedexTakersModule', canActivate: [AuthGuard, TenantGuard, CompatibilityGuard] },
    { path: 'welionsUsersTS', loadChildren: './welionsUsersTS/welions-users-ts.module#WelionsUsersTSModule', canActivate: [CompatibilityGuard] },
    { path: 'onlineMeeting', loadChildren: './onlineMeeting/online-meeting.module#OnlineMeetingModule', canActivate: [CompatibilityGuard] },
    { path: 'compatibilityError', component: CompatibilityErrorComponent },
    { path: 'meetingUsers', loadChildren: './usersMeeting/meeting-users.module#MeetingUsersModule', canActivate: [AuthGuard, TenantGuard, CompatibilityGuard] },
    { path: 'europeAssistanceUsers', loadChildren: './europeAssistanceUsers/europe-assistance-users.module#EuropeAssistanceUsersModule', canActivate: [AuthGuard, TenantGuard, CompatibilityGuard] },
    { path: 'heritageLogin', component: HeritageLoginComponent, canActivate: [CompatibilityGuard] },
    { path: 'emailConfirmationHeritage/:userId/:token', component: HeritageLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
    { path: 'heritage', loadChildren: './heritage/heritage.module#HeritageModule', canActivate: [CompatibilityGuard] },
    { path: 'dei', loadChildren: './dei/dei.module#DeiModule',canActivate: [CompatibilityGuard]},
    { path: 'emailConfirmationDei/:userId/:token', component: DeiLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
    { path: 'deiLogin', component: DeiLoginComponent, canActivate: [CompatibilityGuard] },
    { path: 'importFinancingNotices', component: ImportFinanincingNoticesComponent, canActivate: [AuthGuard, CompatibilityGuard] },
    { path: 'compatibilityError', component: CompatibilityErrorComponent },
    { path: 'error', component: ErrorPageComponent },
    { path: '403', component: PageNotAuthorizedComponent },
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: '**', component: PageNotFoundComponent }
];

@NgModule({
    imports: [
        // Se mi trovassi nella pagina '/home' e volessi andare nella pagina '/profile', tramite Angular funziona corettamente (quindi ad esempio tramite il metodo navigate()).
        // Se invece scrivessi l'url a mano, l'applicazione si ricaricherebbe completamente, perdendo le informazioni salvate con Rxjs, tra cui quelle dell'utente loggato.
        // Per ovviare a questo comportamento, è necessario utilizzare l'hash nell'url: .../#/home, aggiunto tramite il parametro useHash: true
        RouterModule.forRoot(appRoutes, { useHash: true, preloadingStrategy: PreloadAllModules })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }