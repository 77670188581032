<table role="grid" fxFlexFill class="theme-primary-table">
    <tbody>
        <tr>
            <th tabindex="-1" translate="generic.INITIATIVE" style="width: 35%"></th>
            <th tabindex="-1" translate="generic.NEXT_EDITION_DATE"></th>
            <th tabindex="-1" translate="generic.COURSE_ATTRIBUTES"></th>
            <th tabindex="-1" translate="generic.TYPE"></th>
            <th tabindex="-1" translate="generic.CREATION_DATE"></th>
            <th tabindex="-1" translate="generic.ACTIONS"></th>
        </tr>
        <tr *ngFor="let item of lastInitiatives;index as i">
            <td tabindex="-1">{{ item.title }}</td>
            <td tabindex="-1">{{ item && item.fastData && item.fastData.nextEditionDate ? (item.fastData.nextEditionDate
                | amLocale:'it' | amUtc | amDateFormat: dateFormat) : " -- " }}</td>
            <td tabindex="-1">
                <div fxLayout="row">
                    <p class="new-boxes-initiatives" hide-delay="50"
                        attr.aria-label="{{'newDescriptionTable.LS_PRESENT' | translate}}"
                        tooltip="{{'newDescriptionTable.LS_PRESENT' | translate}}"
                        *ngIf="item && item.fastData && item.fastData.isIncludedInCatalog">LS</p>
                    <p tooltip="{{'newDescriptionTable.WL_PRESENT' | translate}}" hide-delay="50"
                        attr.aria-label="{{'newDescriptionTable.WL_PRESENT' | translate}}" class="new-boxes-initiatives"
                        *ngIf="item && item.fastData && item.fastData.isWishListEnabled">WL</p>
                    <p tooltip="{{'newDescriptionTable.IDP_PRESENT' | translate}}" hide-delay="50"
                        attr.aria-label="{{'newDescriptionTable.IDP_PRESENT' | translate}}"
                        class="new-boxes-initiatives" *ngIf="item && item.fastData && item.fastData.isIDPEnabled">IDP
                    </p>
                    <p tooltip="{{'newDescriptionTable.FF_PRESENT' | translate}}" hide-delay="50"
                        attr.aria-label="{{'newDescriptionTable.FF_PRESENT' | translate}}" class="new-boxes-initiatives"
                        *ngIf="item && item.fastData && item.fastData.isFunded">FIN</p>
                </div>
            </td>
            <td tabindex="-1">{{ item.itemTypeLabel }}</td>
            <td tabindex="-1">{{ item.creationDate | amLocale:'it' | amUtc | amDateFormat: dateFormat }}</td>
            <td class="relative-position accessibilityTable">
                <!-- Tolto componente dropdown-component per via dell' accessibilità -->
                <button tabindex="-1" (click)="toggleMenu(i, item)" attr.aria-haspopup="true"
                    attr.aria-controls="{{ 'menu'+ i }}">
                    <svg-icon src="assets/img/gear-meatball-menu.svg"></svg-icon>
                </button>
                <ul role="menu" #menu style="display:none">
                    <li (click)="onEdit(item)" class="item" translate="generic.EDIT"
                        *ngIf="currentStatus && currentStatus.attributeValue !== 'CLOSED'" role="menuitem"></li>
                    <li (click)="onDelete(item)" class="item" translate="generic.REMOVE" role="menuitem"></li>
                    <li (click)="openCloseInitiativeModal()" *ngIf="closeInitiativeEnabled" class="item"
                        translate="generic.ARCHIVE" role="menuitem"></li>
                </ul>
            </td>
        </tr>
    </tbody>
</table>