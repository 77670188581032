/*
* Componente che crea la card della Landing Performance Manager
*/

import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent } from '../../shared/components/base-component.component';
import { RequiredAuth } from 'src/cm2-commonclasses';
import { RedirectService } from 'src/app/shared/services/redirect.service';

@Component({
    selector: 'landing-performance-manager-card',
    templateUrl: './landing-performance-manager-card.component.html',
    styleUrls: ["./landing-performance-manager-card.component.scss"]
})
export class LandingPerformanceManagerCardComponent extends BaseComponent implements OnInit, OnDestroy {
    @Input() auth: string;
    @Input() numberOfCards: number
    @Input() index: number

    backgroundStyleSelected: string;
    backgroundStyles: string[];

    constructor(
        private redirectService: RedirectService
    ) {

        super();

    }

    ngOnInit() {

        // Immagini background a random se ci sono piu di 3 cards
        this.backgroundStyles = [
            "linear-gradient(rgba(195, 47, 45, 0.9), rgba(195, 47, 45, 0.9)), url('../../../assets/img/test_card_1.jpeg')",
            "linear-gradient(rgba(133,23,20,.9), rgba(133,23,20,.9)), url('../../../assets/img/test_card_2.jpeg')",
            "linear-gradient(rgba(241,100,74,.9), rgba(241,100,74,.9)), url('../../../assets/img/test_card_3.jpeg')"]
        if (this.numberOfCards > 3) {
            let randomNumber = Math.floor(Math.random() * this.backgroundStyles.length);
            this.backgroundStyleSelected = this.backgroundStyles[randomNumber];
        } else {
            this.backgroundStyleSelected = this.backgroundStyles[this.index];
        }


    }

    ngOnDestroy() {

    }

    // Funzione che ritorna la funzione per il redirect dei pulsanti
    redirectFunction(y: string) {
        switch (y) {
            case RequiredAuth.COURSEMANAGER_MANAGE_MEETING_MANAGER:
                this.redirectService.goToMeetings();
                break;
            case RequiredAuth.COURSEMANAGER_MANAGE_PERFORMANCE:
                this.redirectService.goToPerformanceManagement();
                break;
        }
    }
}