/*
*  Componente che gestisce i GROUPED_REPORT_TAG
*/

import { Component, OnInit, OnDestroy, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import * as fromApp from '../ngrx/app.reducers';
import { TranslateService } from '@ngx-translate/core';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { ItemsService } from '../core/services/items.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
    selector: 'app-import-financing-notices',
    templateUrl: './importFinanincingNotices.component.html',
    styleUrls: ['./importFinanincingNotices.component.scss']
})
export class ImportFinanincingNoticesComponent implements OnInit, OnDestroy {
    result$: Subscription;
    applicationLang: string;
    importFileTmp: {
        file: FileItem,
        uploader: FileUploader
    } = {
            file: null,
            uploader: new FileUploader({ url: null })
        }
    isLoadingImportFile: boolean;

    constructor(private store: Store<fromApp.AppState>,
        private translate: TranslateService,
        private itemService: ItemsService,
        private cdRef: ChangeDetectorRef,
        private toastr: ToastrService,
        private ngxSmartModalService: NgxSmartModalService,
    ) {
        // Recupero la lingua settata dallo store applicativo, per settare la lingua in cui eseguire le traduzioni
        let applicationLang$: Observable<string> = this.store.select(fromApp.getApplicationLang);
        this.result$ = applicationLang$.subscribe(
            (applicationLang) => {
                // Setto la lingua
                if (applicationLang) {
                    this.applicationLang = applicationLang;
                    this.translate.use(applicationLang);
                }
            });
    }

    ngOnInit() {
        this.resetFileData();
    }

    // Esegue l'upload di una nuova Survey
    uploadImportFinancingNotices() {
        this.isLoadingImportFile = true;
        // Preparo il file da mandare al server
        let uploadObj = {
            file: this.importFileTmp.file
        };
        this.itemService.uploadImportFinancingNotices(uploadObj)
            .subscribe(
                (data: any) => {
                    if (data) {
                        if (data.body) {
                            // Mostro l'errore
                            if (data.body.error) {
                                this.isLoadingImportFile = false;
                                // Necessario per eseguire l'update della variabile anche nel dom
                                this.cdRef.detectChanges();
                                this.toastr.error(this.translate.instant('errors.' + data.body.error));
                            } else if (data.body.response) {
                                let results = data.body.response;
                                // if (results && (results.modulesNotFound || results.invalidRows || results.errors))
                                this.openResultModal(results);
                                // Pulisco i dati della survey
                                this.resetFileData();
                            }
                        }
                    }
                },
                (err) => {
                    this.isLoadingImportFile = false;
                    // Necessario per eseguire l'update della variabile anche nel dom
                    this.cdRef.detectChanges();
                    // MMTODO prova
                    this.toastr.error(this.translate.instant('errors.' + err.message));
                });
    }

    openResultModal(results?: any) {
        const confimation = new EventEmitter<any>();
        // test
        /*results = {
            "updatedModules": 5,
            "errors": 3,
            "invalidRows": [
                "D037",
                "D037",
                "D037",
            ],
            "modulesNotFound": [
                "D037",
                "417",
                "418",
                "418",
                "418",
                "418",
                "418",
                "418",
                "418",
            ]
        }*/
        let modalDescr = "";
        if (results) {
            if (results.updatedModules) {
                modalDescr += '<b>' + results.updatedModules + '</b>' + this.translate.instant("generic.IMPORT_UPDATE_MODULES") + '<br><br>';
            }
            if (results.errors) {
                modalDescr += this.translate.instant("generic.IMPORT_ERRORS_GENERATED") + '<b>' + results.errors + '</b> ' + this.translate.instant("generic.ERRORS") + '<br><br>';
            }
            if (results.invalidRows && results.invalidRows.length) {
                modalDescr += this.translate.instant("generic.IMPORT_ROW_ERRORS") + results.invalidRows.toString() + '<br><br>';
            }
            if (results.modulesNotFound && results.modulesNotFound.length) {
                modalDescr += this.translate.instant("generic.IMPORT_MODULE_ERRORS");
                for (let i = 0; i < results.modulesNotFound.length; i++) {
                    modalDescr += '- ' + results.modulesNotFound[i] + '<br>';
                }
            }
        }
        // Prima di aprire la modale, setto le traduzioni e i metodi da agganciare le funzioni
        const confirmModalData: Object = {
            modalTitle: this.translate.instant('generic.IMPORT_FINISHED'),
            modalBody: modalDescr,
            confirmBtnLabel: this.translate.instant('generic.CONFIRM'),
            confirmation: confimation,
            componentRef: this
        };
        // Setto i dati
        this.ngxSmartModalService.setModalData(confirmModalData, 'confirmModal');
        // Recupero un'istanza della modale
        let confirmModalRef = this.ngxSmartModalService.getModal('confirmModal');
        confirmModalRef.setData(confirmModalData, true);
        confimation.subscribe(() => { });
        // Apro la modale
        confirmModalRef.open();
    }

    // Ripristina il file della proposta temporanea ai valori di default
    resetFileData(skipListener?: boolean) {
        this.importFileTmp.file = null;
        this.importFileTmp.uploader = null;
        this.importFileTmp.uploader = new FileUploader({ url: null });
        this.linkNewfileListeners();
    }

    // Collega gli eventi al file survey da caricare
    linkNewfileListeners() {
        this.importFileTmp.uploader.onAfterAddingFile = (file: FileItem) => {
            this.importFileTmp.file = file;
        };
    }

    downloadTrack() {
        window.open('/assets/documents/FBA_template.xlsx');
    }

    ngOnDestroy(): void {
        if (this.result$) {
            this.result$.unsubscribe();
        }
    }
}
