import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from '../../ngrx/app.reducers';
import { BaseSubscriberComponent } from '../../shared/components/base-subscriber.component';
import { Router } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { GlobalApplicationData } from 'src/app/shared/models/global-application-data.model';

@Component({
    selector: 'app-campagnaVaccinale',
    templateUrl: './campagnaVaccinale.component.html',
    styleUrls: ['./campagnaVaccinale.component.scss']
})
export class CampagnaVaccinaleComponent extends BaseSubscriberComponent {
    applicationContext: string;
    urlList = [
      { id: '363d4112-fa42-4f2a-b851-28f0e2e8f8b4', title: "04/11/2021" },
      { id: '5fd3ead0-317a-4201-b09c-edf5d32b463a', title: "09/11/2021" },
      { id: 'ce713b0d-820d-4a8a-a9f7-5adc57cf325f', title: "11/11/2021" },
      { id: '710f9b96-7c7c-49e6-ac49-cc17d133a49a', title: "18/11/2021" },
    ]

    constructor(private router: Router,
        private store: Store<fromApp.AppState>) {
        super();
        this.store.select(fromApp.getGlobalApplicationData).pipe(
            map((applicationData: GlobalApplicationData) => {
                if (applicationData && applicationData.applicationContext) {
                    this.applicationContext = applicationData.applicationContext + '#/takers/courseSubscription/';
                }
            }),
            take(1)
        ).subscribe();
    }

    openUrl(id) {
        window.open(this.applicationContext + id, '_blank');
    }
}