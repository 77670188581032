/*
 * Reducer per l'autenticazione, triggherate ogniqualvolta viene eseguito il dispatch di un'action, per l'update dello state
 *
*/

import * as AuthActions from './auth.actions';

export interface AuthState {
    token: string;
    authenticated: boolean;
    isSupplier: boolean;
    isFundingSupplier: boolean;
    isTaker: boolean;
    isSmartEnergyTaker: boolean;
    isWelionTs: boolean;
    isOwnership: boolean;
    isWelfareEnel: boolean;
    isOnlineMeetingUser: boolean;
    isPublicMeetingUser: boolean;
    isWelionWelfarePublicMeetingUser: boolean;
    isWelionWelfareTeacher?: boolean;
    isWelionWelfareSupport?: boolean;
    isEnelWelfareTeacher?: boolean;
    isEnelWelfareSupport?: boolean;
    isOnlineMeetingTeacher: boolean;
    isOnlineMeetingSupport: boolean;
    isManager: boolean;
    isAdmin: boolean;
    isMeetingUser: boolean;
    isPerformance: boolean;
    isEuropeAssistance?: boolean;
    isNudgingUser?: boolean;
    isHeritageTaker: boolean;
    isDeiTaker: boolean;
}

// State iniziale
const initialState: AuthState = {
    token: null,
    authenticated: false,
    isSupplier: false,
    isTaker: false,
    isSmartEnergyTaker: false,
    isWelionTs: false,
    isOwnership: false,
    isWelfareEnel: false,
    isOnlineMeetingUser: false,
    isPublicMeetingUser: false,
    isWelionWelfarePublicMeetingUser: false,
    isOnlineMeetingTeacher: false,
    isOnlineMeetingSupport: false,
    isWelionWelfareTeacher: false,
    isWelionWelfareSupport: false,
    isEnelWelfareTeacher: false,
    isEnelWelfareSupport: false,
    isManager: false,
    isAdmin: false,
    isMeetingUser: false,
    isFundingSupplier: false,
    isPerformance: false,
    isEuropeAssistance: false,
    isNudgingUser: false,
    isHeritageTaker: false,
    isDeiTaker: false
};

// E' necessario utilizzare una funzione normale, le Arrow function di ES6 in questo caso non funzionano. Lo state è passato automaticamente da Ngrx, e ne approfittiamo per dargli un valore iniziale.
// Alla fine la funzione tornerà il nuovo state, una nuova copia aggiornata. Infatti, grazie all'immutabilità dello state, in ogni "case" viene sempre tornato un nuovo oggetto.
export function authReducer(state = initialState, action: AuthActions.AuthActions) {
    // Tramite lo switch, identifico di quale azione è stato fatto il dispatch
    switch (action.type) {
        case (AuthActions.SET_USER_AUTHENTICATED):
            return {
                ...state,
                authenticated: true,
                isSupplier: action.payload && action.payload.isSupplier,
                isTaker: action.payload && action.payload.isTaker,
                isSmartEnergyTaker: action.payload && action.payload.isSmartEnergyTaker,
                isWelionTs: action.payload && action.payload.isWelionTs,
                isOwnership: action.payload && action.payload.isOwnership,
                isWelfareEnel: action.payload && action.payload.isWelfareEnel,
                isMeetingUser: action.payload && action.payload.isMeetingUser,
                isOnlineMeetingUser: action.payload && action.payload.isOnlineMeetingUser,
                isPublicMeetingUser: action.payload && action.payload.isPublicMeetingUser,
                isWelionWelfarePublicMeetingUser: action.payload && action.payload.isWelionWelfarePublicMeetingUser,
                isOnlineMeetingTeacher: action.payload && action.payload.isOnlineMeetingTeacher,
                isOnlineMeetingSupport: action.payload && action.payload.isOnlineMeetingSupport,
                isWelionWelfareTeacher: action.payload && action.payload.isWelionWelfareTeacher,
                isWelionWelfareSupport: action.payload && action.payload.isWelionWelfareSupport,
                isEnelWelfareTeacher: action.payload && action.payload.isEnelWelfareTeacher,
                isEnelWelfareSupport: action.payload && action.payload.isEnelWelfareSupport,
                isManager: action.payload && action.payload.isManager,
                isAdmin: action.payload && action.payload.isAdmin,
                isFundingSupplier: action.payload && action.payload.isFundingSupplier,
                isPerformance: action.payload && action.payload.isPerformance,
                isHeritageTaker: action.payload && action.payload.isHeritageTaker,
            };
        case (AuthActions.LOGOUT):
            // Rimuovo il token dal session storage
            sessionStorage.removeItem('isSupplier');
            sessionStorage.removeItem('perimeterToShow');
            sessionStorage.removeItem('yearPerformanceManagementSelected');
            return {
                ...state,
                isSupplier: false,
                isTaker: false,
                isSmartEnergyTaker: false,
                isWelionTs: false,
                isOwnership: false,
                isWelfareEnel: false,
                isMeetingUser: false,
                isOnlineMeetingUser: false,
                isPublicMeetingUser: false,
                isWelionWelfarePublicMeetingUser: false,
                isOnlineMeetingTeacher: false,
                isOnlineMeetingSupport: false,
                isWelionWelfareTeacher: false,
                isWelionWelfareSupport: false,
                isEnelWelfareTeacher: false,
                isEnelWelfareSupport: false,
                isManager: false,
                isAdmin: false,
                isFundingSupplier: false,
                isPerformance: false,
                isHeritageTaker: false
            };
        case (AuthActions.SESSION_LOGOUT):
            // Rimuovo il token dal session storage
            sessionStorage.removeItem('token');
            return {
                ...state,
                authenticated: false,
                token: null
            };
        case (AuthActions.SET_IS_SUPPLIER):
            return {
                ...state,
                isSupplier: true
            };
        case (AuthActions.SET_IS_FUNDINGSUPPLIER):
            return {
                ...state,
                isFundingSupplier: true
            };
        case (AuthActions.SET_IS_TAKER):
            return {
                ...state,
                isTaker: true
            };
        case (AuthActions.SET_IS_SMART_ENERGY_TAKER):
            return {
                ...state,
                isSmartEnergyTaker: true
            };
        case (AuthActions.SET_IS_EUROPE_ASSISTANCE):
            return {
                ...state,
                isEuropeAssistance: true
            };
        case (AuthActions.SET_IS_WELION_TS):
            return {
                ...state,
                isWelionTs: true
            };
        case (AuthActions.SET_IS_OWNERSHIP):
            return {
                ...state,
                isOwnership: true
            };
        case (AuthActions.SET_IS_WELFARE_ENEL):
            return {
                ...state,
                isWelfareEnel: true
            };
        case (AuthActions.SET_IS_MEETING_USER):
            return {
                ...state,
                isMeetingUser: true
            };
        case (AuthActions.SET_IS_ONLINE_MEETING_USER):
            return {
                ...state,
                isOnlineMeetingUser: true
            };
        case (AuthActions.SET_IS_PUBLIC_MEETING_USER):
            return {
                ...state,
                isPublicMeetingUser: true
            };
        case (AuthActions.SET_IS_WELION_WELFARE_PUBLIC_MEETING_USER):
            return {
                ...state,
                isWelionWelfarePublicMeetingUser: true
            };
        case (AuthActions.SET_IS_ONLINE_MEETING_TEACHER):
            return {
                ...state,
                isOnlineMeetingTeacher: true
            };
        case (AuthActions.SET_IS_ONLINE_MEETING_SUPPORT):
            return {
                ...state,
                isOnlineMeetingSupport: true
            };
        case (AuthActions.SET_IS_WELION_WELFARE_TEACHER):
            return {
                ...state,
                isWelionWelfareTeacher: true
            };
        case (AuthActions.SET_IS_WELION_WELFARE_SUPPORT):
            return {
                ...state,
                isWelionWelfareSupport: true
            };
        case (AuthActions.SET_IS_ENEL_WELFARE_TEACHER):
            return {
                ...state,
                isEnelWelfareTeacher: true
            };
        case (AuthActions.SET_IS_ENEL_WELFARE_SUPPORT):
            return {
                ...state,
                isEnelWelfareSupport: true
            };
        case (AuthActions.SET_IS_MANAGER):
            return {
                ...state,
                isManager: true
            };
        case (AuthActions.SET_IS_ADMIN):
            return {
                ...state,
                isAdmin: true
            };
        case (AuthActions.SET_IS_PERFORMANCE):
            return {
                ...state,
                isPerformance: true
            };
        case (AuthActions.SET_IS_HERITAGE_TAKER):
            return {
                ...state,
                isHeritagetaker: true
            };
        case (AuthActions.SET_IS_DEI_TAKER):
            return {
                ...state,
                isDeiTaker: true
            };
        case (AuthActions.SET_TOKEN):
            // Setto il token all'interno del session storage, e poi torno lo state aggiornato con il token
            // Ovviamente lo setto solo se non c'è già
            sessionStorage.setItem('token', action.payload);
            return {
                ...state,
                token: action.payload
            };
        default:
            return state;
    }
}

export const getToken = (state: AuthState) => state.token;
export const isAuthenticated = (state: AuthState) => state.authenticated;
export const isSupplier = (state: AuthState) => state.isSupplier;
export const isFundingSupplier = (state: AuthState) => state.isFundingSupplier;
export const isTaker = (state: AuthState) => state.isTaker;
export const isSmartEnergyTaker = (state: AuthState) => state.isSmartEnergyTaker;
export const isWelionTs = (state: AuthState) => state.isWelionTs;
export const isOwnership = (state: AuthState) => state.isOwnership;
export const isWelfareEnel = (state: AuthState) => state.isWelfareEnel;
export const isMeetingUser = (state: AuthState) => state.isMeetingUser;
export const isOnlineMeetingUser = (state: AuthState) => state.isOnlineMeetingUser;
export const isPublicMeetingUser = (state: AuthState) => state.isPublicMeetingUser;
export const isWelionWelfarePublicMeetingUser = (state: AuthState) => state.isWelionWelfarePublicMeetingUser;
export const isOnlineMeetingTeacher = (state: AuthState) => state.isOnlineMeetingTeacher;
export const isOnlineMeetingSupport = (state: AuthState) => state.isOnlineMeetingSupport;
export const isWelionWelfareTeacher = (state: AuthState) => state.isWelionWelfareTeacher;
export const isWelionWelfareSupport = (state: AuthState) => state.isWelionWelfareSupport;
export const isEnelWelfareTeacher = (state: AuthState) => state.isEnelWelfareTeacher;
export const isEnelWelfareSupport = (state: AuthState) => state.isEnelWelfareSupport;
export const isManager = (state: AuthState) => state.isManager;
export const isAdmin = (state: AuthState) => state.isAdmin;
export const isPerformance = (state: AuthState) => state.isPerformance;
export const isHeritageTaker = (state: AuthState) => state.isHeritageTaker;
export const isDeiTaker = (state: AuthState) => state.isDeiTaker;
