import { Component, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as fromApp from '../../ngrx/app.reducers';
import * as AuthActions from '../ngrx/auth.actions';
import { TranslateService } from '@ngx-translate/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Lang } from '../../../cm2-commonclasses';
import { BaseSubscriberComponent } from 'src/app/shared/components/base-subscriber.component';
import { Subscription } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Component({
    selector: 'app-welion-welfare-users-login',
    templateUrl: './welion-welfare-users-login.component.html',
    styleUrls: ['./welion-welfare-users-login.component.scss']
})
export class WelionWelfareUsersLoginComponent extends BaseSubscriberComponent implements OnInit, OnDestroy {
    langs: Lang[];
    subscriptionFormVisible: boolean;
    resetPasswordFormVisible: boolean;
    isManagingUser$: Subscription;
    userId: string;
    token: string;
    showLoader: boolean;
    translatedModalText: string;
    translatedModalTitle: string;
    isEmailConfirmation: boolean;
    noCheckNames: any;
    newValidationToken: string;
    recoveryUserPassword: boolean;

    constructor(private route: ActivatedRoute,
        private store: Store<fromApp.AppState>,
        public translate: TranslateService,
        private authService: AuthService,
        private router: Router,
        private ngxSmartModalService: NgxSmartModalService,
        private deviceService: DeviceDetectorService,
        private toastr: ToastrService) {
        super();
        this.isEmailConfirmation = false;
        this.recoveryUserPassword = false;
        // Sto in ascolto di quando il globalApplicationData cambia, così da sapere quando ci sono le lingue disponibili poiché, se non ci fossero, il pulsante per il login sarebbe disabilitato
        this.store.select(fromApp.getAvailableLangs)
            .subscribe(
                (langs) => {
                    this.langs = langs;
                });
    }

    ngOnInit() {
        this.route.params
            .subscribe(
                (params: Params) => {
                    this.userId = params['userId'];
                    this.token = params['token'];
                    this.newValidationToken = null;
                    if (this.router.url.indexOf('emailConfirmationWelionWelfareUser') !== -1) {
                        this.isEmailConfirmation = true;
                    } else {
                        this.isEmailConfirmation = false;
                    }
                    if (this.router.url.indexOf('recoveryWelionWelfareUserPassword') !== -1) {
                        this.recoveryUserPassword = true;
                    } else {
                        this.recoveryUserPassword = false;
                    }
                });

        this.subscriptionFormVisible = false;
        this.resetPasswordFormVisible = false;
        this.translatedModalTitle = null;
        this.translatedModalText = null;
    }

    // Toggle del form per l'iscrizione
    toggleSubscriptionFormVisible() {
        this.subscriptionFormVisible = !this.subscriptionFormVisible;
    }

    // Toggle del form per il reset della password
    toggleResetPasswordFormVisible() {
        this.resetPasswordFormVisible = !this.resetPasswordFormVisible;
    }

    changeInput(x: any) {
        if (x.viewModel.trim().length || x.viewModel == "") {
            return false;
        } else {
            return true;
        }

    }

    checkRegExp(x: any) {
        if (x.viewModel || x.viewModel == "") {
            if (/^[A-Za-z\'\`\s\/^[a-z\u00E0-\u00FC]+$\]*$/g.test(x.viewModel.trim()) && x.viewModel.trim().length > 1) {
                return false;

            } else {
                return true;
            }
        } else {
            if (/^[A-Za-z\'\`\s\/^[a-z\u00E0-\u00FC]+$\]*$/g.test(x.trim()) && x.trim().length > 1) {
                return false;

            } else {
                return true;
            }
        }
    }

    isValidForm(form: NgForm) {
        if (form && form.value && form.value.newUserEmail && form.value.newUserFirstName && form.value.newUserLastName && form.value.allowUserPrivacy && form.value.newUserEmail.trim().length && form.value.newUserFirstName.trim().length && form.value.newUserLastName.trim().length
            && !this.checkRegExp(form.value.newUserFirstName) && !this.checkRegExp(form.value.newUserLastName)) {
            return false;
        } else {
            return true;
        }
    }

    // Dal form passato come parametro, ricavo i dati immessi dall'utente per inserirli nel dispatch dell'action che tenterà il login
    onLogin(form: NgForm) {
        this.showLoader = true;
        const email = form.value.email;
        const password = form.value.password;
        this.store.dispatch(new AuthActions.DoLogin({ email: email, password: password, isWelionWelfarePublicMeetingUser: true }));
        this.showLoader = false;
    }

    // Apre l'informativa della privacy
    openPrivacyDocument() {
        const confimation = new EventEmitter<any>();

        const body = `
<p class="bold-text">Informativa sul trattamento dei dati personali ai sensi degli articoli 13 e 14 del Regolamento UE n. 679/2016 del 27 aprile 2016</p>
<div class="space20"></div>
<p>La informiamo che i suoi dati personali anagrafici e identificativi, raccolti nell’ambito dell’iniziativa WELION TRAINING WELFARE, sono trattati da Generali Italia S.p.A. (di seguito anche la Compagnia), quale Titolare, per permetterle la partecipazione alle lezioni in streaming.</p>
<p>Lei potrà conoscere quali sono i suoi dati trattati presso la Compagnia e, ove ne ricorrano le condizioni, esercitare i diversi diritti relativi al loro utilizzo (diritto di accesso, rettifica, aggiornamento, integrazione, cancellazione, limitazione al trattamento, alla portabilità, alla revoca del consenso al trattamento e di ottenere una copia dei propri dati laddove questi siano conservati in paesi al di fuori dell’Unione Europea, nonché di ottenere indicazione del luogo nel quale tali dati vengono conservati o trasferiti) nonché opporsi per motivi legittimi ad un loro particolare trattamento e comunque al loro uso a fini commerciali, in tutto o in parte anche per quanto riguarda l’uso di modalità automatizzate rivolgendosi a: Generali </p>
<p>Italia S.p.A., Via Marocchesa 14, 31021 Mogliano Veneto TV, <a class="text-primary-1 italic-text text-underline" href="mailto:privacy.it@generali.com">privacy.it@generali.com</a> o al Responsabile della Protezione dei Dati (RPD), contattabile via e-mail a “<a class="text-primary-1 italic-text text-underline" href="mailto:RPD.it@generali.com">RPD.it@generali.com</a>” e/o via posta ordinaria all’indirizzo “RPD Generali Italia - Mogliano Veneto, Via Marocchesa 14 31021. La informiamo, inoltre, che, qualora ravvisi un trattamento dei Suoi dati non coerente con i consensi da Lei espressi può sporgere reclamo al Garante per la protezione dei dati personali, con le modalità indicate sul sito del Garante stesso.</p>
<p>I Suoi dati saranno conservati in conformità alla normativa privacy tempo per tempo applicabile, in particolare, per le finalità connesse all’iniziativa, per il periodo necessario agli scopi per i quali sono stati raccolti o trattati e, comunque, non superiore a 24 mesi dal momento della raccolta.</p>
<div class="space20"></div>
<p>I suoi dati non saranno diffusi e saranno trattati con idonee modalità e procedure anche informatizzate, da nostri dipendenti, collaboratori ed altri soggetti anche esterni, designati Responsabili del trattamento o, comunque, operanti quali Titolari, che sono coinvolti nella gestione dei rapporti con lei in essere o che svolgono per nostro conto compiti di natura tecnica, organizzativa, operativa.</p>
`;

        const publicUsersPrivacyData: Object = {
            modalTitle: this.translate.instant('generic.PUBLIC_USER_PRIVACY_TITLE_SECOND'),
            modalBody: this.translate.instant(body),
            confirmBtnLabel: this.translate.instant('generic.CLOSE'),
            confirmation: confimation,
            componentRef: this
        };
        let publicUsersPrivacyRef = this.ngxSmartModalService.getModal('publicUsersPrivacyModal');
        publicUsersPrivacyRef.setData(publicUsersPrivacyData, true);
        confimation
            .pipe(take(1))
            .subscribe(() => {
            });
        // Apro la modale
        publicUsersPrivacyRef.open();
    }

    // Dal form passato come parametro, ricavo i dati immessi dall'utente per il recupero della password
    onRecoverPassword(form: NgForm) {
        this.showLoader = true;
        // Mostro il messaggio di avviso
        if (this.userId && (this.isEmailConfirmation || this.recoveryUserPassword) && (this.token || this.newValidationToken)) {
            // Chiamo il servizio per impostare la nuova password
            this.isManagingUser$ = this.authService.setCustomerUserPassword(this.userId, this.newValidationToken || this.token, form.value.repeatPassword, 'WELION_MONDADORI')
                .subscribe(data => {
                    this.showLoader = false;
                    // Se ci sono errori, li mostor e torno alla lista dei template
                    if (data.error) {
                        this.toastr.error(this.translate.instant('errors.' + data.error));
                    } else {
                        // Mostro il messaggio di avviso
                        this.openSuccessModal('generic.modals.PASSWORD_SET', 'generic.modals.PASSWORD_SET_TITLE');
                        this.isEmailConfirmation = false;
                        this.recoveryUserPassword = false;
                        this.token = null;
                        this.userId = null;
                        this.newValidationToken = null;
                    }
                },
                    (err) => {
                        this.toastr.error(this.translate.instant('errors.' + err.message));
                        this.showLoader = false;
                    });
        } else {
            // Altrimenti significa che devo inviare la mail all'utente
            this.isManagingUser$ = this.authService.initPasswordRecovery(form.value.resetPswEmail, 'WELION_MONDADORI')
                .subscribe(data => {
                    this.showLoader = false;
                    // Se ci sono errori, li mostro e torno alla lista dei template
                    if (data.error) {
                        this.toastr.error(this.translate.instant('errors.' + data.error));
                        // Eseguo il toggle
                        this.toggleResetPasswordFormVisible();
                    } else {
                        // Mostro il messaggio di avviso
                        this.openSuccessModal('generic.modals.EMAIL_FOR_CHANGE_PSW_SENT_BODY', 'generic.modals.EMAIL_FOR_CHANGE_PSW_SENT');
                        // Eseguo il toggle
                        this.toggleResetPasswordFormVisible();
                    }
                },
                    (err) => {
                        this.toastr.error(this.translate.instant('errors.' + err.message));
                        this.showLoader = false;
                    });
        }
    }

    // Dal form passato come parametro, ricavo i dati immessi dall'utente per la registrazione
    onRegister(form: NgForm) {
        this.showLoader = true;
        const deviceInfo = this.deviceService.getDeviceInfo();
        const userAgent = deviceInfo && deviceInfo.userAgent;
        let deviceType;
        if (this.deviceService.isMobile()) {
            // Salvo il fatto che è uno smartphone
            deviceType = 'P';
        } else if (this.deviceService.isTablet()) {
            // Salvo il fatto che è un tablet
            deviceType = 'T';
        } else if (this.deviceService.isDesktop()) {
            // Salvo il fatto che è un computer desktop
            deviceType = 'D';
        }

        // Chiamo il servizio per la registrazione
        this.isManagingUser$ = this.authService.registerCustomer(form.value.newUserEmail.trim(), form.value.newUserFirstName.trim(), form.value.newUserLastName.trim(), userAgent, deviceType, form.value.allowUserPrivacy, 'WELION_MONDADORI')
            .subscribe(data => {
                this.showLoader = false;
                // Se ci sono errori, li mostor e torno alla lista dei template
                if (data.error) {
                    this.toastr.error(this.translate.instant('errors.' + data.error));
                } else {
                    // Mostro il messaggio di avviso
                    this.openSuccessModal('generic.modals.USER_CREATED', 'generic.modals.USER_CREATED_TITLE');
                    this.toggleSubscriptionFormVisible();
                }
            },
                (err) => {
                    this.toastr.error(this.translate.instant('errors.' + err.message));
                    this.showLoader = false;
                });
    }

    // Dal form passato come parametro, controllo se le due password nuove sono uguali
    areNewPswSame(form: NgForm) {
        const newPsw = form.value.newPsw;
        const newPswRepeated = form.value.repeatPassword;
        if (newPsw && newPswRepeated && newPsw === newPswRepeated) {
            return true;
        }
        return false;
    }

    // Dal form passato come parametro, controllo se le due password nuove sono uguali
    areNewUserPswSame(form: NgForm) {
        const newPsw = form.value.newUserPassword;
        const newPswRepeated = form.value.newUserRepeatPassword;
        if (newPsw && newPswRepeated && newPsw === newPswRepeated) {
            return true;
        }
        return false;
    }

    // Annulla l'operazione di una modale di conferma
    genericModalDismiss() {
        return;
    }

    preventDefault(e) {
        e.stopPropagation();
        e.preventDefault();
    }

    openSuccessModal(body: string, title: string) {
        const confimation = new EventEmitter<any>();
        // Prima di aprire la modale, setto le traduzioni e i metodi da agganciare le funzioni
        const confirmModalData: Object = {
            modalTitle: this.translate.instant(title),
            modalBody: this.translate.instant(body),
            confirmBtnLabel: this.translate.instant('generic.OK'),
            confirmation: confimation,
            componentRef: this
        };
        let confirmModalRef = this.ngxSmartModalService.getModal('confirmModal');
        confirmModalRef.setData(confirmModalData, true);
        confimation
            .pipe(take(1))
            .subscribe(() => {
            });
        // Apro la modale
        confirmModalRef.open();
    }

    ngOnDestroy() {
        if (this.isManagingUser$) {
            this.isManagingUser$.unsubscribe();
        }
    }
}
