/*
 * Componente per la pagina di errore compatibilità, che può verificarsi qualora un utente acceda con un device o con un browser non supportato
*/

import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-compatibility-error',
    templateUrl: './compatibility-error.component.html',
    styleUrls: ['./compatibility-error.component.scss']
})
export class CompatibilityErrorComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
