import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromApp from '../../ngrx/app.reducers';
// Import dello State dell'autenticazione, che è quello che selezioneremo con lo slice dallo Store
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Lang } from '../../../cm2-commonclasses';

@Component({
    selector: 'app-login-saml-error',
    templateUrl: './login-saml-error.component.html',
    styleUrls: ['./login-saml-error.component.scss']
})
export class LoginSamlErrorComponent implements OnInit, OnDestroy {
    isFetchingLangs: boolean;
    langs: Lang[];
    error: any;

    constructor(private store: Store<fromApp.AppState>,
        public translate: TranslateService,
        private router: Router) {
        const specificError = this.router.parseUrl(this.router.routerState.snapshot.url).queryParamMap.get("error");
        if (specificError) {
            this.error = {
                specificError: specificError
            };
        }
    }
    ngOnInit() {
    }
    ngOnDestroy() {
    }
}
