/*
* Modale che consiglia all'utente di cambiare browser
*/

import { Component, OnInit } from '@angular/core';
@Component({
    selector: 'switch-browser-modal',
    templateUrl: './switch-browser-modal.component.html'
})
export class SwitchBrowserModalComponent implements OnInit {
    constructor() { }
    ngOnInit() {
    }

    closeSwitchBrowserModal(data) {
        if (data.confirmation && data.componentRef) {
            data.confirmation.emit();
        }
    }
}
