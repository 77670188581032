// Login dei taker senza sso

import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';

import * as fromApp from '../../ngrx/app.reducers';
// Import dello State dell'autenticazione, che è quello che selezioneremo con lo slice dallo Store
import * as AuthActions from '../ngrx/auth.actions';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Lang } from "../../../cm2-commonclasses";

@Component({
    selector: 'app-local-taker-login',
    templateUrl: './taker-local-login.component.html',
    styleUrls: ['./taker-local-login.component.scss']
})
export class TakerLocalLoginComponent implements OnInit, OnDestroy {
    isFetchingLangs: boolean;
    langs: Lang[];
    result$;

    constructor(private store: Store<fromApp.AppState>,
        public translate: TranslateService,
        private router: Router) {
        // Sto in ascolto di quando il globalApplicationData cambia, così da sapere quando ci sono le lingue disponibili poiché, se non ci fossero, il pulsante per il login sarebbe disabilitato
        let availableLangs$: Observable<Lang[]> = this.store.select(fromApp.getAvailableLangs);
        let isFetchingLangs$: Observable<boolean> = this.store.select(fromApp.isFetchingLangs);
        const combinedSelectes$ = combineLatest(availableLangs$, isFetchingLangs$);
        this.result$ = combinedSelectes$.subscribe(
            ([langs, isFetchingLangs]) => {
                this.langs = langs;
                this.isFetchingLangs = isFetchingLangs;
            });
    }
    ngOnInit() {
        let sessionStorageTakerEmail = sessionStorage.getItem('takerEmail');
        let sessionStoragetakerUserId = sessionStorage.getItem('takerUserId');
        // Se ho lo userId (oppure il cid) nel session storage, posso subito eseguire il login
        if (sessionStorageTakerEmail || sessionStoragetakerUserId) {
            this.store.dispatch(new AuthActions.DoLogin({ email: sessionStorageTakerEmail || sessionStoragetakerUserId, password: null, isTaker: true }));
        }
    }
    ngOnDestroy() {
        this.result$.unsubscribe();
    }

    // Dal form passato come parametro, ricavo i dati immessi dall'utente per inserirli nel dispatch dell'action che tenterà il login
    onLogin(form: NgForm) {
        let email: string = form.value.email;
        if (email && !email.includes('@')) {
            email = email.replace(/^p?0*/mgi, "");
        }
        this.store.dispatch(new AuthActions.DoLogin({ email: email, password: null, isTaker: true }));
    }
}