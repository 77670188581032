import { Tag } from "../../../cm2-commonclasses";

/*
 * Modello che dinisce una classe che estende quella dei Tag, per aggiugnerci ad esempio l'ordinamento i stili css
*/
export class ExtendedTag extends Tag {
    order?: string;
    containerClass?: string;
    textClass?: string;
    attributeWeight?: string;
    waveWalue?: string;
}

/*
* Extended Tag utility
*/
export class ExtendedTagUtil {
    // Setta i colori e le classi dei cluster, recuperandoli dai loro attributi
    static setClusterClass(clusters: ExtendedTag[]) {
        if (clusters && clusters.length) {
            for (let i = 0, clustersLength = clusters.length; i < clustersLength; i++) {
                // Setto il CSS del titolo
                let currentCluster = clusters[i];
                if (currentCluster && currentCluster.tagAttributes && currentCluster.tagAttributes.length) {
                    for (let k = 0; k < currentCluster.tagAttributes.length; k++) {
                        let currentAttribute = currentCluster.tagAttributes[k];
                        // Verifico l'ordinamento
                        if (currentAttribute.attributeType === 'ORDER') {
                            currentCluster.order = currentAttribute.attributeValue;
                        }

                        // Setto la classe per lo sfondo
                        if (currentAttribute.attributeType === 'COLOR') {
                            // Imposto le classi che andranno sia quando il filtro è selezionato che quando non lo è. Poi, in base alla condizione, lo setto
                            currentCluster.containerClass = 'bg-' + currentAttribute.attributeValue;
                            currentCluster.textClass = 'text-' + currentAttribute.attributeValue;
                        }
                    }
                }
            }
        }
    }
}