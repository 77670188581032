<!-- Il form di login si vede solamente se ci sono lingue disponibili a sistema -->
<div class="login-smart-energy-page content" fxLayout="column" fxLayoutAlign="center center"
  *ngIf="langs && langs.length">
  <p class="text-title" translate="smartEnergyInitiatives.ENERGY_LAB"></p>
  <div class="line-title-divider"></div>
  <div class="form-container">
    <p class="text-desc margin-bottom30" translate="smartEnergyInitiatives.INSERT_MAIL_OR_CID"></p>
    <p class="text-desc-grey margin-bottom10" translate="smartEnergyInitiatives.MAIL_OR_CID"></p>
    <input type="text" class="smart-energy-input full-width" [(ngModel)]="mailCid"
      [placeholder]="'smartEnergyInitiatives.TYPE_MAIL_OR_CID' | translate"
      [attr.aria-label]="'smartEnergyInitiatives.TYPE_MAIL_OR_CID' | translate" id="mailCid" name="mailCid" />
    <div class="full-width margin-top15" fxLayout="column" fxLayoutAlign="center center">
      <button [disabled]="!mailCid || !mailCid.length" (click)="doLogin()" class="smart-energy-button" translate="smartEnergyInitiatives.CONTINUE"></button>
    </div>
  </div>
</div>

<!-- Nessuna lingua disponibile a sistema -->
<div *ngIf="!langs || !langs.length">
  <p translate="errors.NO_AVAILABLE_LANG"></p>
</div>
