<div style="height: 100vh" fxLayout="row">
    <div fxLayout="column" fxLayoutAlign="center center" fxFill>
        <div class="margin-bottom30">
            <img width="140" title="Generali" alt="Generali" class="logoRegular cursor-pointer"
                src="assets/img/logo_generali.svg">
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
            <h1 class="error_404 text-inline margin-bottom10">403</h1>
        </div>
        <h4 class="text-center"><span translate="errors.403"></span>
        </h4>
    </div>
</div>