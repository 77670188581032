/*
*  Reducer che gestiscono e modificano lo State applicativo riguardante le riunioni
*/

import * as MeetingsActions from './meeting.actions';
import * as CommonClasses from "../../../cm2-commonclasses";
import * as fromApp from '../../ngrx/app.reducers';
import { Item } from "../../../cm2-commonclasses";

// Le iniziative sono una feature dell'applicazione, infatti è un modulo caricato in maniera lazy (e quindi anche lo Store).
// Creo un'interfaccia, FeatureState, in cui mappo le iniziative dello state InitiativeState
// Estendo fromApp.AppState così per ereditare tutte le proprietà dello Store principale
export interface FeatureState extends fromApp.AppState {
    meeting: MeetingState;
}

// Interfaccia dello Store
export interface MeetingState {
    selectedMeetingId: string;
    selectedMeetingData: { currentWizardStep: string, meetingItem: Item };
    meetings: any[];
    count: number;
    fromRecord: number;
    numRecords: number;
    page: number;
    isFetchingMeetings: boolean;
    selectedMeetingType: string;
    meetingsTextFilter: string;
    meetingStartNumber: any;
}

// Store iniziale
const initialMeetingState: MeetingState = {
    selectedMeetingId: null,
    selectedMeetingData: {
        currentWizardStep: null,
        meetingItem: null
    }, // dati relativi alla riunione
    meetings: [], // lista delle riunioni
    count: 0, // contatore totale delle riunioni
    fromRecord: 0, // numero da cui recuperare gli elementi
    numRecords: 5, // elementi da recuperare
    page: 1, // pagina della tabella visualizzata
    isFetchingMeetings: false, // verifica se sto eseguendo una chiamata per recuperare le riunioni
    selectedMeetingType: null,
    meetingsTextFilter: null,
    meetingStartNumber: null
};

export function meetingReducer(state = initialMeetingState, action: MeetingsActions.MeetingsActions) {
    switch (action.type) {
        case MeetingsActions.SET_MEETINGS:
            return {
                ...state,
                isFetchingMeetings: false,
                meetings: [...action.payload] // sovrascrivo le riunioni
            };
        case MeetingsActions.SET_PAGINATION:
            return {
                ...state,
                fromRecord: action.payload.fromRecord,
                numRecords: action.payload.numRecords
            };
        case MeetingsActions.GET_MEETINGS:
            return {
                ...state,
                isFetchingMeetings: true
            };
        case MeetingsActions.SET_MEETINGS_TYPE:
            return {
                ...state,
                selectedMeetingType: action.payload
            };
        case MeetingsActions.SET_MEETINGS_TEXT_FILTER:
            return {
                ...state,
                meetingsTextFilter: action.payload
            };
        case MeetingsActions.SET_PAGE:
            return {
                ...state,
                page: action.payload
            };
        case MeetingsActions.SET_MEETINGS_COUNT:
            return {
                ...state,
                count: action.payload,
            };
        case (MeetingsActions.ADD_MEETING):
            return {
                ...state,
                meetings: [action.payload, ...state.meetings]
            };
        case (MeetingsActions.UPDATE_MEETING):
            // Torneremo sempre lo stato aggiornato, ma prima devo modificare la riunione. Quindi procedo recuperandola
            const meeting = state.meetings && state.meetings.find((item: any) => {
                return item.itemId === action.payload.updatedMeeting.itemId;
            });
            const index = state.meetings.indexOf(meeting);

            const updatedMeeting = {
                ...meeting,
                ...action.payload.updatedMeeting
            };
            // con lo spread operator tiro fuori tutti gli elementi dell'array state.initiatives per aggiungerli nel nuovo array.
            //  Così, li ho aggiunti in maniera immutabile
            let meetings = [...state.meetings];

            if (index >= 0) {
                meetings[index] = updatedMeeting; // ora posso aggiornare la riunione
            } else {
                // se sto aggiorando ma non trovo la riunione l'aggiungo,
                //  questo significa che è stata appena creata dalla prima conferma del primo step
                meetings = [updatedMeeting, ...meetings];
            }
            meetings = meetings.length === 0 ? [updatedMeeting] : meetings;

            if (state.selectedMeetingData && state.selectedMeetingData.meetingItem
                && state.selectedMeetingData.meetingItem === updatedMeeting.itemId) {
                return {
                    ...state,
                    meetings: meetings,
                    selectedMeetingData: {
                        ...state.selectedMeetingData,
                        meetingItem: updatedMeeting
                    }
                };
            } else {
                return {
                    ...state,
                    meetings: meetings
                };
            }
        case (MeetingsActions.SELECT_MEETING):
            // aggiorno una riunione nello state
            // imposto l'id dell'riunione selezionata e come effetto l'aggiorno recuperandola dai servizi remoti
            return {
                ...state,
                selectedMeetingId: action.payload
            };
        case (MeetingsActions.SET_SELECTED_MEETING_ITEM):
            // Salva l'oggetto intero della riunione selezionata
            return {
                ...state,
                selectedMeetingData: {
                    ...state.selectedMeetingData,
                    meetingItem: action.payload
                }
            };
        case (MeetingsActions.SET_PATH_ITEM_OF_SELECTED_MEETING):
            // Salva l'intero oggetto padre (quindi il percorso) della riunione scelta
            return {
                ...state,
                selectedMeetingData: {
                    ...state.selectedMeetingData,
                    pathItem: action.payload
                }
            };
        case (MeetingsActions.DELETE_SELECTED_MEETING_DATA):
            return {
                ...state,
                selectedMeetingData: {
                    meetingItem: null
                }
            };
        case (MeetingsActions.DELETE_MEETING):
            const oldMeetings = [...state.meetings];
            for (let i = 0, meetingsLength = oldMeetings.length; i < meetingsLength; i++) {
                const currentMeeting = oldMeetings[i];
                if (currentMeeting.itemId === action.payload) {
                    oldMeetings.splice(i, 1);
                    break;
                }
            }
            return {
                ...state,
                meetings: oldMeetings
            };
        case (MeetingsActions.SET_CURRENT_WIZARD_STEP):
            // Salva l'oggetto intero della riunione selezionata
            return {
                ...state,
                selectedMeetingData: {
                    ...state.selectedMeetingData,
                    currentWizardStep: action.payload
                }
            };
        case (MeetingsActions.SET_NOTIFICATIONS):
            // Salva l'intero oggetto padre (quindi il percorso) della riunione scelta
            return {
                ...state,
                meetingStartNumber: action.payload
            };
        default:
            return state;
    }
}

// Export delle funzioni che tornano una parte dello Store applicativo
export const getMeetings = (state: MeetingState) => state.meetings;
export const getMeetingsCount = (state: MeetingState) => state.count;
export const getMeetingsFromRecord = (state: MeetingState) => state.fromRecord;
export const getMeetingsNumRecords = (state: MeetingState) => state.numRecords;
export const getMeetingsPage = (state: MeetingState) => state.page;
export const getSelectedMeetingItem = (state: MeetingState) => {
    return state.selectedMeetingData && state.selectedMeetingData.meetingItem;
};
export const isFetchingMeetings = (state: MeetingState) => state.isFetchingMeetings;
export const getWizardCurrentStep = (state: MeetingState) => {
    return state.selectedMeetingData && state.selectedMeetingData.currentWizardStep;
};
export const getMeetingsFilterType = (state: MeetingState) => (state && state.selectedMeetingType) || null;
export const getMeetingsTextFilter = (state: MeetingState) => (state && state.meetingsTextFilter) || null;
export const getNotificationNumber = (state: MeetingState) => (state && state.meetingStartNumber) || null;