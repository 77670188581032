/*
*  Reducer che gestiscono e modificano lo State applicativo riguardante la dashboard
*/

import * as DashboardActions from './dashboard.actions';
import * as fromApp from '../../ngrx/app.reducers';
import { RoleIndicator } from 'src/app/shared/models/role-indicator.model';

// La dashboard è una feature dell'applicazione, infatti è un modulo caricato in maniera lazy (e quindi anche lo Store).
// Creo un'interfaccia, FeatureState, in cui mappo la dashboard
// Estendo fromApp.AppState così per ereditare tutte le proprietà dello Store principale
export interface FeatureState extends fromApp.AppState {
    dashboard: DashboardState
}

// Interfaccia dello Store
export interface DashboardState {
    rolesIndicators: RoleIndicator[];
    isFetchingRolesIndicators: boolean;
}

// Store iniziale
const initialState: DashboardState = {
    rolesIndicators: [], // Lista di indicatori dei ruoli
    isFetchingRolesIndicators: true // Verifica se sto eseguendo una chiamata per recuperare i dati per gli indicatori dei ruoli
};

export function dashboardReducer(state = initialState, action: DashboardActions.DashboardActions) {
    switch (action.type) {
        case DashboardActions.SET_ROLES_INDICATORS:
            if (action.payload) {
                if (action.payload.length) {
                    return {
                        ...state,
                        isFetchingRolesIndicators: false,
                        rolesIndicators: [...action.payload]
                    };
                } else {
                    return {
                        ...state,
                        isFetchingRolesIndicators: false
                    };
                }
            } else {
                return {
                    ...state,
                    isFetchingRolesIndicators: false
                };
            }
        case DashboardActions.GET_ROLES_INDICATORS:
            return {
                ...state,
                isFetchingRolesIndicators: true
            };
        case DashboardActions.DELETE_ROLES_INDICATORS:
            return {
                ...state,
                rolesIndicators: []
            };
        default:
            return state;
    }
}

// Export delle funzioni che tornano una parte dello Store applicativo
export const getRolesIndicators = (state: DashboardState) => (state && state.rolesIndicators) || [];
export const isFetchingRolesIndicators = (state: DashboardState) => state.isFetchingRolesIndicators;