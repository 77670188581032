/*
*  Reducer che gestiscono e modificano lo State applicativo riguardante i template
*/

import * as fromApp from '../../ngrx/app.reducers';
import * as LocationActions from "./location.actions";
import { LocationItem } from "../../shared/models/course.model";

// I template sono una feature dell'applicazione, infatti è un modulo caricato in maniera lazy (e quindi anche lo Store).
// Creo un'interfaccia, FeatureState, in cui mappo i template dello state Templatestate
// Estendo fromApp.AppState così per ereditare tutte le proprietà dello Store principale
export interface FeatureState extends fromApp.AppState {
    template: LocationState
}

// Interfaccia dello Store che torna la lista dei template recuperata
export interface LocationState {
    locations: LocationItem[];
    count: number;
    fromRecord: number;
    numRecords: number;
    page: number;
    isFetchingLocations: boolean;
    filterText: string;
}

// Store iniziale
const initialState: LocationState = {
    locations: [], // lista dei template
    count: 0, // contatore totale dei template
    fromRecord: 0, // numero da cui recuperare gli elementi
    numRecords: 5, // elementi da recuperare
    page: 1, // pagina della tabella visualizzata
    isFetchingLocations: false, // verifica se sto eseguendo una chiamata per recuperare i template
    filterText: ""
};

export function locationReducer(state = initialState, action: LocationActions.LocationActions) {
    switch (action.type) {
        case LocationActions.SET_LOCATIONS:
            // return {
            //     ...state,
            //     templates: [...state.locations, ...action.payload] // sovrascrivo i template
            // };
            return {
                ...state,
                isFetchingLocations: false,
                locations: [...action.payload] // sovrascrivo i template
            };
        case LocationActions.SET_PAGINATION:
            return {
                ...state,
                fromRecord: action.payload.fromRecord,
                numRecords: action.payload.numRecords
            };
        case LocationActions.GET_LOCATIONS:
            return {
                ...state,
                isFetchingLocations: true
            };
        case LocationActions.SET_PAGE:
            return {
                ...state,
                page: action.payload
            };
        case LocationActions.SET_TEXT_FILTER:
            return {
                ...state,
                filterText: action.payload
            };
        case LocationActions.SET_LOCATIONS_COUNT:
            return {
                ...state,
                count: action.payload,
            };
        case (LocationActions.ADD_LOCATION):
            return {
                ...state,
                locations: [action.payload, ...state.locations]
            };
        case (LocationActions.UPDATE_LOCATION):
            // Torneremo sempre lo stato aggiornato, ma prima devo modificare il template. Quindi procedo recuperandolo
            const template = state.locations[action.payload.updatedLocation.itemId]; // template che vogliamo cambiare
            const updatedLocation = {
                ...template,
                ...action.payload.updatedLocation
            };
            const locations = [...state.locations]; // con lo spread operator tiro fuori tutti gli elementi dell'array state.locations per aggiungerli immediatamente nel nuovo array. Così, li ho aggiunti in maniera immutabile
            locations[action.payload.updatedLocation.itemId] = updatedLocation; // ora posso aggiornare il template
            return {
                ...state,
                locations: locations
            };
        case (LocationActions.DELETE_LOCATION):
            const oldTemplates = [...state.locations];
            for (let i = 0, templatesLength = oldTemplates.length; i < templatesLength; i++) {
                let currentTemplate = oldTemplates[i];
                if (currentTemplate.itemId === action.payload) {
                    oldTemplates.splice(i, 1);
                    break;
                }
            }
            return {
                ...state,
                locations: oldTemplates
            };
        default:
            return state;
    }
}

// Export delle funzioni che tornano una parte dello Store applicativo
export const getLocations = (state: LocationState) => (state && state.locations) || [];
export const getLocationsCount = (state: LocationState) => state.count;
export const getLocationsFromRecord = (state: LocationState) => (state && state.fromRecord) || 0;
export const getLocationsNumRecords = (state: LocationState) => (state && state.numRecords) || 5;
export const getLocationsPage = (state: LocationState) => (state && state.page) || 0;
export const isFetchingLocations = (state: LocationState) => state.isFetchingLocations;
export const getLocationFilterText = (state: LocationState) => state.filterText || '';
