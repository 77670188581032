<div class="content" fxLayout="column">
    <!-- Titolo e sottotitolo -->
    <div fxLayout="column" class="container-title">
        <h1 translate="landingPerformanceManager.TITLE"></h1>
        <span translate="landingPerformanceManager.SUBTITLE1"></span>
        <span translate="landingPerformanceManager.SUBTITLE2"></span>
    </div>
    <div fxLayout="row" class="margin-left36" *ngIf="interestedAuths && interestedAuths.length"
        fxLayoutAlign="start start">
        <!-- Componente card -->
        <landing-performance-manager-card class="full-width" *ngFor="let singleAuth of interestedAuths; let i = index"
            [auth]="singleAuth.auth" [numberOfCards]="interestedAuths && interestedAuths.length" [index]="i">
        </landing-performance-manager-card>
    </div>
</div>