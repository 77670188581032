// Login dei welfare enel (test sierologici) senza sso

import { Component, OnInit, OnDestroy, ViewChild, Renderer2 } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, Subscription, Subject } from 'rxjs';

import * as fromApp from '../../ngrx/app.reducers';
// Import dello State dell'autenticazione, che è quello che selezioneremo con lo slice dallo Store
import * as AuthActions from '../ngrx/auth.actions';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Lang, SenecaResponse, User } from "../../../cm2-commonclasses";
import { switchMap, catchError } from 'rxjs/operators';
import { InitiativeService } from 'src/app/initiatives/services/initiative.service';
import { ToastrService } from 'ngx-toastr';
import { NgSelectComponent } from '@ng-select/ng-select';
import { AuthService } from '../services/auth.service';

@Component({
    selector: 'app-europe-assistance-local-login',
    templateUrl: './europe-assistance-local-login.component.html',
    styleUrls: ['./europe-assistance-local-login.component.scss']
})
export class EuropeAssistanceLocalLoginComponent implements OnInit, OnDestroy {
    isFetchingLangs: boolean;
    langs: Lang[];
    result$;
    showLoader: boolean;
    subscriptionFormVisible: boolean;
    resetPasswordFormVisible: boolean;
    isManagingUser$: Subscription;
    userId: string;
    token: string;
    translatedModalText: string;
    translatedModalTitle: string;
    isEmailConfirmation: boolean;
    noCheckNames: any;
    newValidationToken: string;
    recoveryUserPassword: boolean;
    isDemoUsersPublicLogin: boolean;
    @ViewChild('selectCenter') selectCenter;
    getCentersListInput$ = new Subject<string>();
    resetCentersList: boolean;
    searchedCentersText: string;
    isFetchingCenters: boolean;
    centersLoaded: number;
    centersList: User[];
    centersToRetrieve: number;
    selectedCentersList: any;

    constructor(private store: Store<fromApp.AppState>,
        public translate: TranslateService,
        private router: Router,
        private authService: AuthService,
        private toastr: ToastrService,
        private renderer: Renderer2) {
        this.centersLoaded = 0;
        this.centersList = [];
        this.centersToRetrieve = 10;
        // Sto in ascolto di quando il globalApplicationData cambia, così da sapere quando ci sono le lingue disponibili poiché, se non ci fossero, il pulsante per il login sarebbe disabilitato
        let availableLangs$: Observable<Lang[]> = this.store.select(fromApp.getAvailableLangs);
        let isFetchingLangs$: Observable<boolean> = this.store.select(fromApp.isFetchingLangs);
        const combinedSelectes$ = combineLatest(availableLangs$, isFetchingLangs$);
        this.result$ = combinedSelectes$.subscribe(
            ([langs, isFetchingLangs]) => {
                this.langs = langs;
                this.isFetchingLangs = isFetchingLangs;
            });
    }

    ngOnInit() {

        let europeAssistanceUserId = sessionStorage.getItem('europeAssistanceUserId');
        let europeAssistanceCid = sessionStorage.getItem('europeAssistanceCid');
        // Se ho lo userId (oppure il cid) nel session storage, posso subito eseguire il login
        if (europeAssistanceUserId || europeAssistanceCid) {
            this.showLoader = true;
            this.store.dispatch(new AuthActions.DoLogin({ userId: europeAssistanceUserId || europeAssistanceCid, password: null, isEuropeAssistance: true }));
            setTimeout(() => {
                this.translate.get('europeAssistance.SELECT_CENTER').subscribe((translatedText: string) => {
                    this.renderer.setAttribute(this.selectCenter && this.selectCenter.filterInput && this.selectCenter.filterInput.nativeElement, "aria-label", translatedText);
                })
                this.showLoader = false;
            }, 5000)
        }
        this.getPmList();
    }
    ngOnDestroy() {
        this.result$.unsubscribe();
        if (this.getCentersListInput$) {
            this.getCentersListInput$.unsubscribe();
        }
    }

    // Dal form passato come parametro, ricavo i dati immessi dall'utente per inserirli nel dispatch dell'action che tenterà il login
    onLogin(form: NgForm) {
        this.showLoader = true;
        const email = form.value.email;
        const password = form.value.password;
        this.store.dispatch(new AuthActions.DoLogin({ email: email, password: password, isEuropeAssistance: true }));
        this.showLoader = false;
    }

    changeInput(x: any) {
        if (x.viewModel.trim().length || x.viewModel == "") {
            return false;
        } else {
            return true;
        }

    }

    // Recupera una lista di centri
    getPmList() {
        this.getCentersListInput$.pipe(
            switchMap(
                (text) => {
                    if (!text) {
                        // Se non c'è il filtro di ricerca testuale non avvio nessuna ricerva per evitare il 'missing required data'
                        // Torno un observable simulando una senecaResponse
                        let newSenecaresponse = { response: 0 };
                        this.resetCentersList = true;
                        return Observable.create(obs => {
                            obs.next(newSenecaresponse);
                            obs.complete();
                        })
                    } else {
                        // Se richiesto, resetto i dati
                        if (text !== this.searchedCentersText) {
                            this.resetCentersList = true;
                        } else {
                            this.resetCentersList = false;
                        }

                        // Salvo il valore della ricerca testuale
                        this.searchedCentersText = text;
                        this.isFetchingCenters = true;
                        return this.authService.countCenters()
                    }
                }
            ),
            switchMap(
                (pmCounter: SenecaResponse<number>) => {
                    // Se richiesto, cancello di risultati precedenti in quanto sto eseguendo una nuova ricerca
                    if (this.resetCentersList) {
                        this.clearCentersList();
                    }
                    // Continuo solo se ho altri valori da recuperare
                    if (this.centersLoaded < pmCounter.response) {
                        return this.authService.getCenters()
                    } else {
                        // Torno un observable simulando una senecaResponse
                        let newSenecaresponse = { response: [] };
                        return Observable.create(obs => {
                            obs.next(newSenecaresponse);
                            obs.complete();
                        })
                    }
                }
            )
            , catchError((err, caught) => {
                // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
                return caught;
            })
        )
            .subscribe(
                (centersData: SenecaResponse<User[]>) => {
                    if (centersData.error) {
                        // Vedo se c'è la traduzione dell'errore
                        this.toastr.error(this.translate.instant('errors.' + centersData.error));
                    } else {
                        // Aggiungo i risultati alla lista, incrementando il numero di risultati ottenuti
                        this.centersLoaded += centersData.response.length;
                        this.centersList = this.centersList.concat(centersData.response);
                    }
                    this.isFetchingCenters = false;
                }
                , (err) => {
                    if (err && err.message) {
                        // Vedo se c'è la traduzione dell'errore
                        this.toastr.error(this.translate.instant('errors.' + err.message));
                    }
                    this.clearCentersList();
                    this.isFetchingCenters = false;
                }
            );
    }

    // Resetta la lista di centri
    clearCentersList(newPm?: User): void {
        this.centersLoaded = 0;
        this.centersList = [];
    }

    // Infinite scroll per i centri. Questa chiamata viene chiamata quando raggiungo il fondo della lista
    fetchMorePm(ngSelectRef: NgSelectComponent) {
        this.getCentersListInput$.next(ngSelectRef.filterValue);
    }

    updateCenterValue(data) {
        this.selectedCentersList = data;
    }

}