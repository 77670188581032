/*
*  Reducer che gestiscono e modificano lo State applicativo riguardante le iniziative (corsi)
*/

import * as CourseEditionActions from './course-edition.actions';
import * as CommonClasses from "../../../cm2-commonclasses";
import * as fromApp from '../../ngrx/app.reducers';
import { CourseEditionOptions, CourseEdition } from '../../shared/models/course.model';
import { JwtPayload } from "../../../cm2-commonclasses";

// Le edizioni sono una feature dell'applicazione, infatti è un modulo caricato in maniera lazy (e quindi anche lo Store).
// Creo un'interfaccia, FeatureState, in cui mappo le edizioni dello state InitiativeState
// Estendo fromApp.AppState così per ereditare tutte le proprietà dello Store principale
export interface FeatureState extends fromApp.AppState {
    courseEditionState: CourseEditionState;
}

// Interfaccia dello Store
export interface CourseEditionState {
    courseEdition: CourseEdition;
    lastAction: string;
    courseEditionOptions: CourseEditionOptions;
}

// Store iniziale
const initialState: CourseEditionState = {
    courseEdition: null,
    lastAction: '',
    courseEditionOptions: null
};


const _actionsHierarchy = {
    GET_COURSE_EDITION: {
        fromActions: [CourseEditionActions.NEW_COURSE_EDITION,
        CourseEditionActions.EDIT_COURSE_EDITION,
        CourseEditionActions.SET_COURSE_EDITION,
        CourseEditionActions.EDIT_COURSE_EDITION_OPTIONS
        ]
    },
    EDIT_COURSE_EDITION_OPTIONS: {
        fromActions: [CourseEditionActions.NEW_COURSE_EDITION,
        CourseEditionActions.EDIT_COURSE_EDITION,
        CourseEditionActions.EDIT_COURSE_EDITION_OPTIONS,
        CourseEditionActions.SET_COURSE_EDITION_OPITONS,
        CourseEditionActions.SET_COURSE_EDITION
        ]
    },
    PUBLISH: {
        fromActions: [
            CourseEditionActions.SET_COURSE_EDITION,
            CourseEditionActions.GET_COURSE_EDITION,
            CourseEditionActions.SET_COURSE_EDITION_OPITONS,
            CourseEditionActions.EDIT_COURSE_EDITION_OPTIONS,
            CourseEditionActions.ADD_DAY
        ]
    }
};

function _verifyActionByState(action: CourseEditionActions.CourseEditionActions, state: CourseEditionState) {
    let validAction = true;
    // se ho delle restrizioni vado a verificare da dove sto arrivando
    if (action && _actionsHierarchy[action.type]) {
        if (!state || _actionsHierarchy[action.type].fromActions.indexOf(state.lastAction) < 0) {
            validAction = false;
        }
    }
    // altrimenti permetto l'azione sullo state
    return validAction;
}

export function courseEditionReducer(state = initialState, action: CourseEditionActions.CourseEditionActions) {
    // se non ho eseguito nessuna azione posso creare o modificare una edizione
    if (!state.lastAction) {
        switch (action.type) {
            case CourseEditionActions.NEW_COURSE_EDITION:
            case CourseEditionActions.EDIT_COURSE_EDITION:
                return {
                    ...state,
                    lastAction: action.type,
                    courseEdition: action.payload // sovrascrivo per aggiornare lo state
                };
            default:
                return state;
        }
    } else if (_verifyActionByState(action, state)) {
        switch (action.type) {
            case CourseEditionActions.EDIT_COURSE_EDITION_OPTIONS:
                return {
                    ...state,
                    lastAction: action.type,
                    courseEditionOptions: action.payload // sovrascrivo per aggiornare lo state
                };
            case CourseEditionActions.CONFIRM_COURSE_EDITION_OPTIONS:
                return {
                    ...state,
                    lastAction: action.type,
                    courseEditionOptions: {
                        ...action.payload, // sovrascrivo per aggiornare lo state
                        confirmed: true
                    }
                };
            case CourseEditionActions.SET_COURSE_EDITION_OPITONS:
                // corrisponde alla conferma delle opzioni avanzate
                const _courseEdition = action.payload && state.courseEditionOptions ?
                    { ...state.courseEditionOptions, ...action.payload } : null;

                return {
                    ...state,
                    lastAction: action.type,
                    courseEditionOptions: _courseEdition // sovrascrivo per aggiornare lo state
                };
            case CourseEditionActions.SET_COURSE_EDITION:
                return {
                    ...state,
                    courseEdition: action.payload, // assegno le courseEditions con le options avanzate
                    lastAction: action.type
                };
            case CourseEditionActions.CLEAR_COURSE_EDITION_STATE:
                return initialState;
            default:
                return state;
        }
    }

}

// Export delle funzioni che tornano una parte dello Store applicativo
export const getCourseEdition = (state: CourseEditionState) => state.courseEdition;
export const getCourseEditionOptions = (state: CourseEditionState) => state.courseEditionOptions;
