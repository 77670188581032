/*
 * Servizio che permette le chiamate rest relative alle iniziative (corsi)
*/
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CourseManagerItem, Item, ItemAttributeStatusTypes, ItemChild, SenecaResponse, Survey, buildAutocompleteServiceKey, findCachedResult, storeCachedResult, Lang, WebinarRoom } from "../../../cm2-commonclasses";
import * as fromApp from '../../ngrx/app.reducers';
import { switchOnce } from '../../ngrx/util';
import { CourseDaySchedule, CourseEdition, CourseEditionOptions, CourseEditionUtil } from '../../shared/models/course.model';
import { GlobalApplicationData } from '../../shared/models/global-application-data.model';
import * as CourseEditionAction from '../ngrx/course-edition.actions';
import { ItemUtil } from '../../shared/models/item.model';
import { LangsService } from 'src/app/core/services/langs.service';
import * as InitiativeActions from '../ngrx/initiative.actions';

@Injectable({
    providedIn: 'root'
})
export class CourseEditionService {
    applicationData: GlobalApplicationData;
    result$: Subscription;

    updatingDatesAndPlaces: EventEmitter<any>;
    updateDatesAndPlacesDone: EventEmitter<any>;
    returnToWebinarEditEdition: EventEmitter<any>;
    addNewDateInstant: EventEmitter<any>;
    cacheMap: any;

    constructor(private store: Store<fromApp.AppState>, private http: HttpClient) {
        const globalApplicationData$: Observable<GlobalApplicationData> = this.store.select(fromApp.getGlobalApplicationData);
        const combinedSelectes$ = combineLatest(globalApplicationData$);
        this.result$ = combinedSelectes$.pipe(take(1)).subscribe(
            ([globalApplicationData]) => {
                this.applicationData = globalApplicationData;
            });

        this.updatingDatesAndPlaces = new EventEmitter();
        this.updateDatesAndPlacesDone = new EventEmitter();
        this.returnToWebinarEditEdition = new EventEmitter();
        this.addNewDateInstant = new EventEmitter();
        this.cacheMap = {};
    }

    // Servizio che crea una nuova edizione
    createCourseEdition(courseEdition: CourseEdition, publishNow?: boolean, withFullAttributesLoaded?: boolean, withFullChildrenAttributesLoaded?: boolean, itemAttributeTypesToResolve?: string[], prefillTemplates?: boolean, initiative?: Item) {
        // Imposto lo stato a DRAFT
        courseEdition.status = ItemAttributeStatusTypes.DRAFT;

        let parentItemLangs = initiative && initiative.itemLangs;
        // trasformo in Item insieme ai courseDay
        const editionItem: Item = CourseEditionUtil.createCourseEditionItem(courseEdition, initiative, parentItemLangs);
        const reqPayload: CourseManagerItem.CreateCourseEdition = <CourseManagerItem.CreateCourseEdition>{};
        // Se richiesto, prevalorizzo i template mail con quelli dell'iniziativa
        if (prefillTemplates && initiative) {
            CourseEditionUtil.prefillTemplateOnEdition(initiative, editionItem);
        }
        reqPayload.edition = editionItem;

        reqPayload.days = editionItem.itemChilds.map((itemChild: ItemChild) => {
            return itemChild.childObject;
        });

        reqPayload.stageItemId = courseEdition.parentCourse.stageItemId;
        reqPayload.courseModuleItemId = courseEdition.parentCourse.moduleItemId;
        reqPayload.withFullChildrenAttributesLoaded = withFullChildrenAttributesLoaded;
        reqPayload.withFullAttributesLoaded = withFullAttributesLoaded;
        reqPayload.itemAttributeTypesToResolve = itemAttributeTypesToResolve;
        reqPayload.publishNow = !!publishNow;

        let sessionStorageIsSupplier = sessionStorage.getItem('isSupplier') === "true" ? true : false;
        if (sessionStorageIsSupplier) {
            return this.http.post<SenecaResponse<Item>>(
                this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/create-course-edition-for-supplier', reqPayload);
        } else {
            return this.http.post<SenecaResponse<Item>>(
                this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/create-course-edition', reqPayload);
        }
    }

    // Servizio che importa una lista di edizioni webinar
    importWebinarCourseEditionsFromFile(uploadObj, courseEdition: CourseEdition, initiative?: Item) {
        let formData = new FormData();
        formData.append('file', uploadObj.file.file.rawFile);
        formData.append('stageItemId', courseEdition.parentCourse.stageItemId);
        formData.append('courseModuleItemId', courseEdition.parentCourse.moduleItemId);

        // Id dell'aula scelta
        let sambaLiveAccountId = courseEdition && courseEdition && courseEdition.courseSchedules && courseEdition.courseSchedules[0] && courseEdition.courseSchedules[0].webinarTopicAccountId && courseEdition.courseSchedules[0].webinarTopicAccountId.id;
        formData.append('sambaLiveAccountId', sambaLiveAccountId || '');

        // trasformo in Item insieme ai courseDay
        let parentItemLangs = initiative && initiative.itemLangs;
        const editionItem: Item = CourseEditionUtil.createCourseEditionItem(courseEdition, initiative, parentItemLangs);
        // Prevalorizzo i template mail con quelli dell'iniziativa
        if (initiative) {
            CourseEditionUtil.prefillTemplateOnEdition(initiative, editionItem);
        }

        if (editionItem.itemAttributes && editionItem.itemAttributes.length) {
            formData.append('editionAttributes', JSON.stringify(editionItem.itemAttributes));
        }

        if (editionItem.itemChilds && editionItem.itemChilds[0] && editionItem.itemChilds[0].childObject && editionItem.itemChilds[0].childObject.itemAttributes) {
            formData.append('dayAttributes', JSON.stringify(editionItem.itemChilds[0].childObject.itemAttributes));
        }

        let params = new HttpParams();
        const options = {
            params: params
        };

        return this.http.post<SenecaResponse<Item>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/import-webinar-course-editions-from-file', formData, options);
    }

    // Servizio che importa una lista di edizioni corso in aula
    importClassCourseEditionsFromFile(uploadObj, courseEdition: CourseEdition, initiative?: Item) {
        let formData = new FormData();
        formData.append('file', uploadObj.file.file.rawFile);
        formData.append('stageItemId', courseEdition.parentCourse.stageItemId);
        formData.append('courseModuleItemId', courseEdition.parentCourse.moduleItemId);

        // trasformo in Item insieme ai courseDay
        let parentItemLangs = initiative && initiative.itemLangs;
        const editionItem: Item = CourseEditionUtil.createCourseEditionItem(courseEdition, initiative, parentItemLangs);
        // Prevalorizzo i template mail con quelli dell'iniziativa
        if (initiative) {
            CourseEditionUtil.prefillTemplateOnEdition(initiative, editionItem);
        }

        if (editionItem.itemAttributes && editionItem.itemAttributes.length) {
            formData.append('editionAttributes', JSON.stringify(editionItem.itemAttributes));
        }

        if (editionItem.itemChilds && editionItem.itemChilds[0] && editionItem.itemChilds[0].childObject && editionItem.itemChilds[0].childObject.itemAttributes) {
            formData.append('dayAttributes', JSON.stringify(editionItem.itemChilds[0].childObject.itemAttributes));
        }

        let params = new HttpParams();
        const options = {
            params: params
        };

        return this.http.post<SenecaResponse<Item>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/import-physical-course-editions-from-file', formData, options);
    }

    // Servizio che cancella un upload
    deleteUploadFile(uploadId: string) {
        return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/delete-upload-file', {
            uploadId: uploadId
        });
    }

    // Recupera la survey associata ad un oggetto
    getSurveysTemplateByReferenceId(referenceId: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('referenceId', referenceId || '');
        return this.http.get<SenecaResponse<Survey>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-surveys-template-by-reference-id/' + referenceId, {
            params: httpParams
        });
    }

    // Recupera un upload
    getUploadById(uploadId?: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('uploadId', uploadId || '');
        return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-upload-by-id', {
            params: httpParams
        });
    }

    // Elimina un template survey
    deleteSurveyTemplate(referenceId: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('referenceId', referenceId || '');
        return this.http.delete<SenecaResponse<null>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/delete-survey-template', {
            params: httpParams
        });
    }

    // Esegue un upload generico
    uploadAnyFile(uploadObj: any) {
        let formData = new FormData();
        formData.append('file', uploadObj.file.file.rawFile);
        formData.append('referenceId', uploadObj.referenceId);
        formData.append('referenceType', uploadObj.referenceType);
        formData.append('surveyType', uploadObj.surveyType);
        return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/upload-any-file', formData);
    }

    // Esegue l'upload di un template di una Survey
    uploadSurveyTemplate(uploadObj: any) {
        let formData = new FormData();
        formData.append('file', uploadObj.file.file.rawFile);
        formData.append('referenceId', uploadObj.referenceId);
        formData.append('referenceType', uploadObj.referenceType);
        formData.append('surveyType', uploadObj.surveyType);
        formData.append('useCustomGroups', uploadObj.useCustomGroups);
        formData.append('isSurveyNotRepeatable', uploadObj.isSurveyNotRepeatable);
        formData.append('isSurveyCompletableMultipleTimes', uploadObj.isSurveyCompletableMultipleTimes);
        let params = new HttpParams();
        const options = {
            params: params
        };
        const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/upload-survey-template', formData, options);
        return this.http.request(req);
    }

    // Servizio che aggiorna una edizione
    updateCourseEdition(courseEdition: CourseEdition, withFullAttributesLoaded?: boolean, withFullChildrenAttributesLoaded?: boolean, itemAttributeTypesToResolve?: string[], currentLang?: Lang, langsService?: LangsService, skipReturnedObject?: boolean) {
        return this.store.select(fromApp.getSelectedInitiativeItem).pipe(
            switchOnce((selectedInitiative: Item) => {
                const editionItem = ItemUtil.getItemChildById(selectedInitiative, courseEdition.itemId);
                if (editionItem) {
                    // recuperare l'edizione con le options da uploadaree
                    // trasformo in Item insieme ai courseDay
                    CourseEditionUtil.updateCourseEditionItem(editionItem, courseEdition, selectedInitiative, currentLang, langsService);

                    const reqPayload: CourseManagerItem.UpdateCourseEdition = <CourseManagerItem.UpdateCourseEdition>{};
                    reqPayload.days = editionItem.itemChilds.map((itemChild: ItemChild) => {
                        return itemChild.childObject;
                    });

                    reqPayload.edition = {
                        ...editionItem, itemChilds: editionItem.itemChilds.map((child) => {
                            return { ...child, childObject: null };
                        })
                    };
                    reqPayload.stageItemId = courseEdition.parentCourse.stageItemId;
                    // reqPayload.courseModuleItemId = courseEdition.parentCourse.moduleItemId;
                    reqPayload.withFullChildrenAttributesLoaded = withFullChildrenAttributesLoaded;
                    reqPayload.skipReturnedObject = skipReturnedObject
                    reqPayload.withFullAttributesLoaded = withFullAttributesLoaded;
                    reqPayload.itemAttributeTypesToResolve = itemAttributeTypesToResolve;

                    let sessionStorageIsSupplier = sessionStorage.getItem('isSupplier') === "true" ? true : false;
                    if (sessionStorageIsSupplier) {
                        return this.http.post<SenecaResponse<Item>>(
                            this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/update-course-edition-for-supplier', reqPayload);
                    } else {
                        return this.http.post<SenecaResponse<Item>>(
                            this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/update-course-edition', reqPayload);
                    }
                } else {
                    return of(new SenecaResponse('ERROR_COURSE_EDITION_NOT_FOUND', null));
                }
            }),
            map((initiativeData: SenecaResponse<Item>) => {
                // Vedo se c'è la traduzione dell'errore
                if (initiativeData && !initiativeData.error && initiativeData.response) {
                    this.store.dispatch(new InitiativeActions.UpdateInitiative({
                        index: 0,
                        updatedInitiative: initiativeData.response
                    }));
                }
                return initiativeData;
            }),
            take(1)
        );
    }

    publishCourseEdition(courseEdition: CourseEdition, skipReturnedObject?: boolean) {
        return this.store.select(fromApp.getSelectedInitiativeItem).pipe(
            switchOnce((selectedInitiative: Item) => {
                const editionItem = ItemUtil.getItemChildById(selectedInitiative, courseEdition.itemId);
                if (editionItem) {
                    // recuperare l'edizione con le options da uploadaree
                    // trasformo in Item insieme ai courseDay
                    CourseEditionUtil.updateCourseEditionItem(editionItem, courseEdition, selectedInitiative);

                    const reqPayload: CourseManagerItem.CreateCourseEdition = <CourseManagerItem.CreateCourseEdition>{};
                    reqPayload.days = editionItem.itemChilds.map((itemChild: ItemChild) => {
                        return itemChild.childObject;
                    });

                    reqPayload.edition = {
                        ...editionItem, itemChilds: editionItem.itemChilds.map((child) => {
                            return { ...child, childObject: null };
                        })
                    };
                    reqPayload.stageItemId = courseEdition.parentCourse.stageItemId;
                    reqPayload.courseModuleItemId = courseEdition.parentCourse.moduleItemId;
                    reqPayload.publishNow = true;
                    reqPayload.skipReturnedObject = skipReturnedObject

                    let sessionStorageIsSupplier = sessionStorage.getItem('isSupplier') === "true" ? true : false;
                    if (sessionStorageIsSupplier) {
                        return this.http.post<SenecaResponse<Item>>(
                            this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/update-course-edition-for-supplier', reqPayload);
                    } else {
                        return this.http.post<SenecaResponse<Item>>(
                            this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/update-course-edition', reqPayload);
                    }
                } else {
                    return of(new SenecaResponse('ERROR_COURSE_EDITION_NOT_FOUND', null));
                }
            }),
            take(1)
        );
    }

    listAvailableWebinarRooms() {
        return this.http.get<SenecaResponse<WebinarRoom[]>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/list-available-webinar-rooms');
    }

    listAvailableCourseLocations(fromRecord, searchedText: string, useCache?: boolean) {
        const serviceCacheKey = buildAutocompleteServiceKey('courseLocations', false, fromRecord, 0, '', searchedText, useCache);

        if (serviceCacheKey) {
            const cachedResult = findCachedResult(this.cacheMap, serviceCacheKey, searchedText);
            if (cachedResult) {
                return of(cachedResult.response);
            }
        }

        let sessionStorageIsSupplier = sessionStorage.getItem('isSupplier') === "true" ? true : false;
        if (sessionStorageIsSupplier) {
            return this.http.get<any>(
                this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/list-available-course-locations-for-supplier', {
                params: { fromRecord: fromRecord, searchedText: searchedText }
            }).pipe(map((senecaResponse) => {
                if (serviceCacheKey && senecaResponse
                    && !senecaResponse.error && senecaResponse.response) {
                    storeCachedResult(this.cacheMap, serviceCacheKey, senecaResponse, searchedText);
                }
                return senecaResponse;
            }));
        } else {
            return this.http.get<any>(
                this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/search-available-course-location', {
                params: { fromRecord: fromRecord, searchedText: searchedText, numRecords: '25' }
            }).pipe(map((senecaResponse) => {
                if (serviceCacheKey && senecaResponse
                    && !senecaResponse.error && senecaResponse.response) {
                    storeCachedResult(this.cacheMap, serviceCacheKey, senecaResponse, searchedText);
                }
                return senecaResponse;
            }));
        }


    }

    listAvailableCourseLocationRooms(fromRecord, fullLocationText: string, searchedText: string, useCache?: boolean) {
        const serviceCacheKey = buildAutocompleteServiceKey('courseLocationRooms', false, fromRecord, 0,
            fullLocationText, searchedText, useCache);

        if (serviceCacheKey) {
            const cachedResult = findCachedResult(this.cacheMap, serviceCacheKey, searchedText);
            if (cachedResult) {
                return of(cachedResult.response);
            }
        }

        let sessionStorageIsSupplier = sessionStorage.getItem('isSupplier') === "true" ? true : false;
        if (sessionStorageIsSupplier) {
            return this.http.get<any>(
                this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/list-available-course-location-rooms-for-supplier', {
                params: { fromRecord: fromRecord, fullLocationText: fullLocationText, searchedText: searchedText }
            }).pipe(map((senecaResponse) => {
                if (serviceCacheKey && senecaResponse
                    && !senecaResponse.error && senecaResponse.response) {
                    storeCachedResult(this.cacheMap, serviceCacheKey, senecaResponse, searchedText);
                }
                return senecaResponse;
            }));
        } else {
            return this.http.get<any>(
                this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/list-available-course-location-rooms', {
                params: { fromRecord: fromRecord, fullLocationText: fullLocationText, searchedText: searchedText }
            }).pipe(map((senecaResponse) => {
                if (serviceCacheKey && senecaResponse
                    && !senecaResponse.error && senecaResponse.response) {
                    storeCachedResult(this.cacheMap, serviceCacheKey, senecaResponse, searchedText);
                }
                return senecaResponse;
            }));
        }

    }

    listAvailableTeachers(fromRecord, searchedText: string, useCache?: boolean) {
        const serviceCacheKey = buildAutocompleteServiceKey('courseTeachers', false, fromRecord, 0, '', searchedText, useCache);

        if (serviceCacheKey) {
            const cachedResult = findCachedResult(this.cacheMap, serviceCacheKey, searchedText);
            if (cachedResult) {
                return of(cachedResult.response);
            }
        }

        return this.http.get<any>(
            this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/list-available-teachers', {
            params: { fromRecord: fromRecord, searchedText: searchedText }
        }).pipe(map((senecaResponse) => {
            if (serviceCacheKey && senecaResponse
                && !senecaResponse.error && senecaResponse.response) {
                storeCachedResult(this.cacheMap, serviceCacheKey, senecaResponse, searchedText);
            }
            return senecaResponse;
        }));
    }

    listAvailableTutors(fromRecord, searchedText: string, useCache?: boolean) {
        const serviceCacheKey = buildAutocompleteServiceKey('courseTutors', false, fromRecord, 0, '', searchedText, useCache);

        if (serviceCacheKey) {
            const cachedResult = findCachedResult(this.cacheMap, serviceCacheKey, searchedText);
            if (cachedResult) {
                return of(cachedResult.response);
            }
        }

        return this.http.get<any>(
            this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/list-available-tutors', {
            params: { fromRecord: fromRecord, searchedText: searchedText }
        }).pipe(map((senecaResponse) => {
            if (serviceCacheKey && senecaResponse
                && !senecaResponse.error && senecaResponse.response) {
                storeCachedResult(this.cacheMap, serviceCacheKey, senecaResponse, searchedText);
            }
            return senecaResponse;
        }));
    }

    newCourseEdition(newCourseEdition?: CourseEdition) {
        return this.store.select(fromApp.getSelectedInitiativeItem).pipe(
            switchOnce((selectedInitiative: Item) => {
                const _newCourseEdition = newCourseEdition || new CourseEdition();
                _newCourseEdition.courseSchedules = new Array<CourseDaySchedule>();
                this.store.dispatch(new CourseEditionAction.NewCourseEdition(_newCourseEdition));
                return this.store.select(fromApp.getCourseEdition);
            }),
            take(1)
        );
    }

    editCourseEdition(courseEdition: CourseEdition) {
        return this.store.select(fromApp.getSelectedInitiativeItem).pipe(
            switchOnce((selectedInitiative: Item) => {
                this.store.dispatch(new CourseEditionAction.EditCourseEdition(courseEdition));
                return this.store.select(fromApp.getCourseEdition);
            }),
            take(1)
        );
    }

    editCourseEditionOptions(courseEdition: CourseEdition) {
        if (courseEdition) {
            const courseEditionOptions = <CourseEditionOptions>{ ...courseEdition, isOpened: true };
            this.store.dispatch(new CourseEditionAction.EditCourseEditionOptions(courseEditionOptions));
        }
    }

    confirmCourseEditionOptions(options: CourseEditionOptions) {
        if (options) {
            this.store.dispatch(new CourseEditionAction.ConfirmCourseEditionOptions(options));
        }
    }

    getCourseEditionOptions() {
        return this.store.select(fromApp.getCourseEditionOptions);
    }

    setCourseEdition(courseEdition: CourseEdition) {
        this.store.dispatch(new CourseEditionAction.SetCourseEdition(courseEdition));
    }

    setCourseEditionOptions(courseEditionOptions: CourseEditionOptions) {
        this.store.dispatch(new CourseEditionAction.SetCourseEditionOptions(courseEditionOptions));
    }

    clearCourseEditionOptions() {
        this.store.dispatch(new CourseEditionAction.SetCourseEditionOptions(null));
    }

    clearCourseEditionState() {
        this.store.dispatch(new CourseEditionAction.ClearCourseEditionState());
    }

    deleteCourseEdition(courseEditionId: String) {
        return this.http.post<any>(
            this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/delete-course-edition', {
            editionItemId: courseEditionId
        });
    }

    // Invia calendario personalizzato per edizione 
    sendCalendarEdition(editionId: string, title: string, email: string) {
        return this.http.post<any>(
            this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/send-feedbackup-calendar', {
            editionId: editionId,
            title: title,
            email: email
        });
    }
}
