/*
 * Gestione dei dispatch che devono eseguire qualcosa di asincrono e che non alterano lo State (i così chiamati Effect). Struttura molto simile ai Reducer,
 * che verificano il tipo di azione e concatenano una serie di operatori degli Observable per fare qualcosa. L'unica differenza, appunto, è che qui non
 * andiamo a modificare lo State, gestiamo solo lo Side Effect
 */

import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Router } from "@angular/router";
import { map, tap, switchMap, mergeMap } from "rxjs/operators";
import { from, Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import * as CoreActions from "../../core/ngrx/core.actions";
import * as ProfileActions from "../../profile/ngrx/profile.actions";
import { Store } from "@ngrx/store";
import * as AuthActions from "./auth.actions";
import { AuthService } from "../services/auth.service";
import { ToastrService } from "ngx-toastr";
import * as fromApp from "../../ngrx/app.reducers";
import { DeviceDetectorService } from "ngx-device-detector";
import {
    SenecaResponse,
    JwtPayload,
    SupplierPersonJwtPayload,
    SupplierTypes,
} from "../../../cm2-commonclasses";
import { TranslateService } from "@ngx-translate/core";
import { LangsService } from "../../core/services/langs.service";
import { authControl } from "src/app/shared/models/global-application-data.model";
import { UrlService } from "src/app/shared/services/url.service";

// Importo il componente per decodificare il token JWT
const jwtDecode = require("jwt-decode");

// Injectable perché abbiamo bisogno di importare le action e il routing
@Injectable()
export class AuthEffects {
    // actions$ col dollaro per marcare il fatto che è un Observable
    constructor(
        private store: Store<fromApp.AppState>,
        private actions$: Actions,
        private router: Router,
        private authService: AuthService,
        private translate: TranslateService,
        private toastr: ToastrService,
        private langsService: LangsService,
        private deviceService: DeviceDetectorService,
        private urlService: UrlService
    ) {
        let redirectUrl$: Observable<string> = this.store.select(
            fromApp.getRedirectUrl
        );
        redirectUrl$.subscribe(redirectUrl => {
            this.redirectUrl = redirectUrl;
        });
    }

    // Token intero recuperato dai servizi
    token: string;
    // Tiny recuperato dai servizi
    tinyToken: string;
    // Eventuale url richiesto
    redirectUrl: string;
    // Verifica se è un supplier
    isSupplier: boolean;
    // Verifica se è fundingSupplier
    isFundingSupplier: boolean;
    // Verifica se è un taker
    isTaker: boolean;
    isSso: boolean;
    // Verifica se è un utente welion per i test sierologici
    isWelionTs: boolean;
    isWelionTsSso: boolean;
    // Verifica se è un utente per l'ownership
    isOwnership: boolean;
    isOwnershipSso: boolean;
    // Verifica se è un utente enel
    isWelfareEnel: boolean;
    isWelfareEnelSso: boolean;
    isMeetingUser: boolean;
    isPublicMeetingUser: boolean;
    isWelionWelfarePublicMeetingUser: boolean;
    isOnlineMeetingUser: boolean;
    isOnlineMeetingTeacher: boolean;
    isOnlineMeetingSupport: boolean;
    isWelionWelfareTeacher?: boolean;
    isWelionWelfareSupport?: boolean;
    isEnelWelfareTeacher?: boolean;
    isEnelWelfareSupport?: boolean;
    isEuropeAssistance?: boolean;
    isEuropeAssistanceSso?: boolean;
    isSmartEnergyTaker?: boolean;
    isSmartEnergyTakerSso?: boolean;
    // Email della form del login
    authDataMail: string;
    tinyTokenObj;
    urlMeetingUp: any;
    isNudgingUser: boolean;
    isHeritageTaker?: boolean;

    @Effect()
    // Proprietà dell'Effect di cui NgRX starà in watch eseguendo il codice che gli assegnamo sulla destra.
    // Quindi, per prima cosa, si accede alle action dello Store applicativo (che abbiamo iniettato nel costruttore)
    authLogin$ = this.actions$
        .ofType(AuthActions.DO_LOGIN)
        // Ho bisogno del payload dell'action, e lo estraggo con il map()
        .pipe(
            map((action: AuthActions.DoLogin) => {
                this.isTaker = false;
                this.isWelionTs = false;
                this.isOwnership = false;
                this.isWelfareEnel = false;
                this.isEuropeAssistance = false;
                this.isSupplier = false;
                this.isMeetingUser = false;
                this.isOnlineMeetingUser = false;
                this.isPublicMeetingUser = false;
                this.isWelionWelfarePublicMeetingUser = false;
                this.isOnlineMeetingTeacher = false;
                this.isOnlineMeetingSupport = false;
                this.isWelionWelfareTeacher = false;
                this.isWelionWelfareSupport = false;
                this.isEnelWelfareTeacher = false;
                this.isEnelWelfareSupport = false;
                this.isSmartEnergyTaker = false;
                this.isNudgingUser = false;
                this.isHeritageTaker = false;
                // Grazie al map, il payload sarà wrappato in un nuovo Observable, così da poter far in seguito la concatenazione di altri operatori
                return action.payload;
            }),
            // Provo a fare il login. Lo switchMap riceve il contenuto dell'Observable che viene passato, quindi in questo caso il payload
            switchMap(
                (authData: {
                    email?: string;
                    password?: string;
                    publicMeetingUserCustomerUserId?: string;
                    isSupplier?: boolean;
                    isTaker?: boolean;
                    isSso?: boolean;
                    isWelionTs?: boolean;
                    isWelionTsSso?: boolean;
                    isOwnership?: boolean;
                    isOwnershipSso?: boolean;
                    isWelfareEnel?: boolean;
                    isWelfareEnelSso?: boolean;
                    isEuropeAssistance?: boolean;
                    isEuropeAssistanceSso?: boolean;
                    isMeetingUser?: boolean;
                    isOnlineMeetingUser?: boolean;
                    isPublicMeetingUser?: boolean;
                    isWelionWelfarePublicMeetingUser?: boolean;
                    isOnlineMeetingTeacher?: boolean;
                    isOnlineMeetingSupport?: boolean;
                    isWelionWelfareTeacher?: boolean;
                    isWelionWelfareSupport?: boolean;
                    isEnelWelfareTeacher?: boolean;
                    isEnelWelfareSupport?: boolean;
                    isSmartEnergyTaker?: boolean;
                    isSmartEnergyTakerSso?: boolean;
                    userId?: string;
                    isHeritage?: boolean;
                }) => {
                    // Salvo il fatto se è un supplier che sta eseguendo il login
                    this.isSupplier = authData.isSupplier;
                    // Salvo il fatto se è un taker che sta eseguendo il login
                    this.isTaker = authData.isTaker;

                    this.isWelionTs = authData.isWelionTs;
                    this.isWelionTsSso = authData.isWelionTsSso;

                    this.isOwnership = authData.isOwnership;
                    this.isOwnershipSso = authData.isOwnershipSso;

                    this.isWelfareEnel = authData.isWelfareEnel;
                    this.isWelfareEnelSso = authData.isWelfareEnelSso;

                    this.isEuropeAssistance = authData.isEuropeAssistance;
                    this.isEuropeAssistanceSso = authData.isEuropeAssistanceSso;

                    this.isSso = authData.isSso;
                    this.isMeetingUser = authData.isMeetingUser;
                    this.isOnlineMeetingUser = authData.isOnlineMeetingUser;
                    this.isPublicMeetingUser = authData.isPublicMeetingUser;
                    this.isWelionWelfarePublicMeetingUser = authData.isWelionWelfarePublicMeetingUser;
                    this.isOnlineMeetingTeacher = authData.isOnlineMeetingTeacher;
                    this.isOnlineMeetingSupport = authData.isOnlineMeetingSupport;
                    this.isWelionWelfareTeacher = authData.isWelionWelfareTeacher;
                    this.isWelionWelfareSupport = authData.isWelionWelfareSupport;
                    this.isEnelWelfareTeacher = authData.isEnelWelfareTeacher;
                    this.isEnelWelfareSupport = authData.isEnelWelfareSupport;
                    this.isSmartEnergyTaker = authData.isSmartEnergyTaker;
                    this.isSmartEnergyTakerSso = authData.isSmartEnergyTakerSso;
                    this.isHeritageTaker = authData.isHeritage;
                    this.authDataMail = authData.email;
                    // Prima di chiamare il servizio per il login, identifico l'userAgent e il tipo di device dell'utente
                    const deviceInfo = this.deviceService.getDeviceInfo();
                    const userAgent = deviceInfo && deviceInfo.userAgent;
                    let deviceType;
                    if (this.deviceService.isMobile()) {
                        // Salvo il fatto che è uno smartphone
                        deviceType = "P";
                    } else if (this.deviceService.isTablet()) {
                        // Salvo il fatto che è un tablet
                        deviceType = "T";
                    } else if (this.deviceService.isDesktop()) {
                        // Salvo il fatto che è un computer desktop
                        deviceType = "D";
                    }
                    // L'effect, alla fine, si aspetta sempre di tornare un Observable; per questo convertiamo la Promise in un Observable grazie al metodo from()
                    if (this.isSupplier) {
                        this.store.dispatch(new AuthActions.SetIsSupplier());
                        // Poichè quando si entra in home non è ancora stato settato il fatto che l'utente è un supplier, devo forzare il dato in qualche modo
                        sessionStorage.setItem("isSupplier", "true");
                        return from(
                            this.authService.loginSupplier(
                                authData.email,
                                authData.password,
                                deviceType,
                                userAgent
                            )
                        );
                    } else if (this.isTaker) {
                        if (authData.isSso) {
                            // Se non ho ricevuto nessun errore nella risposta, procedo con il convertire il tiny token
                            return from(this.authService.getJWTToken(authData.email));
                        } else {
                            return from(
                                this.authService.loginTaker(
                                    authData.email,
                                    deviceType,
                                    userAgent
                                )
                            );
                        }
                    } else if (this.isSmartEnergyTaker) {
                        if (authData.isSmartEnergyTakerSso) {
                            // Se non ho ricevuto nessun errore nella risposta, procedo con il convertire il tiny token
                            return from(this.authService.getJWTToken(authData.email));
                        } else {
                            return from(
                                this.authService.loginTaker(
                                    authData.email,
                                    deviceType,
                                    userAgent
                                )
                            );
                        }
                    } else if (this.isWelionTs) {
                        if (authData.isWelionTsSso) {
                            // Se non ho ricevuto nessun errore nella risposta, procedo con il convertire il tiny token
                            return from(this.authService.getJWTToken(authData.email));
                        } else {
                            return from(
                                this.authService.loginTaker(
                                    authData.email,
                                    deviceType,
                                    userAgent
                                )
                            );
                        }
                    } else if (this.isEuropeAssistance) {
                        if (authData.isEuropeAssistanceSso) {
                            // Se non ho ricevuto nessun errore nella risposta, procedo con il convertire il tiny token
                            return from(this.authService.getJWTToken(authData.email));
                        } else {
                            return from(
                                this.authService.loginTaker(
                                    authData.email,
                                    deviceType,
                                    userAgent
                                )
                            );
                        }
                    } else if (this.isOwnership) {
                        if (authData.isOwnershipSso) {
                            // Se non ho ricevuto nessun errore nella risposta, procedo con il convertire il tiny token
                            return from(this.authService.getJWTToken(authData.email));
                        } else {
                            return from(
                                this.authService.loginTaker(
                                    authData.email,
                                    deviceType,
                                    userAgent
                                )
                            );
                        }
                    } else if (this.isWelfareEnel || this.isEnelWelfareTeacher || this.isEnelWelfareSupport) {
                        if (authData.isWelfareEnelSso) {
                            // Se non ho ricevuto nessun errore nella risposta, procedo con il convertire il tiny token
                            return from(this.authService.getJWTToken(authData.email));
                        } else {
                            if (this.isEnelWelfareTeacher || this.isEnelWelfareSupport) {
                                return from(
                                    this.authService.checkWebinarPagePassword(
                                        authData.email,
                                        deviceType,
                                        userAgent
                                    )
                                );
                            } else {
                                return from(
                                    this.authService.searchCustomerForPublic(
                                        authData.email,
                                        authData.password,
                                        deviceType,
                                        userAgent,
                                        'WELION_ENEL',
                                        authData.userId
                                    )
                                );
                            }
                        }
                    } else if (this.isWelionWelfarePublicMeetingUser || this.isPublicMeetingUser || this.isOnlineMeetingUser || this.isWelionWelfareTeacher || this.isWelionWelfareSupport || this.isOnlineMeetingTeacher || this.isOnlineMeetingSupport) {
                        if (authData.isSso) {
                            // Se non ho ricevuto nessun errore nella risposta, procedo con il convertire il tiny token
                            return from(this.authService.getJWTToken(authData.email));
                        } else {
                            if (this.isWelionWelfareTeacher || this.isWelionWelfareSupport || this.isOnlineMeetingTeacher || this.isOnlineMeetingSupport) {
                                return from(
                                    this.authService.checkWebinarPagePassword(
                                        authData.email,
                                        deviceType,
                                        userAgent
                                    )
                                );
                            } else {
                                // Se ho la password significa che sto facendo il login come utente pubblico (/#/publicUsersLogin, oppure /#/demoUsersPublicLogin) nell'energy smart
                                if (this.isPublicMeetingUser && (authData.password || authData.publicMeetingUserCustomerUserId)) {
                                    return from(
                                        this.authService.searchCustomerForPublic(
                                            authData.email,
                                            authData.password,
                                            deviceType,
                                            userAgent,
                                            'GEN',
                                            authData.publicMeetingUserCustomerUserId
                                        )
                                    );
                                } else if (this.isWelionWelfarePublicMeetingUser && authData.password) {
                                    // Se ho la password significa che sto facendo il login come utente pubblico (/#/welionWelfareUsersLogin) nella pagina di welfare welion
                                    return from(
                                        this.authService.searchCustomerForPublic(
                                            authData.email,
                                            authData.password,
                                            deviceType,
                                            userAgent,
                                            'WELION_MONDADORI'
                                        )
                                    );
                                } else {
                                    return from(
                                        this.authService.loginTaker(
                                            authData.email,
                                            deviceType,
                                            userAgent
                                        )
                                    );
                                }
                            }
                        }
                    } else if (this.isMeetingUser) {
                        if (authData.isSso) {
                            // Se non ho ricevuto nessun errore nella risposta, procedo con il convertire il tiny token
                            return from(this.authService.getJWTToken(authData.email));
                        } else {
                            return from(
                                this.authService.loginTaker(
                                    authData.email,
                                    deviceType,
                                    userAgent
                                )
                            );
                        }
                    } else if (this.isHeritageTaker) {
                        if (authData.isSso) {
                            // Se non ho ricevuto nessun errore nella risposta, procedo con il convertire il tiny token
                            return of({ response: authData.email });//from(this.authService.getJWTToken(authData.email));
                        } else {
                            return from(
                                this.authService.login(
                                    authData.email,
                                    authData.password,
                                    deviceType,
                                    userAgent
                                )
                            );
                        }
                    }
                    else {
                        return from(
                            this.authService.login(
                                authData.email,
                                authData.password,
                                deviceType,
                                userAgent
                            )
                        );
                    }
                }
            ), // , take(1) DA NON USARE NELL'EFFECT! Essendo quest'ultimo un singleton, una volta fatto l'unsubscribe tramite il take(1), non farà più il subscribe. Pertanto, se si provasse a fare il dispatch intercettato da questo Effect, non produrrebbe più, appunto, nessun effect e non entrerebbe nel metodo
            switchMap((tinyTokenObj: SenecaResponse<any>) => {
                if (tinyTokenObj.error) {
                    throw new Error(tinyTokenObj.error);
                }

                this.tinyTokenObj = tinyTokenObj;

                if ((!this.isTaker && !this.isSmartEnergyTaker && !this.isWelionTs
                    && !this.isWelfareEnel && !this.isMeetingUser && !this.isPublicMeetingUser
                    && !this.isWelionWelfarePublicMeetingUser && !this.isOnlineMeetingUser
                    && !this.isOnlineMeetingTeacher && !this.isOnlineMeetingSupport
                    && !this.isWelionWelfareTeacher && !this.isWelionWelfareSupport
                    && !this.isEnelWelfareTeacher && !this.isEnelWelfareSupport && !this.isEuropeAssistance && !this.isOwnership && (!this.isHeritageTaker && !this.isSso)) || this.isSupplier) {
                    // Devo determinare se l'utente è un amministratore, un manager o entrambe le cose
                    //  if (this.isSso) {
                    //    return from(this.authService.getJWTToken(this.authDataMail));
                    // } else {
                    return from(this.authService.getJWTToken(tinyTokenObj.response));
                    // }
                } else if (this.isHeritageTaker) {
                    return from(this.authService.getJWTToken(tinyTokenObj.response));
                } else {
                    return of(tinyTokenObj);
                }
            }), // , take(1) DA NON USARE NELL'EFFECT! Essendo quest'ultimo un singleton, una volta fatto l'unsubscribe tramite il take(1), non farà più il subscribe. Pertanto, se si provasse a fare il dispatch intercettato da questo Effect, non produrrebbe più, appunto, nessun effect e non entrerebbe nel metodo
            mergeMap((tinyTokenObj: SenecaResponse<any>) => {
                let actionsContainer = [];
                if (this.isTaker) {
                    if (tinyTokenObj.error) {
                        throw new Error(tinyTokenObj.error);
                    }
                    if (!tinyTokenObj.error && !tinyTokenObj.response) {
                        throw new Error("USER_NOT_FOUND");
                    }
                    if (this.isSso) {
                        if (this.redirectUrl) {
                            this.router.navigate([this.redirectUrl]);
                        } else {
                            let storageRedirectUrl = sessionStorage.getItem("redirectUrl");
                            if (!storageRedirectUrl) {
                                this.toastr.warning(
                                    this.translate.instant("warnings.PLEASE_ACCESS_WITH_URL")
                                );
                            } else {
                                this.router.navigate([sessionStorage.getItem("redirectUrl")]);
                            }
                        }
                        let decodedJwt: JwtPayload = jwtDecode(tinyTokenObj.response);
                        const _loggedUser = new JwtPayload();
                        _loggedUser.user = decodedJwt.user;
                        let updatedUser = {
                            loggedUser: _loggedUser
                        };

                        actionsContainer.push(
                            {
                                type: CoreActions.SET_APPLICATION_LANG,
                                payload: decodedJwt.user.userOptions.langCode
                            },
                            {
                                type: AuthActions.SET_IS_TAKER,
                                payload: "true"
                            },
                            {
                                type: AuthActions.SET_TOKEN, // salvo il token
                                payload: tinyTokenObj.response
                            },
                            {
                                type: AuthActions.SET_USER_AUTHENTICATED, // setto l'utente come autenticato
                                payload: { isSupplier: this.isSupplier, isTaker: this.isTaker }
                            },
                            {
                                type: CoreActions.REMOVE_REDIRECT_URL
                            },
                            {
                                type: ProfileActions.UPDATE_USER, // setto l'utente come autenticato
                                payload: updatedUser
                            }
                        );
                    } else {
                        let langToUse = tinyTokenObj.response.userOptions.langCode;
                        // Prima di salvare la lingua dello store applicativo, la imposto anche per il componente che si occupa delle traduzioni
                        this.langsService.useLanguage(langToUse);
                        const _loggedUser = new JwtPayload();
                        _loggedUser.user = tinyTokenObj.response;

                        let updatedUser = {
                            loggedUser: _loggedUser
                        };

                        sessionStorage.setItem("takerEmail", tinyTokenObj.response.email);

                        actionsContainer.push(
                            {
                                type: CoreActions.SET_APPLICATION_LANG,
                                payload: langToUse
                            },
                            {
                                type: AuthActions.SET_IS_TAKER,
                                payload: "true"
                            },
                            {
                                type: AuthActions.SET_USER_AUTHENTICATED, // setto l'utente come autenticato
                                payload: {
                                    isSupplier: this.isSupplier,
                                    isTaker: this.isTaker,
                                    isFundingSupplier: this.isFundingSupplier
                                }
                            },
                            {
                                type: ProfileActions.UPDATE_USER, // setto l'utente come autenticato
                                payload: updatedUser
                            }
                        );
                        if (this.redirectUrl) {
                            this.router.navigate([this.redirectUrl]);
                        } else {
                            let storageRedirectUrl = sessionStorage.getItem("redirectUrl");
                            if (!storageRedirectUrl) {
                                this.toastr.warning(
                                    this.translate.instant("warnings.PLEASE_ACCESS_WITH_URL")
                                );
                                // this.router.navigate([sessionStorage.getItem('redirectUrl')]);
                            } else {
                                this.router.navigate([sessionStorage.getItem("redirectUrl")]);
                            }
                        }
                    }
                } else if (this.isWelionTs) {
                    if (tinyTokenObj.error) {
                        throw new Error(tinyTokenObj.error);
                    }
                    if (!tinyTokenObj.error && !tinyTokenObj.response) {
                        throw new Error("USER_NOT_FOUND");
                    }
                    if (this.isWelionTsSso) {
                        if (this.redirectUrl) {
                            this.router.navigate([this.redirectUrl]);
                        } else {
                            let storageRedirectUrl = sessionStorage.getItem("redirectUrl");
                            if (!storageRedirectUrl) {
                                /* this.toastr.warning(
                                   this.translate.instant("warnings.PLEASE_ACCESS_WITH_URL")
                                 );*/
                                this.router.navigate(["welionsUsersTS/availableSubscriptions/sso/d08afc12-aa2a-45ef-ac88-c362d3e544fb"]);
                            } else {
                                this.router.navigate([sessionStorage.getItem("redirectUrl")]);
                            }
                        }
                        let decodedJwt: JwtPayload = jwtDecode(tinyTokenObj.response);
                        const _loggedUser = new JwtPayload();
                        _loggedUser.user = decodedJwt.user;
                        let updatedUser = {
                            loggedUser: _loggedUser
                        };

                        actionsContainer.push(
                            {
                                type: CoreActions.SET_APPLICATION_LANG,
                                payload: decodedJwt.user.userOptions.langCode
                            },
                            {
                                type: AuthActions.SET_IS_WELION_TS,
                                payload: "true"
                            },
                            {
                                type: AuthActions.SET_TOKEN, // salvo il token
                                payload: tinyTokenObj.response
                            },
                            {
                                type: AuthActions.SET_USER_AUTHENTICATED, // setto l'utente come autenticato
                                payload: { isWelionTs: this.isWelionTs }
                            },
                            {
                                type: CoreActions.REMOVE_REDIRECT_URL
                            },
                            {
                                type: ProfileActions.UPDATE_USER, // setto l'utente come autenticato
                                payload: updatedUser
                            }
                        );
                    } else {
                        let langToUse = tinyTokenObj.response.userOptions.langCode;
                        // Prima di salvare la lingua dello store applicativo, la imposto anche per il componente che si occupa delle traduzioni
                        this.langsService.useLanguage(langToUse);
                        const _loggedUser = new JwtPayload();
                        _loggedUser.user = tinyTokenObj.response;

                        let updatedUser = {
                            loggedUser: _loggedUser
                        };

                        sessionStorage.setItem("welionTSEmail", tinyTokenObj.response.email);

                        actionsContainer.push(
                            {
                                type: CoreActions.SET_APPLICATION_LANG,
                                payload: langToUse
                            },
                            {
                                type: AuthActions.SET_IS_WELION_TS,
                                payload: "true"
                            },
                            {
                                type: AuthActions.SET_USER_AUTHENTICATED, // setto l'utente come autenticato
                                payload: {
                                    isWelionTs: this.isWelionTs
                                }
                            },
                            {
                                type: ProfileActions.UPDATE_USER, // setto l'utente come autenticato
                                payload: updatedUser
                            }
                        );
                        if (this.redirectUrl) {
                            this.router.navigate([this.redirectUrl]);
                        } else {
                            let storageRedirectUrl = sessionStorage.getItem("redirectUrl");
                            if (!storageRedirectUrl) {
                                /* this.toastr.warning(
                                   this.translate.instant("warnings.PLEASE_ACCESS_WITH_URL")
                                 ); */
                                this.router.navigate(["welionsUsersTS/availableSubscriptions/d08afc12-aa2a-45ef-ac88-c362d3e544fb"]);
                            } else {
                                this.router.navigate([sessionStorage.getItem("redirectUrl")]);
                            }
                        }
                    }
                } else if (this.isOwnership) {
                    if (tinyTokenObj.error) {
                        throw new Error(tinyTokenObj.error);
                    }
                    if (!tinyTokenObj.error && !tinyTokenObj.response) {
                        throw new Error("USER_NOT_FOUND");
                    }
                    if (this.isOwnershipSso) {
                        if (this.redirectUrl) {
                            this.router.navigate([this.redirectUrl]);
                        } else {
                            let storageRedirectUrl = sessionStorage.getItem("redirectUrl");
                            if (!storageRedirectUrl) {
                                this.toastr.warning(
                                    this.translate.instant("warnings.PLEASE_ACCESS_WITH_URL")
                                );
                            } else {
                                this.router.navigate([sessionStorage.getItem("redirectUrl")]);
                            }
                        }
                        let decodedJwt: JwtPayload = jwtDecode(tinyTokenObj.response);
                        const _loggedUser = new JwtPayload();
                        _loggedUser.user = decodedJwt.user;
                        let updatedUser = {
                            loggedUser: _loggedUser
                        };

                        actionsContainer.push(
                            {
                                type: CoreActions.SET_APPLICATION_LANG,
                                payload: decodedJwt.user.userOptions.langCode
                            },
                            {
                                type: AuthActions.SET_IS_WELION_TS,
                                payload: "true"
                            },
                            {
                                type: AuthActions.SET_TOKEN, // salvo il token
                                payload: tinyTokenObj.response
                            },
                            {
                                type: AuthActions.SET_USER_AUTHENTICATED, // setto l'utente come autenticato
                                payload: { isOwnership: this.isOwnership }
                            },
                            {
                                type: CoreActions.REMOVE_REDIRECT_URL
                            },
                            {
                                type: ProfileActions.UPDATE_USER, // setto l'utente come autenticato
                                payload: updatedUser
                            }
                        );
                    } else {
                        let langToUse = tinyTokenObj.response.userOptions.langCode;
                        // Prima di salvare la lingua dello store applicativo, la imposto anche per il componente che si occupa delle traduzioni
                        this.langsService.useLanguage(langToUse);
                        const _loggedUser = new JwtPayload();
                        _loggedUser.user = tinyTokenObj.response;

                        let updatedUser = {
                            loggedUser: _loggedUser
                        };

                        sessionStorage.setItem("ownershipEmail", tinyTokenObj.response.email);

                        actionsContainer.push(
                            {
                                type: CoreActions.SET_APPLICATION_LANG,
                                payload: langToUse
                            },
                            {
                                type: AuthActions.SET_IS_WELION_TS,
                                payload: "true"
                            },
                            {
                                type: AuthActions.SET_USER_AUTHENTICATED, // setto l'utente come autenticato
                                payload: {
                                    isOwnership: this.isOwnership
                                }
                            },
                            {
                                type: ProfileActions.UPDATE_USER, // setto l'utente come autenticato
                                payload: updatedUser
                            }
                        );
                        if (this.redirectUrl) {
                            this.router.navigate([this.redirectUrl]);
                        } else {
                            let storageRedirectUrl = sessionStorage.getItem("redirectUrl");
                            if (!storageRedirectUrl) {
                                this.toastr.warning(
                                    this.translate.instant("warnings.PLEASE_ACCESS_WITH_URL")
                                );
                            } else {
                                this.router.navigate([sessionStorage.getItem("redirectUrl")]);
                            }
                        }
                    }
                } else if (this.isWelfareEnel) {
                    if (tinyTokenObj.error) {
                        throw new Error(tinyTokenObj.error);
                    }
                    if (!tinyTokenObj.error && !tinyTokenObj.response) {
                        throw new Error("USER_NOT_FOUND");
                    }
                    if (this.isWelfareEnelSso) {
                        //   if (this.redirectUrl) {
                        //     this.router.navigate([this.redirectUrl]);
                        //   } else {
                        //     let storageRedirectUrl = sessionStorage.getItem("redirectUrl");
                        //     if (!storageRedirectUrl) {
                        /* this.toastr.warning(
                           this.translate.instant("warnings.PLEASE_ACCESS_WITH_URL")
                         );*/
                        // this.router.navigate(["myWelfareEnel/sso/userMeetings"]);
                        this.router.navigate(["myWelfareEnel/userMeetings"]);
                        //    } else {
                        //      this.router.navigate([sessionStorage.getItem("redirectUrl")]);
                        //    }
                        //  }
                        let decodedJwt: JwtPayload = jwtDecode(tinyTokenObj.response);
                        const _loggedUser = new JwtPayload();
                        _loggedUser.user = decodedJwt.user;
                        let updatedUser = {
                            loggedUser: _loggedUser
                        };

                        actionsContainer.push(
                            {
                                type: CoreActions.SET_APPLICATION_LANG,
                                payload: decodedJwt.user.userOptions.langCode
                            },
                            {
                                type: AuthActions.SET_IS_WELFARE_ENEL,
                                payload: "true"
                            },
                            {
                                type: AuthActions.SET_TOKEN, // salvo il token
                                payload: tinyTokenObj.response
                            },
                            {
                                type: AuthActions.SET_USER_AUTHENTICATED, // setto l'utente come autenticato
                                payload: { isWelfareEnel: this.isWelfareEnel }
                            },
                            {
                                type: CoreActions.REMOVE_REDIRECT_URL
                            },
                            {
                                type: ProfileActions.UPDATE_USER, // setto l'utente come autenticato
                                payload: updatedUser
                            }
                        );
                    } else {
                        let langToUse = tinyTokenObj.response.userOptions.langCode;
                        // Prima di salvare la lingua dello store applicativo, la imposto anche per il componente che si occupa delle traduzioni
                        this.langsService.useLanguage(langToUse);
                        const _loggedUser = new JwtPayload();
                        _loggedUser.user = tinyTokenObj.response;

                        let updatedUser = {
                            loggedUser: _loggedUser
                        };

                        sessionStorage.setItem("welfareEnelUserId", tinyTokenObj.response.userId);

                        actionsContainer.push(
                            {
                                type: CoreActions.SET_APPLICATION_LANG,
                                payload: langToUse
                            },
                            {
                                type: AuthActions.SET_IS_WELFARE_ENEL,
                                payload: "true"
                            },
                            {
                                type: AuthActions.SET_USER_AUTHENTICATED, // setto l'utente come autenticato
                                payload: {
                                    isWelfareEnel: this.isWelfareEnel
                                }
                            },
                            {
                                type: ProfileActions.UPDATE_USER, // setto l'utente come autenticato
                                payload: updatedUser
                            }
                        );
                        //   if (this.redirectUrl) {
                        //     this.router.navigate([this.redirectUrl]);
                        //   } else {
                        //     let storageRedirectUrl = sessionStorage.getItem("redirectUrl");
                        //     if (!storageRedirectUrl) {
                        /* this.toastr.warning(
                           this.translate.instant("warnings.PLEASE_ACCESS_WITH_URL")
                         ); */
                        this.router.navigate(["myWelfareEnel/userMeetings"]);
                        //   this.router.navigate(["myWelfareEnel/sso/userMeetings"]);
                        //      } else {
                        //        this.router.navigate([sessionStorage.getItem("redirectUrl")]);
                        //      }
                        //    }
                    }
                } else if (this.isEuropeAssistance) {
                    if (tinyTokenObj.error) {
                        throw new Error(tinyTokenObj.error);
                    }
                    if (!tinyTokenObj.error && !tinyTokenObj.response) {
                        throw new Error("USER_NOT_FOUND");
                    }
                    if (this.isEuropeAssistance) {
                        if (this.redirectUrl) {
                            this.router.navigate([this.redirectUrl]);
                        } else {
                            let storageRedirectUrl = sessionStorage.getItem("redirectUrl");
                            if (!storageRedirectUrl) {
                                this.toastr.warning(
                                    this.translate.instant("warnings.PLEASE_ACCESS_WITH_URL")
                                );
                            } else {
                                this.router.navigate([sessionStorage.getItem("redirectUrl")]);
                            }
                        }
                        let decodedJwt: JwtPayload = jwtDecode(tinyTokenObj.response);
                        const _loggedUser = new JwtPayload();
                        _loggedUser.user = decodedJwt.user;
                        let updatedUser = {
                            loggedUser: _loggedUser
                        };

                        actionsContainer.push(
                            {
                                type: CoreActions.SET_APPLICATION_LANG,
                                payload: decodedJwt.user.userOptions.langCode
                            },
                            {
                                type: AuthActions.SET_IS_EUROPE_ASSISTANCE,
                                payload: "true"
                            },
                            {
                                type: AuthActions.SET_TOKEN, // salvo il token
                                payload: tinyTokenObj.response
                            },
                            {
                                type: AuthActions.SET_USER_AUTHENTICATED, // setto l'utente come autenticato
                                payload: { isEuropeAssistance: true }
                            },
                            {
                                type: CoreActions.REMOVE_REDIRECT_URL
                            },
                            {
                                type: ProfileActions.UPDATE_USER, // setto l'utente come autenticato
                                payload: updatedUser
                            }
                        );
                    } else {
                        let langToUse = tinyTokenObj.response.userOptions.langCode;
                        // Prima di salvare la lingua dello store applicativo, la imposto anche per il componente che si occupa delle traduzioni
                        this.langsService.useLanguage(langToUse);
                        const _loggedUser = new JwtPayload();
                        _loggedUser.user = tinyTokenObj.response;

                        let updatedUser = {
                            loggedUser: _loggedUser
                        };

                        sessionStorage.setItem("europeAssistanceUserId", tinyTokenObj.response.userId);

                        actionsContainer.push(
                            {
                                type: CoreActions.SET_APPLICATION_LANG,
                                payload: langToUse
                            },
                            {
                                type: AuthActions.SET_IS_EUROPE_ASSISTANCE,
                                payload: "true"
                            },
                            {
                                type: AuthActions.SET_USER_AUTHENTICATED, // setto l'utente come autenticato
                                payload: {
                                    isEuropeAssistance: true
                                }
                            },
                            {
                                type: ProfileActions.UPDATE_USER, // setto l'utente come autenticato
                                payload: updatedUser
                            }
                        );
                        if (this.redirectUrl) {
                            this.router.navigate([this.redirectUrl]);
                        } else {
                            let storageRedirectUrl = sessionStorage.getItem("redirectUrl");
                            if (!storageRedirectUrl) {
                                this.toastr.warning(
                                    this.translate.instant("warnings.PLEASE_ACCESS_WITH_URL")
                                );
                                // this.router.navigate([sessionStorage.getItem('redirectUrl')]);
                            } else {
                                this.router.navigate([sessionStorage.getItem("redirectUrl")]);
                            }
                        }
                    }
                } else if (this.isMeetingUser) {
                    if (tinyTokenObj.error) {
                        throw new Error(tinyTokenObj.error);
                    }
                    if (!tinyTokenObj.error && !tinyTokenObj.response) {
                        throw new Error("USER_NOT_FOUND");
                    }

                    if (this.isSso) {
                        if (this.redirectUrl) {
                            this.router.navigate([this.redirectUrl]);
                        } else {
                            let storageRedirectUrl = sessionStorage.getItem("redirectUrl");
                            if (!storageRedirectUrl) {
                                this.toastr.warning(
                                    this.translate.instant("warnings.PLEASE_ACCESS_WITH_URL")
                                );
                            } else {
                                this.router.navigate([sessionStorage.getItem("redirectUrl")]);
                            }
                        }
                        // In questo caso è stato chiamato il getJWTToken che contiene nella response (quindi in tinyTokenObj.response) il token
                        let decodedJwt: JwtPayload = jwtDecode(tinyTokenObj.response);
                        const _loggedUser = new JwtPayload();
                        _loggedUser.user = decodedJwt.user;
                        let updatedUser = {
                            loggedUser: _loggedUser
                        };

                        actionsContainer.push(
                            {
                                type: CoreActions.SET_APPLICATION_LANG,
                                payload: decodedJwt.user.userOptions.langCode
                            },
                            {
                                type: AuthActions.SET_TOKEN,
                                payload: tinyTokenObj.response
                            },
                            {
                                type: AuthActions.SET_USER_AUTHENTICATED, // setto l'utente come autenticato
                                payload: {
                                    isSupplier: this.isSupplier,
                                    isTaker: this.isTaker,
                                    isMeetingUser: this.isMeetingUser
                                }
                            },
                            {
                                type: CoreActions.REMOVE_REDIRECT_URL
                            },
                            {
                                type: ProfileActions.UPDATE_USER, // setto l'utente come autenticato
                                payload: updatedUser
                            }
                        );
                    } else {
                        // In questo caso è stato chiamato il loginTaker che contiene nella response (quindi in tinyTokenObj.response) l'oggetto intero dello User
                        let langToUse = tinyTokenObj.response.userOptions.langCode;
                        // Prima di salvare la lingua dello store applicativo, la imposto anche per il componente che si occupa delle traduzioni
                        this.langsService.useLanguage(langToUse);
                        let updatedUser = {
                            loggedUser: tinyTokenObj.response
                        };

                        sessionStorage.setItem("meetingUserEmail", tinyTokenObj.response.email);

                        actionsContainer.push(
                            {
                                type: CoreActions.SET_APPLICATION_LANG,
                                payload: langToUse
                            },
                            {
                                type: AuthActions.SET_USER_AUTHENTICATED, // setto l'utente come autenticato
                                payload: {
                                    isSupplier: this.isSupplier,
                                    isTaker: this.isTaker,
                                    isFundingSupplier: this.isFundingSupplier,
                                    isMeetingUser: this.isMeetingUser
                                }
                            },
                            {
                                type: ProfileActions.UPDATE_USER, // setto l'utente come autenticato
                                payload: updatedUser
                            }
                        );
                        if (this.redirectUrl) {
                            this.router.navigate([this.redirectUrl]);
                        } else {
                            let storageRedirectUrl = sessionStorage.getItem("redirectUrl");
                            if (!storageRedirectUrl) {
                                this.toastr.warning(
                                    this.translate.instant("warnings.PLEASE_ACCESS_WITH_URL")
                                );
                                // this.router.navigate([sessionStorage.getItem('redirectUrl')]);
                            } else {
                                this.router.navigate([sessionStorage.getItem("redirectUrl")]);
                            }
                        }
                    }
                } else if (this.isOnlineMeetingUser || this.isPublicMeetingUser || this.isOnlineMeetingTeacher || this.isOnlineMeetingSupport) {
                    if (tinyTokenObj.error) {
                        throw new Error(tinyTokenObj.error);
                    }
                    if (!tinyTokenObj.error && !tinyTokenObj.response) {
                        if (this.isOnlineMeetingTeacher || this.isOnlineMeetingSupport) {
                            throw new Error("PASSWORD_NOT_VALID");
                        } else {
                            throw new Error("USER_NOT_FOUND");
                        }
                    }

                    let langToUse = (tinyTokenObj.response && tinyTokenObj.response.userOptions && tinyTokenObj.response.userOptions.langCode) || 'it';
                    // Prima di salvare la lingua dello store applicativo, la imposto anche per il componente che si occupa delle traduzioni
                    this.langsService.useLanguage(langToUse);
                    let updatedUser = {
                        loggedUser: tinyTokenObj.response
                    };

                    if (this.isOnlineMeetingUser) {
                        sessionStorage.setItem("onlineMeetingUserEmail", tinyTokenObj.response.email);
                    } else if (this.isOnlineMeetingTeacher) {
                        sessionStorage.setItem("onlineMeetingTeacherEmail", this.authDataMail);
                    } else if (this.isOnlineMeetingSupport) {
                        sessionStorage.setItem("onlineMeetingSupportEmail", this.authDataMail);
                    } else if (this.isPublicMeetingUser) {
                        sessionStorage.setItem("publicMeetingUser", JSON.stringify(tinyTokenObj.response));
                    }

                    actionsContainer.push(
                        {
                            type: CoreActions.SET_APPLICATION_LANG,
                            payload: langToUse
                        },
                        {
                            type: AuthActions.SET_USER_AUTHENTICATED, // setto l'utente come autenticato
                            payload: {
                                isSupplier: false,
                                isTaker: false,
                                isFundingSupplier: false,
                                isMeetingUser: false,
                                isOnlineMeetingUser: this.isOnlineMeetingUser,
                                isPublicMeetingUser: this.isPublicMeetingUser,
                                isOnlineMeetingTeacher: this.isOnlineMeetingTeacher,
                                isOnlineMeetingSupport: this.isOnlineMeetingSupport
                            }
                        },
                        {
                            type: ProfileActions.UPDATE_USER, // setto l'utente come autenticato
                            payload: updatedUser
                        }
                    );
                    if (this.redirectUrl) {
                        this.router.navigate([this.redirectUrl]);
                    } else {
                        let storageRedirectUrl = sessionStorage.getItem("redirectUrl");
                        if (!storageRedirectUrl) {
                            if (this.isOnlineMeetingUser || this.isPublicMeetingUser) {
                                this.router.navigate(['onlineMeeting/userCourses']);
                            } else if (this.isOnlineMeetingTeacher) {
                                this.router.navigate(['onlineMeeting/teacherCourses']);
                            } else if (this.isOnlineMeetingSupport) {
                                this.router.navigate(['onlineMeeting/supportCourses']);
                            }
                        } else {
                            this.router.navigate([sessionStorage.getItem("redirectUrl")]);
                        }
                    }
                } else if (this.isWelionWelfarePublicMeetingUser || this.isWelionWelfareTeacher || this.isWelionWelfareSupport) {
                    // Login pubblico di welfare welion
                    if (tinyTokenObj.error) {
                        throw new Error(tinyTokenObj.error);
                    }
                    if (!tinyTokenObj.error && !tinyTokenObj.response) {
                        if (this.isWelionWelfareTeacher || this.isWelionWelfareSupport) {
                            throw new Error("PASSWORD_NOT_VALID");
                        } else {
                            throw new Error("USER_NOT_FOUND");
                        }
                    }

                    let langToUse = (tinyTokenObj.response && tinyTokenObj.response.userOptions && tinyTokenObj.response.userOptions.langCode) || 'it';
                    // Prima di salvare la lingua dello store applicativo, la imposto anche per il componente che si occupa delle traduzioni
                    this.langsService.useLanguage(langToUse);
                    let updatedUser = {
                        loggedUser: tinyTokenObj.response
                    };

                    if (this.isWelionWelfareTeacher) {
                        sessionStorage.setItem("welionWelfareTeacherEmail", this.authDataMail);
                    } else if (this.isWelionWelfareSupport) {
                        sessionStorage.setItem("welionWelfareSupportEmail", this.authDataMail);
                    } else if (this.isWelionWelfarePublicMeetingUser) {
                        sessionStorage.setItem("welionWelfarePublicMeetingUser", JSON.stringify(tinyTokenObj.response));
                    }

                    actionsContainer.push(
                        {
                            type: CoreActions.SET_APPLICATION_LANG,
                            payload: langToUse
                        },
                        {
                            type: AuthActions.SET_USER_AUTHENTICATED, // setto l'utente come autenticato
                            payload: {
                                isWelionWelfarePublicMeetingUser: this.isWelionWelfarePublicMeetingUser,
                                isWelionWelfareTeacher: this.isWelionWelfareTeacher,
                                isWelionWelfareSupport: this.isWelionWelfareSupport
                            }
                        },
                        {
                            type: ProfileActions.UPDATE_USER, // setto l'utente come autenticato
                            payload: updatedUser
                        }
                    );

                    if (this.redirectUrl) {
                        this.router.navigate([this.redirectUrl]);
                    } else {
                        let storageRedirectUrl = sessionStorage.getItem("redirectUrl");
                        if (!storageRedirectUrl) {
                            if (this.isWelionWelfarePublicMeetingUser) {
                                this.router.navigate(['welionWelfare/userCourses']);
                            } else if (this.isWelionWelfareTeacher) {
                                this.router.navigate(['welionWelfare/teacherCourses']);
                            } else if (this.isWelionWelfareSupport) {
                                this.router.navigate(['welionWelfare/supportCourses']);
                            }
                        } else {
                            this.router.navigate([sessionStorage.getItem("redirectUrl")]);
                        }
                    }
                } else if (this.isEnelWelfareTeacher || this.isEnelWelfareSupport) {
                    // Login pubblico di welfare Enel
                    if (tinyTokenObj.error) {
                        throw new Error(tinyTokenObj.error);
                    }
                    if (!tinyTokenObj.error && !tinyTokenObj.response) {
                        if (this.isEnelWelfareTeacher || this.isEnelWelfareSupport) {
                            throw new Error("PASSWORD_NOT_VALID");
                        } else {
                            throw new Error("USER_NOT_FOUND");
                        }
                    }

                    let langToUse = (tinyTokenObj.response && tinyTokenObj.response.userOptions && tinyTokenObj.response.userOptions.langCode) || 'it';
                    // Prima di salvare la lingua dello store applicativo, la imposto anche per il componente che si occupa delle traduzioni
                    this.langsService.useLanguage(langToUse);
                    let updatedUser = {
                        loggedUser: tinyTokenObj.response
                    };

                    if (this.isEnelWelfareTeacher) {
                        sessionStorage.setItem("enelWelfareTeacherEmail", this.authDataMail);
                    } else if (this.isEnelWelfareSupport) {
                        sessionStorage.setItem("enelWelfareSupportEmail", this.authDataMail);
                    }

                    actionsContainer.push(
                        {
                            type: CoreActions.SET_APPLICATION_LANG,
                            payload: langToUse
                        },
                        {
                            type: AuthActions.SET_USER_AUTHENTICATED, // setto l'utente come autenticato
                            payload: {
                                isEnelWelfareTeacher: this.isEnelWelfareTeacher,
                                isEnelWelfareSupport: this.isEnelWelfareSupport
                            }
                        },
                        {
                            type: ProfileActions.UPDATE_USER, // setto l'utente come autenticato
                            payload: updatedUser
                        }
                    );

                    if (this.redirectUrl) {
                        this.router.navigate([this.redirectUrl]);
                    } else {
                        let storageRedirectUrl = sessionStorage.getItem("redirectUrl");
                        if (!storageRedirectUrl) {
                            if (this.isEnelWelfareTeacher) {
                                this.router.navigate(['myWelfareEnel/teacherCourses']);
                            } else if (this.isEnelWelfareSupport) {
                                this.router.navigate(['myWelfareEnel/supportCourses']);
                            }
                        } else {
                            this.router.navigate([sessionStorage.getItem("redirectUrl")]);
                        }
                    }
                } else if (this.isSupplier) {
                    if (tinyTokenObj.error) {
                        throw new Error(tinyTokenObj.error);
                    }

                    // mi aspetto un token valido non tiny
                    let decodedJwt: SupplierPersonJwtPayload =
                        tinyTokenObj.response && jwtDecode(tinyTokenObj.response);

                    this.isFundingSupplier =
                        decodedJwt &&
                        decodedJwt.params &&
                        decodedJwt.params.supplier &&
                        decodedJwt.params.supplier.supplierType ==
                        SupplierTypes.FUNDING_SUPPLIER;
                    let supplier =
                        decodedJwt && decodedJwt.params && decodedJwt.params.supplier;

                    if (this.redirectUrl) {
                        this.router.navigate([this.redirectUrl]);
                    } else if (this.router.url.indexOf('medexLogin') !== -1) {
                        this.router.navigate(["medexTakers/medexCourseSubscription"]);
                    } else if (this.isFundingSupplier) {
                        this.router.navigate(["/fundingSupplier"]);
                    } else {
                        this.router.navigate(["suppliers/supplierHome"]);
                    }
                    actionsContainer.push(
                        {
                            type: AuthActions.SET_TOKEN, // salvo il token
                            payload: tinyTokenObj.response
                        },
                        {
                            type: AuthActions.SET_USER_AUTHENTICATED, // setto l'utente come autenticato
                            payload: {
                                isSupplier: this.isSupplier,
                                isTaker: this.isTaker,
                                isFundingSupplier: this.isFundingSupplier
                            }
                        },
                        {
                            type: CoreActions.REMOVE_REDIRECT_URL
                        },
                        {
                            type: CoreActions.START_RENEW_TOKEN_POLLING
                        }
                    );
                } else if (this.isSmartEnergyTaker) {
                    if (tinyTokenObj.error) {
                        throw new Error(tinyTokenObj.error);
                    }
                    if (!tinyTokenObj.error && !tinyTokenObj.response) {
                        throw new Error("USER_NOT_FOUND");
                    }

                    if (this.isSmartEnergyTakerSso) {
                        if (this.redirectUrl) {
                            this.router.navigate([this.redirectUrl]);
                        } else {
                            let storageRedirectUrl = sessionStorage.getItem("redirectUrl");
                            if (!storageRedirectUrl) {
                                this.router.navigate(["smartEnergyTakers/smartEnergyCourseSubscription/sso"]);
                            } else {
                                this.router.navigate([sessionStorage.getItem("redirectUrl")]);
                            }
                        }

                        let decodedJwt: JwtPayload = jwtDecode(tinyTokenObj.response);
                        const _loggedUser = new JwtPayload();
                        _loggedUser.user = decodedJwt.user;
                        let updatedUser = {
                            loggedUser: _loggedUser
                        };

                        actionsContainer.push(
                            {
                                type: CoreActions.SET_APPLICATION_LANG,
                                payload: decodedJwt.user.userOptions.langCode
                            },
                            {
                                type: AuthActions.SET_IS_SMART_ENERGY_TAKER,
                                payload: "true"
                            },
                            {
                                type: AuthActions.SET_TOKEN, // salvo il token
                                payload: tinyTokenObj.response
                            },
                            {
                                type: AuthActions.SET_USER_AUTHENTICATED, // setto l'utente come autenticato
                                payload: { isSmartEnergyTaker: this.isSmartEnergyTaker }
                            },
                            {
                                type: CoreActions.REMOVE_REDIRECT_URL
                            },
                            {
                                type: ProfileActions.UPDATE_USER, // setto l'utente come autenticato
                                payload: updatedUser
                            }
                        );
                    } else {
                        let langToUse = tinyTokenObj.response.userOptions.langCode;
                        // Prima di salvare la lingua dello store applicativo, la imposto anche per il componente che si occupa delle traduzioni
                        this.langsService.useLanguage(langToUse);
                        const _loggedUser = new JwtPayload();
                        _loggedUser.user = tinyTokenObj.response;

                        let updatedUser = {
                            loggedUser: _loggedUser
                        };

                        sessionStorage.setItem("smartEnergyTakerEmail", tinyTokenObj.response.email);

                        actionsContainer.push(
                            {
                                type: CoreActions.SET_APPLICATION_LANG,
                                payload: langToUse
                            },
                            {
                                type: AuthActions.SET_IS_SMART_ENERGY_TAKER,
                                payload: "true"
                            },
                            {
                                type: AuthActions.SET_USER_AUTHENTICATED, // setto l'utente come autenticato
                                payload: {
                                    isSmartEnergyTaker: this.isSmartEnergyTaker
                                }
                            },
                            {
                                type: ProfileActions.UPDATE_USER, // setto l'utente come autenticato
                                payload: updatedUser
                            }
                        );

                        if (this.redirectUrl) {
                            this.router.navigate([this.redirectUrl]);
                        } else {
                            let storageRedirectUrl = sessionStorage.getItem("redirectUrl");
                            if (!storageRedirectUrl) {
                                this.router.navigate(["smartEnergyTakers/smartEnergyCourseSubscription"]);
                            } else {
                                this.router.navigate([sessionStorage.getItem("redirectUrl")]);
                            }
                        }
                    }
                } else {
                    // In questo caso il tinyTokenObj passato al merge non è più il tinyToken ma è il token completo
                    let fullToken = tinyTokenObj.response;
                    let authObject: any = {};
                    let decodedJwt: SupplierPersonJwtPayload =
                        fullToken && jwtDecode(fullToken);

                    authObject = authControl(decodedJwt && decodedJwt.auths);

                    if (this.redirectUrl) {
                        this.router.navigate([this.redirectUrl]);
                    } else if (this.isSupplier) {
                        this.router.navigate(['suppliers/supplierHome']);
                    } else if (authObject.isManager && !authObject.isAdmin && !authObject.isPerformance) {
                        //   this.urlMeetingUp = this.urlService.getMeetingUpApplicationUrl();
                        //   window.location.href = this.urlMeetingUp.url + "/meetingup/#/meetings";
                        this.router.navigate(["meetings"]);
                    } else if (authObject.isPerformance && !authObject.isAdmin && !authObject.isManager) {
                        this.router.navigate(["performanceManagement"]);
                    } else if (authObject.isManager && authObject.isPerformance && !authObject.isAdmin) {
                        this.router.navigate(["landingPerformanceManager"]);
                    } else if ((authObject.isManager && authObject.isAdmin) || (authObject.isPerformance && authObject.isAdmin) || authObject.isAdmin) {
                        this.router.navigate(["/"]);
                    }
                    actionsContainer.push(
                        {
                            type: AuthActions.SET_TOKEN, // salvo il token
                            payload: this.tinyTokenObj.response
                        },
                        {
                            type: AuthActions.SET_USER_AUTHENTICATED, // setto l'utente come autenticato
                            payload: {
                                isSupplier: this.isSupplier,
                                isTaker: this.isTaker,
                                isWelionTs: this.isWelionTs,
                                isOwnership: this.isOwnership,
                                isWelfareEnel: this.isWelfareEnel,
                                isFundingSupplier: this.isFundingSupplier,
                                isManager: authObject.isManager,
                                isAdmin: authObject.isAdmin,
                                isPerformance: authObject.isPerformance
                            }
                        },
                        {
                            type: CoreActions.REMOVE_REDIRECT_URL
                        },
                        {
                            type: CoreActions.START_RENEW_TOKEN_POLLING
                        }
                    );
                }

                return actionsContainer;
            }),
            catchError((err, caught) => {
                // L'errore è una condizione terminale che porrebbe fine all'Observable. Questo interromperebbe il flusso dell'Effect. In lato pratico, significa che se capita un errore, lo stream si interrompe e
                // se cercassi di fare il dispatch dell'azione con l'effect, quest'ultimo non si avvia. Quindi l'utente continua a premere, per esempio, un pulsante e questo non trigghera nessun Effect.
                // Fortunatamente, il catchError() consente di emettere valori personalizzati invece di incrementare l'observer con la callback di errore.
                // La cattura non viene fatta nello stream principale, ma nel flusso interno (quello dello switchMap), quindi l'observable interrotto è quello interno. Quello principale, invece, continua
                // con il valore tornato dal catch.
                // In alternativa, si potrebbe gestire l'errore direttamente all'interno dello switchMap:
                /*
                        switchMap(campaign =>
                            this.advertService.startAd(campaign.name).pipe(
                                map(nameOfUploadedFile => new AdvertActions.StartAdDone(nameOfUploadedFile)),
                                catchError(err => of(new AdvertActions.StartAdFailed(err))
                        ) */
                if (err && err.message) {
                    this.toastr.error(this.translate.instant("errors." + err.message));
                }
                // Quindi, alla fine, torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
                return caught;
            })
        );

    // Dobbiamo esplicitare il fatto che non eseguiamo nessun dispatch. Ovviamente ngrx ha bisogno di lasciare fluire l'Observable in entrata (questo il motivo per cui utilizziamo il do() al posto del subscribe())
    @Effect()
    authLogout$ = this.actions$
        .pipe(
            ofType(AuthActions.LOGOUT)
            , switchMap(() => {
                return from(this.authService.logout());
            })
            , mergeMap(
                (data: SenecaResponse<any>) => {
                    if (data.response === null && data.error === null) {

                        let actionsContainer = [
                            {
                                type: AuthActions.SESSION_LOGOUT
                            },
                            {
                                type: CoreActions.REMOVE_APPLICATION_LANG
                            },
                            {
                                type: ProfileActions.CANCEL_LOGGED_USER
                            }
                        ];
                        this.router.navigate(['/login']);
                        return actionsContainer;
                    }
                }
            )
            , catchError((err, caught) => {
                if (err && err.message) {
                    this.toastr.error(this.translate.instant('errors.' + err.message));
                }
                return caught;
            })
        );
}
