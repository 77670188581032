/*
*  Reducer che gestiscono e modificano lo State applicativo riguardante il Performance Management
*/

import * as PerformanceManagementActions from './performance-management.actions';
import * as fromApp from '../../ngrx/app.reducers';

// il Performance Management è una feature dell'applicazione, infatti è un modulo caricato in maniera lazy (e quindi anche lo Store).
// Creo un'interfaccia, FeatureState, in cui mappo il Performance Management
// Estendo fromApp.AppState così per ereditare tutte le proprietà dello Store principale
export interface FeatureState extends fromApp.AppState {
    performance: PerformanceManagementState
}

// Interfaccia dello Store
export interface PerformanceManagementState {
    performanceYears: string[];
    performanceYearSelected: string;
    isFetchingPerformanceYears: boolean;
    performanceRents: any[];
    count: number;
    fromRecord: number;
    numRecords: number;
    page: number;
    isFetchingPerformanceRents: boolean;
}

// Store iniziale
const initialState: PerformanceManagementState = {
    performanceYears: [], // Lista di anni del periodo
    performanceYearSelected: '', // Anno selezionato
    isFetchingPerformanceYears: true, // Verifica se sto eseguendo una chiamata per recuperare i dati per gli anni del periodo
    performanceRents: [], // Lista rents
    count: 0, // contatore totale delle riunioni
    fromRecord: 0, // numero da cui recuperare gli elementi
    numRecords: 5, // elementi da recuperare
    page: 1, // pagina della tabella visualizzata
    isFetchingPerformanceRents: false // Serve per il loader
};

export function performanceManagementReducer(state = initialState, action: PerformanceManagementActions.PerformanceManagementActions) {
    switch (action.type) {
        case PerformanceManagementActions.SET_PERFORMANCE_YEARS:
            if (action.payload && action.payload.length) {

                return {
                    ...state,
                    isFetchingPerformanceYears: false,
                    performanceYears: [...action.payload]
                };
            } else {
                return state;
            }
        case PerformanceManagementActions.SET_PERFORMANCE_YEAR_SELECTED:
            if (action.payload) {

                return {
                    ...state,
                    performanceYearSelected: action.payload
                };
            } else {
                return state;
            }
        case PerformanceManagementActions.GET_PERFORMANCE_YEARS:
            return {
                ...state,
                isFetchingPerformanceYears: true
            };
        case PerformanceManagementActions.SET_PERFORMANCE_RENTS:
            return {
                ...state,
                isFetchingPerformanceRents: false,
                performanceRents: [...action.payload] // sovrascrivo i rents
            };
        case PerformanceManagementActions.SET_PAGINATION_PERFORMANCE:
            return {
                ...state,
                fromRecord: action.payload.fromRecord,
                numRecords: action.payload.numRecords
            };
        case PerformanceManagementActions.GET_PERFORMANCE_RENTS:
            return {
                ...state,
                isFetchingPerformanceRents: true
            };
        case PerformanceManagementActions.SET_PAGE_PERFORMANCE:
            return {
                ...state,
                page: action.payload
            };
        case PerformanceManagementActions.SET_PERFORMANCE_RENTS_COUNT:
            return {
                ...state,
                count: action.payload,
            };
        default:
            return state;
    }
}

// Export delle funzioni che tornano una parte dello Store applicativo
export const getPerformanceYears = (state: PerformanceManagementState) => (state && state.performanceYears) || [];
export const getPerformanceYearSelected = (state: PerformanceManagementState) => (state && state.performanceYearSelected) || '';
export const isFetchingPerformanceYears = (state: PerformanceManagementState) => state.isFetchingPerformanceYears;
export const getPerformanceRents = (state: PerformanceManagementState) => state.performanceRents;
export const getPerformancesRentsCount = (state: PerformanceManagementState) => state.count;
export const getPerformanceRentsFromRecord = (state: PerformanceManagementState) => state.fromRecord;
export const getPerformanceRentsNumRecords = (state: PerformanceManagementState) => state.numRecords;
export const getPerformanceRentsPage = (state: PerformanceManagementState) => state.page;
export const isFetchingPerformanceRents = (state: PerformanceManagementState) => state.isFetchingPerformanceRents;