// Login nudging con userId

import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';

import * as fromApp from '../../ngrx/app.reducers';
// Import dello State dell'autenticazione, che è quello che selezioneremo con lo slice dallo Store
import * as AuthActions from '../ngrx/auth.actions';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Lang } from "../../../cm2-commonclasses";

@Component({
    selector: 'app-nudging-local-login',
    templateUrl: './nudging-local-login.component.html',
})
export class NudgingLocalLoginComponent implements OnInit, OnDestroy {
    isFetchingLangs: boolean;
    langs: Lang[];
    result$;

    constructor(private store: Store<fromApp.AppState>,
        public translate: TranslateService,
        private router: Router) {
        // Sto in ascolto di quando il globalApplicationData cambia, così da sapere quando ci sono le lingue disponibili poiché, se non ci fossero, il pulsante per il login sarebbe disabilitato
        let availableLangs$: Observable<Lang[]> = this.store.select(fromApp.getAvailableLangs);
        let isFetchingLangs$: Observable<boolean> = this.store.select(fromApp.isFetchingLangs);
        const combinedSelectes$ = combineLatest(availableLangs$, isFetchingLangs$);
        this.result$ = combinedSelectes$.subscribe(
            ([langs, isFetchingLangs]) => {
                this.langs = langs;
                this.isFetchingLangs = isFetchingLangs;
            });
    }
    ngOnInit() {
        let sessionStorageNudgingUserId = sessionStorage.getItem('nudgingUserId');
        // Se ho lo userId nel session storage, posso subito eseguire il login
        if (sessionStorageNudgingUserId) {
            this.store.dispatch(new AuthActions.DoLogin({ email: sessionStorageNudgingUserId, password: null }));
        }
    }
    ngOnDestroy() {
        this.result$.unsubscribe();
    }
}