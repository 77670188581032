/*
* Componente che crea la carde per la creazione di un'iniziativa Smart
*/

import { Component, Input, Output, EventEmitter } from '@angular/core';
@Component({
    selector: 'new-smart-card',
    templateUrl: './new-smart-card.component.html',
    styleUrls: ['./new-smart-card.component.scss']
})

export class NewSmartCardComponent {
    @Input() selectedCreationPossibility: { options: number };
    @Output() toggledChange: EventEmitter<string> = new EventEmitter<string>();
    isLoaded: boolean;
    constructor() {
    }

    // Emette l'evento al componente padre, che cambia la selezione
    changeValue(newValue: string) {
        this.toggledChange.emit(newValue);
    }

    // Renderinig dell'immagine finito
    imgRendered() {
        this.isLoaded = true;
    }
}
