<div class="fixHeader">
    <div class="content">
        <div class="main-menu-header" aria-level="1" fxLayout="row" fxLayoutAlign="space-between center" role="heading">
            <div fxFlex.gt="30" fxLayout="row" fxLayoutAlign="center center">
                <div *ngIf="showMenuButton()" fxLayout="row" role="navigation">
                    <button (click)="toggleMainMenuSidebar()"
                        attr.aria-label="{{ isMainMenuSidebarOpened ? ('header.CLOSE_MAIN_MENU' | translate ) : ('header.OPEN_MAIN_MENU' | translate ) }}">
                        <svg-icon hide-delay="50" tooltip-class="custom-tooltip"
                            tooltip="{{ isMainMenuSidebarOpened ? ('header.CLOSE_MAIN_MENU' | translate ) : ('header.OPEN_MAIN_MENU' | translate ) }}"
                            [src]="isMainMenuSidebarOpened ? 'assets/img/close-menu.svg' : 'assets/img/main-menu.svg'"
                            placement="bottom" id="mainMenuSidebarToolip" display-mobile="false"
                            class="header-icon-hamburger-menu">
                        </svg-icon>
                    </button>
                </div>
                <div class="logo-wrapper" attr.aria-label="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}">
                    <img fxShow.xs="false"
                        *ngIf="!isSupplier && ((isAdmin && !isManager) || (isAdmin && isManager) || (isAdmin && !isPerformance) || (isAdmin && isPerformance))"
                        placement="bottom" hide-delay="50" tooltip-class="custom-tooltip"
                        tooltip="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}"
                        title="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}"
                        alt="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}" fxShow.sm="false"
                        class="logoRegular cursor-pointer" src="assets/img/logo_generali.svg" (click)="goToHome()">
                    <img fxShow.xs="false" *ngIf="isManager && !isAdmin && !isPerformance" placement="bottom"
                        hide-delay="50" tooltip-class="custom-tooltip"
                        tooltip="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}"
                        title="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}"
                        alt="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}" fxShow.sm="false"
                        class="logoRegular cursor-pointer" src="assets/img/logo_generali.svg" (click)="goToMeetings()">
                    <img fxShow.xs="false" *ngIf="!isManager && !isAdmin && isPerformance" placement="bottom"
                        hide-delay="50" tooltip-class="custom-tooltip"
                        tooltip="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}"
                        title="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}"
                        alt="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}" fxShow.sm="false"
                        class="logoRegular cursor-pointer" src="assets/img/logo_generali.svg"
                        (click)="goToPerformanceManagementPage()">
                    <img fxShow.xs="false" *ngIf="isManager && !isAdmin && isPerformance" placement="bottom"
                        hide-delay="50" tooltip-class="custom-tooltip"
                        tooltip="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}"
                        title="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}"
                        alt="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}" fxShow.sm="false"
                        class="logoRegular cursor-pointer" src="assets/img/logo_generali.svg"
                        (click)="goToLandingPerformanceManager()">
                    <img fxShow.xs="false" *ngIf="(isSupplier && isFundingSupplier) || isSupplier" placement="bottom"
                        hide-delay="50" tooltip-class="custom-tooltip"
                        tooltip="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}"
                        title="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}"
                        alt="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}" fxShow.sm="false"
                        class="logoRegular cursor-pointer" src="assets/img/logo_generali.svg"
                        (click)="goToHomeSupplier()">

                    <img fxShow.md="false"
                        *ngIf="!isSupplier && ((isAdmin && !isManager) || (isAdmin && isManager) || (isAdmin && !isPerformance) || (isAdmin && isPerformance))"
                        placement="bottom" hide-delay="50" tooltip-class="custom-tooltip"
                        tooltip="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}"
                        title="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}"
                        alt="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}" fxShow.lg="false" fxShow.xl="false"
                        class="showLogoSmallMoblile cursor-pointer" src="assets/img/logo_generali.svg"
                        (click)="goToHome()">
                    <img fxShow.xs="false" *ngIf="isFundingSupplier" placement="bottom" hide-delay="50"
                        tooltip-class="custom-tooltip" tooltip="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}"
                        fxShow.sm="false" class="logoRegular cursor-pointer" title="logoGenerali"
                        src="assets/img/logo_generali.svg" (click)="goToHomeFundingSupplier()">
                    <img fxShow.md="false" *ngIf="isManager && !isAdmin && !isPerformance" placement="bottom"
                        hide-delay="50" tooltip-class="custom-tooltip"
                        tooltip="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}"
                        title="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}"
                        alt="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}" fxShow.lg="false" fxShow.xl="false"
                        class="showLogoSmallMoblile cursor-pointer" src="assets/img/logo_generali.svg"
                        (click)="goToMeetings()">
                    <img fxShow.md="false" *ngIf="!isManager && !isAdmin && isPerformance" placement="bottom"
                        hide-delay="50" tooltip-class="custom-tooltip"
                        tooltip="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}"
                        title="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}"
                        alt="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}" fxShow.lg="false" fxShow.xl="false"
                        class="showLogoSmallMoblile cursor-pointer" src="assets/img/logo_generali.svg"
                        (click)="goToPerformanceManagementPage()">
                    <img fxShow.md="false" *ngIf="isManager && !isAdmin && isPerformance" placement="bottom"
                        hide-delay="50" tooltip-class="custom-tooltip"
                        tooltip="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}"
                        title="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}"
                        alt="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}" fxShow.lg="false" fxShow.xl="false"
                        class="showLogoSmallMoblile cursor-pointer" src="assets/img/logo_generali.svg"
                        (click)="goToLandingPerformanceManager()">
                    <img fxShow.md="false" *ngIf="isSupplier && !isFundingSupplier" placement="bottom" hide-delay="50"
                        tooltip-class="custom-tooltip" tooltip="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}"
                        title="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}"
                        alt="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}" fxShow.lg="false" fxShow.xl="false"
                        class="showLogoSmallMoblile cursor-pointer" src="assets/img/logo_generali_mobile.svg"
                        (click)="goToHomeSupplier()">
                    <img fxShow.md="false" *ngIf="isFundingSupplier" placement="bottom" hide-delay="50"
                        tooltip-class="custom-tooltip" tooltip="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}"
                        fxShow.lg="false" title="logoGenerali" fxShow.xl="false"
                        class="showLogoSmallMoblile cursor-pointer" src="assets/img/logo_generali_mobile.svg"
                        (click)="goToHomeFundingSupplier()">
                    <img fxShow.xs="false" *ngIf="isManager && !isAdmin && !isPerformance" placement="bottom"
                        hide-delay="50" tooltip-class="custom-tooltip"
                        tooltip="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}"
                        title="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}"
                        alt="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}" class="logoMeetingup"
                        src="assets/img/logo_meetingup.svg" (click)="goToMeetings()">
                    <img fxShow.xs="false"
                        *ngIf="isManager && !isAdmin && isPerformance && isThisCurrentPage('meetings')"
                        placement="bottom" hide-delay="50" tooltip-class="custom-tooltip"
                        tooltip="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}"
                        title="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}"
                        alt="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}" class="logoMeetingup"
                        src="assets/img/logo_meetingup.svg" (click)="goToLandingPerformanceManager()">
                    <img fxShow.xs="false"
                        *ngIf="(!isSupplier && ((isAdmin && !isManager) || (isAdmin && isManager) || (isAdmin && !isPerformance) || (isAdmin && isPerformance)) && isThisCurrentPage('meetings'))"
                        placement="bottom" hide-delay="50" tooltip-class="custom-tooltip"
                        tooltip="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}"
                        title="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}"
                        alt="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}" class="logoMeetingup"
                        src="assets/img/logo_meetingup.svg" (click)="goToHome()">
                </div>
            </div>
            <div flex fxLayout="row" fxLayoutAlign="end center">
                <div class="header-linkApp-coursemanager">
                    <div class="header-linkApp-coursemanager" fxLayout="row" fxLayoutAlign="end center">
                        <div *ngIf="showMenuButton()" class="cursor-pointer" placement="bottom" hide-delay="50" tooltip-class="custom-tooltip"
                            tooltip="{{ 'header.NOTIFICATIONS' | translate }}"
                            attr.aria-label="{{ 'header.NOTIFICATIONS' | translate }}" fxShow.xs="false"
                            fxShow.sm="false" fxLayout="row" fxLayoutAlign="start center">
                            <svg-icon class="header-icon-notify" src="assets/img/notify_icon.svg"></svg-icon>
                            <span class="badge-notify-coursemanager margin0">{{notificationList.length}}</span>
                        </div>
                        <div fxShow.xs="false" fxShow.sm="false" class="section-profile" [ngClass]="{'border-left0': !showMenuButton()}">
                            <div layout="column" class="img-avatar-coursemanager-home-wrapper" fxLayout="row"
                                fxLayoutAlign="center center">
                                <img class="img-avatar-coursemanager-home" [src]="avatar" alt="avatar" title="avatar">
                            </div>
                            <div *ngIf="loggedUser && loggedUser.user" placement="bottom" class="wrapper-name-role"
                                fxLayout="column" fxLayoutAlign="center start">
                                <p class="user-name">{{ loggedUser.user.forename }} {{ loggedUser.user.surname }}
                                </p>
                                <p *ngIf="loggedUser.jobFamilyPrimaria"
                                    class="text-primary-5 user-role truncate-role-header">{{
                                    loggedUser.user.userjobFamilyPrimaria }}</p>
                            </div>
                        </div>
                        <div fxLayout="column">
                            <div fxShow.xs="false" fxShow.sm="false" fxLayout="row">
                                <button placement="bottom" hide-delay="50" id="logoutToolip"
                                    tooltip-class="custom-tooltip" tooltip="{{ 'header.LOGOUT' | translate }}"
                                    attr.aria-label="{{ 'header.LOGOUT' | translate }}" fxShow.xs="false"
                                    fxShow.sm="false" fxLayout="row" fxLayoutAlign="start center">
                                    <svg-icon (click)="onLogout()" class="header-icon-logout"
                                        src="assets/img/logout_icon.svg"></svg-icon>
                                </button>
                            </div>
                            <button placement="bottom" id="openSecondaryMenuToolip" hide-delay="50"
                                tooltip-class="custom-tooltip" tooltip="{{ 'header.OPEN_SECONDARY_MENU' | translate }}"
                                attr.aria-label="{{ 'header.LOGOUT' | translate }}" fxShow.md="false" fxShow.lg="false"
                                fxShow.xl="false" fxLayout="row">
                                <svg-icon (click)="toggleProfileMenuSidebar()" class="header-icon-profile-menu"
                                    src="assets/img/profile-menu.svg"></svg-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>