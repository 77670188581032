import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, switchMap, filter } from 'rxjs/operators';

import * as fromApp from '../../ngrx/app.reducers';
import { onceIfNotEmpty } from 'src/app/ngrx/util';
import { isGenertel, isDas, isAlleanza, isGho, isEuass } from '../models/global-application-data.model';
import { UrlService } from './url.service';

@Injectable()
export class TenantGuard implements CanActivate {
    constructor(private store: Store<fromApp.AppState>,
        private urlService: UrlService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.store.select(fromApp.isAuthenticated)
            .pipe(
                filter((isAuthenticated) => isAuthenticated),
                switchMap(() => {
                    return this.store.select(fromApp.getLoggedUser);
                }),
                onceIfNotEmpty(),
                map(loggedUser => {
                    let isGenertelTenant = false;
                    let isGhoTenant = false;
                    let isAlleanzaTenant = false;
                    let isDasTenant = false;
                    let isEuassTenant = false;

                    if (loggedUser && loggedUser.user && loggedUser.user.userTenant && loggedUser.user.userTenant.tenant) {
                        isGenertelTenant = isGenertel(loggedUser && loggedUser.user && loggedUser.user.userTenant && loggedUser.user.userTenant.tenant) || isGenertel(loggedUser && loggedUser.user && loggedUser.user.userTenant && loggedUser.user.userTenant.tenant);
                        isGhoTenant = isGho(loggedUser && loggedUser.user && loggedUser.user.userTenant && loggedUser.user.userTenant.tenant) || isGho(loggedUser && loggedUser.user && loggedUser.user.userTenant && loggedUser.user.userTenant.tenant);
                        isAlleanzaTenant = isAlleanza(loggedUser && loggedUser.user && loggedUser.user.userTenant && loggedUser.user.userTenant.tenant) || isAlleanza(loggedUser && loggedUser.user && loggedUser.user.userTenant && loggedUser.user.userTenant.tenant);
                        isDasTenant = isDas(loggedUser && loggedUser.user && loggedUser.user.userTenant && loggedUser.user.userTenant.tenant) || isDas(loggedUser && loggedUser.user && loggedUser.user.userTenant && loggedUser.user.userTenant.tenant);
                        isEuassTenant = isEuass(loggedUser && loggedUser.user && loggedUser.user.userTenant && loggedUser.user.userTenant.tenant) || isEuass(loggedUser && loggedUser.user && loggedUser.user.userTenant && loggedUser.user.userTenant.tenant);

                        // Se non sono del tenant di generali e se ho un token in sessione, prima di eseguire il redirect salvo nel localStorage il tiny token
                        const sessionStorageToken: string = sessionStorage.getItem("token");
                        if ((isDasTenant || isEuassTenant || isAlleanzaTenant || isGenertelTenant || isGhoTenant) && sessionStorageToken && sessionStorageToken.length) {
                            localStorage.setItem("token", sessionStorageToken);
                        };

                        if (isDasTenant) {
                            // Il tenant dell'utente loggato è di Genertel, quindi vado nella sottocartella /das/
                            const applicationContext = this.urlService.getApplicationUrl().baseUrl;
                            let suffix = "/home";
                            let sessionStorageRedirectUrl: string = sessionStorage.getItem('redirectUrl');
                            if (sessionStorageRedirectUrl && sessionStorageRedirectUrl.indexOf("takers") > -1) {
                                // Un utente Genertel sta cercando accedere al minisito del cm2, quindi rimando al minisito del cm2 di Genertel
                                suffix = sessionStorageRedirectUrl;
                            } else {
                                // Se non ho nulla nel sessionStorage allora recupero tutto l'url desiderato nell'url 
                                suffix = state.url;
                            }
                            window.location.href = applicationContext + "das/#" + suffix;
                            return false;
                        }

                        if (isEuassTenant) {
                            // Il tenant dell'utente loggato è di Genertel, quindi vado nella sottocartella /das/
                            const applicationContext = this.urlService.getApplicationUrl().baseUrl;
                            let suffix = "/home";
                            let sessionStorageRedirectUrl: string = sessionStorage.getItem('redirectUrl');
                            if (sessionStorageRedirectUrl && sessionStorageRedirectUrl.indexOf("takers") > -1) {
                                // Un utente Genertel sta cercando accedere al minisito del cm2, quindi rimando al minisito del cm2 di Genertel
                                suffix = sessionStorageRedirectUrl;
                            } else {
                                // Se non ho nulla nel sessionStorage allora recupero tutto l'url desiderato nell'url 
                                suffix = state.url;
                            }
                            window.location.href = applicationContext + "euass/#" + suffix;
                            return false;
                        }

                        if (isAlleanzaTenant) {
                            // Il tenant dell'utente loggato è di Genertel, quindi vado nella sottocartella /alleanza/
                            const applicationContext = this.urlService.getApplicationUrl().baseUrl;
                            let suffix = "/home";
                            let sessionStorageRedirectUrl: string = sessionStorage.getItem('redirectUrl');
                            if (sessionStorageRedirectUrl && sessionStorageRedirectUrl.indexOf("takers") > -1) {
                                // Un utente Genertel sta cercando accedere al minisito del cm2, quindi rimando al minisito del cm2 di Genertel
                                suffix = sessionStorageRedirectUrl;
                            } else {
                                // Se non ho nulla nel sessionStorage allora recupero tutto l'url desiderato nell'url 
                                suffix = state.url;
                            }
                            window.location.href = applicationContext + "alleanza/#" + suffix;
                            return false;
                        }

                        if (isGenertelTenant) {
                            // Il tenant dell'utente loggato è di Genertel, quindi vado nella sottocartella /gt/
                            const applicationContext = this.urlService.getApplicationUrl().baseUrl;
                            let suffix = "/home";
                            let sessionStorageRedirectUrl: string = sessionStorage.getItem('redirectUrl');
                            if (sessionStorageRedirectUrl && sessionStorageRedirectUrl.indexOf("takers") > -1) {
                                // Un utente Genertel sta cercando accedere al minisito del cm2, quindi rimando al minisito del cm2 di Genertel
                                suffix = sessionStorageRedirectUrl;
                            } else {
                                // Se non ho nulla nel sessionStorage allora recupero tutto l'url desiderato nell'url 
                                suffix = state.url;
                            }
                            window.location.href = applicationContext + "gt/#" + suffix;
                            return false;
                        }

                        if (isGhoTenant) {
                            // Il tenant dell'utente loggato è di Gho, quindi vado nella sottocartella /gho/
                            const applicationContext = this.urlService.getApplicationUrl().baseUrl;
                            let suffix = "/home";
                            let sessionStorageRedirectUrl: string = sessionStorage.getItem('redirectUrl');
                            if (sessionStorageRedirectUrl && sessionStorageRedirectUrl.indexOf("takers") > -1) {
                                // Un utente Gho sta cercando accedere al minisito del cm2, quindi rimando al minisito del cm2 di Gho
                                suffix = sessionStorageRedirectUrl;
                            } else {
                                // Se non ho nulla nel sessionStorage allora recupero tutto l'url desiderato nell'url 
                                suffix = state.url;
                            }
                            window.location.href = applicationContext + "gho/#" + suffix;
                            return false;
                        }
                    }
                    return true;
                })
            )
    }
}