// Login dei taker tramite sso

import { Component, OnInit, OnDestroy } from "@angular/core";
// Import dello State dell'autenticazione, che è quello che selezioneremo con lo slice dallo Store
import { TranslateService } from "@ngx-translate/core";
import { Lang } from "../../../cm2-commonclasses";
import { DeviceDetectorService } from "ngx-device-detector";
import { UrlService } from "../../shared/services/url.service";

@Component({
    selector: "app-user-meeting-login",
    templateUrl: "./user-meeting-login.component.html",
    styleUrls: ["./user-meeting-login.component.scss"]
})
export class UserMeetingLoginComponent implements OnInit, OnDestroy {
    isFetchingLangs: boolean;
    langs: Lang[];

    constructor(
        public translate: TranslateService,
        private deviceService: DeviceDetectorService,
        private urlService: UrlService
    ) {
        // Prima di chiamare il servizio per il login, identifico l'userAgent e il tipo di device dell'utente
        const deviceInfo = this.deviceService.getDeviceInfo();
        const userAgent = deviceInfo && deviceInfo.userAgent;
        let deviceType;
        if (this.deviceService.isMobile()) {
            // Salvo il fatto che è uno smartphone
            deviceType = "P";
        } else if (this.deviceService.isTablet()) {
            // Salvo il fatto che è un tablet
            deviceType = "T";
        } else if (this.deviceService.isDesktop()) {
            // Salvo il fatto che è un computer desktop
            deviceType = "D";
        }
        // Ridireziono l'utente verso il sistema di SSO
        let storageRedirectUrl = sessionStorage.getItem("redirectUrl");
        const redirectUrl = this.urlService.getSSOUrl(
            deviceType,
            userAgent,
            "/#/" + storageRedirectUrl
        );
        window.location.href = redirectUrl;
    }
    ngOnInit() { }
    ngOnDestroy() { }
}
