<!-- Modale di conferma -->
<ngx-smart-modal #publicUsersPrivacyModal identifier="publicUsersPrivacyModal"
    customClass="nsm-dialog-animation-fade full-width nsm-centered confirm-modal privacy-modal-container">
    <div *ngIf="publicUsersPrivacyModal.hasData()">
        <!-- Titolo -->
        <div fxFlexFill fxLayout="row" fxLayoutAlign="start center" class="modal-title-container">
            <p class="modal-title">{{publicUsersPrivacyModal.getData().modalTitle}}</p>
        </div>
        <!-- Testo centrale della mdoale-->
        <div class="fix-height">
            <div class="modal-body-container">
                <p [innerHTML]="publicUsersPrivacyModal.getData().modalBody | safeHtml: 'html'"></p>
            </div>
        </div>

        <!-- Pulsante di conferma -->
        <div *ngIf="publicUsersPrivacyModal.getData().confirmBtnLabel" fxFlexFill fxLayout="row" fxLayout.xs="column"
            fxLayout.sm="column" fxLayoutAlign="center center" class="modal-actions-container">
            <button (click)="publicUsersPrivacyModal.close(); emitConfirmation(publicUsersPrivacyModal.getData());"
                class="btn-primary-1 large to-uppercase"
                type="button">{{publicUsersPrivacyModal.getData().confirmBtnLabel}}</button>
        </div>

    </div>
</ngx-smart-modal>