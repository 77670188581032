/*
 * Azioni per l'autenticazione. Il LOGIN setta semplicemente l'utente come loggato; il DO_LOGIN invece chiamerà i servizi per l'autenticazione a back-end
 *
 */

import { Action } from "@ngrx/store";

// Costanti che identificano tutte le azioni
export const DO_LOGIN = "DO_LOGIN";
// Esegue semplicemente lo switch a front-end di autenticazione, da loggato ad uscito
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const SET_USER_AUTHENTICATED = "SET_USER_AUTHENTICATED";
export const SET_IS_PUBLIC_MEETING_USER = "SET_IS_PUBLIC_MEETING_USER";
export const SET_TOKEN = "SET_TOKEN";
export const SET_IS_SUPPLIER = "SET_IS_SUPPLIER";
export const SET_IS_FUNDINGSUPPLIER = 'SET_IS_FUNDINGSUPPLIER';
export const SET_IS_TAKER = 'SET_IS_TAKER';
export const SET_IS_SMART_ENERGY_TAKER = 'SET_IS_SMART_ENERGY_TAKER';
export const SET_IS_EUROPE_ASSISTANCE = 'SET_IS_EUROPE_ASSISTANCE';
export const SET_IS_WELION_TS = 'SET_IS_WELION_TS';
export const SET_IS_OWNERSHIP = 'SET_IS_OWNERSHIP';
export const SET_IS_WELFARE_ENEL = 'SET_IS_WELFARE_ENEL';
export const SET_SUPPLIER_PAYLOAD = 'SET_SUPPLIER_PAYLOAD';
export const SET_IS_MANAGER = "SET_IS_MANAGER";
export const SET_IS_ADMIN = "SET_IS_ADMIN";
export const SET_IS_MEETING_USER = "SET_IS_MEETING_USER";
export const SET_IS_ONLINE_MEETING_USER = "SET_IS_ONLINE_MEETING_USER";
export const SET_IS_ONLINE_MEETING_TEACHER = "SET_IS_ONLINE_MEETING_TEACHER";
export const SET_IS_ONLINE_MEETING_SUPPORT = "SET_IS_ONLINE_MEETING_SUPPORT";
export const SET_IS_WELION_WELFARE_TEACHER = "SET_IS_WELION_WELFARE_TEACHER";
export const SET_IS_WELION_WELFARE_SUPPORT = "SET_IS_WELION_WELFARE_SUPPORT";
export const SET_IS_ENEL_WELFARE_TEACHER = "SET_IS_ENEL_WELFARE_TEACHER";
export const SET_IS_ENEL_WELFARE_SUPPORT = "SET_IS_ENEL_WELFARE_SUPPORT";
export const SET_IS_PERFORMANCE = "SET_IS_PERFORMANCE";
export const SET_IS_WELION_WELFARE_PUBLIC_MEETING_USER = "SET_IS_WELION_WELFARE_PUBLIC_MEETING_USER";
export const SESSION_LOGOUT = "SESSION_LOGOUT";
export const SET_IS_HERITAGE_TAKER = 'SET_IS_HERITAGE_TAKER';
export const SET_IS_DEI_TAKER = 'SET_IS_DEI_TAKER';
export class DoLogin implements Action {
    readonly type = DO_LOGIN;

    // Payload dell'azione
    constructor(
        public payload: {
            email?: string;
            password?: string;
            userId?: string;
            publicMeetingUserCustomerUserId?: string;
            isSupplier?: boolean;
            isFundingSupplier?: boolean,
            isTaker?: boolean;
            isWelionTs?: boolean;
            isWelionTsSso?: boolean;
            isEuropeAssistance?: boolean;
            isEuropeAssistanceSso?: boolean;
            isOwnership?: boolean;
            isOwnershipSso?: boolean;
            isWelfareEnel?: boolean;
            isWelfareEnelSso?: boolean;
            isSso?: boolean;
            isManager?: boolean;
            isAdmin?: boolean;
            isMeetingUser?: boolean;
            isOnlineMeetingUser?: boolean;
            isPublicMeetingUser?: boolean;
            isWelionWelfarePublicMeetingUser?: boolean;
            isOnlineMeetingTeacher?: boolean;
            isOnlineMeetingSupport?: boolean;
            isWelionWelfareTeacher?: boolean;
            isWelionWelfareSupport?: boolean;
            isEnelWelfareTeacher?: boolean;
            isEnelWelfareSupport?: boolean;
            isSmartEnergyTaker?: boolean;
            isSmartEnergyTakerSso?: boolean;
            isNudgingUser?: boolean;
            isHeritage?: boolean,
            isDei?:boolean
        }
    ) { }
}

export class Login implements Action {
    readonly type = LOGIN;
}

export class SetIsSupplier implements Action {
    readonly type = SET_IS_SUPPLIER;
}

export class SetIsFundingSupplier implements Action {
    readonly type = SET_IS_FUNDINGSUPPLIER;
}

export class SetIsTaker implements Action {
    readonly type = SET_IS_TAKER;
}

export class SetIsSmartEnergyTaker implements Action {
    readonly type = SET_IS_SMART_ENERGY_TAKER;
}
export class SetIsEuropeAssistance implements Action {
    readonly type = SET_IS_EUROPE_ASSISTANCE;
}
export class SetIsWelionTs implements Action {
    readonly type = SET_IS_WELION_TS;
}
export class SetIsOwnership implements Action {
    readonly type = SET_IS_OWNERSHIP;
}
export class SetIsWelfareEnel implements Action {
    readonly type = SET_IS_WELFARE_ENEL;
}
export class SessionLogout implements Action {
    readonly type = SESSION_LOGOUT;
}
export class SetIsMeetingUser implements Action {
    readonly type = SET_IS_MEETING_USER;
}
export class SetIsOnlineMeetingUser implements Action {
    readonly type = SET_IS_ONLINE_MEETING_USER;
}
export class SetIsPublicMeetingUser implements Action {
    readonly type = SET_IS_PUBLIC_MEETING_USER;
}
export class SetIsWelionWelfarePublicMeetingUser implements Action {
    readonly type = SET_IS_WELION_WELFARE_PUBLIC_MEETING_USER;
}
export class SetIsOnlineMeetingTeacher implements Action {
    readonly type = SET_IS_ONLINE_MEETING_TEACHER;
}
export class SetIsOnlineMeetingSupport implements Action {
    readonly type = SET_IS_ONLINE_MEETING_SUPPORT;
}
export class SetIsWelionWelfareTeacher implements Action {
    readonly type = SET_IS_WELION_WELFARE_TEACHER;
}
export class SetIsWelionWelfareSupport implements Action {
    readonly type = SET_IS_WELION_WELFARE_SUPPORT;
}
export class SetIsEnelWelfareTeacher implements Action {
    readonly type = SET_IS_ENEL_WELFARE_TEACHER;
}
export class SetIsEnelWelfareSupport implements Action {
    readonly type = SET_IS_ENEL_WELFARE_SUPPORT;
}

export class SetIsHeritageTaker implements Action {
    readonly type = SET_IS_HERITAGE_TAKER;
}

export class SetIsDEITaker implements Action {
    readonly type = SET_IS_DEI_TAKER;
}

export class SetIsManager implements Action {
    readonly type = SET_IS_MANAGER;
}

export class SetIsAdmin implements Action {
    readonly type = SET_IS_ADMIN;
}

export class SetIsPerformance implements Action {
    readonly type = SET_IS_PERFORMANCE;
}

export class Logout implements Action {
    readonly type = LOGOUT;
}

export class SetUserAuthenticated implements Action {
    readonly type = SET_USER_AUTHENTICATED;
    // Payload dell'azione
    constructor(
        public payload?: {
            isSupplier?: boolean;
            isFundingSupplier?: boolean,
            isTaker?: boolean;
            isWelionTs?: boolean;
            isEuropeAssistance?: boolean;
            isOwnership?: boolean;
            isWelfareEnel?: boolean;
            isWelfareEnelSso?: boolean;
            isManager?: boolean;
            isAdmin?: boolean;
            isMeetingUser?: boolean;
            isOnlineMeetingUser?: boolean;
            isWelionWelfarePublicMeetingUser?: boolean;
            isPublicMeetingUser?: boolean;
            isOnlineMeetingTeacher?: boolean;
            isOnlineMeetingSupport?: boolean;
            isPerformance?: boolean;
            isWelionWelfareTeacher?: boolean;
            isWelionWelfareSupport?: boolean;
            isEnelWelfareTeacher?: boolean;
            isEnelWelfareSupport?: boolean;
            isSmartEnergyTaker?: boolean;
            isSmartEnergyTakerSso?: boolean;
            userId?: string;
            isNudgingUser?: boolean;
            isHeritageTaker?: boolean;
        }
    ) { }
}

export class SetToken implements Action {
    readonly type = SET_TOKEN;

    // Payload dell'azione
    constructor(public payload: string) { }
}


// Definisco un tipo custom per eseguire il bundle delle azioni in un singolo export
export type AuthActions =
    | Login
    | Logout
    | SessionLogout
    | SetToken
    | SetUserAuthenticated
    | SetIsSupplier
    | SetIsFundingSupplier
    | SetIsTaker
    | SetIsEuropeAssistance
    | SetIsWelionTs
    | SetIsOwnership
    | SetIsWelfareEnel
    | SetIsMeetingUser
    | SetIsOnlineMeetingUser
    | SetIsPublicMeetingUser
    | SetIsWelionWelfarePublicMeetingUser
    | SetIsOnlineMeetingTeacher
    | SetIsWelionWelfareTeacher
    | SetIsWelionWelfareSupport
    | SetIsEnelWelfareTeacher
    | SetIsEnelWelfareSupport
    | SetIsOnlineMeetingSupport
    | SetIsManager
    | SetIsAdmin
    | SetIsPerformance
    | DoLogin
    | SetIsSmartEnergyTaker
    | SetIsHeritageTaker
    | SetIsDEITaker;
